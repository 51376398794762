import NavigationContent from "../NavigationContent/NavigationContent";
import IconsContent from "../../Assets/img/IconsContent";
import SlideToggle from "react-slide-toggle";
import { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'
import { InputSelect } from "../Select/InputSelect";
import { UserService } from "../../../Main/Services/UserService";
import Preloader from "../Preloader/Preloader";
import Modal from "../Modal";
import { Link } from "react-router-dom";
import ModalTranslator from "../Modal/ModalTranslator";
import ModalAddDataAcceptedNPA from "../Modal/ModalAddDataAcceptedNPA";
import ModalAddRiskFactorsNPA from "../Modal/ModalAddRiskFactorsNPA";
import { useTranslation } from "react-i18next";
import ModalExpert from "../Modal/ModalExpert";
import ChangeProject from "../ChangeProject/ChangeProject";


export default function FormViewContainer({ project, expertsList, correctorsList, translatorsList, projectId, coordinatorsList }) {
    const { t, i18n } = useTranslation("common");

    const [isShowModalCoordinator, setIsShowModalCoordinator] = useState(false);
    const [onClearCoordinator, setOnClearCoordinator] = useState(false);
    const [isShowModalAddExpert, setIsShowModalAddExpert] = useState(false)
    const [isShowModalAddNPAData, setIsShowModalAddNPAData] = useState(false)
    const [isShowModalAddRiskFactorsNPA, setIsShowModalAddRiskFactorsNPA] = useState(false)
    const [isExpertAdded, setIsExpertAdded] = useState(false)
    const [onClear, setOnClear] = useState(false)
    const [changeCorrectorModal, setChangeCorrectorModal] = useState(false)
    const [changeTranslatorModal, setChangeTranslatorModal] = useState(false)
    const [personCorrector, setPersonCorrector] = useState()
    const [expertRecomended, setExpertRecomended] = useState()
    const [person1, setPerson1] = useState();
    const [isShowModalError, setIsShowModalError] = useState(false);
    const userLocation = localStorage.getItem('.UserLocation')
    const [newPlayAnimation, setNewPlayAnimation] = useState(false)
    const [editProject, setEditProject] = useState(false)
    const { register, reset, control, handleSubmit, formState: { errors } } = useForm({ mode: 'onBlur' })

    const getExpertRecomendName = () => {
        setNewPlayAnimation(true)
        UserService.getExpertRecomend(projectId).then((response) => {
            console.log(response);
            setNewPlayAnimation(false)
            // setExpertRecomendedID(response?.data?.data?.id)
            setExpertRecomended(response.data.data);
            setIsShowModalAddExpert(true)
            // setExpertRecomendedCount(response?.data?.data?.count)

        }).catch(err => { setNewPlayAnimation(false); setIsShowModalError(true) })


    }

    return (
        <div className={'projects_page conclusionCoordinatorDetails__page '}>
            {newPlayAnimation && <Preloader playAnimation={false} />}
            <NavigationContent direct={[{ goTo: `${userLocation}`, name: t("main") }, {
                goTo: `${userLocation}`,
                name: t("project")
            }, { goTo: `${userLocation}/projects/1`, name: t("form_view") }]}
            // name={t("project")} 
            />


            <main className="main" style={{ padding: "10px 30px" }}>

                <div className="main_header" style={{ justifyContent: "space-between" }}>
                    <h2>{t("project_view_form")}
                        {((userLocation == "/coordinator" || userLocation == "/clerk") && project.status?.id !== 7) && <span style={{ cursor: "pointer" }} onClick={() => setEditProject(!editProject)}>{IconsContent.EditUser()}</span>}
                    </h2>
                    {userLocation == '/coordinator' && project.status?.id == 7 ?
                        <div className="header__content">
                            <button className="header__content-correct">{t("add_opinion")}{IconsContent.FileSignature()}</button>

                            <button className="header__content-accept" onClick={() => setIsShowModalAddNPAData(true)}>{t("notice_npa_data")}{IconsContent.Calculator()}</button>
                            {project.published_at == null ?
                                <button className="header__content-approve " onClick={() => {
                                    setNewPlayAnimation(true);
                                    UserService.putPublush(project.id, { publish: 1 })
                                        .then(() => { setNewPlayAnimation(false); setIsExpertAdded(true) })
                                        .catch(err => { setNewPlayAnimation(false); setIsShowModalError(true) })
                                }}>
                                    {t("publish on an external portal")} {IconsContent.Globe()}
                                </button> :
                                <button className="header__content-approve " onClick={() => {
                                    setNewPlayAnimation(true); UserService.putPublush(project.id, { publish: 0 })
                                        .then(() => { setNewPlayAnimation(false); setIsExpertAdded(true) })
                                        .catch(err => { setNewPlayAnimation(false); setIsShowModalError(true) })
                                }}>
                                    {t("remove from external portal")}{IconsContent.Globe()}
                                </button>}
                        </div> : null}
                    {userLocation == '/coordinator' && project.status?.id == 1 ?
                        <div className="header__content">
                            <span className='conclusion-finish' style={{ cursor: "pointer" }} onClick={() => { setIsShowModalCoordinator(true); setOnClearCoordinator(false); }}>{IconsContent.Users()} {t("add_coordinator")}</span>
                        </div> : null
                    }
                </div>
                <div className="view_form__content">
                    <div className="content_left">
                        <div className="logs">
                            <div className=" conclusionCoordinatorDetails__value project_details">
                                <h5 >{t("responsible_coordinator")}: <span>{person1 ? person1.label : project.coordinator ? project.coordinator.full_name : "не выбран"}</span></h5>
                                <div className='conclusionCoordinatorDetails__value-info'>
                                    <p> {t("project_id")}: <span>{project.id}</span> </p>
                                    <p>{t("project_created_at")}: <span>{project?.created_at}</span></p>
                                    <p>{t("project_files_ru")}: <a href={project.locales?.archive?.kk}>Скачать {IconsContent.DownLoad()}</a></p>
                                    <p>{t("project_files_kk")}: <a href={project.locales?.archive?.ru}>Скачать {IconsContent.DownLoad()}</a></p>
                                    <p>Статус: <span>{project?.status.locales.name[i18n.language]}</span></p>
                                    {userLocation == '/coordinator' && project.status?.id == 7 && project.published_at && <p style={{ display: "flex", alignItems: "center" }}>{t("risk_factor_accepted")}: <span>не отмечено</span>
                                        <button className="header__content-upgrade" style={{ width: "fit-content", alignSelf: "right" }} onClick={() => setIsShowModalAddRiskFactorsNPA(true)}>Указать{IconsContent.Calculator()}</button>
                                    </p>}
                                </div>
                            </div>
                        </div>

                        {/* <div className="other_inforamtions">
                            <div className="get_inform">
                                <div className="inform_name">
                                    <h5>{t("incoming_letter_number")}</h5>
                                </div>
                                <div className="inform_value">
                                    <p>{project?.incoming_letter_number}</p>
                                </div>
                            </div>
                            <div className="get_inform">
                                <div className="inform_name">
                                    <h5>{t("outgoing_letter_number")}</h5>
                                </div>
                                <div className="inform_value">
                                    <p>{project?.outgoing_letter_number}</p>
                                </div>
                            </div>
                            <div className="get_inform">
                                <div className="inform_name">
                                    <h5>{t("outgoing_mail_date")}</h5>
                                </div>
                                <div className="inform_value">
                                    <p>{project?.outgoing_mail_date}</p>
                                </div>
                            </div>
                            <div className="get_inform">
                                <div className="inform_name">
                                    <h5>{t("date_of_receipt_of_the_draft_regulatory_legal_act")}</h5>
                                </div>
                                <div className="inform_value">
                                    <p>{project?.date_of_receipt_of_the_draft_regulatory_legal_act}</p>
                                </div>
                            </div>
                            <div className="get_inform">
                                <div className="inform_name">
                                    <h5>{t("document_type")}</h5>
                                </div>
                                <div className="inform_value">
                                    <p>{project?.document_type !== null ? project?.document_type.name : 'Нет данных'}</p>
                                </div>
                            </div>
                            <div className="get_inform">
                                    <div className="inform_name">
                                        <h5>{t("name")}</h5>
                                    </div>
                                    <div className="inform_value">
                                        <p>{project?.locales.name[i18n.language]}</p>
                                    </div>
                            </div>
                            <div className="get_inform">
                                <div className="inform_name">
                                    <h5>{t("branch_of_legiclation")}</h5>
                                </div>
                                <div className="inform_value">
                                    {project?.branch_of_legislations.map((item, index) =>
                                        <p key={index}>{item?.locales.name[i18n.language]}</p>)}
                                </div>
                            </div>
                            <div className="get_inform">
                                <div className="inform_name">
                                    <h5>{t("purpose_of_act")}</h5>
                                </div>
                                <div className="inform_value">
                                    <p>{project?.locales.purpose_and_structure_of_the_draft_regulatory_legal_act[i18n.language]}</p>
                                </div>
                            </div>
                            <div className="get_inform">
                                <div className="inform_name">
                                    <h5>{t("authority_developer")}</h5>
                                </div>
                                <div className="inform_value">
                                    <p>{project?.authority_developer.locales.name[i18n.language]}</p>
                                </div>
                            </div>
                            <div className="get_inform">
                                <div className="inform_name">
                                    <h5>{t("documents_kk")}</h5>
                                </div>
                                <div className="inform_value">
                                    <p>{project?.locales.documents_submitted_for_scientific_anti_corruption_expertise[i18n.language]}</p>
                                </div>
                            </div>
                            <div className="get_inform">
                                <div className="inform_name">
                                    <h5>{t("documents_ru")}</h5>
                                </div>
                                <div className="inform_value">
                                    <p>{project?.locales.documents_submitted_for_scientific_anti_corruption_expertise[i18n.language]}</p>
                                </div>
                            </div>
                            <div className="get_inform">
                                <div className="inform_name">
                                    <h5>{t("object_kk")}</h5>
                                </div>
                                <div className="inform_value">
                                    <p>{project?.locales.the_subject_and_goals_of_scientific_anti_corruption_expertise[i18n.language]}</p>
                                </div>
                            </div>
                            <div className="get_inform">
                                <div className="inform_name">
                                    <h5>{t("object_ru")}</h5>
                                </div>
                                <div className="inform_value">
                                    <p>{project?.locales.the_subject_and_goals_of_scientific_anti_corruption_expertise[i18n.language]}</p>
                                </div>
                            </div>
                            <div className="get_inform">
                                <div className="inform_name">
                                    <h5>{t("number_of_pages")}</h5>
                                </div>
                                <div className="inform_value">
                                    <p>{project?.number_of_pages}</p>
                                </div>
                            </div>
                            <div className="get_inform">
                                <div className="inform_name">
                                    <h5>Email</h5>
                                </div>
                                <div className="inform_value">
                                    <p>{project?.email}</p>
                                </div>
                            </div>

                        </div> */}

                        <ChangeProject project={project} editProject={editProject} />

                        {/* <div className="logs">
                            <div className="logs_header">
                                <p>Логи</p>
                            </div>

                            <SlideToggle>
                                {({ toggle, setCollapsibleElement }) => (
                                    <div className="my-collapsible">
                                        <button onClick={toggle}>
                                            <span>Молдабек Сырым Пернебекұлы (Изменено)</span>
                                            <span>2022-07-05 18:25:09 {IconsContent.ArrUp()}</span>
                                        </button>
                                        <div className={`hidden_information my-collapsible__content`} ref={setCollapsibleElement}>
                                            <p className="date">12:41:01</p>
                                            <div className="information_doc">
                                                <p className="header">Тип документа: </p>
                                                <p className="description">Нормативные правовые постановления Национального Банка Республики Казахстан и иных центральных государственных органов</p>
                                            </div>
                                            <br />
                                            <div className="information_doc" style={{ marginBottom: 28 }}>
                                                <p className="header">Тип документа: </p>
                                                <p className="description">Нормативные правовые постановления Национального Банка Республики Казахстан и иных центральных государственных органов</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </SlideToggle>
                        </div> */}
                    </div>
                    <div className="content_right">
                        {project?.conclusions.length == 0 ?
                            <div className="inform">
                                <div className="inform_name">
                                    <h5>{t("expertise")}</h5>
                                </div>
                                <div className="inform_value">
                                    <p>{t("expertise")}</p>
                                </div>

                                {userLocation == "/coordinator" && project.status.id == 2 ?
                                    <button className="addExpert" onClick={() => getExpertRecomendName()}>
                                        <span>{t("assign_expert")}</span>
                                        {IconsContent.UserPlus()}
                                    </button> : null
                                }
                            </div> :

                            <div className="inform">
                                <div className="inform_name">
                                    <h5>{t("expertise_data")}</h5>
                                    {userLocation == '/coordinator' && project.status.id == 7 ? <a href={project.final_conclusion}> <button className="addExpert" >
                                        Загрузить итоговое заключение{IconsContent.File()}</button></a> : null}


                                    {/* onClick={()=>UserService.getFinalConclusion(projectId).then()} */}

                                    {userLocation == '/coordinator' && project.status.id !== 7 && <div className="inform-name__buttons">
                                        <button onClick={() => setChangeCorrectorModal(true)}>{t("change_corrector")}</button>
                                        <button onClick={() => setChangeTranslatorModal(true)}>{t("change_translator")}</button>
                                    </div>
                                    }
                                </div>


                                {userLocation == '/expert' ?
                                    project?.conclusions.map(item => {
                                        if (item.expert.id == localStorage.getItem('.UserID')) {
                                            return (
                                                <div className=" conclusionCoordinatorDetails__value">
                                                    <h5 >{t("expert")}: <span>{item.expert?.full_name}</span></h5>
                                                    <div className='conclusionCoordinatorDetails__value-info'>
                                                        <p style={{ display: "flex", justifyContent: "space-between" }}> {t("conclusions_id")}: {item.id}
                                                            <button className="reference" style={{ backgroundColor: "var(--color-aquamarine)" }}>
                                                                <Link to={`${userLocation}/conclusions/details/${item?.id}`}>Перейти в заключение</Link>
                                                            </button>
                                                        </p>
                                                        <p>{t("responsible_corrector")}: <span>{project.corrector !== null ? project.corrector?.full_name : "Нет данных"}</span></p>
                                                        <p>{t("responsible_translator")}: <span>{project.translator !== null ? project.translator?.full_name : "Нет данных"}</span></p>
                                                        <p>{t("deadline")}: <span>{item.deadline}</span></p>
                                                        <p>Статус: <span>{item.status.name}</span></p>
                                                    </div>
                                                </div>)
                                        }
                                    }) : project?.conclusions.map(item =>
                                        <div className=" conclusionCoordinatorDetails__value">
                                            <h5 >{t("expert")}: <span>{item.expert?.full_name}</span></h5>
                                            <p style={{ display: "flex", justifyContent: "right", margin: "0 0 -35px 0" }}>
                                                <button className="addExpert" style={{ backgroundColor: "var(--color-aquamarine)", padding: "8px", marginTop: "5px" }}>
                                                    <Link to={`${userLocation}/conclusions/details/${item?.id}`}>{t("go_to_conclusion")}</Link>
                                                </button>
                                            </p>
                                            <div className='conclusionCoordinatorDetails__value-info'>
                                                <p> {t("conclusions_id")}: {item.id} </p>
                                                <p>{t("responsible_corrector")}: <span>{project.corrector !== null ? project.corrector?.full_name : "Нет данных"}</span></p>
                                                <p>{t("responsible_translator")}: <span>{project.translator !== null ? project.translator?.full_name : "Нет данных"}</span></p>
                                                <p>{t("deadline")}: <span>{item.deadline}</span></p>
                                                <p>Статус: <span>{item.status.locales.name[i18n.language]}</span></p>
                                            </div>
                                        </div>)}

                                {userLocation == '/coordinator' && project.status.id !== 7 ?
                                    <button className="addExpert" onClick={() => getExpertRecomendName()}>
                                        <span >{t("add_expert")}</span>
                                        {IconsContent.UserPlus()}
                                    </button> : null
                                }
                            </div>
                        }


                        <div className="inform">
                            <div className="inform_name">
                                <h5>{t("re-examination")}</h5>
                            </div>
                            {project?.re_examination ? <div className="inform_value">
                                <p>{t("extra_expertise_assigned")}</p>
                            </div> :
                                <div className="inform_value">
                                    <p>{t("extra_expertise_not_assigned")}</p>
                                </div>}

                        </div>

                        <div className="inform">
                            <div className="inform_name">
                                <h5>{t("note")}</h5>
                            </div>
                            <div className="inform_value">
                                <p>{project?.note ? project?.note : t("no_comments")}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal changeCorrectorModal={changeCorrectorModal} setChangeCorrectorModal={setChangeCorrectorModal} setOnClear={setOnClear} projectId={projectId} setIsShowModalAddExpert={setIsShowModalAddExpert} setIsExpertAdded={setIsExpertAdded} IconsContent={IconsContent} onClear={onClear} correctorsList={correctorsList} setPersonCorrector={setPersonCorrector} />
                <ModalTranslator changeTranslatorModal={changeTranslatorModal} setChangeTranslatorModal={setChangeTranslatorModal} setOnClear={setOnClear} projectId={projectId} setIsShowModalAddExpert={setIsShowModalAddExpert} setIsExpertAdded={setIsExpertAdded} IconsContent={IconsContent} onClear={onClear} translatorsList={translatorsList} setPersonCorrector={setPersonCorrector} />

                {isShowModalAddExpert && <ModalExpert
                    project={project}
                    expertsList={expertsList}
                    isShowModalAddExpert={isShowModalAddExpert}
                    setIsShowModalAddExpert={setIsShowModalAddExpert}
                    projectId={projectId}
                    correctorsList={correctorsList}
                    translatorsList={translatorsList}
                    setIsExpertAdded={setIsExpertAdded}
                    expertRecomended={expertRecomended}
                    setNewPlayAnimation={setNewPlayAnimation}
                />}
                {isShowModalAddNPAData && <ModalAddDataAcceptedNPA setNewPlayAnimation={setNewPlayAnimation} isShowModalAddNPAData={isShowModalAddNPAData} setIsShowModalAddNPAData={setIsShowModalAddNPAData} setIsExpertAdded={setIsExpertAdded} setIsShowModalError={setIsShowModalError} projectId={projectId} />}
                {isShowModalAddRiskFactorsNPA && <ModalAddRiskFactorsNPA setNewPlayAnimation={setNewPlayAnimation} isShowModalAddRiskFactorsNPA={isShowModalAddRiskFactorsNPA} setIsShowModalAddRiskFactorsNPA={setIsShowModalAddRiskFactorsNPA} setIsExpertAdded={setIsExpertAdded} setIsShowModalError={setIsShowModalError} projectId={projectId} project={project} />}

                <div className={isExpertAdded ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                    <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
                        <p>
                            <span onClick={() => { setIsExpertAdded(false); window.location.reload() }}>{IconsContent.Close()}</span>
                        </p>
                        <div>
                            <p>{IconsContent.DocCreated()}</p>
                            <p>Данные добавлены</p>
                            <button onClick={() => { setIsExpertAdded(false); window.location.reload() }}>Закрыть</button>
                        </div>
                    </div>
                </div>

                < div className={isShowModalCoordinator ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"} >
                    <div className="transmittal-letters__modal-content" onClick={(e) => e.stopPropagation()}>
                        <p className="transmittal-letters__modal-heading">
                            <span>Выберите ответственного координатора</span>
                            <span
                                onClick={() => {
                                    setIsShowModalCoordinator(false);
                                    setOnClearCoordinator(true);
                                }}
                            >
                                {IconsContent.Close()}
                            </span>
                        </p>
                        <div className="input_wrapper input_wrapper-addProject">
                            <label htmlFor="">ФИО</label>
                            <InputSelect
                                control={control}
                                noRequired
                                name="coordinator_id"
                                placeholder="Выберите "
                                onClear={onClearCoordinator}
                                requiredMessage="Выберите координатора"
                                options={coordinatorsList}
                                selectedValue={(arg) => setPerson1(arg)}
                            />
                        </div>
                        <div className="btn_action">
                            <button
                                className="btn_assign"
                                onClick={() => {
                                    setNewPlayAnimation(true);
                                    setIsShowModalCoordinator(false);
                                    UserService.putExpert({ coordinator_id: person1.value }, projectId)
                                        .then(() => { setIsExpertAdded(true); setNewPlayAnimation(false) })
                                        .catch(err => { console.log(err); setNewPlayAnimation(false); setIsShowModalError(true) })
                                }}
                            >
                                Назначить
                            </button>
                        </div>
                    </div>
                </div >

                <div className={isShowModalError ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                    <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
                        <p>
                            <span onClick={() => setIsShowModalError(false)}>
                                {IconsContent.Close()}
                            </span>
                        </p>
                        <div>
                            <p>{IconsContent.Error()}</p>
                            <p>Произошла ошибка</p>
                            <button onClick={() => {
                                setIsShowModalError(false);
                                // window.location.reload();
                            }}>
                                Закрыть
                            </button>
                        </div>
                    </div>
                </div>
            </main >
        </div >
    )
}