import NavigationContent from "../../NavigationContent/NavigationContent";
import IconsContent from "../../../Assets/img/IconsContent";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import DatePickerInput from "../../DatePicker/DatePicker";
import { UserService } from "../../../../Main/Services/UserService";
import { InputSelect } from "../../Select/InputSelect";
import Select from "react-select";
import { convertObjectToFormData } from "../../../../Main/Services/utils";
import Preloader from "../../Preloader/Preloader";
import { useTranslation } from "react-i18next";





export default function AddProjectContainer({ branchOfLegislation, authorityDevelopers, correctorsList, coordinatorsList, translatorsList, documentTypes }) {
  const { t, i18n } = useTranslation("common")
  const navigate = useNavigate()
  const { register, handleSubmit, control, reset, formState: { errors } } = useForm({ mode: "onBlur" });

  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalDocCreated, setIsShowModalDocCreated] = useState(false);
  const [isShowModalError, setIsShowModalError] = useState(false);
  const [fileName, setFileName] = useState();
  const [fileName2, setFileName2] = useState();
  const [fileName3, setFileName3] = useState();
  const [person1, setPerson1] = useState();
  const [error, setError] = useState(false)
  const [projectData, setProjectData] = useState()

  const onSubmit = (newProjectData) => {

    newProjectData = {
      ...newProjectData,
      uploads_ru: newProjectData.uploads_ru,
      uploads_kk: newProjectData.uploads_kk,
      uploads_ai: [
        { content: newProjectData.uploads_ai[0].content.Filelist[0] },
      ],
    };
    const formData = convertObjectToFormData(newProjectData);
    setProjectData(formData)
    setIsShowModalConfirm(true)
    // reset();
  };


  const [newPlayAnimation, setNewPlayAnimation] = useState(false)

  const clickToAccept = async () => {
    setNewPlayAnimation(true);
    setIsShowModalConfirm(false);
    await UserService.addProjects(projectData).then((response) => {
      setIsShowModalDocCreated(true);;
    }).catch(err => { setIsShowModalError(true); setError(err.data) })
    setIsShowModalConfirm(false);
    setNewPlayAnimation(false);
  }

  return (
    <div className={"addProject_page transmittal-letters__form  "}>
      {newPlayAnimation && <Preloader playAnimation={false} />}
      <NavigationContent
        direct={[
          { goTo: `/clerk`, name: t("main") },
          { goTo: `/clerk`, name: t("project") },
          { goTo: `/clerk/projects/1`, name: t("add_project") },
        ]}
        name={t("add_new_project")}
      />
      <main>
        <div className="required">
          <p>{t("required_field")}*</p>

          <form className={"form_catcher"} onSubmit={handleSubmit(onSubmit)}>
            <input style={{ visibility: "hidden" }} value={1}{...register("status_id")} />
            <div className="input_wrapper">
              <label htmlFor="">{t("re-examination")}*</label>
              {/* <input type="text" className={errors.re_examination ? "form-error-border-color" : ""} {...register("re_examination", { required: "Заполните обязательное поле", })} /> */}
              <InputSelect
                control={control}
                name="re_examination"
                placeholder={t("placeholder_select")}
                options={[
                  { value: "1", label: "Да" },
                  { value: "0", label: "Нет" },
                ]}
              />
              {errors.re_examination && <p className="form--required">{errors.re_examination.message}</p>}
            </div>
            <div className="input_wrapper">
              <label htmlFor="">{t("incoming_letter_number")}*</label>
              <input
                type="text"
                className={
                  errors.incoming_letter_number ? "form-error-border-color" : ""
                }
                {...register("incoming_letter_number", {
                  required: "Заполните обязательное поле",
                })}
              />
              {errors.incoming_letter_number && (<p className="form--required">{errors.incoming_letter_number.message}</p>)}
            </div>
            <div className="input_wrapper">
              <label htmlFor="">{t("outgoing_letter_number")}*</label>
              <input
                type="text"
                className={
                  errors.outgoing_letter_number ? "form-error-border-color" : ""
                }
                {...register("outgoing_letter_number", {
                  required: "Заполните обязательное поле",
                })}
              />
              {errors.outgoing_letter_number && (<p className="form--required">{errors.outgoing_letter_number.message}</p>)}
            </div>
            <div className="input_wrapper">
              <label htmlFor="">{t("outgoing_mail_date")} *</label>
              <DatePickerInput name="outgoing_mail_date" control={control} />
              {errors.outgoing_mail_date && (<p className="form--required">{errors.outgoing_mail_date.message}</p>)}
            </div>
            <div className="input_wrapper">
              <label htmlFor="">
                {t("date_of_receipt_of_the_draft_regulatory_legal_act")} *
              </label>
              <DatePickerInput
                name="date_of_receipt_of_the_draft_regulatory_legal_act"
                control={control}
              />
              {errors.date_of_receipt_of_the_draft_regulatory_legal_act && (<p className="form--required">{errors.date_of_receipt_of_the_draft_regulatory_legal_act.message}</p>)}
            </div>
            <div className="input_wrapper">
              <label htmlFor="">{t("document_type")}*</label>
              <InputSelect
                control={control}name="document_type_id"placeholder={t("placeholder_select")}options={documentTypes}/>
              {errors.document_type_id && (
                <p className="form--required">
                  {errors.document_type_id.message}
                </p>
              )}
            </div>
            <div className="input_wrapper">
              <label htmlFor="">
                {t("name_kk")}*
              </label>
              <input
                type="text"
                className={errors.name_kk ? "form-error-border-color" : ""}
                {...register("name_kk", {
                  required: "Заполните обязательное поле",
                })}
              />
              {errors.name_kk && (
                <p className="form--required">{errors.name_kk.message}</p>
              )}
            </div>
            <div className="input_wrapper">
              <label htmlFor="">
                {t("name_ru")} *
              </label>
              <input
                type="text"
                className={errors.name_ru ? "form-error-border-color" : ""}
                {...register("name_ru", {
                  required: "Заполните обязательное поле",
                })}
              />
              {errors.name_ru && (
                <p className="form--required">{errors.name_ru.message}</p>
              )}
            </div>
            <div className="input_wrapper">
              <label htmlFor="">{t("branch_of_legiclation")} *</label>
              <Controller
                name="branch_of_legislations"
                control={control}
                rules={{ required: "Выберите поле" }}
                render={({ field: { onChange, ref, value } }) =>
                  <Select
                    isMulti
                    ref={ref}
                    options={branchOfLegislation}
                    value={branchOfLegislation?.find((c) => c.value === value)}
                    onChange={(val) => onChange(val.map((c) => c.value))}
                    classNamePrefix="custom-select"
                    placeholder={t("placeholder_select")}

                  />
                } />
              {errors.branch_of_legislation_id && <p className="form--required">{errors.branch_of_legislation_id.message}</p>}
            </div>
            <div className="input_wrapper">
              <label htmlFor="">
                {t("purpose_of_act_kk")} *
              </label>
              <input
                type="text"
                className={
                  errors.purpose_and_structure_of_the_draft_regulatory_legal_act_kk
                    ? "form-error-border-color"
                    : ""
                }
                {...register(
                  "purpose_and_structure_of_the_draft_regulatory_legal_act_kk",
                  { required: "Заполните обязательное поле" }
                )}
              />
              {errors.purpose_and_structure_of_the_draft_regulatory_legal_act_kk && (
                <p className="form--required">
                  {
                    errors
                      .purpose_and_structure_of_the_draft_regulatory_legal_act_kk
                      .message
                  }
                </p>
              )}
            </div>
            <div className="input_wrapper">
              <label htmlFor="">
                {t("purpose_of_act_ru")} *
              </label>
              <input
                type="text"
                className={
                  errors.purpose_and_structure_of_the_draft_regulatory_legal_act_ru
                    ? "form-error-border-color"
                    : ""
                }
                {...register(
                  "purpose_and_structure_of_the_draft_regulatory_legal_act_ru",
                  { required: "Заполните обязательное поле" }
                )}
              />
              {errors.purpose_and_structure_of_the_draft_regulatory_legal_act_ru && (
                <p className="form--required">
                  {
                    errors
                      .purpose_and_structure_of_the_draft_regulatory_legal_act_ru
                      .message
                  }
                </p>
              )}
            </div>
            <div className="input_wrapper">
              <label htmlFor="">
                {t("documents_kk")} *
              </label>
              <input
                type="text"
                className={
                  errors.documents_submitted_for_scientific_anti_corruption_expertise_kk
                    ? "form-error-border-color"
                    : ""
                }
                {...register(
                  "documents_submitted_for_scientific_anti_corruption_expertise_kk",
                  { required: "Заполните обязательное поле" }
                )}
              />
              {errors.documents_submitted_for_scientific_anti_corruption_expertise_kk && (
                <p className="form--required">
                  {
                    errors
                      .documents_submitted_for_scientific_anti_corruption_expertise_kk
                      .message
                  }
                </p>
              )}
            </div>
            <div className="input_wrapper">
              <label htmlFor="">
                {t("documents_ru")} *
              </label>
              <input
                type="text"
                className={
                  errors.documents_submitted_for_scientific_anti_corruption_expertise_ru
                    ? "form-error-border-color"
                    : ""
                }
                {...register(
                  "documents_submitted_for_scientific_anti_corruption_expertise_ru",
                  { required: "Заполните обязательное поле" }
                )}
              />
              {errors.documents_submitted_for_scientific_anti_corruption_expertise_ru && (
                <p className="form--required">
                  {
                    errors
                      .documents_submitted_for_scientific_anti_corruption_expertise_ru
                      .message
                  }
                </p>
              )}
            </div>
            <div className="input_wrapper">
              <label htmlFor="">
                {t("object_kk")}
                *
              </label>
              <input
                type="text"
                className={
                  errors.the_subject_and_goals_of_scientific_anti_corruption_expertise_kk
                    ? "form-error-border-color"
                    : ""
                }
                {...register(
                  "the_subject_and_goals_of_scientific_anti_corruption_expertise_kk",
                  { required: "Заполните обязательное поле" }
                )}
              />
              {errors.the_subject_and_goals_of_scientific_anti_corruption_expertise_kk && (
                <p className="form--required">
                  {
                    errors
                      .the_subject_and_goals_of_scientific_anti_corruption_expertise_kk
                      .message
                  }
                </p>
              )}
            </div>
            <div className="input_wrapper">
              <label htmlFor="">
                {t("object_ru")} *
              </label>
              <input
                type="text"
                className={
                  errors.the_subject_and_goals_of_scientific_anti_corruption_expertise_ru
                    ? "form-error-border-color"
                    : ""
                }
                {...register(
                  "the_subject_and_goals_of_scientific_anti_corruption_expertise_ru",
                  { required: "Заполните обязательное поле" }
                )}
              />
              {errors.the_subject_and_goals_of_scientific_anti_corruption_expertise_ru && (
                <p className="form--required">
                  {
                    errors
                      .the_subject_and_goals_of_scientific_anti_corruption_expertise_ru
                      .message
                  }
                </p>
              )}
            </div>
            <div className="input_wrapper">
              <label htmlFor="">{t("number_of_pages")}</label>
              <input type="number" {...register("number_of_pages")} />
            </div>

            <div className="input_wrapper input_wrapper-upload">
              <label className={"label"}>{t("project_files_kk")}</label>
              <input className="file__upload--input" multiple type="file" id={"file"}
                {...register("uploads_kk", { required: "Введите обязательное поле", onChange: (e) => setFileName(e.target.files) })} />
              <div >
                <label htmlFor="file" className="file__upload--button">{t("choose_file")}{IconsContent.PaperClip()}</label>
                <p >{fileName && Object.keys(fileName).map(val => <span >{fileName[val].name}</span>)}</p>
              </div>
            </div>

            <div className="input_wrapper input_wrapper-upload">
              <label className={"label"}>{t("project_files_ru")}</label>
              <input className="file__upload--input" multiple type="file" id={"file2"}
                {...register("uploads_ru", { required: "Введите обязательное поле", onChange: (e) => setFileName2(e.target.files) })} />
              <div >
                <label htmlFor="file2" className="file__upload--button">{t("choose_file")}{IconsContent.PaperClip()}</label>
                <p >{fileName2 && Object.keys(fileName2).map(val => <span >{fileName2[val].name}</span>)}</p>
              </div>
            </div>


            <div className="input_wrapper">
              <label htmlFor="">{t("authority_developer")} *</label>
              <InputSelect
                control={control}
                name="authority_developer_id"
                placeholder={t("placeholder_select")}
                options={authorityDevelopers}
              />
              {errors.authority_developer_id && (
                <p className="form--required">
                  {errors.authority_developer_id.message}
                </p>
              )}
            </div>

            <div className="input_wrapper">
              <label htmlFor="">{t("note")}</label>
              <input type="text" {...register("note")} />
            </div>

            <div className="input_wrapper">
              <label htmlFor="">Email</label>
              <input type="text" className={errors.email ? "form-error-border-color" : ""}
                {...register("email", {
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Введенный email не корректен",
                  }
                })} />
              {errors.email && (<p className="form--required">{errors.email.message}</p>)}
            </div>

            <div className="input_wrapper input_wrapper-upload">
              <label className={"label"}>{t("ai_file")}</label>
              {/* <div className="inner_wrapper">
                <input
                  // multiple
                  type="file"
                  id={"file3"}
                  accept={".doc, .docx"}
                  {...register("uploads_ai.0.content.Filelist", {
                    required: "Введите обязательное поле",
                    onChange: (e) => setFileName3(e.target.files),
                  })}
                />
                <label htmlFor={"file3"} className={"file_contain"}>
                  <div className={"file_put"}>
                    <span>{t("choose_main_file")}</span>
                    {IconsContent.PaperClip()}

                  </div>
                  <span className={"span"}>
                    {fileName3
                      ? Object.keys(fileName3).map(val => fileName3[val].name)
                      : t("choose_docx_file")}
                  </span>
                </label>
              </div> */}

              <input className="file__upload--input" type="file" id={"file3"} accept={".docx"}
                {...register("uploads_ai.0.content.Filelist", { required: "Введите обязательное поле", onChange: (e) => setFileName3(e.target.files) })} />
              <div >
                <label htmlFor="file3" style={{ backgroundColor: "var(--color-red)" }} className="file__upload--button">{t("choose_main_file")}{IconsContent.PaperClip()}</label>
                <p >{fileName3 && Object.keys(fileName3).map(val => <span style={{ color: "var(--color-red)" }}>{fileName3[val].name}</span>)}</p>
              </div>
            </div>
            <div className="save">
              <button className="save_push" >{t("add_project")}</button>
            </div>
          </form >
        </div>
      </main >

      {/* Выберите ответственного координатора */}

      <div className={isShowModalConfirm ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
        <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
          <p>
            <span onClick={() => setIsShowModalConfirm(false)}>{IconsContent.Close()}</span>
          </p>
          <div>
            <p>{IconsContent.Ask()}</p>
            <p style={{ color: '#0D152C', marginTop: "20px", fontSize: "18px", fontWeight: "500" }}>Вы действительно хотите добавить новый проект?</p>
            <div className='isShowModalDeleteUserConfirm'>
              <button onClick={() => clickToAccept()}
              >Подтвердить</button>
              <button type="button" onClick={() => setIsShowModalConfirm(true)}>Закрыть</button>
            </div>
          </div>
        </div>
      </div>

      {/* < div className={isShowModalCoordinator ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"} >
        <div className="transmittal-letters__modal-content" onClick={(e) => e.stopPropagation()}>
          <p className="transmittal-letters__modal-heading">
            <span>Выберите ответственного координатора</span>
            <span
              onClick={() => {
                setIsShowModalCoordinator(false);
                setOnClearCoordinator(true);
              }}
            >
              {IconsContent.Close()}
            </span>
          </p>
          <div className="input_wrapper input_wrapper-addProject">
            <label htmlFor="">ФИО</label>
            <InputSelect
              control={control}
              name="coordinator_id"
              placeholder="Выберите "
              onClear={onClearCoordinator}
              requiredMessage="Выберите координатора"
              options={coordinatorsList}
              selectedValue={(arg) => setPerson1(arg)}
            />
          </div>
          <div className="btn_action">
            <button
              className="btn_assign"
              onClick={() => setIsShowModalCoordinator(false)}
            >
              Подписать
            </button>
          </div>
        </div>
      </div > */}

      <div className={isShowModalDocCreated ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
        <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
          <p>
            <span onClick={() => setIsShowModalDocCreated(false)}>
              {IconsContent.Close()}
            </span>
          </p>
          <div>
            <p>{IconsContent.DocCreated()}</p>
            <p>Документ сформирован</p>
            <button onClick={() => { setIsShowModalDocCreated(false); navigate('/clerk'); }}>
              Закрыть
            </button>
          </div>
        </div>
      </div>

      <div className={isShowModalError ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
        <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
          <p>
            <span onClick={() => setIsShowModalError(false)}>
              {IconsContent.Close()}
            </span>
          </p>
          <div>
            <p>{IconsContent.Error()}</p>
            <p>Произошла ошибка</p>
            <button onClick={() => {
              setIsShowModalError(false);
              // window.location.reload();
            }}>
              Закрыть
            </button>
          </div>
        </div>
      </div>
    </div >
  );
}


