import React from "react";
import IconsContent from "../../Assets/img/IconsContent";

const TableFirst = ({ state, setState }) => {
	const handleSort = () => {
		const sortedData = [...state].sort((a, b) => {
			return a.first > b.first ? 1 : -1;
		});
		setState(sortedData);
	};
	return (
		<td className="first" style={{width: 'fit-content'}}>
			<div className="first__arrows">
				<p>ID</p>
				<div className="table_sort" onClick={handleSort}>
					<p>{IconsContent.ChevronUp()}</p>
					<p>{IconsContent.ChevronDown()}</p>
				</div>
			</div>
		</td>
	);
};

export default TableFirst;
