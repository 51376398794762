import { useEffect, useRef, useState } from "react";
import IconsContent from "../../../Assets/img/IconsContent";
import iconsContent from "../../../Assets/img/IconsContent";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { UserService } from "../../../../Main/Services/UserService";
import Preloader from "../../Preloader/Preloader";


export default function TransmittalLettersFormContainer() {
    const navigate = useNavigate()
    let transmittalLetterId = useLocation().pathname.replace(/[a-z\/]/g, '')
    const [isShowModalDocCreated, setIsShowModalDocCreated] = useState(false);
    const [error, setError] = useState(false)
    const [isShowModalError, setIsShowModalError] = useState(false);
    const [newPlayAnimation, setNewPlayAnimation] = useState(false)

    const { register, handleSubmit, control, reset, formState: { errors } } = useForm({ mode: "onBlur" });
    const onSubmit = (transmittalLeterData) => {
        setNewPlayAnimation(true)
        UserService.addTransmittalLetter(transmittalLetterId, transmittalLeterData).then(response => {

            setNewPlayAnimation(false)
            setIsShowModalDocCreated(true);
        }).catch(err => { setNewPlayAnimation(false); setIsShowModalError(true); setError(err.response.data.errors) });
        // reset();
    };

    return (
        <div className={'transmittal-letters__form projects_page'}>
            {newPlayAnimation && <Preloader playAnimation={false} />}
            <div className="header_wrapper_name">
                <h1>Формирование сопроводительного письма</h1>
            </div>
            <main>
                <h5>Обязательные поля *</h5>
                <form action="" onSubmit={handleSubmit(onSubmit)}>
                    {/* <div className="input_wrapper">
                        <label htmlFor="">ID проекта *</label>
                        <input type="number"className={errors.project_id ? "form-error-border-color" : ""}{...register("project_id", {required: "Заполните обязательное поле",})}/>
                        {errors.project_id && (<p className="form--required">{errors.project_id.message}</p>)}
                    </div> */}
                    <div className="input_wrapper">
                        <label htmlFor="">Тип документа и разработчик (в соответствующем падеже на русском) *</label>
                        <input type="text" className={errors.document_type_and_developer_kk ? "form-error-border-color" : ""}{...register("document_type_and_developer_kk", { required: "Заполните обязательное поле", })} />
                        {errors.document_type_and_developer_kk && (<p className="form--required">{errors.document_type_and_developer_kk.message}</p>)}
                    </div>
                    <div className="input_wrapper">
                        <label htmlFor="">Тип документа и разработчик (в соответствующем падеже на казахском) *</label>
                        <input type="text" className={errors.document_type_and_developer_ru ? "form-error-border-color" : ""}{...register("document_type_and_developer_ru", { required: "Заполните обязательное поле", })} />
                        {errors.document_type_and_developer_ru && (<p className="form--required">{errors.document_type_and_developer_ru.message}</p>)}
                    </div>
                    <div className="input_wrapper">
                        <label htmlFor="">Количество листов приложения *</label>
                        <input type="number" className={errors.number_of_application_sheets ? "form-error-border-color" : ""}{...register("number_of_application_sheets", { required: "Заполните обязательное поле", })} />
                        {errors.number_of_application_sheets && (<p className="form--required">{errors.number_of_application_sheets.message}</p>)}
                    </div>
                    <div className="input_wrapper">
                        <label htmlFor="">ФИО руководителя проекта  *</label>
                        <input type="text" className={errors.leader_full_name ? "form-error-border-color" : ""}{...register("leader_full_name", { required: "Заполните обязательное поле", })} />
                        {errors.leader_full_name && (<p className="form--required">{errors.leader_full_name.message}</p>)}
                    </div>

                    <button className={'submit'}>Добавить сопроводительное письмо</button>
                    {/* <button className={'cancel'}>Сохранить и добавить проект</button> */}
                </form>
            </main>


            <div className={isShowModalDocCreated ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
                    <p>
                        <span onClick={() => { setIsShowModalDocCreated(false); navigate('/coordinator/transmittalletters') }}>
                            {IconsContent.Close()}
                        </span>
                    </p>
                    <div>
                        <p>{IconsContent.DocCreated()}</p>
                        <p>Документ сформирован</p>
                        <button onClick={() => { setIsShowModalDocCreated(false); navigate('/coordinator/transmittalletters') }}>
                            Закрыть
                        </button>
                    </div>
                </div>
            </div>

            <div className={isShowModalError ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
                    <p>
                        <span onClick={() => setIsShowModalError(false)}>
                            {IconsContent.Close()}
                        </span>
                    </p>
                    <div>
                        <p>{IconsContent.Error()}</p>
                        <p>Произошла ошибка {error}</p>
                        <p>{error}</p>
                        <button onClick={() => { setIsShowModalError(false); window.location.reload(); }}>
                            Закрыть
                        </button>
                    </div>
                </div>
            </div>

            {/* <div className={isShowModalConfirm ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
                    <p>
                        <span onClick={() => setIsShowModalConfirm(false)}>{IconsContent.Close()}</span>
                    </p>
                    <div>
                        <p>{IconsContent.Ask()}</p>
                        <p style={{ color: '#0D152C', marginTop: "20px", fontSize: "18px", fontWeight: "500" }}>Вы действительно хотите добавить новый проект?</p>
                        <div className='isShowModalDeleteUserConfirm'>
                            <button onClick={() => clickToAccept()}
                            >Подтвердить</button>
                            <button type="button" onClick={() => setIsShowModalConfirm(true)}>Закрыть</button>
                        </div>
                    </div>
                </div>
            </div> */}


        </div>
    )
}