import React from "react";

function EyeHide({ styles, clickToEye }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 57 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles}
      onClick={() => clickToEye(true)}>
      <path
        d="M42.4086 42.4086C38.4319 45.4399 33.5896 47.1192 28.59 47.2009C12.3055 47.2009 3 28.59 3 28.59C5.89374 23.1972 9.90729 18.4857 14.7714 14.7714M23.7046 10.5374C25.3059 10.1626 26.9454 9.97523 28.59 9.97909C44.8745 9.97909 54.18 28.59 54.18 28.59C52.7678 31.2318 51.0837 33.719 49.1551 36.0111M33.5219 33.5219C32.883 34.2076 32.1125 34.7576 31.2564 35.139C30.4003 35.5204 29.4761 35.7256 28.539 35.7421C27.6019 35.7586 26.6711 35.5862 25.8021 35.2352C24.9331 34.8842 24.1437 34.3618 23.4809 33.6991C22.8182 33.0363 22.2958 32.2469 21.9448 31.3779C21.5938 30.5089 21.4214 29.5781 21.4379 28.641C21.4544 27.7039 21.6595 26.7797 22.041 25.9236C22.4224 25.0675 22.9724 24.297 23.6581 23.6581"
        stroke="#4ABD9B"
        stroke-width="4.65273"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M3 3L54.18 54.18" stroke="#4ABD9B" stroke-width="4.65273" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}

export default EyeHide;
