import "../../Styles/Layout.css";
import AddUserContainer from "../../Components/pages/Administrator/AddUserContainer";
import MainLayout from "../../Components/Layout/MainLayout";
import { useLocation } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import IconsContent from "../../Assets/img/IconsContent";
import { useState, useEffect } from "react";
import { UserService } from "../../../Main/Services/UserService";
import Preloader from "../../Components/Preloader/Preloader";
import { AdministratorkNavBar } from "../../../Main/Services/http";


export default function AddUserPage() {
  let num = useLocation().state
  const [branchOfLegislation, setBranchOfLegislation] = useState()
  useEffect(() => {
    UserService.getBranchOfLegislation()
      .then(response => {

        let arr = response.data.data.map(item => { return { value: item.id, label: item.locales.name.ru } })
        setBranchOfLegislation(arr)
      });
  }, [])

  const [playAnimation, setPlayAnimation] = useState(false)
  useEffect(() => {
    const onPageLoad = () => {
      setPlayAnimation(true);
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <MainLayout>
      <Preloader playAnimation={playAnimation} />
      <Navbar direction={AdministratorkNavBar()} />
      <AddUserContainer branchOfLegislation={branchOfLegislation} />
    </MainLayout>
  )
}