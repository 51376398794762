import React from "react";
import SlideToggle from "react-slide-toggle";
import IconsContent from "../../../Assets/img/IconsContent";
import NavigationContent from "../../NavigationContent/NavigationContent";

const ConclusionsDetailsContainer = () => {
	return (
		<div className="conclusionCoordinatorDetails__page projects_page">
			<NavigationContent
				direct={[
					{ goTo: `/superadministrator`, name: `Главная` },
					{ goTo: `/superadministrator/conclusions`, name: `Заключения` },
					{
						goTo: `/superadministrator/conclusions/details/1`,
						name: `Заключения`,
					},
				]}
				name={"Заключения"}
			/>
			<main>
				<div className="superadmin_main_header">
					<div className="superadmin_status">
						<div className="superadmin_status_type">
							<p className="superadmin_status_name">Статус</p>
							<p className="superadmin_status_code superadmin_status_code-green">
								Новый
							</p>
						</div>
						<div className="superadmin_status_type">
							<p className="superadmin_status_name">Создан</p>
							<p className="superadmin_status_code superadmin_status_code-blue">
								05.07.2022 17:23:24
							</p>
						</div>
					</div>
					<div className="btn_download btn_download-green">
						<button>
							<span>Скачать файлы</span> {IconsContent.File()}
						</button>
					</div>
				</div>
				<div className="view_form__content">
					<div className="content_left">
						<div className="logs">
							<div className="logs_header">
								<p>Логи</p>
							</div>

							<SlideToggle>
								{({ toggle, setCollapsibleElement }) => (
									<div className="my-collapsible">
										<button onClick={toggle}>
											<span>Молдабек Сырым Пернебекұлы (Изменено)</span>
											<span>2022-07-05 18:25:09 {IconsContent.ArrUp()}</span>
										</button>
										<div
											className={`hidden_information my-collapsible__content`}
											ref={setCollapsibleElement}
										>
											<p className="date">12:41:01</p>
											<div className="information_doc">
												<p className="header">Тип документа: </p>
												<p className="description">
													Нормативные правовые постановления Национального Банка
													Республики Казахстан и иных центральных
													государственных органов
												</p>
											</div>
											<br />
											<div
												className="information_doc"
												style={{ marginBottom: 28 }}
											>
												<p className="header">Тип документа: </p>
												<p className="description">
													Нормативные правовые постановления Национального Банка
													Республики Казахстан и иных центральных
													государственных органов
												</p>
											</div>
										</div>
									</div>
								)}
							</SlideToggle>
						</div>
						<div className="other_inforamtions">
							<div className="get_inform">
								<div className="inform_name">
									<h5>Эксперт</h5>
								</div>
								<div className="inform_value">
									<p>Эксперт</p>
								</div>
							</div>

							<div className="get_inform">
								<div className="inform_name">
									<h5>Наименование проекта (на русском)</h5>
								</div>
								<div className="inform_value">
									<p>Наименование проекта (на русском)</p>
								</div>
							</div>

							<div className="get_inform">
								<div className="inform_name">
									<h5>Крайний срок выполнения</h5>
								</div>
								<div className="inform_value">
									<p>2022-07-05 00:00:00</p>
								</div>
							</div>

							<div className="get_inform">
								<div className="inform_name">
									<h5>Статус</h5>
								</div>
								<div className="inform_value">
									<p>Статус</p>
								</div>
							</div>

							<div className="get_inform">
								<div className="inform_name">
									<h5>Комментарий корректора</h5>
								</div>
								<div className="inform_value">
									<p>
										Решения маслихата Павлодарской области «О внесении изменений
										в решение Павлодарского областного маслихата от 22 апреля
										2021 года № 23/3 «Об утверждении Правил содержания животных
										в Павлодарской области»
									</p>
								</div>
							</div>
							<div className="get_inform ">
								<div className="inform_name">
									<h5>Комментарий переводчика</h5>
								</div>
								<div className="inform_value">
									<p>
										Решения маслихата Павлодарской области «О внесении изменений
										в решение Павлодарского областного маслихата от 22 апреля
										2021 года № 23/3 «Об утверждении Правил содержания животных
										в Павлодарской области»
									</p>
								</div>
							</div>
							<div className="get_inform">
								<div className="inform_name">
									<h5>Анализ рисков коррупции процесса продвижения проекта</h5>
								</div>
								<div className="inform_value">
									<p>Анализ рисков коррупции процесса продвижения проекта</p>
								</div>
							</div>

							<div className="get_inform">
								<div className="inform_name">
									<h5>
										Подробный анализ факторов риска и рисков коррупции проекта
									</h5>
								</div>
								<div className="inform_value">
									<p>
										Подробный анализ факторов риска и рисков коррупции проекта
									</p>
								</div>
							</div>

							<div className="get_inform">
								<div className="inform_name">
									<h5>Вывод заключения</h5>
								</div>
								<div className="inform_value">
									<p>Вывод заключения</p>
								</div>
							</div>

							<div className="get_inform">
								<div className="inform_name">
									<h5>ФИО пользователя</h5>
								</div>
								<div className="inform_value">
									<p>ФИО пользователя</p>
								</div>
							</div>

							<div className="get_inform">
								<div className="inform_name">
									<h5>Дата и время создания и изменения</h5>
								</div>
								<div className="inform_value">
									<p>Дата и время создания и изменения</p>
								</div>
							</div>
							<div className="get_inform">
								<div className="inform_name">
									<h5>ФИО пользователя</h5>
								</div>
								<div className="inform_value">
									<p>ФИО пользователя</p>
								</div>
							</div>
						</div>
					</div>
					<div className="content_right">
						<div className="inform">
							<div className="inform_name">
								<h5>Дополнительные данные</h5>
							</div>
							<div className=" conclusionCoordinatorDetails__value">
								<h5>
									Эксперт: <span>Молдабек Сырым Пернебекұлы</span>
								</h5>
								<div className="conclusionCoordinatorDetails__value-info">
									<p>
										ID проекта: <span>35646</span>
									</p>
									<p>
										Ответственный корректор: <span>Наименование проекта</span>
									</p>
									<p>
										Крайний срок выполнения: <span>Дата (гггг-мм-дд)</span>
									</p>
									<p>
										Статус: <span>Статус</span>
									</p>
									<p>
										Эксперт: <span>ФИО</span>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>
	);
};

export default ConclusionsDetailsContainer;
