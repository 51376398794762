import PageRole from "../PageRole/PageRole";
import Navbar from "../Navbar/Navbar";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";


export default function MainLayout({children, profileData}){

    const navigate = useNavigate();
    let currentLocation = useLocation().pathname
    let userLocation = localStorage.getItem('.UserLocation')

   useEffect(()=>{
        if(userLocation === null){
            navigate('/home')
        }else if(currentLocation.includes(userLocation)||currentLocation.includes('/home')){
            navigate(currentLocation)
        }else{
            navigate(userLocation)
        }
   },[])

    return(
        <div className={'layout'}>
            <header>
                <PageRole />
            </header>
            <main>
                {children}
            </main>
            <footer>
                <p>© 2023 Antikor v.0.5.2</p>
            </footer>
        </div>
    )
}