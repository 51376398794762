import React from 'react';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { useState } from 'react';
import { Controller } from 'react-hook-form'
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const Wysiwyg = ({ name, control, defaultValu }) => {



    // const [editorState, setEditorState] = useState(() => EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(defaultValu))))
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(defaultValu ? defaultValu : ""))))
    console.log(name)
    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value, ref } }) =>
                    // <Select
                    //     onChange={(e) => { onChange(e?.value); 
                    //         console.log(ref); 
                    //         selectedValue(e?.label) }}
                    //     classNamePrefix="custom-select"
                    //     placeholder={placeholder}
                    //     options={options}
                    //     defaultInputValue={defaultValue}
                    // />
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={(e) => { setEditorState(e); onChange(draftToHtml(convertToRaw(e.getCurrentContent()))) }}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        editorStyle={{ height: '200px', overflow:"hidden"}}
                    />
                } />
        </>
    )
}

export default Wysiwyg
