import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import NavigationContent from "../../NavigationContent/NavigationContent";
import InputMask from "react-input-mask";
import Select from "react-select";
import { Link } from "react-router-dom";
import GeneratePassword from "../../GeneratePassword/GeneratePassword";

export default function EditUserContainer() {
	const [newUserRole, setNewUserRole] = useState("Coordinator");
	//useForm
	const {
		register,
		//formState: { errors },
		handleSubmit,
	} = useForm({
		mode: "onBlur",
		//resolver: yupResolver(actionCatalogSchema),
	});

	// submit/add data
	const onSubmit = (data) => {
		console.log(data);
	};

	return (
		<div className={"transmittal-letters__form addProject_page  projects_page"}>
			<NavigationContent
				direct={[
					{ goTo: `/superadministrator`, name: `Главная` },
					{ goTo: `/superadministrator/users`, name: `Пользователи` },
					{
						goTo: `/superadministrator/users/editUser`,
						name: `Редактирование Переводчик`,
					},
				]}
				name={"Редактирование Переводчик"}
			/>
			<div className="header_wrapper_name"></div>
			<main>
				<div className="c-card">
					<h5>Обязательные поля *</h5>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="input_wrapper">
							<label htmlFor="">Роль</label>
							<Select
								options={[
									{ value: "Corrector", label: "Корректор" },
									{ value: "Administrator", label: "Администратор" },
									{ value: "Coordinator", label: "Координатор" },
									{ value: "Expert", label: "Эксперт" },
									{ value: "Translator", label: "Переводчик" },
								]}
								onChange={(e) => setNewUserRole(e.value)}
								classNamePrefix="custom-select"
								placeholder="Корректор"
							/>
						</div>
						<div className="input_wrapper">
							<label htmlFor="">E-mail *</label>
							<input type="text" value={"mail@mail.ru"} />
						</div>
						<div className="input_wrapper">
							<label htmlFor="">Пароль *</label>
							<GeneratePassword passValue={"wfnjrfefe"} />
						</div>
						<div className="input_wrapper">
							<label htmlFor="">ФИО (на русском) *</label>
							<input type="text" value={"Шаяхмет Гулшат Кайраткызы"} />
						</div>
						<div className="input_wrapper">
							<label htmlFor="">ФИО (на казахском) *</label>
							<input type="text" value={"Шаяхмет Гулшат Кайраткызы"} />
						</div>
						<div className="input_wrapper">
							<label htmlFor="">ИИН *</label>
							<input type="text" value={"90032342342354256"} />
						</div>
						{newUserRole == "Coordinator" || newUserRole == "Expert" ? (
							<>
								<div className="input_wrapper">
									<label htmlFor="">Дата рождения *</label>
									<input type="text" value={"02.02.1990"} />
								</div>
								<div className="input_wrapper">
									<label htmlFor="">Телефон *</label>
									<InputMask
										mask="+7 (999) 999 - 99 - 99"
										placeholder="+7 (___) ___ - __ - __"
										value={"777777777777"}
									/>
								</div>
								<div className="input_wrapper">
									<label htmlFor="">Должность (на русском) *</label>
									<input type="text" value={"Должность (на русском)"} />
								</div>
								<div className="input_wrapper">
									<label htmlFor="">Должность (на казахском) *</label>
									<input type="text" value={"Должность (на казахском)"} />
								</div>
							</>
						) : null}
						<div className="input_wrapper">
							<label htmlFor="">Примечания (на русском)</label>
							<input type="text" value={"Примечание (на русском)"} />
						</div>
						<div
							className={
								newUserRole !== "Expert"
									? "input_wrapper-last"
									: "input_wrapper"
							}
						>
							<label htmlFor="">Примечания (на казахском)</label>
							<input type="text" value={"Примечание (на казахском)"} />
						</div>
						<div>
							<button className={"button button_submit"}>Сохранить</button>
							<Link to={`/superadministrator/users`} href="#">
								<button className={"button button_close"}>Закрыть</button>
							</Link>
						</div>
					</form>
				</div>
			</main>
		</div>
	);
}
