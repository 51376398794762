import React from 'react'
import { useLocation } from 'react-router-dom'

const StatisticsContainerByNPADevsDetails = () => {

    const devData = useLocation().state
    console.log(devData)

    return (
        <div className='statistics'>
            <h1>Детальная статистика по эксперту</h1>
            <div className='statistics__developerDetails'>
                <div className='statistics__data-container'>
                    <h3>Факторы риска</h3>
                    <table>
                        <thead>
                            <tr>
                                <td>ID</td>
                                <td>НАИМЕНОВАНИЕ КОРРУПЦИОГЕННОЙ ФОРМЫ</td>
                                <td>КОЛИЧЕСТВО РИСКОВ КОРРУПЦИИ</td>
                            </tr>
                        </thead>
                        <tbody>
                            {devData[0].map(item =>
                                <tr>
                                    <td>{item.id}</td>
                                    <td>{item.coorFormName}</td>
                                    <td>{item.amount}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className='statistics__data-container'>
                    <h3>Риски коррупции</h3>
                    <table>
                        <thead>
                            <tr>
                                <td>ID</td>
                                <td>НАИМЕНОВАНИЕ КОРРУПЦИОГЕННОЙ ФОРМЫ</td>
                                <td>КОЛИЧЕСТВО РИСКОВ КОРРУПЦИИ</td>
                            </tr>
                        </thead>
                        <tbody>
                            {devData[1].map(item =>
                                <tr>
                                    <td>{item.id}</td>
                                    <td>{item.coorFormName}</td>
                                    <td>{item.amount}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>


            </div>
        </div>

    )
}

export default StatisticsContainerByNPADevsDetails