import Email from "./email";
import s from "../../Styles/AuthForm.module.css";
import Lock from "./lock";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UserService } from "../../../Main/Services/UserService";
import EyeView from "../../Assets/icons/EyeView"
import EyeHide from "../../Assets/icons/EyeHide";

export default function AuthForm() {
  const { register, handleSubmit, formState: { errors }, } = useForm({ mode: 'onChange' });
  const [viewPassword, setViewPassword] = useState(false)
  const onSubmit = (data) => {

    UserService.signInUser(data)
      .then(response => {
        navigate(`/${response.data.data.user.roles[0].slug}`)

        localStorage.setItem('.AuthToken', response.data.data.token)
        localStorage.setItem('.UserName', response.data.data.user.full_name)
        localStorage.setItem('.UserLocation', `/${response.data.data.user.roles[0].slug}`)
        localStorage.setItem('.UserID', `${response.data.data.user.id}`)
      })
      .catch((err) => setInCorrect(true));
  };
  const clickToEye = (bolean) => {
    setViewPassword(bolean)
  }

  const { t, i18n } = useTranslation("login");
  const navigate = useNavigate();
  const [isIncorrect, setInCorrect] = useState(false);

  return (
    <form action="" className={s.form} onSubmit={handleSubmit(onSubmit)}>
      {isIncorrect ? (
        <div className={s.formContent} style={{ color: `red` }}>
          {t("incorrect")}
        </div>
      ) : (
        <div className={s.formContent}>{t("input")}</div>
      )}
      <div className={s.inputContainer} style={{ marginBottom: 15 }}>
        <Email />
        <input type="text" className={errors.username ? s.input_error : ""} {...register("username", { required: "Заполните обязательное поле", })} />
        {errors.username && <p className="form--required">{errors.username.message}</p>}

      </div>
      <div className={s.inputContainer} style={{ marginBottom: 20 }}>
        <Lock />
        <input type={viewPassword ? "text" : "password"} className={errors.password ? s.input_error : ""} {...register("password", { required: "Заполните обязательное поле", })} />
        <p className="form--required"> {errors.password && errors.password.message}</p>
        {viewPassword ? <EyeView styles={s.eye} clickToEye={clickToEye} /> : <EyeHide styles={s.eye} clickToEye={clickToEye} />}
      </div>
      <button className={s.btn}>{t("login")}</button>
    </form>
  );
}
