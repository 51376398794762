import IconsContent from "../../Assets/img/IconsContent";
import { Link, useLocation } from "react-router-dom";
import iconsContent from "../../Assets/img/IconsContent";
import NavDrop from "./NavDrop";
import { AiOutlineMenu } from "react-icons/ai";
import { useEffect, useState } from "react";

export default function Navbar({ direction }) {
	const [isMenu, setIsMenu] = useState(false);
	const [isShowFilter, setIsShowFilter] = useState(false);

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		setIsShowFilter(isMenu);
	// 	}, 1);
	// }, [isMenu]);

	// useEffect(() => {
	// 	if (!isShowFilter) {
	// 		setTimeout(() => {
	// 			setIsMenu(isShowFilter);
	// 		}, 550);
	// 	}
	// }, [isShowFilter]);

	const location = useLocation();
	const pathName = location.pathname;
	const Directions = direction.map((element, index) => {
		if (element.dropDown !== undefined) {
			return <NavDrop key={index} element={element} index={index} />;
		}
		return (
			<Link key={index} to={element.goTo}>
				<li
					
					className={`direct  ${
						pathName === `${element.goTo}` ? `btn_active` : null
					}`}
				>
					{element.iCon}
					{element.name}
				</li>
			</Link>
		);
	});
	return (
		<>
			<div className={"navbarWrapper"}>
				<div className="home_wrapper">
					<Link to="/home" className={"home_link"}>
						{IconsContent.Home()}
					</Link>
					{/* <div className="cover_1" /> */}
				</div>
				<nav className={"navbar_directions"}>
					<ul className={"navbar_direction_wrapper"}>{Directions}</ul>
					{/* <div className="cover_1" /> */}
					{/* <button
						className={"button_menu"}
						onClick={() => {
							setIsMenu(true);
						}}
					>
						<AiOutlineMenu style={{ color: "white", width: 24, height: 24 }} />
					</button> */}
				</nav>
	
				{/* {isMenu && (
					<div className="menu_modal">
						<div className={`menu_content ${!isShowFilter ? `disActive` : null}`}>
							<ul className={"navbar_direction_wrapper"}>{Directions}</ul>
						</div>
						<div
							className="menu_cover"
							onClick={() => {
								setIsShowFilter(false);
							}}
						/>
					</div>
				)} */}
			</div>
			
		</>
	);
}
