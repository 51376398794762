import "../../Styles/Layout.css";
import ProjectsContainer from "../../Components/pages/ProjectsContainer";
import MainLayout from "../../Components/Layout/MainLayout";
import Navbar from "../../Components/Navbar/Navbar";
import { UserService } from "../../../Main/Services/UserService";
import { useEffect, useState } from "react";
import Preloader from "../../Components/Preloader/Preloader";
import { AdministratorkNavBar } from "../../../Main/Services/http";

export default function AdministratorIndexPage() {
    const [expertsList, setExpertsList] = useState()
    const [projects, setProjects] = useState()
    const [coordinatorsList, setCoordinatorsList] = useState()
    const [projectStatuses, setProjectStatuses] = useState()
    const [authorityDevelopers, setAuthorityDevelopers] = useState()
    const [branchOfLegislation, setBranchOfLegislation] = useState()
    const [documentTypes, setDocumentTypes] = useState()


    useEffect(() => {
        UserService.getProjects().then(res => setProjects(res.data)).catch(err => console.log(err))
        UserService.getCoordinators()
            .then(response => {
                let arr = response.data.data.map(item => { return { value: item.id, label: item.full_name } })
                setCoordinatorsList(arr);
            });
        UserService.getExperts()
            .then(response => {
                let arr = response.data.data.map(item => { return { value: item.id, label: item.full_name } })
                setExpertsList(arr);
            });
        UserService.getProjectStatuses()
            .then(response => {
                let arr = response.data.data.map(item => { return { value: item.id, label: item.name } })
                setProjectStatuses(arr)
            });

        UserService.getBranchOfLegislation()
            .then(response => {
                let arr = response.data.data.map(item => { return { value: item.id, label: item.locales.name.ru } })
                setBranchOfLegislation(arr)
            });
        UserService.getDocumentTypes()
            .then(response => {
                let arr = response.data.data.map(item => { return { value: item.id, label: item.locales.name.ru } })
                setDocumentTypes(arr);
            });
        UserService.getAuthorityDevelopers()
            .then(response => {
                let arr = response.data.data.map(item => { return { value: item.id, label: item.locales.name.ru } })
                setAuthorityDevelopers(arr)
            });

    }, []);

    const [playAnimation, setPlayAnimation] = useState(false)

    useEffect(() => {
        const onPageLoad = () => {
            setPlayAnimation(true);
        };

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);


    return (

        <MainLayout>
            <Preloader playAnimation={playAnimation} />
            <Navbar direction={AdministratorkNavBar()} />
            {projects && branchOfLegislation && projectStatuses && documentTypes && authorityDevelopers && coordinatorsList && expertsList && <ProjectsContainer
                projects={projects}
                branchOfLegislation={branchOfLegislation}
                projectStatuses={projectStatuses}
                documentTypes={documentTypes}
                authorityDevelopers={authorityDevelopers}
                coordinatorsList={coordinatorsList}
                expertsList={expertsList}
            />}
        </MainLayout>
    )
}