import "../../Styles/Layout.css";
import CatalogDetailsAuthDevelopers from "../../Components/pages/Administrator/CatalogDetailsAuthDevelopers";
import MainLayout from "../../Components/Layout/MainLayout";
import { UserService } from "../../../Main/Services/UserService";
import Navbar from "../../Components/Navbar/Navbar";
import Preloader from "../../Components/Preloader/Preloader";
import { useEffect, useState } from "react";
import { AdministratorkNavBar } from "../../../Main/Services/http";

export default function CatalogDetails() {

    const [playAnimation, setPlayAnimation] = useState(false)
    const [authorityDevelopers, setAuthorityDevelopers] = useState()

    useEffect(() => {
        UserService.getAuthorityDevelopers()
            .then(response => setAuthorityDevelopers(response.data.data));
    }, []);

    useEffect(() => {
        const onPageLoad = () => {
            setPlayAnimation(true);
        };

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    return (
        <MainLayout>
            <Preloader playAnimation={playAnimation} />
            <Navbar direction={AdministratorkNavBar()} />
            {authorityDevelopers&&<CatalogDetailsAuthDevelopers authDevelopers={authorityDevelopers}/>}
        </MainLayout>
    )
}