import MainLayout from "../../Components/Layout/MainLayout";
import ProfileContainer from "../../Components/pages/ProfileContainer";
import { ClerkNavBar } from "../../../Main/Services/http";
import Navbar from "../../Components/Navbar/Navbar";
import { useState, useEffect } from "react";
import { UserService } from "../../../Main/Services/UserService";

export default function Profile() {

    const [userData, setUserData] = useState()
    let userId = localStorage.getItem('.UserID')
    useEffect(() => {

        UserService.getUser(userId)
            .then(response => {
                let arr = response.data.data
                setUserData(arr);

            })
    }, []);
    return (
        <MainLayout>
            <Navbar direction={ClerkNavBar()} />
            {userData && <ProfileContainer userData={userData} />}
        </MainLayout>
    )
}