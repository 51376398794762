import s from './Language.module.css'
import {useTranslation} from "react-i18next";


export default function Language(){
    const {t, i18n} = useTranslation()

    function ChangeLanguageF(lang){
        i18n.changeLanguage(lang)
        document.cookie = `lang=${lang}`;
    }


    return(
        <div className={s.LanguageComponent}>
            <button  className={i18n.language === 'kk' ? s.active : null} onClick={() => {ChangeLanguageF('kk')}} >ҚАЗ</button>
            <button  className={i18n.language === 'ru' ? s.active : null} onClick={() => {ChangeLanguageF('ru')}}>РУС</button>
        </div>
    )
}