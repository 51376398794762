import { useState } from "react";
import { useForm } from 'react-hook-form'
import DatePickerInput from "../DatePicker/DatePicker";
import { InputSelect } from "../Select/InputSelect";
import { UserService } from "../../../Main/Services/UserService";



function Modal({ changeCorrectorModal, setChangeCorrectorModal, setOnClear, projectId, setIsShowModalAddExpert, setIsExpertAdded, IconsContent, onClear, correctorsList, setPersonCorrector }) {

    const { register, reset, control, handleSubmit, formState: { errors } } = useForm({ mode: 'onBlur' })
    const userLocation = localStorage.getItem('.UserLocation')


    const onSubmit = (putExpertData) => {
        if (userLocation == '/coordinator' && changeCorrectorModal) {
            delete putExpertData.members
            UserService.putExpert(putExpertData, projectId)
                .then(response => {

                    setIsShowModalAddExpert(false)
                    setIsExpertAdded(true)
                })
        }
    }

    return (
        <div className={changeCorrectorModal ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
            <div className="transmittal-letters__modal-content transmittal-letters__form" >
                <div className="transmittal-letters__wrapper">
                    <p className="transmittal-letters__modal-heading">
                        <span>Выберите корректора</span>
                        <span onClick={() => { setChangeCorrectorModal(false); setOnClear(true); reset() }}>{IconsContent.Close()}</span>
                    </p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="input_wrapper input_wrapper-addProject">
                            <label htmlFor="">ФИО</label>
                            <InputSelect control={control}
                                placeholder="Выберите"
                                name="corrector_id"
                                onClear={onClear}
                                noRequired
                                options={correctorsList}
                                selectedValue={(arg) => setPersonCorrector(arg)}
                            />
                            {errors.members && <p className="form--required">required</p>}
                        </div>
                        <div className="btn_action" >
                            <button className="btn_assign"
                            // onClick={() => setIsShowModalAddExpert(false)}
                            >Подписать</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Modal;