import React from 'react'
import { useState, useEffect } from "react";
import { useForm, Controller } from 'react-hook-form'
import { UserService } from "../../../Main/Services/UserService";
import IconsContent from '../../Assets/img/IconsContent';
import { useTranslation } from "react-i18next";

const AcceptedBtn = ({ detailedFactorId, detailedFactorStatus, setNewPlayAnimation }) => {
    console.log(detailedFactorStatus)
    const [status, setStatus] = useState(detailedFactorStatus == null ? true : detailedFactorStatus)
    console.log(detailedFactorId)
    useEffect(() => {
        setNewPlayAnimation(true)
        UserService.putDetailedFactors(detailedFactorId, { _method: "PUT", accepted: status })
            .then(() => setNewPlayAnimation(false))
            .catch(err => console.log(err))
    }, [status])
    return (
        <p className='risk_factors-btn'>
            <button type="button" className={status ? "status__inactive " : "status__active "}> Отклонить</button>
            <button type="button" className={status ? "status__active " : "status__inactive "}>Принять</button>
            <input type="radio"
                onClick={() => setStatus(!status)}
            />
        </p>
    )
}

const ModalAddDataAcceptedNPA = ({ projectId, setNewPlayAnimation, isShowModalAddRiskFactorsNPA, setIsShowModalAddRiskFactorsNPA, project, setIsExpertAdded, setIsShowModalError }) => {

    const { t, i18n } = useTranslation("common");

    return (
        <>
            <div className={isShowModalAddRiskFactorsNPA ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                <div className="transmittal-letters__modal-content transmittal-letters__form" style={{ padding: "15px", width: "80%" }}>
                    <div className="transmittal-letters__wrapper">
                        <p className="transmittal-letters__modal-heading">
                            <span>Отметить факторы риска принятого НПА</span>
                            <span onClick={() => { window.location.reload(); setIsShowModalAddRiskFactorsNPA(false) }}>{IconsContent.Close()}</span>
                        </p>
                        <form style={{ maxWidth: "90%" }}>
                            {project.conclusions.map(item =>
                                <div className='reports-details__active '>
                                    <h3>{t("expert")}: {item.expert.full_name}</h3>
                                    {item.risk_factors.map(val =>

                                        <div className='reports-details__factors'>
                                            <p className='reports-details__factors--name'>{val.locales.name[i18n.language]}</p>
                                            <AcceptedBtn detailedFactorId={val.id} detailedFactorStatus={val.accepted} setNewPlayAnimation={setNewPlayAnimation} />
                                        </div>)}
                                </div>)}
                            <div className="btn_action" >
                                <button className="btn_assign" onClick={() => window.reload()} >Закрыть</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ModalAddDataAcceptedNPA