import IconsContent from "../../Assets/img/IconsContent";
import { Controller, useForm } from 'react-hook-form'
import { useState } from "react";
import Select from 'react-select';
import { UserService } from "../../../Main/Services/UserService";
import { useTranslation } from "react-i18next";
import Preloader from "../Preloader/Preloader";

export default function ProfileContainer({ userData, branchOfLegislation }) {
    const userLocation = localStorage.getItem('.UserLocation')
    const [isShowModalUserEdited, setIsShowModalUserEdited] = useState(false)
    const [editButton, setEditButton] = useState(true)
    const [newPlayAnimation, setNewPlayAnimation] = useState(false)
    const [isShowModalError, setIsShowModalError] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState(() => {
        return userData.branch_of_legislations.map(item => {
            return { label: item.name, value: item.id }
        })
    });
    const [status, setStatus] = useState(() => { if (userData?.available == 0) { return false } else { return true } })


    const { register, reset, control, handleSubmit, formState: { errors } } = useForm({ mode: 'onBlur' })
    const onSubmit = (userDataChanged) => {
        setNewPlayAnimation(true)
        userDataChanged = {
            ...userDataChanged,
            available: status
        }

        UserService.upDateUser(userData.id, userDataChanged)
            .then(response => {
                setNewPlayAnimation(false);
                setIsShowModalUserEdited(true)

            })
            .catch(() => { setNewPlayAnimation(false); setIsShowModalError(true) })
    }

    return (
        <div className='users__page '>
            {newPlayAnimation && <Preloader playAnimation={false} />}
            <h1>Пользователи внутри</h1>
            <main style={{ padding: "20px" }}>
                <div className='users__page-container'>
                    <div className='users__page-header'>
                        <p>
                            {IconsContent.User("#4ABD9B")}
                            <span>{userData?.full_name}</span>
                        </p>
                        <p className='user-control'>
                            <span onClick={() => { reset(); setEditButton(!editButton) }}>{IconsContent.EditUser()}</span>
                        </p>
                    </div>
                    <p className='users__page-role'>{userData?.roles[0].name}</p>

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div style={{ display: 'none' }} className='reports-details__content'>
                            <h5>ФИО </h5>
                            {editButton ? <input type="text" value={null} readOnly placeholder={userData?.full_name} />
                                :
                                <input type="text" defaultValue={userData?.full_name}
                                    className={errors.full_name ? "form-error-border-color" : ""} {...register("full_name", { required: "Заполните обязательное поле" })} />}
                            {errors.full_name && <p className="form--required">{errors.full_name.message}</p>}
                        </div>
                        {userLocation == "/expert" ? <div className='reports-details__content reports-details__active'>
                            <h5>Статус активности</h5>
                            {editButton ? <p>
                                <button type="button" className={userData?.available ? "status__inactive faded" : "status__active faded"}> Не активен</button>
                                <button type="button" className={userData?.available ? "status__active faded" : "status__inactive faded"}>Активен</button>
                            </p>
                                :
                                <p>
                                    <button type="button" className={status ? "status__inactive " : "status__active "}> Не активен</button>
                                    <button type="button" className={status ? "status__active " : "status__inactive "}>Активен</button>

                                    <input type="radio"
                                        onClick={() => setStatus(!status)}
                                    />
                                </p>}

                        </div> : null}
                        <div className='reports-details__content'>
                            <h5>Email</h5>
                            {editButton ? <input type="text" value={null} readOnly placeholder={userData?.email} /> :
                                <input type="text" defaultValue={userData?.email} className={errors.email ? "form-error-border-color" : ""}{...register("email", {
                                    required: "Заполните обязательное поле",
                                    pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: "Введенный email не корректен"
                                    }
                                })
                                } />}
                            {errors.email && <p className="form--required">{errors.email.message}</p>}
                        </div>

                        <div className='reports-details__content'>
                            <h5>ФИО пользователя</h5>
                            {editButton ? <input type="text" value={null} readOnly placeholder={userData?.full_name} />
                                :
                                <input type="text" defaultValue={userData?.full_name}
                                    className={errors.full_name ? "form-error-border-color" : ""} {...register("full_name", { required: "Заполните обязательное поле" })} />}
                            {errors.full_name && <p className="form--required">{errors.full_name.message}</p>}
                        </div>

                        {/* <div className='reports-details__content'>
                            <h5>Уведомления</h5>
                            {editButton ?
                                <div className='reports-details__content--notification '>
                                    <p> WEB уведомления
                                        <input type="checkbox" {...register("via_web")}  checked={userData?.settings?.viaPublisher==0?false:true}/>
                                        <span className="span-checked-view"></span>
                                    </p>
                                    <p>Телеграм бот
                                        <input type="checkbox" {...register("via_telegram")} checked={userData?.settings?.viaTelegram == 0 ? false : true} />
                                        <span className="span-checked-view"></span>
                                    </p>
                                    <p> Email рассылка
                                        <input type="checkbox" {...register("via_email")} checked={userData?.settings?.viaMail == 0 ? false : true} />
                                        <span className="span-checked-view"></span>
                                    </p>
                                </div>
                                :
                                <div className='reports-details__content--notification'>
                                    <p> WEB уведомления
                                        <input type="checkbox" {...register("viaPublisher")} />
                                        <span className="span-checked"></span>
                                    </p>
                                    <p>Телеграм бот
                                        <input type="checkbox" {...register("viaTelegram")}  />
                                        <span className="span-checked"></span>
                                    </p>
                                    <p> Email рассылка
                                        <input type="checkbox" {...register("viaMail")}  />
                                        <span className="span-checked"></span>
                                    </p>
                                </div>}

                        </div>
                        <div className='reports-details__content'>
                            <h5>Телеграм ID</h5>
                            {editButton ? <input type="text" value={null} readOnly placeholder={userData?.tin} />
                                :
                                <input type="text" readOnly={editButton} defaultValue={userData?.tin} maxLength="12" minLength="12" className={errors.tin ? "form-error-border-color" : ""} {...register("tin", {
                                    required: "Заполните обязательное поле",
                                    minLength: {
                                        value: 12,
                                        message: "ИИН должен содержать 12 цифр"
                                    },
                                    pattern: {
                                        value: /^\d+$/,
                                        message: "ИИН должен содержать только цифры"
                                    }
                                })} />}
                        </div> */}

                        <div className='reports-details__content'>
                            <h5>ИИН</h5>
                            {editButton ? <input type="text" value={null} readOnly placeholder={userData?.tin} />
                                :
                                <input type="text" readOnly={editButton} defaultValue={userData?.tin} maxLength="12" minLength="12" className={errors.tin ? "form-error-border-color" : ""} {...register("tin", {
                                    required: "Заполните обязательное поле",
                                    minLength: {
                                        value: 12,
                                        message: "ИИН должен содержать 12 цифр"
                                    },
                                    pattern: {
                                        value: /^\d+$/,
                                        message: "ИИН должен содержать только цифры"
                                    }
                                })} />}
                        </div>

                        <div className='reports-details__content'>
                            <h5>Примечания</h5>
                            {editButton ? <input type="text" readOnly placeholder={userData?.note} />
                                :
                                <input type="text" defaultValue={userData?.note}  {...register("note")} />}
                        </div>
                        {userLocation == '/expert' ?
                            <div className="reports-details__content " >
                                <h5>Специализация (Максимальное значение - 5)</h5>
                                <Controller
                                    name="branch_of_legislations"
                                    control={control}
                                    render={({ field: { onChange, ref, value } }) =>
                                        <Select
                                            isMulti
                                            value={selectedOptions}
                                            onChange={(val) => { onChange(val.map((c) => c.value)); setSelectedOptions(val) }}
                                            ref={ref}
                                            options={branchOfLegislation}
                                            isDisabled={editButton}
                                            isOptionDisabled={() => selectedOptions.length >= 5}
                                            placeholder="Выберите"
                                            classNamePrefix="profile-select"
                                        />
                                    } />
                            </div> : null}

                        <div className=' reports-details__content'>
                            <h5>Пароль</h5>
                            {editButton ? <input type="text" value={null} readOnly placeholder={'************'} />
                                :
                                <input type="text" className={errors.password ? "form-error-border-color" : ""} {...register("password", { required: "Заполните обязательное поле" })} />}
                            {errors.password && <p className="form--required">{errors.password.message}</p>}
                        </div>

                        {!editButton &&
                            <button className='c-btn-aquamarine'>Сохранить изменения</button>}
                    </form>


                </div>

                <div className={isShowModalUserEdited ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                    <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
                        <p>
                            <span onClick={() => setIsShowModalUserEdited(false)}>{IconsContent.Close()}</span>
                        </p>
                        <div>
                            <p>{IconsContent.DocCreated()}</p>
                            <p>Информация о пользователе обновлена!</p>
                            <div className='isShowModalDeleteUserConfirm'>
                                <button onClick={() => { setIsShowModalUserEdited(false); window.location.reload() }}>Закрыть</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={isShowModalError ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                    <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
                        <p>
                            <span onClick={() => setIsShowModalError(false)}>
                                {IconsContent.Close()}
                            </span>
                        </p>
                        <div>
                            <p>{IconsContent.Error()}</p>
                            <p>Произошла ошибка</p>
                            <button onClick={() => {
                                setIsShowModalError(false);
                                // window.location.reload();
                            }}>
                                Закрыть
                            </button>
                        </div>
                    </div>
                </div>

            </main>
        </div>
    )
}