export default function (pathname) {
	const splitValue = localStorage.getItem('.UserLocation') ;
	if (splitValue === "/corrector") {
		return "Корректор";
	}
	if (splitValue === "/expert") {
		return "Эксперт";
	}
	if (splitValue === "/translator") {
		return "Переводчик";
	}
	if (splitValue === "/coordinator") {
		return "Координатор";
	}
	if (splitValue === "/administrator") {
		return "Администратор";
	}
	if (splitValue === "/superadministrator") {
		return "Суперадминистратор";
	}
	if (splitValue === "/clerk") {
		return "Делопроизводитель";
	}
	if (splitValue === "/economist") {
		return "Экономист";
	}

	return pathname;
}
