import axios from "axios";
import IconsContent from "../../Presentation/Assets/img/IconsContent";
import {useTranslation} from "react-i18next";

const BASE_URL = 'https://api.saraptama.crocos.kz'
// const BASE_URL = 'https://api.anticorr.kazguu.kz'
// const BASE_URL = process.env.REACT_APP_BASE_URL 

 const axiosInstance = axios.create({
    baseURL: `${BASE_URL}/api/v1`,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
 })
 export default axiosInstance

export const CoordinatorNavBar =  () => {
    const { t, i18n } = useTranslation("common");
  return [
    {
        name: t("project"),
        goTo: '/coordinator',
        iCon: IconsContent.BriefCase()
    },
    {
        name: t("transmittal_letters"),
        goTo: '/coordinator/transmittalletters',
        iCon: IconsContent.ClipBoard()
    },
    {
        name: t("conclusions"),
        goTo: '/coordinator/conclusions',
        iCon: IconsContent.FillCheck()
    },

    // {
    //     name: t("expert_statistic"),
    //     goTo:  "/coordinator/experts-statistics",
    //     iCon: IconsContent.Users()
    // },
    {
        name: t("reports"),
        goTo: "/coordinator/reports",
        iCon: IconsContent.FileLineChar(),
        dropDown: [
            {
                name: t("reports_priced"),
                goTo: `/coordinator/reports/priced`
            },
        ]
    }
]
}
export const AdministratorkNavBar =  () => {
    const { t, i18n } = useTranslation("common");
  return [
    {
        name: t("project"),
        goTo: '/administrator',
        iCon: IconsContent.BriefCase()
    },
    {
        name: t("transmittal_letters"),
        goTo: '/administrator/transmittalletters',
        iCon: IconsContent.ClipBoard()
    },
    {
        name: t("conclusions"),
        goTo: '/administrator/conclusions',
        iCon: IconsContent.FillCheck()
    },

    {
        name: t("users"),
        goTo:  "/administrator/users",
        iCon: IconsContent.Users()
    },
    {
        name: t("catalogs"),
        goTo:  "/administrator/catalogs",
        iCon: IconsContent.Handbooks()
    }
]
}
export const ExpertNavBar =  () => {
    const { t, i18n } = useTranslation("common");
  return [
    {
        name: t("project"),
        goTo: '/expert',
        iCon: IconsContent.BriefCase()
    },
    {
        name: t("conclusions"),
        goTo: '/expert/conclusions',
        iCon: IconsContent.ClipBoard()
    },
    {
        name: t("risk_factors"),
        goTo: '/expert/factors',
        iCon: IconsContent.AlertCircle()
    },
    // {
    //     name: 'Статистика',
    //     goTo: "/expert/statistics/projects",
    //     iCon: IconsContent.Statistics(),
    //     dropDown: [
    //         {
    //             name: `В разрезе проектов`,
    //             goTo: `/expert/statistics/projects`
    //         },
    //         {
    //             name: `В разрезе заключений`,
    //             goTo: `/expert/statistics/conclusions`
    //         },
    //         {
    //             name: `В разрезе экспертов`,
    //             goTo: `/expert/statistics/experts`
    //         },
    //         {
    //             name: `В разрезе разработчиков НПА`,
    //             goTo: `/expert/statistics/develop`
    //         }
    //     ]
    // },
    // {
    //     name: t("reports"),
    //     goTo: "/expert/reports",
    //     iCon: IconsContent.Report()
    // }
]
}
export const EconomistNavBar =  () => {
    const { t, i18n } = useTranslation("common");
  return [
    {
        name: t("project"),
        goTo: '/economist',
        iCon: IconsContent.BriefCase()
    },
    {
        name: t("catalogs"),
        goTo: '/economist/document-types',
        iCon: IconsContent.FillCheck()
    }
]
}
export const ClerkNavBar =  () => {
    const { t, i18n } = useTranslation("common");
  return [
    {
        name: t("project"),
        goTo: '/clerk',
        iCon: IconsContent.BriefCase()
    },
    {
        name: t("conclusions"),
        goTo: '/clerk/conclusions',
        iCon: IconsContent.FillCheck()
    }
]
}