import React from 'react'
import { useState } from "react";
import { UserService } from "../../../Main/Services/UserService";
import { useForm } from "react-hook-form";
import IconsContent from "../../Assets/img/IconsContent";
import EyeView from '../../Assets/icons/EyeView';
import EyeHide from '../../Assets/icons/EyeHide';
import Preloader from "../Preloader/Preloader";

const ModalAdministrator = ({ isShowModalConclusionSentForECP, setIsShowModalConclusionSentForECP, transmittalLetterId }) => {
    const [isShowModalDocCreated, setIsShowModalDocCreated] = useState(false)
    const [fileName3, setFileName3] = useState('')
    const [error, setError] = useState()
    const [showPassword, setShowPassword] = useState(false)
    const [newPlayAnimation, setNewPlayAnimation] = useState(false)

    const { register, reset, control, handleSubmit, formState: { errors } } = useForm({ mode: 'onBlur' })

    const onSubmit = (newProjectData) => {
        setNewPlayAnimation(true)

        var formdata = new FormData();
        formdata.append("_method", "put");
        formdata.append("eds[password]", newProjectData.password);
        formdata.append("eds[file]", newProjectData.file3[0])
        UserService.uploadTransmittalLetterECP(transmittalLetterId, formdata)
            .then((response) => {
                setNewPlayAnimation(false)
                setIsShowModalConclusionSentForECP(false)
                setIsShowModalDocCreated(true)
            })
            .catch(err => { setNewPlayAnimation(false); setError("Не верный пароль") })
    }

    return (
        <>
            <div className={isShowModalConclusionSentForECP ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                {newPlayAnimation && <Preloader playAnimation={false} />}
                <div className="transmittal-letters__modal-content transmittal-letters__form" >
                    <div className="transmittal-letters__wrapper " >
                        <p className="transmittal-letters__modal-heading">
                            <span>Подписание сопроводительного письма через ЭЦП</span>
                            <span onClick={() => setIsShowModalConclusionSentForECP(false)}>{IconsContent.Close()}</span>
                        </p>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <h2 style={{ margin: '10px 0px 40px 0' }}>Вы утверждаете сопроводительное письмо, созданное координатором. После подписания данное письмо будет опубликованно на внешнем портале.</h2>
                            <div className='input_wrapper-ecp'>
                                <div className="inner_wrapper">
                                    <input
                                        type="file"
                                        id={"file"}

                                        {...register("file3", {
                                            onChange: (e) =>
                                                setFileName3(e.target.value.replace(`C:\\fakepath\\`, "")),
                                        })}
                                    />

                                    <label htmlFor={"file"} className={"file_contain"}>

                                        <div className={"file_put"}>
                                            {errors.file ?
                                                <span style={{ color: 'red' }}>Выберите файл</span> :
                                                <span>Выберите файл</span>}
                                            {IconsContent.PaperClip()}

                                        </div>
                                        <span className={"span"}>
                                            {fileName3
                                                ? fileName3
                                                : "Размер файла не должен превышать 20 МБ"}
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div className="input_wrapper" style={{ position: "relative" }}>
                                <label htmlFor="">Введите пароль</label>
                                <div>
                                    <div className="input-password">
                                        <input type={showPassword ? "password" : "text"} {...register("password")} />
                                        <div style={{ position: "absolute", top: "0", right: "0" }} onClick={() => setShowPassword(!showPassword)}>{showPassword ? <EyeHide /> : <EyeView />} </div>

                                    </div>
                                    {errors.password && <p className="form--required">{errors.password.message}</p>}
                                    {error && <p style={{ textAlign: 'center', marginTop: '20px' }} className="form--required">{error}</p>}
                                </div>
                            </div>

                            <div className="btn_action" >
                                <button className="btn_assign header__content-correct" >
                                    Подписать
                                </button>
                                <button className="btn_assign" type='button' onClick={() => setIsShowModalConclusionSentForECP(false)}>
                                    Отмена
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={isShowModalDocCreated ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
                    <p>
                        <span onClick={() => setIsShowModalDocCreated(false)}>
                            {IconsContent.Close()}
                        </span>
                    </p>
                    <div>
                        <p>{IconsContent.DocCreated()}</p>
                        <p style={{ textAlign: "center", marginTop: "20px" }}>Сопроводительное письмо подписано и опубликовано на внешнем портале.</p>
                        <button onClick={() => { setIsShowModalDocCreated(false); window.location.reload(); }}>
                            Закрыть
                        </button>
                    </div>
                </div>
            </div>
        </>

    )
}

export default ModalAdministrator
