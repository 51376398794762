import React from 'react'
import '../Statistics.scss'
import { Link } from 'react-router-dom'
import TableFirst from '../../../TableFirst/TableFirst';


const StatisticsContainerByNPADevs = () => {

    const developersArray = [
        {
            id: 1254,
            devName: 'Министерство финансов РК',
            devType: 'Центральные государственные органы',
            projectsAmmount: 247,
            riskFactors: [
                {
                    id: 1,
                    coorFormName: 'Коллизии норм права',
                    amount: 19
                },
                {
                    id: 2,
                    coorFormName: 'Двоякая формулировка',
                    amount: 572
                }
            ],
            coorRisks: [
                {
                    id: 1,
                    coorFormName: 'Необоснованный отказ в информации физическим и юридическим лицам, предоставление которой предусмотрено законодательством Республики Казахстан, либо ее задержка, передача недостоверной или неполной информации',
                    amount: 14
                },
                {
                    id: 2,
                    coorFormName: 'Злоупотребление должностными полномочиями',
                    amount: 23
                },
            ]
        },
        {
            id: 123,
            devName: 'Акимат Западно-Казахстанской области',
            devType: 'Местные исполнительные органы',
            projectsAmmount: 47,
            riskFactors: [
                {
                    id: 1,
                    coorFormName: 'Коллизии норм права',
                    amount: 28
                },
                {
                    id: 2,
                    coorFormName: 'Двоякая формулировка',
                    amount: 57
                }
            ],
            coorRisks: [
                {
                    id: 1,
                    coorFormName: 'Необоснованный отказ в информации физическим и юридическим лицам, предоставление которой предусмотрено законодательством Республики Казахстан, либо ее задержка, передача недостоверной или неполной информации',
                    amount: 38
                },
                {
                    id: 2,
                    coorFormName: 'Злоупотребление должностными полномочиями',
                    amount: 23
                },
            ]
        },
    ]
    return (
        <div className='statistics'>
            <h1>Статистика</h1>
            <div className='statistics__developers'>
                <div className='statistics__data-container'>
                    <h3>Статистика в разрезе разработчиков НПА</h3>
                    <div className='statistics__developers-content'>
                        <table>
                            <thead>
                                <tr>
                                    <TableFirst/>
                                    <td>НАИМЕНОВАНИЕ</td>
                                    <td>ТИП ОРГАНА-РАЗРАБОТЧИКА</td>
                                    <td>КОЛЛИЧЕСТВО ПРОЭКТОВ</td>
                                    <td>КОЛИЧЕСТВО ФАКТОРОВ РИСКА</td>
                                    <td>КОЛЛИЧЕСТВО РИСКОВ КОРРУПЦИИ</td>
                                </tr>
                            </thead>
                            <tbody>
                                {developersArray.map(item =>
                                    <tr>
                                        <td>{item.id}</td>
                                        <td><Link to={{pathname:`/expert/statistics/develop/${item.devName}`}} state={[item.riskFactors,item.coorRisks]}>{item.devName}</Link></td>
                                        <td>{item.devType}</td>
                                        <td>{item.projectsAmmount}</td>
                                        <td>{item.riskFactors.reduce((prev, next) => prev + next.amount, 0)}</td>
                                        <td>{item.coorRisks.reduce((prev, next) => prev + next.amount, 0)}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StatisticsContainerByNPADevs