import IconsContent from "../../../Assets/img/IconsContent";
import { useEffect, useState } from "react";
import iconsContent from "../../../Assets/img/IconsContent";
import { Link } from "react-router-dom";

export default function CatalogsContainer() {
    const [isShowModal, setIsShowModal] = useState(false)
    const [isDeleted, setIsDeleted] = useState(false)

    const catalogsData = [
        ['Тип документа','doc-types'],
        ['Отрасль законодательства'],
        ['Риски коррупции'],
        ['Wiki факторы риска'],
        ['Области/риски'],
        ['Города/Регионы'],
        ['МРП и МЗП'],
        ['Органы-разработчики','auth-developers'],
    ]

    return (
        <div className={' projects_page catalogs_page'}>
            <div className="header_wrapper_name">
                <h1>Справочники</h1>
                <span className={'add_project'}>Добавить {IconsContent.AddProject()}</span> 
            </div>
            <main>
                {catalogsData.map((item,index) => {
                    return(
                    <div className="catalogs__container">
                        <p>
                            <span>{item[0]}</span>
                            <span><Link to={`/administrator/catalog/${item[1]}`} state={item}>{IconsContent.ArrowRight()}</Link></span>
                        </p>
                        <span onClick={()=>setIsShowModal(true)}>{IconsContent.Bin()}</span>
                    </div>
                    )
                })}
            </main>
            {
                <div 
                    className={ isShowModal? "transmittal-letters__modal transmittal-letters__modal-active":"transmittal-letters__modal"}
                    onClick={()=>setIsShowModal(false)}
                >
                <div className="transmittal-letters__modal-content" onClick={(e)=>e.stopPropagation()}>
                    <p className="transmittal-letters__modal-heading">
                        <span>Удаление справочника</span>
                        <span onClick={()=>setIsShowModal(false)}>{IconsContent.Close()}</span>
                    </p>
                    {isDeleted?
                        <h6>Справочник [Наименование справочника] успешно удален</h6>
                        :
                        <h6>Вы уверены что хотите удалить справочник [Наименование справочника]?</h6>
                    }
                    <div className="btn_action btn_action-admin" >
                        {isDeleted?null:<button className="btn_finalize" onClick={()=>setIsDeleted(true)}>Удалить</button>}
                        <button  className="btn_assign" onClick={()=>{
                            setIsShowModal(false)
                            setTimeout(()=>{
                                setIsDeleted(false)
                            },300)
                        }}>Закрыть</button>
                    </div>
                </div>
                </div>
            }
        </div>
    )
}