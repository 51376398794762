
import IconsContent from "../../Assets/img/IconsContent";
import { useEffect, useState } from "react";
import iconsContent from "../../Assets/img/IconsContent";
import DatePickerInput from "../DatePicker/DatePicker";
import TableFirst from '../TableFirst/TableFirst';
import { UserService } from "../../../Main/Services/UserService";
import { InputSelect } from "../Select/InputSelect";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";


export default function ReportsContainer({ expertsList, authorityDevelopers, projectsReport }) {
  const userLocation = localStorage.getItem('.UserLocation')
  const { t, i18n } = useTranslation("common");
  const [projectsFiltered, setProjectsFiltered] = useState()
  const [onClear, setOnClear] = useState(false)
  const [filteredPage, setFilteredPage] = useState(1)
  const [isFilter, setIsFilter] = useState(false)
  const [filteredData, setFilteredData] = useState('')

  const { register, handleSubmit, control, reset, formState: { errors } } = useForm({ mode: "onBlur" });
  const onSubmit = (filteredData) => {

    //---React-Hook object converting to URL string
    let arrFilterItems = Object.keys(filteredData).map((item, index) => {
      if (Object.values(filteredData)[index] !== '' && Object.values(filteredData)[index] !== undefined && Object.values(filteredData)[index] !== "Invalid date") {
        return `filter[${item}]=${Object.values(filteredData)[index]}`
      }
    })
      .filter(item => item !== undefined)
    console.log(arrFilterItems)
    let newArr = arrFilterItems.map((item, index) => index == arrFilterItems.length - 1 ? `${item}` : `${item}&`)
    console.log(newArr.toString().replace(/[,]/gi, ''))
    //---------------------------------------------

    // UserService.getFilteredData(newArr.toString().replace(/[,]/gi, ''))
    setFilteredData(newArr.toString().replace(/[,]/gi, ''))
    setFilteredPage(1)
    // UserService.getFilteredData(filteredPage,newArr.toString().replace(/[,]/gi, ''))
    //     .then(response => {
    //         
    //         setProjectsFiltered(response.data)
    //     })
    // reset()
  }


  // useEffect(() => {
  //   UserService.getFilteredDataCompleted(filteredPage, filteredData)
  //     .then(res => setProjectsFiltered(res.data))
  //     .catch(err => console.log(err))
  // }, [filteredPage, filteredData])


  return (
    <div className={'conclusion__coordinator users__page projects_page'}>
      <h1>{t("reports")}</h1>
      <main>
        <div className="main_header">
          <button className={'btn_filter'} onClick={() => { setIsFilter(true) }}>
            <span className="name">Фильтр</span>
            {IconsContent.Filter()}
          </button>
          <div className={"btn_search"}>
            {IconsContent.Search()}
            <input type="email" placeholder={'Поиск...'} />
          </div>
          {/* <div className="btn_export">Экспорт в Excel</div> */}
        </div>
        <div className="table_wrapper" style={{ overflowX: "scroll" }}>
          <table className={'table_styling'}>
            <thead >
              <tr className={'table_heading '}>
                <TableFirst />
                <td style={{ minWidth: "500px" }}>НАИМЕНОВАНИЕ НОРМАТИВНОГОПРАВОВОГО АКТА, ПРИНЯТОГО В ОТЧЕТНОМ ПЕРИОДЕ</td>
                <td >ДАТА ПРИНЯТИЯ</td>
                <td >РЕГИСТРАЦИОННЫЙ НОМЕР</td>
                <td style={{ minWidth: "200px" }}>ЭКСПЕРТЫ</td>
                <td style={{ minWidth: "300px" }}>ОБЩЕЕ КОЛЛИЧЕСТВО РЕКОМЕНДАЦИЙ, ПРЕДСТАВЛЕННЫХ В ЗАКЛЮЧЕНИИ НАЭ ПО ПРОЕКТУ НПА</td>
                <td style={{ minWidth: "300px" }}>ОБЩЕЕ КОЛЛИЧЕСТВО РЕКОМЕНДАЦИЙ, ВНЕСЕННЫХ В ПРИНЯТЫЙ НПА ПО ИТОГАМ НАЭ</td>
                <td className="last"></td>
              </tr>
            </thead>
            <tbody>
              {projectsReport && projectsReport.data.map((res, index) => {
                const isSecond = index % 2
                return (
                  <>
                    <tr key={res?.index} className={'table_body '} style={{ backgroundColor: `${isSecond === 1 ? `#F9F9F9` : `inherit`}` }}>
                      <td>{res?.id}</td>
                      <td>0</td>
                      <td>0</td>
                      <td className="table_nowrap">0</td>
                      <td colSpan={4}>0</td>
                    </tr>

                  </>
                )
              })}
            </tbody>
          </table>

          {/* <div className="table_wrapper_footer">
            <div className="footer_number">
              {projectsFiltered && <p>Найдено {projectsFiltered.meta.total} из {projects.meta?.total} </p>}
            </div>
            <div className="footer_pagination">
              {projectsFiltered && projectsFiltered.meta?.total > projectsFiltered.meta?.per_page ? [...Array(Math.ceil(projectsFiltered.meta.total / projectsFiltered.meta?.per_page))].map((item, index) =>
                <button className={filteredPage == index + 1 ? 'btn_pagination active' : 'btn_pagination'} onClick={() => setFilteredPage(index + 1)}> {index + 1}</button>) : null}
            </div>
          </div> */}
        </div>
      </main>

      <div className={`modal_filter ${isFilter ? 'active' : ''}`}>
        <div className={`modal_content`}>
          <div className="modal_header">
            <div className="header_word">
              <h5>Фильтр</h5>
              {IconsContent.Filter('#4ABD9B', `2`)}
            </div>
            <button onClick={() => { setIsFilter(false) }}>{iconsContent.Cancel()}</button>
          </div>
          <div className="modal_form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="input_wrapper">
                <label htmlFor="">ID</label>
                <input type="number" {...register(`id`)} />
              </div>
              <div className="input_wrapper">
                <label htmlFor="">Наименование проекта нормативного правового акта</label>
                <input type="text" {...register(`name`)} />
              </div>
              <div className="input_wrapper ">
                <label htmlFor="">Эксперт</label>
                <InputSelect control={control} name="expert_id" noRequired onClear={onClear} placeholder="Выберите" options={expertsList} />
              </div>
              <div className="input_wrapper ">
                <label htmlFor="">Орган разработчик</label>
                <InputSelect control={control} name="authority_developer_id" noRequired onClear={onClear} placeholder="Выберите" options={authorityDevelopers} />
              </div>
              <div className="input_wrapper ">
                <label htmlFor="">Крайний срок выполнения</label>
                <DatePickerInput noRequired onClear={onClear} name='deadline' control={control} />
              </div>

            </form>
          </div>
        </div>
        <div className="modal_cover" onClick={() => { setIsFilter(false) }} />
      </div>
    </div>
  )
}
