import "../../Styles/Layout.css";
import CatalogsContainer from "../../Components/pages/Administrator/CatalogsContainer";
import MainLayout from "../../Components/Layout/MainLayout";
import { useLocation } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import IconsContent from "../../Assets/img/IconsContent";
import Preloader from "../../Components/Preloader/Preloader";
import { useEffect, useState } from "react";
import { AdministratorkNavBar } from "../../../Main/Services/http";

export default function CatalogsPage(){
    
    const [playAnimation, setPlayAnimation] = useState(false)
    useEffect(() => {
	  const onPageLoad = () => {
		setPlayAnimation(true);
	  };
  
	  if (document.readyState === 'complete') {
		onPageLoad();
	  } else {
		window.addEventListener('load', onPageLoad);
		return () => window.removeEventListener('load', onPageLoad);
	  }
	}, []);
    return(
        <MainLayout>
            <Preloader playAnimation={playAnimation}/>
            <Navbar direction={AdministratorkNavBar()}/>
            <CatalogsContainer/>
        </MainLayout>
    )
}