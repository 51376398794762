import MainLayout from "../../Components/Layout/MainLayout";
import FormViewContainer from "../../Components/pages/FormViewContainer";
import { CoordinatorNavBar } from "../../../Main/Services/http";
import Navbar from "../../Components/Navbar/Navbar";
import { useState, useEffect } from "react";
import { UserService } from "../../../Main/Services/UserService";
import { useLocation } from "react-router-dom";
import Preloader from "../../Components/Preloader/Preloader";

export default function FormView() {

    let projectId = useLocation().pathname.replace(/[a-z\/]/g, '')

    const [project, setProject] = useState()
    const [expertsList, setExpertsList] = useState()
    const [correctorsList, setCorrectorsList] = useState()
    const [translatorsList, setTranslatorsList] = useState()
    const [coordinatorsList, setCoordinatorsList] = useState()

    useEffect(() => {
        UserService.getProject(projectId)
            .then(response => {
                let arr = response.data.data

                setProject(arr);
            })
        UserService.getCoordinators()
            .then(response => {

                let arr = response.data.data.map(item => { return { value: item.id, label: item.full_name } })
                setCoordinatorsList(arr);
            });
        UserService.getExperts()
            .then(response => {

                let arr = response.data.data.map(item => { return { value: item.id, label: item.full_name } })
                setExpertsList(arr);
            });
        UserService.getCorrectors()
            .then(response => {

                let arr = response.data.data.map(item => { return { value: item.id, label: item.full_name } })

                setCorrectorsList(arr);
            });
        UserService.getTranslators()
            .then(response => {
                let arr = response.data.data.map(item => { return { value: item.id, label: item.full_name } })

                setTranslatorsList(arr);
            });
    }, []);

    const [playAnimation, setPlayAnimation] = useState(false)
    useEffect(() => {
        const onPageLoad = () => {
            setPlayAnimation(true);
        };

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    return (
        <MainLayout >
            <Preloader playAnimation={playAnimation} />

            <Navbar direction={CoordinatorNavBar()} />
            {(project && expertsList && correctorsList && translatorsList) ? (
                <FormViewContainer
                    project={project}
                    expertsList={expertsList}
                    correctorsList={correctorsList}
                    translatorsList={translatorsList}
                    projectId={projectId}
                    coordinatorsList={coordinatorsList}
                />)
                : <></>}

        </MainLayout>
    )
}