import iconsContent from "../../Assets/img/IconsContent";
import {Link, useLocation} from "react-router-dom";
import {useState} from "react";

export default function NavDrop({element, index}){
    const [isClick, setIsClick] = useState(false)
    const location = useLocation();
    const pathName = location.pathname

    return(
        <li key={index} className={`direct ${isClick ? `btn_active` : null}`} onClick={() => {setIsClick(!isClick)}} style={{position: `relative`}}>
            <a className={'drop'}>{element.iCon}<Link to={element.goTo}>{element.name}</Link> {iconsContent.ArrDown('drop_Ic')}</a>
            {isClick && <div className="dropdown_content">
                {element.dropDown.map((other, index) => <Link to={other.goTo} className={`${pathName === other.goTo ? `active` : null}`}>{other.name}</Link>)}
            </div>}

        </li>
    )
}