import "../../Styles/Layout.css";
import DocumentTypeContainer from "../../Components/pages/DocumentTypeContainer";
import MainLayout from "../../Components/Layout/MainLayout";
import Navbar from "../../Components/Navbar/Navbar";
import { EconomistNavBar } from "../../../Main/Services/http";
import { UserService } from "../../../Main/Services/UserService";
import { useEffect, useState } from "react";
import Preloader from "../../Components/Preloader/Preloader";

export default function CoordinatorIndexPage() {

    const [documentTypes, setDocumentTypes] = useState()


    useEffect(() => {
        UserService.getDocumentTypes()
            .then(response => {

                setDocumentTypes(response.data.data);
            });
    }, [])

    const [playAnimation, setPlayAnimation] = useState(false)
    useEffect(() => {
        const onPageLoad = () => {
            setPlayAnimation(true);
        };

        if (document.readyState === 'complete') {
            setTimeout(() => {
                onPageLoad();
            }, 2000);
        } else {
            window.addEventListener('load', onPageLoad);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    return (
        <MainLayout>
            <Preloader playAnimation={playAnimation} />
            <Navbar direction={EconomistNavBar()} />
            {documentTypes && <DocumentTypeContainer documentTypes={documentTypes} />}
        </MainLayout>
    )
}