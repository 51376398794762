import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "../Pages/LoginPage/LoginPage";
import HomePage from "../Pages/HomePage/HomePage";

//-----------Corrector Pages Imports
import CorrectorIndexPage from "../Pages/Corrector";
import FormViewCorrector from "../Pages/Corrector/FormViewCorrector";
import ConclusionsCorrector from "../Pages/Corrector/ConclusionsCorrector";
import FactorsCorrector from "../Pages/Corrector/FactorsCorrector";
import ProfileCorrector from "../Pages/Corrector/ProfileCorrector";
import ConclusionCorrectorDetails from "../Pages/Corrector/ConclusionCorrectorDetails";


//-----------Translator Pages Imports
import TranslatorIndexPage from "../Pages/Translator";
import FormViewTranslator from "../Pages/Translator/FormViewTranslator";
import ConclusionsTranslator from "../Pages/Translator/ConclusionsTranslator";
import FactorsTranslator from "../Pages/Translator/FactorsTranslator";
import ProfileTranslator from "../Pages/Translator/ProfileTranslator";
import ConclusionTranslatorDetails from "../Pages/Translator/ConclusionTranslatorDetails";

//-----------Clerk Pages Imports
import ClerkIndexPage from "../Pages/Clerk";
import AddProjectClerk from "../Pages/Clerk/addProject";
import FormViewClerk from "../Pages/Clerk/FormViewClerk";
import ConclusionsClerk from "../Pages/Clerk/ConclusionsClerk";
import ProfileClerk from "../Pages/Clerk/ProfileClerk";
import ConclusionClerkDetails from "../Pages/Clerk/ConclusionClerkDetails";

//-----------Coordinator Pages Imports
import CoordinatorIndexPage from "../Pages/Coordinator";
import AddProject from "../Pages/Coordinator/addProject";
import TransmittalLetters from "../Pages/Coordinator/TransmittalLetters";
import TransmittalLettersForm from "../Pages/Coordinator/TransmittalLettersForm";
import FormViewCoordinator from "../Pages/Coordinator/FormViewCoordinator";
import ConclusionsCoordinator  from "../Pages/Coordinator/ConclusionsCoordinator";
import ConclusionCoordinatorDetails from "../Pages/Coordinator/ConclusionCoordinatorDetails";
import ProfileCoordinator from "../Pages/Coordinator/ProfileCoordinator";
import ReportsCoordinator from "../Pages/Coordinator/Reports";
import ReportsPricedCoordinator from "../Pages/Coordinator/ReportsPriced";
import ReportsPricedDetailsCoordinator from "../Pages/Coordinator/ReportsPricedDetails";
import ReportsDetailsCoordinator from "../Pages/Coordinator/ReportsDetails";

//-----------Expert Pages Import
import ExpertIndexPage from "../Pages/Expert";
import FormViewExpert from "../Pages/Expert/FormViewExpert";
import ConclusionsExpert from "../Pages/Expert/ConclusionsExpert";
// import AddConclusionExpert from "../Pages/Expert/AddConclusionExpert";
import ConclusionExpertDetails from "../Pages/Expert/ConclusionExpertDetails ";
import ConclusionsExpertEdit from    "../Pages/Expert/ConclusionsExpertEdit";
import FactorsExpert from "../Pages/Expert/FactorsExpert";
import StatisticsProjects from "../Pages/Expert/Statistics/StatisticsProjects";
import StatisticsConclusions from "../Pages/Expert/Statistics/StatisticsConclusions";
import StatisticsNPADev from "../Pages/Expert/Statistics/StatisticsNPADevs";
import StatisticsNPADevDetails from "../Pages/Expert/Statistics/StatisticsNPADevsDetails";
import ReportsExpert from "../Pages/Expert/Reports";
import ProfileExpert from "../Pages/Expert/ProfileExpert";

//-----------Administrator Pages Import
import AdministratorIndexPage from "../Pages/Administrator";
import FormViewAdministrator from "../Pages/Administrator/FormViewAdministrator";
import TransmittalLettersAdministrator from "../Pages/Administrator/TransmittalLettersAdministrator";
import ConclusionsAdministratorDetails from "../Pages/Administrator/ConclusionsAdministratorDetails";
import ConclusionsAdministrator from "../Pages/Administrator/ConclusionsAdministrator";
import UsersAdministrator from "../Pages/Administrator/UsersAdministrator";
import AddUserAdministrator from "../Pages/Administrator/addUser";
import UserDetailsAdministrator from "../Pages/Administrator/UserDetailsAdministrator";
import Catalogs from "../Pages/Administrator/Catalogs";
import ProfileAdministrator from "../Pages/Administrator/ProfileAdministrator";
import StatisticsExperts from "../Pages/Coordinator/StatisticsExperts";
import StatisticsExpertsDetails from "../Pages/Coordinator/StatisticsEpertsDetails";
import CatalogDetailsDocTypes from "../Pages/Administrator/CatalogDocTypes";
import CatalogDetailsAuthDevelopers from "../Pages/Administrator/CatalogAuthDevelopers";
import CoordinatorWelcomePage from "../Pages/Coordinator/WelcomePage";
import SuperAdministratorIndexPage from "../Pages/SuperAdministrator";
import FormViewSuperAdmin from "../Pages/SuperAdministrator/FormViewSuperAdministrator";
import ConclusionSuperAdministrator from "../Pages/SuperAdministrator/ConclusionSuperAdministrator";
import ConclusionsSuperAdministratorDetails from "../Pages/SuperAdministrator/ConclusionsSuperAdministratorDetails";
import UsersSuperAdministrator from "../Pages/SuperAdministrator/UsersSuperAdministrator";
import AddUserPageSuperAdministrator from "../Pages/SuperAdministrator/addUserSuperAdministrator";
import UserDetalsSuperAdnibitrator from "../Pages/SuperAdministrator/UserDetailSuperAdministrator";
import EditUserPageSuperAdministrator from "../Pages/SuperAdministrator/editUserSuperAdministrator";

//-----------Economist Pages Import
import EconomistIndexPage from "../Pages/Economist"
import EconomistDocumentTypePage from "../Pages/Economist/DocumentTypeEconomist";
import ProfileEconomist from "../Pages/Economist/ProfileEconomist"

function RoutesContent() {

	return (
		<Routes>
			<Route path="/" element={<LoginPage />} />
			<Route path="/home" element={<HomePage/>} />

			{/* Corrector ROUTING */}
			<Route path={"/corrector"} element={<CorrectorIndexPage />} />
			<Route path={"/corrector/projects/*"} element={<FormViewCorrector />} />
			<Route path={"/corrector/conclusions"} element={<ConclusionsCorrector />}/>
			<Route path={"/corrector/conclusions/details/*"} element={<ConclusionCorrectorDetails />} />
			<Route path={"/corrector/factors"} element={<FactorsCorrector />} />
			<Route path={"/corrector/profile"} element={<ProfileCorrector />} />

			{/* Translator ROUTING */}
			<Route path={"/translator"} element={<TranslatorIndexPage />} />
			<Route path={"/translator/projects/*"} element={<FormViewTranslator />} />
			<Route path={"/translator/conclusions"}element={<ConclusionsTranslator />}/>
			<Route path={"/translator/conclusions/details/*"} element={<ConclusionTranslatorDetails />} />
			<Route path={"/translator/factors"} element={<FactorsTranslator />} />
			<Route path={"/translator/profile"} element={<ProfileTranslator />} />

			{/* Clerk ROUTING */}
			<Route path={"/clerk"} element={<ClerkIndexPage />} />
			<Route path="/clerk/addProject" element={<AddProjectClerk />} />
			<Route path={"/clerk/projects/*"} element={<FormViewClerk />} />
			<Route path={"/clerk/conclusions"}element={<ConclusionsClerk />}/>
			<Route path={"/clerk/conclusions/details/*"} element={<ConclusionClerkDetails />} />
			<Route path={"/clerk/profile"} element={<ProfileClerk/>} />

			{/* Expert ROUTING */}
			<Route path="/expert" element={<ExpertIndexPage />} />
			{/* <Route path="/expert/addConclusion" element={<AddConclusionExpert />} /> */}
			<Route path="/expert/projects/*" element={<FormViewExpert />} />
			<Route path="/expert/conclusions" element={<ConclusionsExpert />} />
			<Route path="/expert/conclusions/details/*" element={<ConclusionExpertDetails  />} />
			<Route path="/expert/conclusions/edit/*" element={<ConclusionsExpertEdit  />} />
			<Route path="/expert/factors" element={<FactorsExpert />} />
			<Route path="/expert/statistics/projects"element={<StatisticsProjects />}/>
			<Route path="/expert/statistics/conclusions"element={<StatisticsConclusions />}/>
			<Route path="/expert/statistics/develop" element={<StatisticsNPADev />} />
			<Route path="/expert/statistics/develop/:developID"element={<StatisticsNPADevDetails />}/>
			<Route path="/expert/reports" element={<ReportsExpert />} />
			<Route path="/expert/profile" element={<ProfileExpert />} />

			{/* Coordinator ROUTING */}
			<Route path={"/coordinator/WelcomePage"}element={<CoordinatorWelcomePage />}/>
			<Route path="/coordinator" element={<CoordinatorIndexPage />} />
			<Route path="/coordinator/projects/*" element={<FormViewCoordinator />} />
			<Route path="/coordinator/conclusions"element={<ConclusionsCoordinator />}/>
			<Route path="/coordinator/conclusions/details/*"element={<ConclusionCoordinatorDetails />}/>
			<Route path="/coordinator/transmittalletters"element={<TransmittalLetters />}/>
			<Route path="/coordinator/transmittalletters/form/*" element={<TransmittalLettersForm />}/>
			<Route path="/coordinator/experts-statistics"element={<StatisticsExperts />}/>
			<Route path="/coordinator/experts-statistics/*"element={<StatisticsExpertsDetails />}/>
			{/* <Route path="/coordinator/users" element={<UsersCoordinator />} />
			<Route path="/coordinator/user/details/*" element={<UserDetailsCoordinator />} />
			<Route path="/coordinator/addUser" element={<AddUser />} /> */}
			<Route path="/coordinator/reports" element={<ReportsCoordinator />} />
			<Route path="/coordinator/reports/details/*" element={<ReportsDetailsCoordinator />} />
			<Route path="/coordinator/reports/priced" element={<ReportsPricedCoordinator />}/>
			<Route path="/coordinator/reports/priced/details/*" element={<ReportsPricedDetailsCoordinator />}/>
			<Route path="/coordinator/profile" element={<ProfileCoordinator />} />
			<Route path="/coordinator/addProject" element={<AddProject />} />

			{/* Administrator ROUTING */}
			<Route path="/administrator" element={<AdministratorIndexPage />} />
			<Route path="/administrator/projects/*" element={<FormViewAdministrator />}/>
			<Route path="/administrator/transmittalletters" element={<TransmittalLettersAdministrator />}/>
			<Route path="/administrator/conclusions"element={<ConclusionsAdministrator />}/>
			<Route path="/administrator/conclusions/details/*"element={<ConclusionsAdministratorDetails />}/>
			<Route path="/administrator/users" element={<UsersAdministrator />} />
			<Route path="/administrator/addUser" element={<AddUserAdministrator />} />
			<Route path="/administrator/profile" element={<ProfileAdministrator />} />
			<Route path="/administrator/user/details/*" element={<UserDetailsAdministrator />}/>
			<Route path="/administrator/catalogs" element={<Catalogs />} />
			<Route path="/administrator/catalog/doc-types" element={<CatalogDetailsDocTypes />}/>
			<Route path="/administrator/catalog/auth-developers" element={<CatalogDetailsAuthDevelopers />}/>
			{/* <Route path="/administrator/transmittalletters/form" element={<TransmittalLettersFormAdministrator />}/> */}

			{/* SuperAdministrator ROUTING */}
			<Route path="/superadministrator" element={<SuperAdministratorIndexPage />} />
			<Route path="/superadministrator/projects/*" element={<FormViewSuperAdmin />} />
			<Route path="/superadministrator/conclusions" element={<ConclusionSuperAdministrator />} />
			<Route path="/superadministrator/conclusions/details/*" element={<ConclusionsSuperAdministratorDetails />} />
			<Route path="/superadministrator/users" element={<UsersSuperAdministrator />} />
			<Route path="/superadministrator/users/addUser" element={<AddUserPageSuperAdministrator />} />
			<Route path="/superadministrator/users/details/*" element={<UserDetalsSuperAdnibitrator />} />
			<Route path="/superadministrator/users/editUser" element={<EditUserPageSuperAdministrator />} />

			{/* Economist ROUTING */}
			<Route path={"/economist"} element={<EconomistIndexPage />} />
			<Route path={"/economist/document-types"} element={<EconomistDocumentTypePage />} />
			<Route path={"/economist/profile"} element={<ProfileEconomist/>} />

		</Routes>
	);
}
export default RoutesContent;
