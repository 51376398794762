import s from '../../Styles/PageRole.module.css'
import IconsContent from '../../Assets/img/IconsContent'
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import RoleFinder from "../roleFinder/RoleFinder";
import { useTranslation } from "react-i18next";
import Pusher from 'pusher-js';

export default function PageRole() {
    const { t, i18n } = useTranslation()
    function ChangeLanguageF(lang) {
        i18n.changeLanguage(lang)
        document.cookie = `lang=${lang}`;
    }

    const userLocation = localStorage.getItem('.UserLocation')
    const [onClick, setOnClick] = useState(false);
    const [isNotification, setIsNotification] = useState(false)
    const location = useLocation();
    const pathName = location.pathname;
    const [wbSocet, setWbSocet] = useState()
    wbSocet&& alert(wbSocet.message)
    const roles = ['/corrector', '/translator', '/expert', '/coordinator', '/administrator', '/clerk', '/economist']
    let signedRole = roles.filter((item => pathName.includes(item)))


    const state = {
        name: localStorage.getItem('.UserName'),
        role: RoleFinder(pathName)
    }

    // useEffect(() => {
    //     var pusher = new Pusher('7e53ea7da3b5501b1024', {
    //         cluster: 'ap1',
    //     });
    //     const channel = pusher.subscribe('popup-channel');
    //     channel.bind('coordinator-appointed', data => {
    //         // handle the event and update the state
    //         setWbSocet(data)
    //     });
    // }, [wbSocet])

    return (
        <div className={s.role_wrapper}>
            <a href="#"><img src="/img/PageLogo.png" alt="PageLogo" className={s.page_logo} /></a>

            <div className={s.leftSide}>
                {/* {userLocation !== null ? <div className={s.notification}>
                    {IconsContent.BellRing()}
                    <div className={s.notificationNumber}>2</div>

                    {isNotification && <div className={s.showNotification}>
                        <div className={`${s.notifiItem} ${s.dash}`}>
                            <div className={s.itemStatus} style={{ backgroundColor: `#4ABD9B` }} />

                            <p className={s.informator}>Новый проект № (ID) (Наименование проекта) на экспертизу. Срок
                                выполнения: до (дата)</p>
                        </div>

                        <div className={s.notifiItem}>
                            <div className={s.itemStatus} style={{ backgroundColor: `#4ABD9B` }} />

                            <p className={s.informator}>Эксперт [ФИО Эксперта] отправил на предварительную проверку
                                заключение №(ID заключения) проекта № (ID) (Наименование проекта)</p>
                        </div>
                    </div>}

                    <div className={s.cover} onClick={() => { setIsNotification(!isNotification) }} />
                </div> : null} */}
                <div className={s.LanguageComponent}>
                    <button className={i18n.language === 'kk' ? s.btn_active : s.btn_inactive} onClick={() => { ChangeLanguageF('kk') }} >Қаз</button>
                    <button className={i18n.language === 'ru' ? s.btn_active : s.btn_inactive} onClick={() => { ChangeLanguageF('ru') }}>Рус</button>
                </div>
                <div className={s.btnWrapper}>
                    {userLocation !== null ? <button className={s.btn_page_role} onClick={() => { setOnClick(!onClick) }}>
                        {IconsContent.User()}
                        <div className={s.info}>
                            <span className={s.user_name}>
                                {state?.name}
                            </span>
                            <span className={s.user_role}>
                                {state?.role}
                            </span>
                        </div>
                        {IconsContent.ArrowDown()}
                    </button> :
                        <Link to="/"><button className={s.btn_page_role} >Войти {IconsContent.User()}</button></Link>}

                    {onClick && <div className={s.dropContent}>
                        <Link to={`${userLocation}/profile`} className={`${pathName === `${signedRole}/profile` ? s.btn_active : null}`}>Профиль</Link>
                        <Link to={'/'}><p onClick={() => {
                            localStorage.removeItem('.AuthToken');
                            localStorage.removeItem('.UserLocation');
                            localStorage.removeItem('.UserName');
                            localStorage.removeItem('.UserID');
                        }}>Выход</p></Link>
                    </div>}

                </div>

            </div>


        </div>
    )
}