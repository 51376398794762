import React from 'react'
import { useState, useEffect } from "react";
import { useForm, Controller } from 'react-hook-form'
import { UserService } from "../../../Main/Services/UserService";
import Select from "react-select";
import IconsContent from '../../Assets/img/IconsContent';
import { InputSelect } from "../Select/InputSelect";
import DatePickerInput from "../DatePicker/DatePicker";
import Preloader from "../Preloader/Preloader";

const ModalExpert = ({ project, expertsList, projectId, expertRecomended, setNewPlayAnimation, isShowModalAddExpert, setIsShowModalAddExpert, correctorsList, translatorsList, setIsExpertAdded }) => {
    // const [expertRecomended, setExpertRecomended] = useState()
    
    const [isExpertSelected, setIsExpertSelected] = useState(() => { if (expertRecomended) { return false } else { return true } })
    const [expertSelected, setExpertSelected] = useState(expertRecomended?.expert.id)
    const [isShowModalError, setIsShowModalError] = useState(false);

    
    // const [expertRecomendedID, setExpertRecomendedID] = useState()
    // const [expertRecomendedCount, setExpertRecomendedCount] = useState()

    // const getExpertRecomendName =  () => {
    //    UserService.getExpertRecomend(projectId).then((response) => {
    //         console.log(response);
    //         // setExpertRecomendedID(response?.data?.data?.id)
    //         setExpertRecomended(response?.data?.data);
    //         // setExpertRecomendedCount(response?.data?.data?.count)
    //     }).catch(err => console.log(err))

    // }
    // isShowModalAddExpert&&getExpertRecomendName()

    const { register, reset, control, handleSubmit, formState: { errors } } = useForm({ mode: 'onBlur' })

    const onSubmit = (putExpertData) => {
        putExpertData = {
            ...putExpertData,
            members: [
                { ...putExpertData.members[0], expert_id: expertSelected ? expertSelected : expertRecomended.expert.id }
            ]
        }
        setNewPlayAnimation(true)
        UserService.putExpert(putExpertData, projectId)
            .then(response => {

                setNewPlayAnimation(false)
                setIsShowModalAddExpert(false)
                setIsExpertAdded(true)
            }).catch(err => { console.log(err); setNewPlayAnimation(false); setIsShowModalError(true) });
    }
    const closeToModal = () => {
        if (expertRecomended?.id) UserService.saveRecExpert(expertRecomended?.id).then(res => console.log(res)).catch(err => console.log(err))
        setIsShowModalAddExpert(false)
    }
    const [hidden, setHidden] = useState(false)
    if (isShowModalAddExpert) {
        setTimeout(() => {
            setHidden(true)
        }, 3000);
    }
    return (
        <>
            <div className={isShowModalAddExpert ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                <div className="transmittal-letters__modal-content transmittal-letters__form" >
                    <div className="transmittal-letters__wrapper">
                        <p className="transmittal-letters__modal-heading">
                            <span>Выберите эксперта</span>
                            <span onClick={() => closeToModal()}>{IconsContent.Close()}</span>
                        </p>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {expertRecomended ?
                                <div className="input_wrapper input_wrapper-addProject expert__preloader">
                                    <label htmlFor="">Рекомендуемый системой эксперт из - {hidden && expertRecomended.count}</label>
                                    <input readOnly defaultValue={expertRecomended.expert.full_name} />
                                    <input className={!hidden ? 'expert__preloader--input' : "hidden"} type="text" value={"Идет подбор экспертов "} />
                                    <div className={!hidden ? 'expert__preloader--div' : "hidden"}></div>
                                </div>
                                :
                                <div className="input_wrapper input_wrapper-addProject expert__preloader">
                                    <label htmlFor="">Рекомендуемый системой эксперт из - {hidden && "0"}</label>
                                    <input readOnly defaultValue={'Подходящего эксперта не найдено'} />
                                    <input className={!hidden ? 'expert__preloader--input' : "hidden"} type="text" value={"Идет подбор экспертов "} />
                                    <div className={!hidden ? 'expert__preloader--div' : "hidden"}></div>
                                </div>
                            }
                            <div className="input_wrapper input_wrapper-addProject">
                                <label htmlFor="">Ручной выбор эксперта</label>
                                <Controller
                                    name="members[0][expert_id]"
                                    control={control}
                                    rules={{ required: isExpertSelected }}
                                    render={({ field: { onChange, ref } }) =>
                                        <Select
                                            isClearable={true}
                                            onChange={(e) => {
                                                onChange(e?.value);
                                                console.log(e);
                                                setExpertSelected(e?.value)
                                            }}
                                            classNamePrefix="custom-select"
                                            placeholder="Выберите"
                                            options={expertsList}
                                        />
                                    } />
                                {errors.members && isExpertSelected && <p className="form--required">Выберите эксперта</p>}
                            </div>
                            {project.corrector == null ?
                                <div className="input_wrapper input_wrapper-addProject">
                                    <label htmlFor="">Выбрать корректора</label>
                                    <InputSelect control={control}
                                        name="corrector_id"
                                        placeholder="Выберите "
                                        options={correctorsList}
                                    // selectedValue={(arg) => setPerson1(arg)}
                                    />
                                    {errors.corrector_id && <p className="form--required">{errors.corrector_id.message}</p>}
                                </div> : null}
                            {project.translator == null ? <div className="input_wrapper input_wrapper-addProject">
                                <label htmlFor="">Выбрать переводчика</label>
                                <InputSelect control={control}
                                    name="translator_id"
                                    placeholder="Выберите "
                                    options={translatorsList}
                                // selectedValue={(arg) => setPerson1(arg)}
                                />
                                {errors.translator_id && <p className="form--required">{errors.translator_id.message}</p>}
                            </div> : null}
                            <div className="input_wrapper addProject_page">
                                <label htmlFor="">Крайний срок выполнения</label>
                                <DatePickerInput name='members[0][deadline]' control={control} />
                                {errors.members && <p className="form--required">{errors.members[0].deadline.message}</p>}
                            </div>
                            <div className="input_wrapper ">
                                <label htmlFor="">Примечания координатора</label>
                                <div>
                                    <textarea type="text" {...register("members[0][note]")} />
                                    {/* {errors.members[0] && <p className="form--required">{errors.members[0].note.message}</p>} */}
                                </div>
                            </div>

                            <div className="btn_action" >
                                <button className="btn_assign"
                                // onClick={() => setIsShowModalAddExpert(false)}
                                >Добавить</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={isShowModalError ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
                    <p>
                        <span onClick={() => setIsShowModalError(false)}>
                            {IconsContent.Close()}
                        </span>
                    </p>
                    <div>
                        <p>{IconsContent.Error()}</p>
                        <p>Произошла ошибка</p>
                        <button onClick={() => {
                            setIsShowModalError(false);
                            window.location.reload();
                        }}>
                            Закрыть
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModalExpert