import React from 'react'
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { UserService } from '../../../Main/Services/UserService';
import IconsContent from '../../Assets/img/IconsContent'
import MainLayout from '../../Components/Layout/MainLayout'
import { useEffect, useState } from "react";
import Preloader from '../../Components/Preloader/Preloader';
import { useTranslation } from "react-i18next";
import Pagination from '@mui/material/Pagination';
import { Link, useLocation } from "react-router-dom";


const HomePage = () => {
    const { t, i18n } = useTranslation("common");
    const [publishedProjects, setPublishedProjects] = useState()
    const [filteredPage, setFilteredPage] = useState(1)
    const navigate = useNavigate()
    const currentURL = useLocation().pathname
    console.log(currentURL)
    useEffect(() => {
        UserService.getProjectsPublish(filteredPage)
            .then(resp => {
                setPublishedProjects(resp.data);
            })
    }, [filteredPage]);

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
    ];
    const customStyles = {
        control: (provided) => ({
            ...provided,
            paddingRight: "20px",
            borderRadius: "60px",
        }),

        input: (provided) => ({
            ...provided,
            fontSize: "16px",
            lineHeight: "20px",
            padding: "20px 55px",
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "20px",
            padding: "20px 45px",
        }),
    };


    const [playAnimation, setPlayAnimation] = useState(false)

    useEffect(() => {
        const onPageLoad = () => {
            setPlayAnimation(true);
        };

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);
    return (
        <MainLayout>
            <Preloader playAnimation={playAnimation} />
            <div className='homePage'>
                <header>
                    <h1>Научные антикоррупционные экспертизы</h1>
                    <h2>Ознакомьтесь с научными антикоррупционными экспертизами проектов законодательных и нормативных актов</h2>
                </header>
                <section >
                    <div className=' homePage__search-panel'>
                        <div className="btn_search">
                            {IconsContent.Search()}
                            <input type="email" placeholder={'Номер проекта'} />
                        </div>
                        <div className="btn_search">
                            {IconsContent.Search()}
                            <input type="email" placeholder={'Наименование проекта'} />
                        </div>
                    </div>
                    <div className=' homePage__search-select'>
                        <div className='btn_search-select'>
                            <span>{IconsContent.Search()}</span>
                            <Select
                                classNamePrefix="btn_search-customSelect"
                                placeholder={"Специализация"}
                                options={options}
                                styles={customStyles}
                            />
                        </div>
                        <div className='btn_search-select'>
                            <span>{IconsContent.Search()}</span>
                            <Select
                                classNamePrefix="btn_search-customSelect"
                                placeholder={"Орган-разработчик"}
                                options={options}
                                styles={customStyles}
                            />
                        </div>
                    </div>
                    {publishedProjects && publishedProjects.data.map((item, index) => {
                        return (
                            <div key={index} className="homePage__expertise">
                                <p className="homePage__expertise-id">№ {item.id}</p>
                                <p className="homePage__expertise-header">{item.locales.name[i18n.language]}</p>
                                <p className="homePage__expertise-meta">
                                    <span>{IconsContent.User()}{item.authority_developer.locales.name[i18n.language]}</span>
                                    <span>{IconsContent.Calendar()}{item.created_at}</span>
                                </p>
                                <p className="homePage__expertise-button">
                                    <button onClick={() => UserService.getConclusionPDF(item.id, "kk").then(response => { console.log(response); document.location.assign(response.data) })}>Скачать заключение (казахский) {IconsContent.File()}</button>
                                    <button onClick={() => UserService.getConclusionPDF(item.id, "ru").then(response => { console.log(response); document.location.assign(response.data) })}>Скачать заключение (русский) {IconsContent.File()}</button>
                                    {item.cover_letter && <button style={{ backgroundColor: "#4cd2aa" }}><a href={item?.cover_letter?.src}>Скачать сопроводительную записку {IconsContent.File()}</a></button>}
                                </p>
                            </div>)
                    })}
                </section>
                <div className="footer_pagination">
                    {publishedProjects && publishedProjects?.meta?.total > publishedProjects.meta?.per_page ?
                        <Pagination count={Math.ceil(publishedProjects.meta.total / publishedProjects.meta?.per_page)} page={filteredPage} onChange={(event, value) => setFilteredPage(value)} variant="outlined" shape="rounded" /> : null}
                    {/* <button className={'btn_pagination '}>1</button>
                                <button className={'btn_pagination'}>2</button> */}
                </div>
            </div>
        </MainLayout>
    )
}

export default HomePage