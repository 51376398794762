import MainLayout from "../../Components/Layout/MainLayout";
import ConclusionsContainer from "../../Components/pages/ConclusionsContainer";
import IconsContent from "../../Assets/img/IconsContent";
import Navbar from "../../Components/Navbar/Navbar";
import { useEffect, useState } from "react";
import { UserService } from "../../../Main/Services/UserService";
import Preloader from "../../Components/Preloader/Preloader";

export default function Conclusion() {
    const [conclusions, setConclusions] = useState([])
    const [conclusionStatuses, setConclusionStatuses] = useState()
    const [expertsList, setExpertsList] = useState()
    const [correctorsList, setCorrectorsList] = useState()
    const [translatorsList, setTranslatorsList] = useState()
    useEffect(() => {
        UserService.getConclusions()
            .then(response => {
                let arr = response.data
                setConclusions(arr);

            }).catch(err => console.log(err));
        UserService.getExperts()
            .then(response => {

                let arr = response.data.data.map(item => { return { value: item.id, label: item.full_name } })
                setExpertsList(arr);
            });
        UserService.getConclusionStatuses()
            .then(response => {
                let arr = response.data.data.map(item => { return { value: item.id, label: item.locales.name } })
                setConclusionStatuses(arr);

            })
        UserService.getCorrectors()
            .then(response => {

                let arr = response.data.data.map(item => { return { value: item.id, label: item.full_name } })

                setCorrectorsList(arr);
            });
        UserService.getTranslators()
            .then(response => {
                let arr = response.data.data.map(item => { return { value: item.id, label: item.full_name } })

                setTranslatorsList(arr);
            })
    }, []);
    const [playAnimation, setPlayAnimation] = useState(false)

    useEffect(() => {
        const onPageLoad = () => {
            setPlayAnimation(true);
        };

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    return (

        <MainLayout >
            <Preloader playAnimation={playAnimation} />

            <Navbar direction={[
                {
                    name: 'Проекты',
                    goTo: '/translator',
                    iCon: IconsContent.BriefCase()
                },
                {
                    name: 'Заключения',
                    goTo: '/translator/conclusions',
                    iCon: IconsContent.ClipBoard()
                },
                {
                    name: 'Факторы риска',
                    goTo: '/translator/factors',
                    iCon: IconsContent.AlertCircle()
                },
            ]
            } />
            {conclusions && expertsList && correctorsList && translatorsList && conclusionStatuses && <ConclusionsContainer
                conclusions={conclusions}
                expertsList={expertsList}
                correctorsList={correctorsList}
                translatorsList={translatorsList}
                conclusionStatuses={conclusionStatuses}
            />}
        </MainLayout>
    )
}