
import IconsContent from "../../Assets/img/IconsContent";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import DatePickerInput from "../DatePicker/DatePicker";
import { UserService } from "../../../Main/Services/UserService";
import { InputSelect } from "../Select/InputSelect";
import Select from "react-select";
import { convertObjectToFormDataPUT } from '../../../Main/Services/utils'
import Preloader from "../Preloader/Preloader";
import { useTranslation } from "react-i18next";


export default function ChangeProject({ project, editProject }) {
    const { t, i18n } = useTranslation("common")
    const navigate = useNavigate()
    const { register, handleSubmit, control, reset, formState: { errors } } = useForm({
        mode: "onBlur", defaultValues: {
            document_type_id: project?.document_type.id,
            authority_developer_id: project?.authority_developer.id,
            branch_of_legislations: project?.branch_of_legislations.map(item => item.id)
        }
    });

    const [isShowModalDocCreated, setIsShowModalDocCreated] = useState(false);
    const [isShowModalError, setIsShowModalError] = useState(false);
    const [fileName, setFileName] = useState();
    const [fileName2, setFileName2] = useState();
    const [branchOfLegislation, setBranchOfLegislation] = useState()
    const [authorityDevelopers, setAuthorityDevelopers] = useState()
    const [documentTypes, setDocumentTypes] = useState()
    const [person1, setPerson1] = useState();
    const [error, setError] = useState(false)

    const onSubmit = (newProjectData) => {

        newProjectData = {
            ...newProjectData,
            uploads_ru: newProjectData.uploads_ru,
            uploads_kk: newProjectData.uploads_kk,
        };
        const formData = convertObjectToFormDataPUT(newProjectData);

        // const editedData = { ...newProjectData }
        // delete editedData.uploads_kk
        // delete editedData.uploads_ru
        UserService.editProject(project.id, formData)
            .then((response) => {
                setIsShowModalDocCreated(true);;
            }).catch(err => { setIsShowModalError(true); setError(err.data) })

        // const formData = new FormData()
        // formData.append(`uploads_ru`, newProjectData.uploads_ru)
        // formData.append(`uploads_kk`, newProjectData.uploads_kk)
        // formData.append("_method", "put");
        // UserService.editProjectUpload(project.id, formData)
        //     .then((response) => {
        //         setIsShowModalDocCreated(true);;
        //     }).catch(err => { setIsShowModalError(true); setError(err.data) })

    };
    const [newPlayAnimation, setNewPlayAnimation] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState(() => {
        return project?.branch_of_legislations.map(item => {
            return { label: item.name, value: item.id }
        })
    });

    useEffect(() => {
        UserService.getBranchOfLegislation()
            .then(response => {

                let arr = response.data.data.map(item => { return { value: item.id, label: item.locales.name.ru } })
                setBranchOfLegislation(arr)
            });
        UserService.getAuthorityDevelopers()
            .then(response => {
                let arr = response.data.data.map(item => { return { value: item.id, label: item.locales.name.ru } })
                setAuthorityDevelopers(arr)
            });

        UserService.getDocumentTypes()
            .then(response => {
                let arr = response.data.data.map(item => { return { value: item.id, label: item.locales.name.ru } })

                setDocumentTypes(arr);
            });
    }, [])
    console.log(branchOfLegislation)
    return (
        <div>
            {newPlayAnimation && <Preloader playAnimation={false} />}

            <form className="other_inforamtions" onSubmit={handleSubmit(onSubmit)}>

                <div className="get_inform">
                    <div className="inform_name">
                        <h5>{t("incoming_letter_number")}</h5>
                    </div>
                    <div className="inform_value">
                        {editProject ? <input type="text" defaultValue={project?.incoming_letter_number} {...register("incoming_letter_number", { required: "Заполните обязательное поле", })} /> :
                            <p>{project?.incoming_letter_number}</p>}
                        {errors.incoming_letter_number && (<p className="form--required">{errors.incoming_letter_number.message}</p>)}
                    </div>
                </div>
                <div className="get_inform">
                    <div className="inform_name">
                        <h5>{t("outgoing_letter_number")}</h5>
                    </div>
                    <div className="inform_value">
                        {editProject ? <input type="text" defaultValue={project?.outgoing_letter_number} {...register("outgoing_letter_number", { required: "Заполните обязательное поле", })} /> :
                            <p>{project?.outgoing_letter_number}</p>}
                        {errors.outgoing_letter_number && (<p className="form--required">{errors.outgoing_letter_number.message}</p>)}
                    </div>
                </div>
                <div className="get_inform">
                    <div className="inform_name">
                        <h5>{t("outgoing_mail_date")}</h5>
                    </div>
                    <div className="inform_value">
                        {editProject ? <input type="text" defaultValue={project?.outgoing_mail_date} {...register("outgoing_mail_date", { required: "Заполните обязательное поле", })} /> :
                            <p>{project?.outgoing_mail_date}</p>}
                        {errors.outgoing_mail_date && (<p className="form--required">{errors.outgoing_mail_date.message}</p>)}
                    </div>
                </div>
                <div className="get_inform">
                    <div className="inform_name">
                        <h5>{t("date_of_receipt_of_the_draft_regulatory_legal_act")}</h5>
                    </div>
                    <div className="inform_value">
                        {editProject ? <input type="text" defaultValue={project?.date_of_receipt_of_the_draft_regulatory_legal_act} {...register("date_of_receipt_of_the_draft_regulatory_legal_act", { required: "Заполните обязательное поле", })} /> :
                            <p>{project?.date_of_receipt_of_the_draft_regulatory_legal_act}</p>}
                        {errors.date_of_receipt_of_the_draft_regulatory_legal_act && (<p className="form--required">{errors.date_of_receipt_of_the_draft_regulatory_legal_act.message}</p>)}
                    </div>
                </div>

                <div className="get_inform">
                    <div className="inform_name">
                        <h5>{t("document_type")}</h5>
                    </div>
                    <div className="inform_value">
                        {editProject ? <Controller
                            name={"document_type_id"}
                            control={control}
                            render={({ field: { onChange, ref } }) =>
                                <Select
                                    ref={ref}
                                    onChange={(e) => {
                                        onChange(e?.value);
                                    }}
                                    classNamePrefix="custom-select"
                                    placeholder={"Выберите"}
                                    options={documentTypes}
                                    defaultInputValue={project?.document_type.locales.name[i18n.language]}
                                />
                            } /> :
                            <p>{project?.document_type.locales.name[i18n.language]}</p>}
                        {errors.document_type_id && (<p className="form--required">{errors.document_type_id.message}</p>)}
                    </div>
                </div>


                <div className="get_inform">
                    <div className="inform_name">
                        <h5>{t("name_kk")}</h5>
                    </div>
                    <div className="inform_value">
                        {editProject ? <textarea type="text" defaultValue={project?.locales.name.kk} {...register("name_kk", { required: "Заполните обязательное поле", })} /> :
                            <p>{project?.locales.name.kk}</p>}
                        {errors.name_kk && (<p className="form--required">{errors.name_kk.message}</p>)}
                    </div>
                </div>
                <div className="get_inform">
                    <div className="inform_name">
                        <h5>{t("name_ru")}</h5>
                    </div>
                    <div className="inform_value">
                        {editProject ? <textarea type="text" defaultValue={project?.locales.name.ru} {...register("name_ru", { required: "Заполните обязательное поле", })} /> :
                            <p>{project?.locales.name.ru}</p>}
                        {errors.name_ru && (<p className="form--required">{errors.name_ru.message}</p>)}
                    </div>
                </div>


                <div className="get_inform">
                    <div className="inform_name">
                        <h5>{t("branch_of_legiclation")}</h5>
                    </div>
                    <div className="inform_value">
                        {editProject ? <Controller
                            name="branch_of_legislations"
                            control={control}
                            render={({ field: { onChange, ref, value } }) =>
                                <Select
                                    isMulti
                                    value={selectedOptions}
                                    onChange={(val) => { setSelectedOptions(val); onChange(val.map((c) => c.value)) }}
                                    ref={ref}
                                    options={branchOfLegislation}
                                    placeholder="Выберите"
                                    classNamePrefix="custom-select"
                                />
                            } /> :
                            project?.branch_of_legislations.map((item, index) =>
                                <p key={index}>{item?.locales.name[i18n.language]}</p>)}
                    </div>
                </div>


                <div className="get_inform">
                    <div className="inform_name">
                        <h5>{t("purpose_of_act_kk")}</h5>
                    </div>
                    <div className="inform_value">
                        {editProject ? <textarea type="text" defaultValue={project?.locales.purpose_and_structure_of_the_draft_regulatory_legal_act.kk} {...register("purpose_and_structure_of_the_draft_regulatory_legal_act_kk", { required: "Заполните обязательное поле", })} /> :
                            <p>{project?.locales.purpose_and_structure_of_the_draft_regulatory_legal_act.kk}</p>}
                        {errors.purpose_and_structure_of_the_draft_regulatory_legal_act_kk && (<p className="form--required">{errors.purpose_and_structure_of_the_draft_regulatory_legal_act_kk.message}</p>)}
                    </div>
                </div>
                <div className="get_inform">
                    <div className="inform_name">
                        <h5>{t("purpose_of_act_ru")}</h5>
                    </div>
                    <div className="inform_value">
                        {editProject ? <textarea type="text" defaultValue={project?.locales.purpose_and_structure_of_the_draft_regulatory_legal_act.ru} {...register("purpose_and_structure_of_the_draft_regulatory_legal_act_ru", { required: "Заполните обязательное поле", })} /> :
                            <p>{project?.locales.purpose_and_structure_of_the_draft_regulatory_legal_act.ru}</p>}
                        {errors.purpose_and_structure_of_the_draft_regulatory_legal_act_kk && (<p className="form--required">{errors.purpose_and_structure_of_the_draft_regulatory_legal_act_kk.message}</p>)}
                    </div>
                </div>

                <div className="get_inform">
                    <div className="inform_name">
                        <h5>{t("authority_developer")}</h5>
                    </div>
                    <div className="inform_value">
                        {editProject ?
                            <Controller
                                name={"authority_developer_id"}
                                control={control}
                                render={({ field: { onChange } }) =>
                                    <Select
                                        onChange={(e) => {
                                            onChange(e?.value);
                                        }}
                                        classNamePrefix="custom-select"
                                        placeholder={"Выберите"}
                                        options={authorityDevelopers}
                                        defaultInputValue={project?.authority_developer.locales.name[i18n.language]}
                                    />
                                } /> :
                            <p>{project?.authority_developer.locales.name[i18n.language]}</p>}
                        {errors.authority_developer_id && (<p className="form--required">{errors.authority_developer_id.message}</p>)}
                    </div>
                </div>

                <div className="get_inform">
                    <div className="inform_name">
                        <h5>{t("documents_kk")}</h5>
                    </div>
                    <div className="inform_value">
                        {editProject ? <input type="text" defaultValue={project?.locales.documents_submitted_for_scientific_anti_corruption_expertise.kk} {...register("documents_submitted_for_scientific_anti_corruption_expertise_kk", { required: "Заполните обязательное поле", })} /> :
                            <p>{project?.locales.documents_submitted_for_scientific_anti_corruption_expertise.kk}</p>}
                        {errors.documents_submitted_for_scientific_anti_corruption_expertise_kk && (<p className="form--required">{errors.documents_submitted_for_scientific_anti_corruption_expertise_kk.message}</p>)}
                    </div>
                </div>
                <div className="get_inform">
                    <div className="inform_name">
                        <h5>{t("documents_ru")}</h5>
                    </div>
                    <div className="inform_value">
                        {editProject ? <input type="text" defaultValue={project?.locales.documents_submitted_for_scientific_anti_corruption_expertise.ru} {...register("documents_submitted_for_scientific_anti_corruption_expertise_ru", { required: "Заполните обязательное поле", })} /> :
                            <p>{project?.locales.documents_submitted_for_scientific_anti_corruption_expertise.ru}</p>}
                        {errors.documents_submitted_for_scientific_anti_corruption_expertise_ru && (<p className="form--required">{errors.documents_submitted_for_scientific_anti_corruption_expertise_ru.message}</p>)}
                    </div>
                </div>



                {editProject && <div className="get_inform input_wrapper-upload">
                    <label style={{ fontSize: "12px", fontWeight: "600" }}>{t("project_files_kk")}</label>
                    <input className="file__upload--input" multiple type="file" id={"file"} accept={".doc, .docx"}
                        {...register("uploads_kk", { onChange: (e) => setFileName(e.target.files) })} />
                    <div >
                        <label htmlFor="file" className="file__upload--button">{t("choose_file")}{IconsContent.PaperClip()}</label>
                        <p >{fileName && Object.keys(fileName).map(val => <span >{fileName[val].name}</span>)}</p>
                    </div>
                </div>}

                {editProject && <div className="input_wrapper input_wrapper-upload">
                    <label style={{ fontSize: "12px", fontWeight: "600" }}>{t("project_files_ru")}</label>
                    <input className="file__upload--input" multiple type="file" id={"file2"} accept={".doc, .docx"}
                        {...register("uploads_ru", { onChange: (e) => setFileName2(e.target.files) })} />
                    <div >
                        <label htmlFor="file2" className="file__upload--button">{t("choose_file")}{IconsContent.PaperClip()}</label>
                        <p >{fileName2 && Object.keys(fileName2).map(val => <span >{fileName2[val].name}</span>)}</p>
                    </div>
                </div>}

                <div className="get_inform">
                    <div className="inform_name">
                        <h5>{t("object_kk")}</h5>
                    </div>
                    <div className="inform_value">
                        {editProject ? <textarea type="text" defaultValue={project?.locales.the_subject_and_goals_of_scientific_anti_corruption_expertise.kk} {...register("the_subject_and_goals_of_scientific_anti_corruption_expertise_kk", { required: "Заполните обязательное поле", })} /> :
                            <p>{project?.locales.the_subject_and_goals_of_scientific_anti_corruption_expertise.kk}</p>}
                        {errors.the_subject_and_goals_of_scientific_anti_corruption_expertise_kk && (<p className="form--required">{errors.the_subject_and_goals_of_scientific_anti_corruption_expertise_kk.message}</p>)}
                    </div>
                </div>

                <div className="get_inform">
                    <div className="inform_name">
                        <h5>{t("object_ru")}</h5>
                    </div>
                    <div className="inform_value">
                        {editProject ? <textarea type="text" defaultValue={project?.locales.the_subject_and_goals_of_scientific_anti_corruption_expertise.ru} {...register("the_subject_and_goals_of_scientific_anti_corruption_expertise_ru", { required: "Заполните обязательное поле", })} /> :
                            <p>{project?.locales.the_subject_and_goals_of_scientific_anti_corruption_expertise.ru}</p>}
                        {errors.the_subject_and_goals_of_scientific_anti_corruption_expertise_ru && (<p className="form--required">{errors.the_subject_and_goals_of_scientific_anti_corruption_expertise_ru.message}</p>)}
                    </div>
                </div>


                <div className="get_inform">
                    <div className="inform_name">
                        <h5>{t("number_of_pages")}</h5>
                    </div>
                    <div className="inform_value">
                        {editProject ? <input type="text" defaultValue={project?.number_of_pages} {...register("number_of_pages", { required: "Заполните обязательное поле", })} /> :
                            <p>{project?.number_of_pages}</p>}
                        {errors.number_of_pages && (<p className="form--required">{errors.number_of_pages.message}</p>)}
                    </div>
                </div>
                <div className="get_inform">
                    <div className="inform_name">
                        <h5>Email</h5>
                    </div>
                    <div className="inform_value">
                        <p>{project?.email}</p>
                    </div>
                </div>



                {editProject && <button className="edit-project" >{t("apply_changes")}</button>}
            </form >

            <div className={isShowModalDocCreated ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
                    <p>
                        <span onClick={() => setIsShowModalDocCreated(false)}>
                            {IconsContent.Close()}
                        </span>
                    </p>
                    <div>
                        <p>{IconsContent.DocCreated()}</p>
                        <p>Документ сформирован</p>
                        <button onClick={() => { setIsShowModalDocCreated(false); navigate('/clerk'); }}>
                            Закрыть
                        </button>
                    </div>
                </div>
            </div>

            <div className={isShowModalError ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
                    <p>
                        <span onClick={() => setIsShowModalError(false)}>
                            {IconsContent.Close()}
                        </span>
                    </p>
                    <div>
                        <p>{IconsContent.Error()}</p>
                        <p>Произошла ошибка</p>
                        <button onClick={() => {
                            setIsShowModalError(false);
                            // window.location.reload();
                        }}>
                            Закрыть
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
}


