
import NavigationContent from "../../NavigationContent/NavigationContent";
import { useState, useEffect } from "react";
import Preloader from "../../Preloader/Preloader";
import IconsContent from "../../../Assets/img/IconsContent";
import TableFirst from "../../TableFirst/TableFirst";
import { UserService } from "../../../../Main/Services/UserService";
import { useTranslation } from "react-i18next";
import { useForm } from 'react-hook-form'

const AuthDevelopers = ({ authDevelopersData, isShowModalCoordinator, setIsShowModalCoordinator, setNewPlayAnimation, setAuthorityDevelopersData }) => {
    const [isShowModalError, setIsShowModalError] = useState(false);
    const [isShowModalDocCreated, setIsShowModalDocCreated] = useState(false);

    const defaultValue = authDevelopersData ? {
        name_kk: authDevelopersData.locales.name.kk,
        name_ru: authDevelopersData.locales.name.ru,
    } : null

    const { register, reset, control, handleSubmit, formState: { errors } } = useForm({
        mode: 'onBlur',
        defaultValues: defaultValue
    })
    const onSubmit = (authDevelopersChanged) => {
        console.log(authDevelopersChanged)
        setNewPlayAnimation(true)
        authDevelopersData ? UserService.putAuthorityDevelopers(authDevelopersData.id, authDevelopersChanged)
            .then(() => { setNewPlayAnimation(false); setIsShowModalDocCreated(true) })
            .catch(err => { console.log(err); setNewPlayAnimation(false); setIsShowModalError(true) }) :

            UserService.addAuthorityDevelopers(authDevelopersChanged)
                .then(() => { setNewPlayAnimation(false); setIsShowModalDocCreated(true) })
                .catch(err => { console.log(err); setNewPlayAnimation(false); setIsShowModalError(true) })
    }

    return (
        <>
            < div className={isShowModalCoordinator ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"} >
                <div className="transmittal-letters__modal-content" onClick={(e) => e.stopPropagation()}>

                    <div className="transmittal-letters__wrapper">
                        <p className="transmittal-letters__modal-heading">
                            <span>{authDevelopersData ? "Редактирование органа разработчика" : "Создание органа разработчика"}</span>
                            <span onClick={() => { setIsShowModalCoordinator(false); setAuthorityDevelopersData() }}>
                                {IconsContent.Close()}
                            </span>
                        </p>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="input_wrapper input_wrapper-addProject">
                                <label htmlFor="">Наименование (на казахском)</label>
                                <input type="text" {...register("name_kk")} />
                            </div>
                            <div className="input_wrapper input_wrapper-addProject">
                                <label htmlFor="">Наименование (на русском)</label>
                                <input type="text"  {...register("name_ru")} />
                            </div>
                            <button className="btn_assign" onClick={() => { setNewPlayAnimation(true) }}>
                                Сохранить изменения
                            </button>
                        </form>
                    </div>
                </div>
            </div >
            <div className={isShowModalDocCreated ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
                    <p>
                        <span onClick={() => setIsShowModalDocCreated(false)}>
                            {IconsContent.Close()}
                        </span>
                    </p>
                    <div>
                        <p>{IconsContent.DocCreated()}</p>
                        <p>Данные изменены!</p>
                        <button onClick={() => { setIsShowModalDocCreated(false); window.location.reload(); }}>
                            Закрыть
                        </button>
                    </div>
                </div>
            </div>

            <div className={isShowModalError ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
                    <p>
                        <span onClick={() => setIsShowModalError(false)}>
                            {IconsContent.Close()}
                        </span>
                    </p>
                    <div>
                        <p>{IconsContent.Error()}</p>
                        <p>Произошла ошибка</p>
                        <button onClick={() => {
                            setIsShowModalError(false);
                            window.location.reload();
                        }}>
                            Закрыть
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default function CatalogDetailsAuthDevelopers({ authDevelopers }) {
    const [isShowModalCoordinator, setIsShowModalCoordinator] = useState(false);
    const [newPlayAnimation, setNewPlayAnimation] = useState(false)
    const [authDevelopersData, setAuthorityDevelopersData] = useState()
    const [authAdd, setAuthAdd] = useState(false)
    const { t, i18n } = useTranslation("common");
    console.log(authDevelopers)

    return (

        <div className={'transmittal-letters__form  projects_page'}>
            {newPlayAnimation && <Preloader playAnimation={false} />}
            <NavigationContent direct={[
                { goTo: `/administrator`, name: `Главная` },
                { goTo: `/administrator/catalogs`, name: `Справочники` },
                { goTo: `/administrator/catalog/auth-developers`, name: "Органы разработчики" }
            ]} />
            <p className="auth-developers__add">Органы разработчики <span onClick={() => { setIsShowModalCoordinator(true) }}> Добавить{IconsContent.AddProject()}</span></p>
            <main>
                <div className="table_wrapper">
                    <table className={'table_styling table_styling-catalogs'}>
                        <thead >
                            <tr className={'table_heading'}>
                                <TableFirst />
                                <td>Наименование органа разработчика</td>
                                <td className="last"></td>
                            </tr>
                        </thead>
                        <tbody>
                            {authDevelopers.map((res, index) => {
                                const isSecond = index % 2
                                return (
                                    <tr key={res?.id} className={'table_body '} style={{ backgroundColor: `${isSecond === 1 ? `#F9F9F9` : `inherit`}` }}>
                                        <td>{res?.id}</td>
                                        <td>{res?.locales.name[i18n.language]}</td>
                                        <td colSpan={2}>
                                            <span style={{ cursor: "pointer" }} onClick={() => { setAuthorityDevelopersData(res); setIsShowModalCoordinator(true) }}>{IconsContent.EditUser()}</span>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>

                    {isShowModalCoordinator && <AuthDevelopers authDevelopersData={authDevelopersData} setAuthorityDevelopersData={setAuthorityDevelopersData}
                        isShowModalCoordinator={isShowModalCoordinator} setIsShowModalCoordinator={setIsShowModalCoordinator} setNewPlayAnimation={setNewPlayAnimation} authAdd={authAdd} />}

                </div>
            </main>
        </div>
    )
}