
import NavigationContent from "../NavigationContent/NavigationContent";
import { useState, useEffect } from "react";
import Preloader from "../Preloader/Preloader";
import IconsContent from "../../Assets/img/IconsContent";
import TableFirst from "../TableFirst/TableFirst";
import { UserService } from "../../../Main/Services/UserService";
import { useTranslation } from "react-i18next";
import { useForm } from 'react-hook-form'

export const DocumentType = ({ documentTypeData, isShowModalCoordinator, setIsShowModalCoordinator, setNewPlayAnimation }) => {
    const [isShowModalError, setIsShowModalError] = useState(false);
    const [isShowModalDocCreated, setIsShowModalDocCreated] = useState(false);

    const { register, reset, control, handleSubmit, formState: { errors } } = useForm({
        mode: 'onBlur', defaultValues: {
            name_kk: documentTypeData.locales.name.kk,
            name_ru: documentTypeData.locales.name.ru,
            group_kk: documentTypeData.locales.group.kk,
            group_ru: documentTypeData.locales.group.ru,
            base_cost: documentTypeData.base_cost,
            remark_cost: documentTypeData.remark_cost,
            coordinator_cost: documentTypeData.locales.coordinator_cost,
        }
    })
    const onSubmit = (documentTypeChanged) => {
        setNewPlayAnimation(true)
        UserService.putDocumentTypes(documentTypeData.id, documentTypeChanged)
            .then(() => { setNewPlayAnimation(false); setIsShowModalDocCreated(true) })
            .catch(err => { console.log(err); setNewPlayAnimation(false); setIsShowModalError(true) })
    }

    return (
        <>
            < div className={isShowModalCoordinator ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"} >
                <div className="transmittal-letters__modal-content" onClick={(e) => e.stopPropagation()}>

                    <div className="transmittal-letters__wrapper">
                        <p className="transmittal-letters__modal-heading">
                            <span>Редактирование типа документа</span>
                            <span onClick={() => { setIsShowModalCoordinator(false) }}>
                                {IconsContent.Close()}
                            </span>
                        </p>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="input_wrapper input_wrapper-addProject">
                                <label htmlFor="">Наименование (на казахском)</label>
                                <input type="text" {...register("name_kk")} />
                            </div>
                            <div className="input_wrapper input_wrapper-addProject">
                                <label htmlFor="">Наименование (на русском)</label>
                                <input type="text"  {...register("name_ru")} />
                            </div>
                            <div className="input_wrapper input_wrapper-addProject">
                                <label htmlFor="">Группа НПА (на казахском)</label>
                                <input type="text"  {...register("group_kk")} />
                            </div>
                            <div className="input_wrapper input_wrapper-addProject">
                                <label htmlFor="">Группа НПА (на русском)</label>
                                <input type="text"  {...register("group_ru")} />
                            </div>
                            <div className="input_wrapper input_wrapper-addProject">
                                <label htmlFor="">Стоимость базового показателя</label>
                                <input type="number" {...register("base_cost")} />
                            </div>
                            <div className="input_wrapper input_wrapper-addProject">
                                <label htmlFor="">Стоимость базового показателя координатора</label>
                                <input type="number" {...register("coordinator_cost")} />
                            </div>
                            <div className="input_wrapper input_wrapper-addProject">
                                <label htmlFor="">Стоимость замечания</label>
                                <input type="number"  {...register("remark_cost")} />
                            </div>
                            <button className="btn_assign" onClick={() => { setNewPlayAnimation(true) }}>
                                Сохранить изменения
                            </button>
                        </form>
                    </div>
                </div>
            </div >
            <div className={isShowModalDocCreated ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
                    <p>
                        <span onClick={() => setIsShowModalDocCreated(false)}>
                            {IconsContent.Close()}
                        </span>
                    </p>
                    <div>
                        <p>{IconsContent.DocCreated()}</p>
                        <p>Данные изменены!</p>
                        <button onClick={() => { setIsShowModalDocCreated(false); window.location.reload(); }}>
                            Закрыть
                        </button>
                    </div>
                </div>
            </div>

            <div className={isShowModalError ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
                    <p>
                        <span onClick={() => setIsShowModalError(false)}>
                            {IconsContent.Close()}
                        </span>
                    </p>
                    <div>
                        <p>{IconsContent.Error()}</p>
                        <p>Произошла ошибка</p>
                        <button onClick={() => {
                            setIsShowModalError(false);
                            window.location.reload();
                        }}>
                            Закрыть
                        </button>
                    </div>
                </div>
            </div></>
    )
}

export default function CatalogDetailsContainer({ documentTypes }) {

    const [documentTypeData, setDocumentTypeData] = useState()
    const [isShowModalCoordinator, setIsShowModalCoordinator] = useState(false);
    const [newPlayAnimation, setNewPlayAnimation] = useState(false)
    const { t, i18n } = useTranslation("common");




    return (

        <div className={'transmittal-letters__form  projects_page'}>
            {newPlayAnimation && <Preloader playAnimation={false} />}
            <main>
                <div className="table_wrapper">
                    <table className={'table_styling table_styling-catalogs'}>
                        <thead >
                            <tr className={'table_heading'}>
                                <TableFirst />
                                <td>Наименование вида НПА</td>
                                <td>Группа НПА</td>
                                <td>Стоимость базового показателя</td>
                                <td>Стоимость замечания</td>
                                <td>БП координатора</td>
                                <td className="last"></td>
                            </tr>
                        </thead>
                        <tbody>
                            {documentTypes && documentTypes.map((res, index) => {
                                const isSecond = index % 2
                                return (
                                    <tr key={res?.id} className={'table_body '} style={{ backgroundColor: `${isSecond === 1 ? `#F9F9F9` : `inherit`}` }}>
                                        <td>{res?.id}</td>
                                        <td>{res?.locales.name[i18n.language]}</td>
                                        <td>{res?.locales.group[i18n.language]}</td>
                                        <td>{res?.base_cost}</td>
                                        <td>{res?.remark_cost}</td>
                                        <td>{res?.locales.coordinator_cost}</td>
                                        <td colSpan={2}>
                                            <span style={{ cursor: "pointer" }} onClick={() => { setDocumentTypeData(res); setIsShowModalCoordinator(true) }}>{IconsContent.EditUser()}</span>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    {documentTypeData && <DocumentType documentTypeData={documentTypeData}
                        isShowModalCoordinator={isShowModalCoordinator} setIsShowModalCoordinator={setIsShowModalCoordinator} setNewPlayAnimation={setNewPlayAnimation} />}

                </div>
            </main>
        </div>
    )
}