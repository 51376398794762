import MainLayout from "../../Components/Layout/MainLayout";
import { ClerkNavBar } from "../../../Main/Services/http";
import Navbar from "../../Components/Navbar/Navbar";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import { UserService } from "../../../Main/Services/UserService";
import ConclusionDetailsContainer from '../../Components/pages/ConclusionDetailsContainer'

export default function FormViewDetail() {
    const [conclusion, setConclusion] = useState(null)
    let getIdDate = useLocation().pathname.replace(/[a-z\/]/g, '')

    useEffect(() => {
        UserService.getConclusion(getIdDate)
            .then(response => {
                let arr = response.data.data
                setConclusion(arr);

            }).catch(err => console.log(err));

    }, []);
    console.log(conclusion);
    return (
        <MainLayout>
            <Navbar direction={ClerkNavBar()} />
            {conclusion &&
                <ConclusionDetailsContainer conclusion={conclusion} />
            }
        </MainLayout>
    )
}