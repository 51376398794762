import IconsContent from "../../../Assets/img/IconsContent";
import { useEffect, useState } from "react";
import iconsContent from "../../../Assets/img/IconsContent";
import Select from "react-select";
import TableFirst from "../../TableFirst/TableFirst";
import Pagination from "../../Pagination/Pagination";

export default function ConclusionsContainer() {
	const [fullNumber, setFullNumber] = useState(0);
	const [filteredNumber, setFilteredNumber] = useState(0);
	const [isFilter, setIsFilter] = useState(false);
	const [isShowFilter, setIsShowFilter] = useState(null);
	const [isShowTableData, setIsShowTableData] = useState();

	const [currentPage, setCurrentPage] = useState(1);
	const [recordsPerPage] = useState(2);
	const stateInfo = [
		{
			id: 1234,
			expert: "Иванова Т.И",
			projectID: 3245,
			deadline: "2022-07-05 00:00:00",
			status: "Статус1",
			noticeCoor: "Примечание (координатор)",
			noticeExpert: "Примечание (эксперт)",
			changedBy: "Петров С.Е.",
			createdAt: "2022-07-05 00:00:00",
			changedAt: "2022-07-05 00:00:00",
			statusTerm: "new-project",
		},
		{
			id: 5543,
			expert: "Иванова Т.И",
			projectID: 3245,
			deadline: "2022-07-05 00:00:00",
			status: "Статус2",
			status: "Статус1",
			noticeCoor: "Примечание (координатор)",
			noticeExpert: "Примечание (эксперт)",
			changedBy: "Петров С.Е.",
			createdAt: "2022-07-05 00:00:00",
			changedAt: "2022-07-05 00:00:00",
			statusTerm: "one-day",
		},
		{
			id: 1254,
			expert: "Иванова Т.И",
			projectID: 3245,
			deadline: "2022-07-05 00:00:00",
			status: "Статус1",
			status: "Статус1",
			noticeCoor: "Примечание (координатор)",
			noticeExpert: "Примечание (эксперт)",
			changedBy: "Петров С.Е.",
			createdAt: "2022-07-05 00:00:00",
			changedAt: "2022-07-05 00:00:00",
			statusTerm: "two-days",
		},
	];
	const [state, setState] = useState(stateInfo);

	const indexOfLastRecord = currentPage * recordsPerPage;
	const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
	const currentRecords = state.slice(indexOfFirstRecord, indexOfLastRecord);
	const nPages = Math.ceil(state.length / recordsPerPage);

	useEffect(() => {
		setTimeout(() => {
			setIsShowFilter(isFilter);
		}, 1);
	}, [isFilter]);

	useEffect(() => {
		if (!isShowFilter) {
			setTimeout(() => {
				setIsFilter(isShowFilter);
			}, 500);
		}
	}, [isShowFilter]);

	const tableBody = currentRecords.map((res, index) => {
		const isSecond = index % 2;
		return (
			<>
				<tr
					key={res?.id}
					className={
						isShowTableData == index
							? `table_body table_body-admin ${res.statusTerm} no-border`
							: `table_body table_body-admin ${res.statusTerm}`
					}
				>
					<td>
						<p>{res?.id}</p>
					</td>
					<td>
						<p>{res?.expert}</p>
					</td>
					<td>
						<p>{res?.projectID}</p>
					</td>
					<td>
						<p>{res?.deadline}</p>
					</td>
					<td className="table_status">
						<p>
							{res?.status}
							<span
								onClick={() =>
									setIsShowTableData(isShowTableData == index ? null : index)
								}
							>
								{IconsContent.ArrowDownGreen()}
							</span>
						</p>
					</td>
					<td>
						<p></p>
					</td>
					<td>
						<p></p>
					</td>
					<td className={"li_a"}>
						<p>
							<a href="/superadministrator/conclusions/details/*">
								{IconsContent.Eye()}
							</a>
						</p>
					</td>
				</tr>
				<tr
					className={
						isShowTableData == index
							? `table_body table_folding ${res.statusTerm}`
							: "none"
					}
				>
					<td colSpan={3}>
						<p>Примечание (координатор)</p>
						<p>{res?.noticeCoor}</p>
					</td>
					<td>
						<p>Примечание (эксперт)</p>
						<p>{res?.noticeExpert}</p>
					</td>
					<td className="table_changedBy">
						<p>Изменен</p>
						<p>{res?.changedBy}</p>
					</td>
					<td>
						<p>Создано</p>
						<p>{res?.createdAt}</p>
					</td>
					<td>
						<p>Изменено</p>
						<p>{res?.changedAt}</p>
					</td>
					<td></td>
				</tr>
			</>
		);
	});

	return (
		<div className={"conclusion__coordinator projects_page"}>
			<h1>Заключения</h1>
			<main className="c-card">
				<div className="main_header">
					<button
						className={"btn_filter"}
						onClick={() => {
							setIsFilter(true);
						}}
					>
						<span className="name">Фильтр</span>
						{IconsContent.Filter()}
					</button>
					<div className={"btn_search"}>
						{IconsContent.Search()}
						<input type="email" placeholder={"Поиск..."} />
					</div>
				</div>
				<div className="status-legeng">
					<span className="status-legeng__new-project">Новый проект</span>
					<span className="status-legeng__two-days">Срок выполнения 2 дня</span>
					<span className="status-legeng__one-day">Срок выполнения 1 день</span>
				</div>
				<div className="table_wrapper">
					<table className={"table_styling table_styling-admin"}>
						<thead>
							<tr className={"table_heading"}>
								<TableFirst state={state} setState={setState} />
								<td>Эксперт</td>
								<td>ID ПРОЕКТА</td>
								<td>КРАЙНИЙ СРОК ВЫПОЛНЕНИЯ</td>
								<td>СТАТУС</td>
								<td className="table_transparent">2022-07-05 00:00:00</td>
								<td className="table_transparent">2022-07-05 00:00:00</td>
								<td className="last"></td>
							</tr>
						</thead>
						<tbody>{tableBody}</tbody>
					</table>

					<div className="table_wrapper_footer">
						<div className="footer_number">
							<p>
								Найдено {filteredNumber} из {fullNumber}
							</p>
						</div>
						<div className="footer_pagination">
							<Pagination
								nPages={nPages}
								currentPage={currentPage}
								setCurrentPage={setCurrentPage}
							/>
						</div>
					</div>
				</div>
			</main>

			{isFilter && (
				<div className={`modal_filter`}>
					<div
						className={`modal_content ${!isShowFilter ? `disActive` : null}`}
					>
						<div className="modal_header">
							<div className="header_word">
								<h5>Фильтр</h5>
								{IconsContent.Filter("#4ABD9B", `2`)}
							</div>
							<button
								onClick={() => {
									setIsShowFilter(false);
								}}
							>
								{IconsContent.Cancel()}
							</button>
						</div>
						<div className="modal_form">
							<form action="">
								<div className="input_wrapper">
									<label htmlFor="">ID</label>
									<input type="text" />
								</div>
								<div className="input_wrapper">
									<label htmlFor="">ID проекта</label>
									<input type="text" />
								</div>
								<div className="input_wrapper">
									<label htmlFor="">Эксперт</label>
									<Select
										options={[
											{ value: "point1", label: "Пункт1" },
											{ value: "point2", label: "Пункт2" },
										]}
										classNamePrefix="custom-select"
										placeholder="Да"
									/>
								</div>
								<div className="input_wrapper">
									<label htmlFor="">Ответственный корректор</label>
									<Select
										options={[
											{ value: "point1", label: "Пункт1" },
											{ value: "point2", label: "Пункт2" },
										]}
										classNamePrefix="custom-select"
										placeholder="Да"
									/>
								</div>
								<div className="input_wrapper">
									<label htmlFor="">Ответственный переводчик</label>
									<Select
										options={[
											{ value: "point1", label: "Пункт1" },
											{ value: "point2", label: "Пункт2" },
										]}
										classNamePrefix="custom-select"
										placeholder="Да"
									/>
								</div>
								<div className="input_wrapper ender">
									<label htmlFor="">Статус</label>
									<Select
										options={[
											{ value: "point1", label: "Пункт1" },
											{ value: "point2", label: "Пункт2" },
										]}
										classNamePrefix="custom-select"
										placeholder="Да"
									/>
								</div>

								<button className={"submit"}>Применить</button>
								<button className={"cancel"}>Сбросить</button>
							</form>
						</div>
					</div>
					<div
						className="modal_cover"
						onClick={() => {
							setIsShowFilter(false);
						}}
					/>
				</div>
			)}
		</div>
	);
}
