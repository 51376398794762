import http from "./http";

const signInUser = (data) => {
  return http.post("login", data);
};

// ---------------------------------------------------------------project Requests
const getProjects = (page)=>{
    return http.get(`projects?orderby[id]=desc&per_page=8&page=${page}`,{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}` } })
}
const getProject= (project_id)=>{
    return http.get(`projects\\${project_id}`,{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}` } })
}
const editProject= (project_id, editData)=>{
    return http.post(`projects\\${project_id}`,editData,{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}` } })
}

const editProjectUpload= (project_id, editData)=>{
    return http.post(`projects\\${project_id}`,editData,{ headers: 
        {  'Content-Type': 'multipart/form-data', 
        "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}` } })
}

const getProjectsPublish = (page)=>{
    return http.get(`public-projects?orderby[id]=desc&page=${page}`)
}
const addProjects = async (newProjectData)=>{
    return await http.post('projects', newProjectData, { headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`}})
}
const getFilteredData = (page,filteredItem)=>{
    return http.get(`projects?orderby[id]=desc&per_page=8&page=${page}&${filteredItem}`,
        {headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`}}
        )
}
const getFilteredDataPending = (page,filteredItem)=>{
    return http.get(`projects?orderby[id]=desc&per_page=8&page=${page}&filter[status_id]=2&${filteredItem}`,
        {headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`}}
        )
}
const getFilteredDataAccepted = (page,filteredItem)=>{
    return http.get(`projects?orderby[id]=desc&per_page=8&page=${page}&filter[status_id]=4&${filteredItem}`,
        {headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`}}
        )
}
const getFilteredDataCompleted = (page,filteredItem)=>{
    return http.get(`projects?orderby[id]=desc&per_page=8&page=${page}&filter[status_id]=7&${filteredItem}`,
        {headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`}}
        )
}
const putPublush= (project_id, publish_status)=>{
    return http.put(`projects\\${project_id}`,publish_status,{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}` } })
}



// ---------------------------------------------------------------conclusion Requests
const getConclusions = ()=>{
    return http.get('conclusions?orderby[id]=desc&per_page=8',{headers:{ "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`}})
}
const getConclusionPDF = (projectID,lang)=>{
    return http.get(`generate-project-pdf/${projectID}?lang=${lang}`)
}
const getConclusion= (conclusion_id)=>{
    return http.get(`conclusions\\${conclusion_id}`,{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}` } })
}
const getFinalConclusion= (project_id)=>{
    return http.get(`generate-pdf-final-conclusion\\${project_id}`,{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}` } })
}
const editConclusion = (conclusionId, userDataChanged)=>{
    return http.put(`conclusions/${conclusionId}`, userDataChanged, {headers:{ "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`}})
}
const putConclusion= (conclusion_id, conclusion_status)=>{
    return http.put(`conclusions\\${conclusion_id}`,conclusion_status,{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}` } })
}
const getFilteredDataConclusionsPending = (page,filteredItem)=>{
    return http.get(`conclusions?orderby[id]=desc&per_page=8&page=${page}&filter[status_id]=1&${filteredItem}`,
        {headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`}}
        )
}
const getConclusionStatuses = ()=>{
    return http.get('conclusion-statuses',{headers:{ "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`}})
}
const getFilteredDataConclusionsAccepted = (page,filteredItem)=>{
    return http.get(`conclusions?orderby[id]=desc&per_page=8&page=${page}&filter[status_id]=2&${filteredItem}`,
        {headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`}}
        )
}
const getFilteredDataConclusionsCompleted = (page,filteredItem)=>{
    return http.get(`conclusions?orderby[id]=desc&per_page=8&page=${page}&filter[status_id]=9&${filteredItem}`,
        {headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`}}
        )
}
const getFilteredDataConclusions = (page,filteredItem)=>{
    return http.get(`conclusions?orderby[id]=desc&per_page=8&page=${page}&${filteredItem}`,
        {headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`}}
        )
}


// ---------------------------------------------------------------transmittalLeters Requests
const addTransmittalLetter = (transmittLeterID, transmittalLeterData)=>{
    return http.put(`cover-letters/${transmittLeterID}`, transmittalLeterData, { headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`}})
}
const getTransmittalLetters = ()=>{
    return http.get(`cover-letters?filter[project][published]=true&orderby[id]=desc&per_page=8`,{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`}})
}
const getFilteredDataLetters = (page,filteredItem)=>{
    return http.get(`cover-letters?filter[project][published]=true&orderby[id]=desc&per_page=8&${filteredItem}&page=${page}`,{headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`}})
}
const getTransmittalLettersFilled = (page)=>{
    return http.get(`cover-letters?filter[project][published]=true&orderby[id]=desc&per_page=8&page=${page}`,{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`}})
}
const getTransmittalLettersUnFilled = (page)=>{
    return http.get(`cover-letters?filter[project][published]=true&orderby[id]=desc&per_page=8&page=${page}`,{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`}})
}
const getTransmittalLettersAssigned = (page)=>{
    return http.get(`cover-letters?filter[project][published]=true&orderby[id]=desc&per_page=8&page=${page}`,{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`}})
}



//---------------------------------------------user Requests
const addUser = (addUserData)=>{
    return http.post('users', addUserData,{headers:{ "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`}})
}
const getUser = (userId)=>{
    return http.get(`users/${userId}`,{headers:{ "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`}})
}
const getUsers = ()=>{
    return http.get(`users?per_page=8`,{headers:{ "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`}})
}
const getUsersFiltered = (page,filteredItem)=>{
    return http.get(`users?per_page=8&${filteredItem}page=${page}`,{headers:{ "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`}})
}
const deleteUser = (userId)=>{
    return http.delete(`users/${userId}`,{headers:{ "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`}})
}
const upDateUser = (userId, userDataChanged)=>{
    return http.put(`users/${userId}`, userDataChanged, {headers:{ "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`}})
}
const getCoordinators = (page,filteredItem)=>{
    return http.get(`users?filter[role][slug]=coordinator&per_page=8&${filteredItem}page=${page}`,{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`} })
}
const getCorrectors = (page,filteredItem)=>{
    return http.get(`users?filter[role][slug]=corrector&per_page=8&${filteredItem}page=${page}`,{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`} })
}
const getClerks = (page,filteredItem)=>{
    return http.get(`users?filter[role][slug]=clerk&per_page=8&${filteredItem}page=${page}`,{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`} })
}
const getTranslators = (page,filteredItem)=>{
    return http.get(`users?filter[role][slug]=translator&per_page=8&${filteredItem}page=${page}`,{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`} })
}
const getExperts = (page,filteredItem,per_page)=>{
    return http.get(`users?filter[role][slug]=expert&per_page=${per_page?per_page:300}&${filteredItem}page=${page}`,{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`} })
}

const getBranchOfLegislation = ()=>{
    return http.get('branch-of-legislations?per_page=50',{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`} })
}
const getAuthorityDevelopers = ()=>{
    return http.get('authority-developers?per_page=300',{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`} })
}
const putAuthorityDevelopers = (authDeveloperId, authDeveloperData)=>{
    return http.put(`authority-developers/${authDeveloperId}`,authDeveloperData,{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`} })
}
const addAuthorityDevelopers = (authDeveloperData)=>{
    return http.post(`authority-developers`, authDeveloperData,{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`} })
}

const saveRecExpert = (userId)=>{
    return http.delete(`recomendation-experts/${userId}`,{headers:{ "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`}})
}
const getExpertRecomend = (projectId)=>{
    return http.post('recomendation-experts', {project_id:projectId}, { headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`} })
}

const getProjectStatuses = ()=>{
    return http.get('project-statuses',{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`} })
}
const getDocumentTypes = ()=>{
    return http.get('document-types?per_page=30',{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`} })
}
const putDocumentTypes = (docID,data)=>{
    return http.put(`document-types/${docID}`,data,{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`} })
}
const getCatos = ()=>{
    return http.get('catos',{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`} })
}
const putExpert = (data,pageID)=>{
    return http.put(`projects/${pageID}`,data ,{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}`} })
}
const getRiskFactors= ()=>{
    return http.get('risk-factors?per_page=30',{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}` } })
}
const getCorruptionFactors= ()=>{
    return http.get('corruption-factors?per_page=50',{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}` } })
}

const putLowAnalys= (comment_id, comment_data)=>{
    return http.put(`law-analys-expert-comments\\${comment_id}`, comment_data,{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}` } })
}
const uploadConclusion= (conclusion_id, data)=>{
    return http.post(`conclusions\\${conclusion_id}`,data,{ headers: 
        {  'Content-Type': 'multipart/form-data', 
        "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}` } })
}
const uploadECP= (conclusion_id, data)=>{
    return http.post(`conclusions\\${conclusion_id}`,data,{ headers: { 'Content-Type': 'multipart/form-data', "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}` } })
}
const uploadTransmittalLetterECP= (conclusion_id, data)=>{
    return http.post(`cover-letters\\${conclusion_id}`,data,{ headers: { 'Content-Type': 'multipart/form-data', "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}` } })
}
const detailedFactors = (id)=>{
    return http.delete(`detailed_factors\\${id}`,{ headers: { 'Content-Type': 'multipart/form-data', "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}` } })
}
const putDetailedFactors = (id, data)=>{
    return http.post(`detailed_factors\\${id}`,data, { headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}` } })
}
//_method: put
const getNPA = (page, filteredData)=>{
    return http.get(`npa?per_page=8&page=${page}&${filteredData}`,{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}` } })
}
const getNPA2 = ()=>{
    return http.get('consolidated-by-cost-test',{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}` } })
}
const reports = (page)=>{
    return http.get(`projects-report?per_page=8&page=${page}`,{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}` } })
}
const addReport = (reportData)=>{
    return http.post('project-report-save',reportData,{ headers: { "Authorization": `Bearer ${localStorage.getItem('.AuthToken')}` } })
}


export  const UserService = {
    signInUser,
    getProjects,
    getProject,
    addProjects,
    getConclusionPDF,
    getBranchOfLegislation,
    getAuthorityDevelopers,
    putAuthorityDevelopers,
    addAuthorityDevelopers,
    getExperts,
    getClerks,
    getCoordinators,
    getCorrectors,
    getTranslators,
    getProjectStatuses,
    getDocumentTypes,
    putDocumentTypes,
    getFilteredData,
    putExpert,
    addUser,
    getUser,
    deleteUser,
    upDateUser,
    editConclusion,
    getConclusionStatuses,
    getConclusions,
    getConclusion,
    putConclusion,
    uploadConclusion,
    uploadECP,
    addTransmittalLetter,
    getTransmittalLetters,
    getFilteredDataLetters,
    getFilteredDataConclusions,
    getFilteredDataConclusionsAccepted,
    getFilteredDataConclusionsPending,
    getFilteredDataConclusionsCompleted,
    getCatos,
    getProjectsPublish,
    putPublush,
    getExpertRecomend,
    saveRecExpert,
    getFilteredDataPending,
    getFilteredDataAccepted,
    getFilteredDataCompleted,
    putLowAnalys,
    getRiskFactors,
    getCorruptionFactors,
    uploadTransmittalLetterECP,
    getTransmittalLettersFilled,
    getTransmittalLettersAssigned,
    getTransmittalLettersUnFilled,
    getUsers,
    getUsersFiltered,
    getFinalConclusion,
    editProject,
    editProjectUpload,
    detailedFactors,
    getNPA,
    getNPA2,
    putDetailedFactors,
    reports,
    addReport
}

