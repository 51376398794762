import IconsContent from "../../Assets/img/IconsContent";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TableFirst from "../TableFirst/TableFirst";
import { UserService } from "../../../Main/Services/UserService";
import { InputSelect } from "../Select/InputSelect";
import { useForm } from 'react-hook-form'
import moment from 'moment';
import { useTranslation } from "react-i18next";
import Pagination from '@mui/material/Pagination';

export default function ConclusionsContainer({ conclusions, translatorsList, correctorsList, expertsList, conclusionStatuses }) {
    const { t, i18n } = useTranslation("common")
    const userLocation = localStorage.getItem('.UserLocation')
    const [conclusionsFiltered, setConclusionsFiltered] = useState()
    const [filteredPage, setFilteredPage] = useState(1)
    const [isFilter, setIsFilter] = useState(false)
    const [isShowTableData, setIsShowTableData] = useState()
    const [onClear, setOnClear] = useState(false)
    const [filteredData, setFilteredData] = useState('')
    const [filteredDataAll, setFilteredDataAll] = useState('')
    const [filteredDataPending, setFilteredDataPending] = useState('')
    const [filteredDataAccepted, setFilteredDataAccepted] = useState('')
    const [filteredDataCompleted, setFilteredDataCompleted] = useState('')
    const [filterSideMenu, setFilterSideMenu] = useState(0)
    // const currentDate = Number(moment(new Date).format("DD"))
    let z = conclusionsFiltered && [...Array(Math.ceil(conclusionsFiltered?.meta?.total / conclusionsFiltered?.meta?.per_page))]
    conclusionStatuses = conclusionStatuses.map(item => { return ({ value: item.value, label: item.label[i18n.language] }) })

    useEffect(() => {
        UserService.getFilteredDataConclusions(filteredPage, filteredData)
            .then(response => {

                setFilteredDataAll(response.data);
                if (filterSideMenu == 0) {
                    setConclusionsFiltered(response.data);
                }
            })
    }, [filteredPage, filteredData])

    useEffect(() => {
        UserService.getFilteredDataConclusionsPending(filteredPage, filteredData)
            .then(response => {
                setFilteredDataPending(response.data);
                if (filterSideMenu == 2) {
                    setConclusionsFiltered(response.data);
                }
            })
    }, [filteredPage, filteredData])

    useEffect(() => {
        UserService.getFilteredDataConclusionsAccepted(filteredPage, filteredData)
            .then(response => {
                setFilteredDataAccepted(response.data);
                if (filterSideMenu == 1) {
                    setConclusionsFiltered(response.data)
                }
            })

    }, [filteredPage, filteredData])

    useEffect(() => {
        UserService.getFilteredDataConclusionsCompleted(filteredPage, filteredData)
            .then(response => {
                setFilteredDataCompleted(response.data);
                if (filterSideMenu == 3) {
                    setConclusionsFiltered(response.data);
                }
            })
    }, [filteredPage, filteredData])



    const { register, handleSubmit, control, reset, formState: { errors } } = useForm({ mode: 'onBlur' })
    const onSubmit = (filteredData) => {

        //---React-Hook object converting to URL string
        let arrFilterItems = Object.keys(filteredData).map((item, index) => {
            if (Object.values(filteredData)[index] !== '' && Object.values(filteredData)[index] !== undefined && Object.values(filteredData)[index] !== "Invalid date") {
                return `filter[${item}]=${Object.values(filteredData)[index]}`
            }
        })
            .filter(item => item !== undefined)
        console.log(arrFilterItems)
        let newArr = arrFilterItems.map((item, index) => index == arrFilterItems.length - 1 ? `${item}` : `${item}&`)
        console.log(newArr.toString().replace(/[,]/gi, ''))
        //---------------------------------------------

        // UserService.getFilteredData(newArr.toString().replace(/[,]/gi, ''))
        setFilteredData(newArr.toString().replace(/[,]/gi, ''))
        // UserService.getFilteredDataConclusions(newArr.toString().replace(/[,]/gi, ''))
        //     .then(response => {
        //         
        //         setConclusionsFiltered(response.data.data);
        //         setOnClear(false)}
        //     )
        reset()
    }


    const tableBody = conclusionsFiltered && conclusionsFiltered.data.map((res, index) => {
        let deadLineColor;
        if (Number(moment(moment(res?.deadline) - moment()).format("DD")) == 2) {
            deadLineColor = "two-days"
        } else if (Number(moment(moment(res?.deadline) - moment()).format("DD")) == 1 || res?.deadline === moment().format("YYYY-MM-DD")) {
            deadLineColor = "one-day"
        } else {
            deadLineColor = "new-project"
        }
        return (
            <tbody>
                <tr key={res?.id} className={`table_body table_body-admin ${deadLineColor} `}
                // style={{ backgroundColor: ` ${isSecond === 1 ? `#F9F9F9` : `inherit`}` }}
                >
                    <td>{res?.id}</td>
                    <td >{res?.expert_full_name}</td>
                    <td >{res?.project_id}</td>
                    <td>{res?.deadline}</td>
                    <td className='table_status'>
                        <p>
                            {res?.status.locales.name[i18n.language]}
                            {userLocation == "/coordinator" ?
                                <span onClick={() => setIsShowTableData(isShowTableData == index ? null : index)}>
                                    {IconsContent.ArrowDownGreen()}
                                </span> : null}
                        </p>
                    </td>
                    <td>
                        {userLocation == '/expert' && res.status.id == 2 || userLocation == '/expert' && res.status.id == 10 || userLocation == '/expert' && res.status.id == 12 || userLocation == '/expert' && res.status.id == 13 ? <Link to={`/expert/conclusions/edit/${res?.id}`}>{IconsContent.Edit()} </Link> : null}
                    </td>
                    <td className={'li_a'}>
                        <Link to={`${userLocation}/conclusions/details/${res?.id}`}> {IconsContent.Eye()}</Link>
                    </td>
                </tr>
                {userLocation == "/coordinator" ? <tr className={isShowTableData == index ? 'table_body table_folding' : 'none'}>
                    <td colSpan={6}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <p>Примечание (координатор)</p>
                                <p>Нет данных</p>
                            </div>
                            <div>
                                <p>Примечание (эксперт)</p>
                                <p>Нет данных</p>
                            </div>
                            <div>
                                <p>Изменен</p>
                                <p>{res?.changedBy}</p>
                            </div>
                            <div>
                                <p>Создано</p>
                                <p>{res?.created_at}</p>
                            </div>
                            <div>
                                <p>Изменено</p>
                                <p>{res?.updated_at}</p>
                            </div>
                        </div>

                    </td>
                </tr> : null}
            </tbody>
        )
    })

    return (
        <div className={'conclusion__coordinator projects_page'}>
            <h1>Заключения</h1>
            <main className="main__page">
                <div className="main__page--left">
                    <p className={filterSideMenu == 0 ? "main__page--active" : null} onClick={() => { setFilterSideMenu(0); setFilteredPage(1); setConclusionsFiltered(filteredDataAll) }}>{IconsContent.Folder()}{t("all_project")}<span>{filteredDataAll?.meta?.total}</span></p>
                    <p className={filterSideMenu == 1 ? "main__page--active" : null} onClick={() => { setFilterSideMenu(1); setFilteredPage(1); setConclusionsFiltered(filteredDataAccepted) }}>{IconsContent.FolderAccepted()}{t("in_operative")}<span>{filteredDataAccepted?.meta?.total}</span></p>
                    <p className={filterSideMenu == 2 ? "main__page--active" : null} onClick={() => { setFilterSideMenu(2); setFilteredPage(1); setConclusionsFiltered(filteredDataPending) }}>{IconsContent.FolderPending()}{t("waiting")}<span>{filteredDataPending?.meta?.total}</span></p>
                    <p className={filterSideMenu == 3 ? "main__page--active" : null} onClick={() => { setFilterSideMenu(3); setFilteredPage(1); setConclusionsFiltered(filteredDataCompleted) }}>{IconsContent.FolderCompleted()}{t("completed")}<span>{filteredDataCompleted?.meta?.total}</span></p>
                </div>
                <div className="main__page--right">
                    <div className="main_header">
                        <button className={'btn_filter'} onClick={() => { setIsFilter(true) }}>
                            <span className="name">Фильтр</span>
                            {IconsContent.Filter()}
                        </button>
                        <div className={"btn_search"}>
                            {IconsContent.Search()}
                            <input type="email" placeholder={t("search")}  {...register("name", { onChange: () => handleSubmit(onSubmit)() })} />
                        </div>
                    </div>
                    <div className="status-legeng">
                        <span className="status-legeng__new-project">{t("new_project")}</span>
                        <span className="status-legeng__two-days">{t("two_days")}</span>
                        <span className="status-legeng__one-day">{t("one_day")}</span>
                    </div>
                    <div className="table_wrapper">
                        <table className={'table_styling table_styling-admin'}>
                            <thead >
                                <tr className={'table_heading'}>
                                    <TableFirst />
                                    <td>{t("expert")}</td>
                                    <td >{t("project_id")}</td>
                                    <td>{t("deadline")}</td>
                                    <td >СТАТУС</td>
                                    <td></td>
                                    <td className="last"></td>
                                </tr>
                            </thead>

                            {tableBody}

                        </table>

                        <div className="table_wrapper_footer">
                            <div className="footer_number">
                                {conclusionsFiltered && <p>Найдено {conclusionsFiltered.meta.total} из {conclusions.meta.total} </p>}
                            </div>
                            <div className="footer_pagination">
                                {conclusionsFiltered && conclusionsFiltered?.meta?.total > conclusions.meta?.per_page ?
                                    <Pagination count={Math.ceil(conclusionsFiltered.meta.total / conclusionsFiltered.meta?.per_page)} page={filteredPage} onChange={(event, value) => setFilteredPage(value)} variant="outlined" shape="rounded" /> : null}
                                {/* <button className={'btn_pagination '}>1</button>
                                <button className={'btn_pagination'}>2</button> */}
                            </div>
                        </div>
                    </div>
                </div >
            </main>

            <div className={`modal_filter ${isFilter ? 'active' : ''}`}>
                <div className={`modal_content`}>
                    <div className="modal_header">
                        <div className="header_word">
                            <h5>Фильтр</h5>
                            {IconsContent.Filter('#4ABD9B', `2`)}
                        </div>
                        <button onClick={() => { setIsFilter(false) }}>{IconsContent.Cancel()}</button>
                    </div>
                    <div className="modal_form">

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="input_wrapper">
                                <label htmlFor="">ID заключения</label>
                                <input type="number" {...register(`conclusion_id`)} />
                            </div>
                            <div className="input_wrapper">
                                <label htmlFor="">ID проекта</label>
                                <input type="number" {...register(`project_id`)} />
                            </div>
                            <div className="input_wrapper">
                                <label htmlFor="">Статус</label>
                                <InputSelect control={control} name="status_id" noRequired onClear={onClear} placeholder="Выберите" options={conclusionStatuses} />
                            </div>
                            <div className="input_wrapper ">
                                <label htmlFor="">Эксперт</label>
                                <InputSelect control={control} name="expert_id" noRequired onClear={onClear} placeholder="Выберите" options={expertsList} />
                            </div>
                            <div className="input_wrapper ">
                                <label htmlFor="">Ответственный корректор</label>
                                <InputSelect control={control} name="corrector_id" noRequired onClear={onClear} placeholder="Выберите" options={correctorsList} />
                            </div>
                            <div className="input_wrapper ">
                                <label htmlFor="">Ответственный переводчик</label>
                                <InputSelect control={control} name="translator_id" noRequired onClear={onClear} placeholder="Выберите" options={translatorsList} />
                            </div>
                            <div className="filter__ender">
                                <button type="submit" className={'submit'}>Применить</button>
                                <button type="button" onClick={(e) => { setOnClear(!onClear); reset(); e.stopPropagation() }} className={'submit reset '}>Сбросить</button>
                            </div>

                        </form>
                    </div>
                </div>
                <div className="modal_cover" onClick={() => { setIsFilter(false) }} />
            </div>
        </div>
    )
}