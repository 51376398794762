import React from "react";

export default function Pagination({ nPages, currentPage, setCurrentPage }) {
	const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

	return (
		<div>
			<ul className="pagination">
				{pageNumbers.map((pgNumber) => (
					<li
						key={pgNumber}
						className={`btn_pagination ${
							currentPage == pgNumber ? "active" : ""
						} `}
						onClick={() => setCurrentPage(pgNumber)}
					>
						<a className="page-link" href="#">
							{pgNumber}
						</a>
					</li>
				))}
			</ul>
		</div>
	);
}
