import "../../Styles/Layout.css";
import ConclusionDetailsContainer from '../../Components/pages/ConclusionDetailsContainer'
import MainLayout from "../../Components/Layout/MainLayout";
import Navbar from "../../Components/Navbar/Navbar";
import { CoordinatorNavBar } from "../../../Main/Services/http";
import { useEffect, useState } from "react";
import { UserService } from "../../../Main/Services/UserService";
import { useLocation } from "react-router-dom";
import Preloader from "../../Components/Preloader/Preloader";

export default function ConclusionCoordinator() {

  let conclusionId = useLocation().pathname.replace(/[a-z\/]/g, '')
  const [conclusion, setConclusion] = useState()

  useEffect(() => {

    UserService.getConclusion(conclusionId)
      .then(response => {
        let arr = response.data.data
        setConclusion(arr);

      })
  }, []);

  const [playAnimation, setPlayAnimation] = useState(false)
  useEffect(() => {
    const onPageLoad = () => {
      setPlayAnimation(true);
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <MainLayout>
      <Preloader playAnimation={playAnimation} />
      <Navbar direction={CoordinatorNavBar()} />
      {conclusion && <ConclusionDetailsContainer conclusion={conclusion} />}
    </MainLayout>
  )
}