import React from 'react'
import '../Statistics.scss'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';

const StatisticsContainerByConclusion = () => {


    const dataBar1 = [
        {
            name: "",
            uv: 4000,
            pv: 2400,
            lv: 490,
            amt: 2400
        },
        {
            name: "",
            uv: 3000,
            pv: 1398,
            lv: 490,
            amt: 2210
        },
        {
            name: "",
            uv: 2000,
            pv: 9800,
            lv: 490,
            amt: 2290
        },
        {
            name: "",
            uv: 2780,
            pv: 3908,
            lv: 490,
            amt: 2000
        },
        {
            name: "",
            uv: 1890,
            pv: 4800,
            lv: 490,
            amt: 2181
        },
        {
            name: "",
            uv: 2390,
            pv: 3800,
            lv: 490,
            amt: 2500
        },
        {
            name: "",
            uv: 3490,
            pv: 4300,
            lv: 490,
            amt: 2100
        },
        {
            name: "",
            uv: 4000,
            pv: 2400,
            lv: 490,
            amt: 2400
        },
        {
            name: "",
            uv: 3000,
            pv: 1398,
            lv: 490,
            amt: 2210
        },
        {
            name: "",
            uv: 2000,
            pv: 9800,
            lv: 490,
            amt: 2290
        },
        {
            name: "",
            uv: 2780,
            pv: 3908,
            lv: 490,
            amt: 2000
        },
        {
            name: "",
            uv: 1890,
            pv: 4800,
            lv: 490,
            amt: 2181
        },
        {
            name: "",
            uv: 2390,
            pv: 3800,
            lv: 490,
            amt: 2500
        },
        {
            name: "",
            uv: 3490,
            pv: 4300,
            lv: 490,
            amt: 2100
        },
        {
            name: "",
            uv: 2390,
            pv: 3800,
            lv: 490,
            amt: 2500
        },
        {
            name: "",
            uv: 3490,
            pv: 4300,
            lv: 490,
            amt: 2100
        },
        {
            name: "",
            uv: 4000,
            pv: 2400,
            lv: 490,
            amt: 2400
        },
        {
            name: "",
            uv: 3000,
            pv: 1398,
            lv: 490,
            amt: 2210
        },
        {
            name: "",
            uv: 2000,
            pv: 9800,
            lv: 490,
            amt: 2290
        },
        {
            name: "",
            uv: 2780,
            pv: 3908,
            lv: 490,
            amt: 2000
        },
        {
            name: "",
            uv: 1890,
            pv: 4800,
            lv: 490,
            amt: 2181
        },
        {
            name: "",
            uv: 2390,
            pv: 3800,
            lv: 490,
            amt: 2500
        },
        {
            name: "",
            uv: 3490,
            pv: 4300,
            lv: 490,
            amt: 2100
        },
    ];

    const dataBar2 = [
        {
            name: "",
            uv: 4000,
            pv: 2400,
            lv: 490,
            amt: 2400
        },
        {
            name: "",
            uv: 3000,
            pv: 1398,
            lv: 490,
            amt: 2210
        },
        {
            name: "",
            uv: 2000,
            pv: 9800,
            lv: 490,
            amt: 2290
        },
        {
            name: "",
            uv: 2780,
            pv: 3908,
            lv: 490,
            amt: 2000
        },
        {
            name: "",
            uv: 1890,
            pv: 4800,
            lv: 490,
            amt: 2181
        },
        {
            name: "",
            uv: 2390,
            pv: 3800,
            lv: 490,
            amt: 2500
        },
        {
            name: "",
            uv: 3490,
            pv: 4300,
            lv: 490,
            amt: 2100
        },
        {
            name: "",
            uv: 2390,
            pv: 3800,
            lv: 490,
            amt: 2500
        },
        {
            name: "",
            uv: 3490,
            pv: 4300,
            lv: 490,
            amt: 2100
        },
        {
            name: "",
            uv: 3490,
            pv: 4300,
            lv: 490,
            amt: 2100
        },
    ];

    const dataPie = [
        { name: 'Назначено', value: 100, color: '#ef8c62' },
        { name: 'На корректировке', value: 200, color: '#2147a7' },
        { name: 'Статус 3', value: 300, color: '#003b5d' },
        { name: 'Статус 4', value: 400, color: '#4cd2aa' },
    ];
    const dataPieOveralValue = dataPie.reduce((prev, next) => prev + next.value, 0)

    return (
        <div className='statistics'>
            <h1>Статистика</h1>
            <div className='statistics__dataBar1'>
                <div className='statistics__data-container'>
                    <h3>По экспертам</h3>
                    <div className='statistics__dataBar1-content'>
                        <BarChart
                            width={1200}
                            height={400}
                            data={dataBar1}
                            barGap={0}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5
                            }}
                        >
                            <CartesianGrid strokeDasharray="1 1" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="pv" name='Эксперты' fill="#2147a7" />
                            <Bar dataKey="uv" name='Эксперты 2' fill="#4abd9b" />
                            <Bar dataKey="lv" name='Эксперты 3' fill="#ef8c62" />
                        </BarChart>
                    </div>
                </div>
            </div>
            <div className='statistics__dataBar2'>
                <div className='statistics__data-container'>
                    <h3>По типам</h3>
                    <div className='statistics__dataBar2-content'>
                        <BarChart
                            width={1200}
                            height={400}
                            data={dataBar2}
                            barGap={0}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5
                            }}
                        >
                            <CartesianGrid strokeDasharray="1 1" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="pv" name='Тип 1' fill="#2147a7" />
                            <Bar dataKey="uv" name='Тип 2' fill="#4abd9b" />
                            <Bar dataKey="lv" name='Тип 3' fill="#ef8c62" />
                        </BarChart>
                    </div>
                </div>
            </div>
            <div className='statistics__dataPie'>
                <div className='statistics__data-container'>
                    <h3>По статусам</h3>
                    <div className='statistics__dataPie-content'>
                        <PieChart width={300} height={300}>
                            <Pie
                                data={dataPie}
                                cx={145}
                                cy={150}
                                innerRadius={100}
                                outerRadius={130}
                                fill="#8884d8"
                                paddingAngle={3}
                                dataKey="value"
                            >
                                {dataPie.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={entry.color} />
                                ))}

                            </Pie>

                        </PieChart>
                        <div className='statistics__dataPie-overal'>
                            <p>Всего</p>
                            <h2>{dataPieOveralValue}</h2>
                        </div>

                    </div>
                    <div className='statistics__dataPie-legend'>
                        {dataPie.map(item =>
                            <>
                                <span style={{borderColor: item.color}}></span>
                                <div>
                                    <p>{item.name}</p>
                                    <p>{item.value / dataPieOveralValue * 100}<span>%</span></p>
                                </div>
                            </>)}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default StatisticsContainerByConclusion