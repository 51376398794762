import { useEffect, useState } from "react";
import NavigationContent from "../../NavigationContent/NavigationContent";
import InputMask from 'react-input-mask'
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form'
import IconsContent from "../../../Assets/img/IconsContent";
import { InputSelect } from "../../Select/InputSelect";
import { UserService } from "../../../../Main/Services/UserService";
import DatePickerInput from "../../DatePicker/DatePicker";


export default function AddUserContainer({ branchOfLegislation }) {

    const { register, handleSubmit, control, reset, formState: { errors } } = useForm({ mode: 'onBlur' })
    const [userData, setUserData] = useState()
    const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
    const [isShowModalDocCreated, setIsShowModalDocCreated] = useState(false);
    const [isShowModalError, setIsShowModalError] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    console.log(selectedOptions)

    const onSubmit = (addUserData) => {
        console.log(addUserData)
        setUserData(addUserData)
        setIsShowModalConfirm(true)
        // UserService.addUser(addUserData)
        //     .then(response => {
        //         
        //         // setIsShowModalDocCreated(true)
        //     })
        // reset();
    }

    const [newUserRole, setNewUserRole] = useState()
    console.log(newUserRole)

    return (
        <div className={'transmittal-letters__form addProject_page projects_page'}>
            <NavigationContent direct={[
                { goTo: `/administrator`, name: `Главная` },
                { goTo: `/administrator/users`, name: `Пользователи` },
                { goTo: `/administrator/users/1`, name: `Добавить пользователя` }
            ]} name={"Добавление нового пользователя"} />

            <div className="header_wrapper_name">
            </div>
            <main>
                <h5>Обязательные поля *</h5>
                <form action="" onSubmit={handleSubmit(onSubmit)}>
                    <div className="input_wrapper">
                        <label htmlFor="">Роль</label>
                        <InputSelect control={control}
                            name="role"
                            // defaultValue={2}
                            // onClear={onClearCoordinator}
                            placeholder="Выберите роль"
                            selectedValue={(arg) => setNewUserRole(arg.label)}
                            options={[
                                { value: "corrector", label: 'Корректор' },
                                { value: "coordinator", label: 'Координатор' },
                                { value: "expert", label: 'Эксперт' },
                                { value: "translator", label: 'Переводчик' },
                                { value: "clerk", label: 'Делопроизводитель' },
                                { value: "economist", label: 'Экономист' },
                            ]}
                        />
                        {errors.role && <p className="form--required">{errors.role.message}</p>}

                    </div>
                    <div className="input_wrapper">
                        <label htmlFor="">E-mail *</label>
                        <input type="text" className={errors.email ? "form-error-border-color" : ""}{...register("email", {
                            required: "Заполните обязательное поле",
                            pattern: {
                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: "Введенный email не корректен"
                            }
                        })
                        } />
                        {errors.email && <p className="form--required">{errors.email.message}</p>}
                    </div>
                    <div className="input_wrapper">
                        <label htmlFor="">Пароль *</label>
                        <input type="text" className={errors.password ? "form-error-border-color" : ""} {...register("password", { required: "Заполните обязательное поле" })} />
                        {errors.password && <p className="form--required">{errors.password.message}</p>}
                    </div>
                    <div className="input_wrapper">
                        <label htmlFor="">ФИО пользователя *</label>
                        <input type="text" className={errors.full_name ? "form-error-border-color" : ""} {...register("full_name", { required: "Заполните обязательное поле" })} />
                        {errors.full_name && <p className="form--required">{errors.full_name.message}</p>}
                    </div>
                    <div className="input_wrapper">
                        <label htmlFor="">ИИН *</label>
                        <input type="text" maxLength="12" minLength="12" className={errors.tin ? "form-error-border-color" : ""} {...register("tin", {
                            required: "Заполните обязательное поле",
                            minLength: {
                                value: 12,
                                message: "ИИН должен содержать 12 цифр"
                            },
                            pattern: {
                                value: /^\d+$/,
                                message: "ИИН должен содержать только цифры"
                            }
                        })} />
                        {errors.tin && <p className="form--required">{errors.tin.message}</p>}
                    </div>
                    {newUserRole == "Координатор" || newUserRole == "Эксперт" ?
                        <>
                            <div className="input_wrapper">
                                <label htmlFor="">Дата рождения *</label>
                                <DatePickerInput name='birth_date' control={control} />
                                {errors.birth_date && <p className="form--required">{errors.birth_date.message}</p>}
                            </div>
                            <div className="input_wrapper">
                                <label htmlFor="">Телефон *</label>
                                <InputMask mask="+79999999999" {...register("phone")} placeholder="+7 (___) ___ - __ - __" />
                                {errors.phone && <p className="form--required">{errors.phone.message}</p>}
                            </div>
                            <div className="input_wrapper">
                                <label htmlFor="">Должность (на казахском) *</label>
                                <input type="text" className={errors.job_title_kk ? "form-error-border-color" : ""}{...register("job_title_kk", { required: "Заполните обязательное поле" })} />
                                {errors.job_title_kk && <p className="form--required">{errors.job_title_kk.message}</p>}
                            </div>
                            <div className="input_wrapper">
                                <label htmlFor="">Должность (на казахском) *</label>
                                <input type="text" className={errors.job_title_ru ? "form-error-border-color" : ""} {...register("job_title_ru", { required: "Заполните обязательное поле" })} />
                                {errors.job_title_ru && <p className="form--required">{errors.job_title_ru.message}</p>}
                            </div>
                        </>

                        : null}
                    <div className="input_wrapper">
                        <label htmlFor="" >Примечания (на казахском)</label>
                        <input type="text" {...register("note_kk")} />
                    </div>
                    <div className={newUserRole !== "Эксперт" ? "input_wrapper-last" : "input_wrapper"}>
                        <label htmlFor="">Примечания (на русском)</label>
                        <input type="text" {...register("note_ru")} />
                    </div>
                    {newUserRole == "Эксперт" ?

                        <>
                            <div className="input_wrapper">
                                <label htmlFor="">Организация (на русском) * </label>
                                <input type="text" {...register("organization_kk")} />
                            </div>
                            <div className="input_wrapper">
                                <label htmlFor="">Организация (на русском) *</label>
                                <input type="text" {...register("organization_ru")} />
                            </div>
                            <div className="input_wrapper">
                                <label htmlFor="">Вид участия</label>
                                <Select options={[
                                    { value: 1, label: 'Государственный' },
                                    { value: 2, label: 'Общественный' },

                                ]}
                                    classNamePrefix="custom-select" placeholder="Выберите" />
                            </div>
                            <div className="input_wrapper">
                                <label htmlFor="">Ученая/Академическая степень (на казахском) *</label>
                                <input type="text" className={errors.academic_degree_kk ? "form-error-border-color" : ""} {...register("academic_degree_kk", { required: "Заполните обязательное поле" })} />
                                {errors.academic_degree_kk && <p className="form--required">{errors.academic_degree_kk.message}</p>}
                            </div>
                            <div className="input_wrapper">
                                <label htmlFor="">Ученая/Академическая степень (на русском)*</label>
                                <input type="text" className={errors.academic_degree_ru ? "form-error-border-color" : ""} {...register("academic_degree_ru", { required: "Заполните обязательное поле" })} />
                                {errors.academic_degree_ru && <p className="form--required">{errors.academic_degree_ru.message}</p>}
                            </div>
                            <div className="input_wrapper">
                                <label htmlFor="">Научное звание (на казахском)*</label>
                                <input type="text" className={errors.scientific_title_kk ? "form-error-border-color" : ""}{...register("scientific_title_kk", { required: "Заполните обязательное поле" })} />
                                {errors.scientific_title_kk && <p className="form--required">{errors.scientific_title_kk.message}</p>}
                            </div>
                            <div className="input_wrapper">
                                <label htmlFor="">Научное звание (на русском)*</label>
                                <input type="text" className={errors.scientific_title_ru ? "form-error-border-color" : ""} {...register("scientific_title_ru", { required: "Заполните обязательное поле" })} />
                                {errors.scientific_title_ru && <p className="form--required">{errors.scientific_title_ru.message}</p>}
                            </div>
                            <div className={newUserRole == "Expert" ? "input_wrapper-last" : "input_wrapper"}>
                                <label htmlFor="">Специализация (Максимальное значение - 5)</label>
                                <Controller
                                    name="branch_of_legislations"
                                    control={control}
                                    render={({ field: { onChange, ref, value } }) =>
                                        // <Select
                                        //     isMulti
                                        //     ref={ref}
                                        //     options={branchOfLegislation}
                                        //     value={branchOfLegislation?.find((c) => c.value === value)}
                                        //     onChange={(val) => onChange(val.map((c) => c.value))}
                                        //     isOptionDisabled={(option) => branchOfLegislation?.find((c) => c.value === value).length >= 5}
                                        //     classNamePrefix="custom-select"
                                        //     placeholder="Выберите"
                                        // />
                                        <Select
                                            isMulti
                                            value={branchOfLegislation?.find((c) => c.value === value)}
                                            onChange={(val) => { onChange(val.map((c) => c.value)); setSelectedOptions(val) }}
                                            // value={selectedOptions}
                                            ref={ref}
                                            // onChange={(o) => onChange(o.value)}
                                            options={branchOfLegislation}
                                            // only allow user to choose up to 3 options
                                            isOptionDisabled={() => selectedOptions.length >= 5}
                                            placeholder="Выберите"
                                            classNamePrefix="custom-select"
                                        />
                                    } />
                            </div>
                        </>
                        : null}
                    <button className={'submit'}>Сохранить</button>

                </form>
                <div className={isShowModalConfirm ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                    <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
                        <p>
                            <span onClick={() => setIsShowModalConfirm(false)}>{IconsContent.Close()}</span>
                        </p>
                        <div>
                            <p>{IconsContent.Ask()}</p>
                            <p style={{ color: '#0D152C', marginTop: "20px", fontSize: "18px", fontWeight: "500" }}>Вы действительно хотите добавить нового пользователя?</p>
                            <div className='isShowModalDeleteUserConfirm'>
                                <button onClick={() => UserService.addUser(userData).then((response) => {

                                    setIsShowModalDocCreated(true); setIsShowModalConfirm(false)
                                }).catch(err => { setIsShowModalError(true) })}>Подтвердить</button>
                                <button onClick={() => setIsShowModalConfirm(false)}>Закрыть</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={isShowModalDocCreated ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                    <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
                        <p>
                            <span onClick={() => setIsShowModalDocCreated(false)}>
                                {IconsContent.Close()}
                            </span>
                        </p>
                        <div>
                            <p>{IconsContent.DocCreated()}</p>
                            <p>Пользователь сформирован</p>
                            <button onClick={() => { setIsShowModalDocCreated(false); window.location.reload(); }}>
                                Закрыть
                            </button>
                        </div>
                    </div>
                </div>
                <div className={isShowModalError ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                    <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
                        <p>
                            <span onClick={() => setIsShowModalError(false)}>
                                {IconsContent.Close()}
                            </span>
                        </p>
                        <div>
                            <p>{IconsContent.Error()}</p>
                            <p>Произошла ошибка</p>
                            <button onClick={() => { setIsShowModalError(false); window.location.reload(); }}>
                                Закрыть
                            </button>
                        </div>
                    </div>
                </div>
            </main>


        </div>
    )
}