import { useLocation } from "react-router-dom";
import MainLayout from "../../Components/Layout/MainLayout";
import ReportsDetailsContainer from "../../Components/pages/Coordinator/ReportsDetailsContainer";
import { CoordinatorNavBar } from "../../../Main/Services/http";
import Navbar from "../../Components/Navbar/Navbar";
import Preloader from "../../Components/Preloader/Preloader";
import { useEffect, useState } from "react";

export default function Reports(){
    let num = useLocation().state
    const [playAnimation, setPlayAnimation] = useState(false)
    useEffect(() => {
	  const onPageLoad = () => {
		setPlayAnimation(true);
	  };
  
	  if (document.readyState === 'complete') {
		onPageLoad();
	  } else {
		window.addEventListener('load', onPageLoad);
		return () => window.removeEventListener('load', onPageLoad);
	  }
	}, []);
    
    return(
        <MainLayout isExpertRole num={num}>
            <Preloader playAnimation={playAnimation}/>
           <Navbar direction={CoordinatorNavBar()}/>
            <ReportsDetailsContainer />
        </MainLayout>
    )
}