import React from 'react'
import IconsContent from '../../../Assets/img/IconsContent'
import { useForm } from 'react-hook-form'
import { UserService } from '../../../../Main/Services/UserService'
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Preloader from "../../Preloader/Preloader";

const UserDetailsContainer = ({ userData }) => {

  const navigate = useNavigate()

  const [isShowModalUserDeleted, setIsShowModalUserDeleted] = useState()
  const [isShowModalUserEdited, setIsShowModalUserEdited] = useState()
  const [isShowModalUserDeletedConfirm, setIsShowModalUserDeletedConfirm] = useState()
  const [editButton, setEditButton] = useState(true)
  const [editPasswordButton, setEditPasswordButton] = useState(true)
  const [newPlayAnimation, setNewPlayAnimation] = useState(false)
  const [isShowModalError, setIsShowModalError] = useState(false);

  console.log(editButton)

  const { register, reset, handleSubmit, formState: { errors } } = useForm({ mode: 'onBlur' })

  const onSubmit = (userDataChanged) => {
    setNewPlayAnimation(true)
    console.log(userDataChanged)
    UserService.upDateUser(userData.id, userDataChanged)
      .then(() => { setNewPlayAnimation(false); setIsShowModalUserEdited(true) })
      .catch(() => { setNewPlayAnimation(false); setIsShowModalError(true) })

  }

  return (
    <div className='projects_page users__page'>
      {newPlayAnimation && <Preloader playAnimation={false} />}
      <h1>Пользователи внутри</h1>
      <main >
        <div className='users__page-container'>
          <div className='users__page-header'>
            <p>
              {IconsContent.User("#4ABD9B")}
              <span>{userData?.full_name}</span>
            </p>
            <p className='user-control'>
              <span onClick={() => setIsShowModalUserDeletedConfirm(true)}>{IconsContent.DeleteUser()}</span>
              <span onClick={() => { setEditButton(!editButton) }}>{IconsContent.EditUser()}</span>
            </p>
          </div>
          <p className='users__page-role'>{userData?.roles[0].name}</p>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='reports-details__content'>
              <h5>Email</h5>
              {editButton ? <input type="text" value={null} readOnly placeholder={userData?.email} /> :
                <input type="text" defaultValue={userData?.email} className={errors.email ? "form-error-border-color" : ""}{...register("email", {
                  required: "Заполните обязательное поле",
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Введенный email не корректен"
                  }
                })
                } />}
              {errors.email && <p className="form--required">{errors.email.message}</p>}
            </div>

            <div className='reports-details__content'>
              <h5>ФИО пользователя</h5>
              {editButton ? <input type="text" value={null} readOnly placeholder={userData?.full_name} />
                :
                <input type="text" defaultValue={userData?.full_name}
                  className={errors.full_name ? "form-error-border-color" : ""} {...register("full_name", { required: "Заполните обязательное поле" })} />}
              {errors.full_name && <p className="form--required">{errors.full_name.message}</p>}
            </div>
            <div className='reports-details__content'>
              <h5>ИИН</h5>
              {editButton ? <input type="text" value={null} readOnly placeholder={userData?.tin} />
                :
                <input type="text" readOnly={editButton} defaultValue={userData?.tin} maxLength="12" minLength="12" className={errors.tin ? "form-error-border-color" : ""} {...register("tin", {
                  required: "Заполните обязательное поле",
                  minLength: {
                    value: 12,
                    message: "ИИН должен содержать 12 цифр"
                  },
                  pattern: {
                    value: /^\d+$/,
                    message: "ИИН должен содержать только цифры"
                  }
                })} />}
            </div>
            <div className='reports-details__content'>
              <h5>Примечания (на казахском)</h5>
              {editButton ? <input type="text" value={null} readOnly placeholder={userData?.note} />
                :
                <input type="text" defaultValue={userData?.note} {...register("note")} />}
            </div>
            <div className='reports-details__content'>
              <h5>Примечания (на русском)</h5>
              {editButton ? <input type="text" value={null} readOnly placeholder={userData?.note} />
                :
                <input type="text" defaultValue={userData?.note}  {...register("note")} />}
            </div>
            <div className='reports-details__content'>
              <h5 style={{ display: "flex", justifyContent: "space-between" }}>Пароль {!editButton && <span style={{ cursor: "pointer" }} onClick={() => { reset(); setEditPasswordButton(!editPasswordButton) }}>{IconsContent.EditUser()}</span>}</h5>
              {editPasswordButton && editPasswordButton ? <input type="text" value={null} readOnly placeholder={'************'} />
                :
                <input type="text" className={errors.password ? "form-error-border-color" : ""} {...register("password", { required: "Заполните обязательное поле" })} />}
              {errors.password && <p className="form--required">{errors.password.message}</p>}
            </div>
            {!editButton ?
              <button className='c-btn-aquamarine'>Сохранить изменения</button> : null}

          </form>

        </div>

        <div className={isShowModalUserDeletedConfirm ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
          <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
            <p>
              <span onClick={() => setIsShowModalUserDeletedConfirm(false)}>{IconsContent.Close()}</span>
            </p>
            <div>
              <p>{IconsContent.DocCreated()}</p>
              <p>Вы действительно хотите удалить пользователя {userData?.full_name}?</p>
              <div className='isShowModalDeleteUserConfirm'>
                <button onClick={() => UserService.deleteUser(userData?.id).then(res => setIsShowModalUserDeleted(true))}>Удалить</button>
                <button onClick={() => setIsShowModalUserDeletedConfirm(false)}>Закрыть</button>
              </div>
            </div>
          </div>
        </div>

        <div className={isShowModalUserDeleted ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
          <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
            <p>
              <span onClick={() => { setIsShowModalUserDeleted(false) }}>{IconsContent.Close()}</span>
            </p>
            <div>
              <p>{IconsContent.DocCreated()}</p>
              <p>Пользователь удален</p>
              <button onClick={() => { setIsShowModalUserDeleted(false); navigate('/administrator/users') }}>Закрыть</button>
            </div>
          </div>
        </div>
        <div className={isShowModalUserEdited ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
          <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
            <p>
              <span onClick={() => { setIsShowModalUserEdited(false) }}>{IconsContent.Close()}</span>
            </p>
            <div>
              <p>{IconsContent.DocCreated()}</p>
              <p>Данные изменены!</p>
              <button onClick={() => { setIsShowModalUserEdited(false); window.location.reload() }}>Закрыть</button>
            </div>
          </div>
        </div>


        <div className={isShowModalError ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
          <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
            <p>
              <span onClick={() => setIsShowModalError(false)}>
                {IconsContent.Close()}
              </span>
            </p>
            <div>
              <p>{IconsContent.Error()}</p>
              <p>Произошла ошибка</p>
              <button onClick={() => {
                setIsShowModalError(false);
                // window.location.reload();
              }}>
                Закрыть
              </button>
            </div>
          </div>
        </div>

      </main>
    </div>
  )
}

export default UserDetailsContainer