import MainLayout from "../../Components/Layout/MainLayout";
import ProfileContainer from "../../Components/pages/ProfileContainer";
import IconsContent from "../../Assets/img/IconsContent";
import Navbar from "../../Components/Navbar/Navbar";
import { useState, useEffect } from "react";
import { UserService } from "../../../Main/Services/UserService";
import Preloader from "../../Components/Preloader/Preloader";
import { AdministratorkNavBar } from "../../../Main/Services/http";

export default function Profile() {
  console.log(Math.ceil(9 / 8))
  const [userData, setUserData] = useState()
  let userId = localStorage.getItem('.UserID')
  useEffect(() => {

    UserService.getUser(userId)
      .then(response => {
        let arr = response.data.data
        setUserData(arr);

      })
  }, []);

  const [playAnimation, setPlayAnimation] = useState(false)
  useEffect(() => {
    const onPageLoad = () => {
      setPlayAnimation(true);
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <MainLayout>
      <Preloader playAnimation={playAnimation} />
      <Navbar direction={AdministratorkNavBar()} />
      {userData && <ProfileContainer userData={userData} />}
    </MainLayout>
  )
}