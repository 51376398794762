import IconsContent from "../../../Assets/img/IconsContent";
import { useEffect, useState } from "react";
import iconsContent from "../../../Assets/img/IconsContent";
import { Link } from "react-router-dom";
import Select from "react-select";
import TableFirst from "../../TableFirst/TableFirst";
import Pagination from "../../Pagination/Pagination";

export default function UsersContainer() {
	const [fullNumber, setFullNumber] = useState(0);
	const [filteredNumber, setFilteredNumber] = useState(0);
	const [isFilter, setIsFilter] = useState(false);
	const [isShowFilter, setIsShowFilter] = useState(null);
	const [isShowTableData, setIsShowTableData] = useState();
	const [usersRole, setUsersRole] = useState("Координатор");
	const [isShowModal, setIsShowModal] = useState(false);
	const [isDeleted, setIsDeleted] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [recordsPerPage] = useState(2);

	const state1 = [
		{
			id: 1234,
			email: "mail@mail.com",
			name: "Иванова Т.И",
			iin: 880911375894,
			status: "Статус2",
			phone: "+7 701 435 54 43",
			jobTitle: "Должность (на русском)",
			notice: "Примечание (на русском)",
			birthDate: "22-07-05",
			changedName: "ФИО создателя",
			createdAt: "2022-07-05 00:00:00",
			changedAt: "2022-07-05 00:00:00",
		},
		{
			id: 5543,
			email: "mail@mail.com",
			name: "Иванова Т.И",
			iin: 880911375894,
			status: "Статус1",
			phone: "+7 701 435 54 43",
			jobTitle: "Должность (на русском)",
			notice: "Примечание (на русском)",
			birthDate: "22-07-05",
			changedName: "ФИО создателя",
			createdAt: "2022-07-05 00:00:00",
			changedAt: "2022-07-05 00:00:00",
		},
		{
			id: 1254,
			email: "mail@mail.com",
			name: "Иванова Т.И",
			iin: 880911375894,
			status: "Статус3",
			phone: "+7 701 435 54 43",
			jobTitle: "Должность (на русском)",
			notice: "Примечание (на русском)",
			birthDate: "22-07-05",
			changedName: "ФИО создателя",
			createdAt: "2022-07-05 00:00:00",
			changedAt: "2022-07-05 00:00:00",
		},
	];
	const state2 = [
		{
			id: 1234,
			email: "mail@mail.com",
			name: "Сидорова Е.Ю.",
			iin: 880911375894,
			status: "Статус2",
			phone: "+7 701 435 54 43",
			jobTitle: "Должность (на русском)",
			notice: "Примечание (на русском)",
			birthDate: "22-07-05",
			changedName: "ФИО создателя",
			createdAt: "2022-07-05 00:00:00",
			changedAt: "2022-07-05 00:00:00",
		},
		{
			id: 5543,
			email: "mail@mail.com",
			name: "Сидорова Е.Ю.",
			iin: 880911375894,
			status: "Статус1",
			phone: "+7 701 435 54 43",
			jobTitle: "Должность (на русском)",
			notice: "Примечание (на русском)",
			birthDate: "22-07-05",
			changedName: "ФИО создателя",
			createdAt: "2022-07-05 00:00:00",
			changedAt: "2022-07-05 00:00:00",
		},
		{
			id: 1254,
			email: "mail@mail.com",
			name: "Сидорова Е.Ю.",
			iin: 880911375894,
			status: "Статус3",
			phone: "+7 701 435 54 43",
			jobTitle: "Должность (на русском)",
			notice: "Примечание (на русском)",
			birthDate: "22-07-05",
			changedName: "ФИО создателя",
			createdAt: "2022-07-05 00:00:00",
			changedAt: "2022-07-05 00:00:00",
		},
	];
	const state3 = [
		{
			id: 1234,
			email: "mail@mail.com",
			name: "Петрова И.Н",
			iin: 880911375894,
			status: "Статус2",
			phone: "+7 701 435 54 43",
			jobTitle: "Должность (на русском)",
			notice: "Примечание (на русском)",
			birthDate: "22-07-05",
			changedName: "ФИО создателя",
			createdAt: "2022-07-05 00:00:00",
			changedAt: "2022-07-05 00:00:00",
		},
		{
			id: 5543,
			email: "mail@mail.com",
			name: "Петрова И.Н",
			iin: 880911375894,
			status: "Статус1",
			phone: "+7 701 435 54 43",
			jobTitle: "Должность (на русском)",
			notice: "Примечание (на русском)",
			birthDate: "22-07-05",
			changedName: "ФИО создателя",
			createdAt: "2022-07-05 00:00:00",
			changedAt: "2022-07-05 00:00:00",
		},
		{
			id: 1254,
			email: "mail@mail.com",
			name: "Петрова И.Н",
			iin: 880911375894,
			status: "Статус3",
			phone: "+7 701 435 54 43",
			jobTitle: "Должность (на русском)",
			notice: "Примечание (на русском)",
			birthDate: "22-07-05",
			changedName: "ФИО создателя",
			createdAt: "2022-07-05 00:00:00",
			changedAt: "2022-07-05 00:00:00",
		},
		{
			id: 1234,
			email: "mail@mail.com",
			name: "Петрова И.Н",
			iin: 880911375894,
			status: "Статус2",
			phone: "+7 701 435 54 43",
			jobTitle: "Должность (на русском)",
			notice: "Примечание (на русском)",
			birthDate: "22-07-05",
			changedName: "ФИО создателя",
			createdAt: "2022-07-05 00:00:00",
			changedAt: "2022-07-05 00:00:00",
		},
		{
			id: 5543,
			email: "mail@mail.com",
			name: "Петрова И.Н",
			iin: 880911375894,
			status: "Статус1",
			phone: "+7 701 435 54 43",
			jobTitle: "Должность (на русском)",
			notice: "Примечание (на русском)",
			birthDate: "22-07-05",
			changedName: "ФИО создателя",
			createdAt: "2022-07-05 00:00:00",
			changedAt: "2022-07-05 00:00:00",
		},
	];
	const usersRolesArray = ["Координатор", "Переводчик", "Корректор"];

	useEffect(() => {
		setTimeout(() => {
			setIsShowFilter(isFilter);
		}, 1);
	}, [isFilter]);

	useEffect(() => {
		if (!isShowFilter) {
			setTimeout(() => {
				setIsFilter(isShowFilter);
			}, 500);
		}
	}, [isShowFilter]);
	let nPages;
	function usersRoleFunction(usersRole) {
		let usersArray;
		if (usersRole == "Корректор") {
			usersArray = state1;
		}
		if (usersRole == "Координатор") {
			usersArray = state2;
		}
		if (usersRole == "Переводчик") {
			usersArray = state3;
		}
		const indexOfLastRecord = currentPage * recordsPerPage;
		const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
		const currentRecords = usersArray.slice(
			indexOfFirstRecord,
			indexOfLastRecord
		);

		nPages = Math.ceil(usersArray.length / recordsPerPage);
		const tableBody = currentRecords.map((res, index) => {
			const isSecond = index % 2;
			return (
				<>
					<tr key={res?.id} className={"table_body"}>
						<td>{res?.id}</td>
						<td>{res?.email}</td>
						<td>{res?.name}</td>
						<td>{res?.iin}</td>
						<td colSpan={2} className="table_status">
							<p>
								{res?.status}
								<span
									onClick={() =>
										setIsShowTableData(isShowTableData == index ? null : index)
									}
								>
									{IconsContent.ArrowDownGreen()}
								</span>
							</p>
						</td>

						<td colSpan={2}>
							<div className={"users__page-control"}>
								<a onClick={() => setIsShowModal(true)}>
									{IconsContent.DeleteUser()}
								</a>
								<a href="/superadministrator/users/editUser">
									{IconsContent.EditUser()}
								</a>
								<a href="/superadministrator/users/details/*">
									{IconsContent.Eye()}
								</a>
							</div>
						</td>
					</tr>
					<tr
						className={
							isShowTableData == index ? "table_body table_folding" : "none"
						}
					>
						<td colSpan={8}>
							<div>
								<p>
									<span>Телефон</span>
									<span>{res?.phone}</span>
								</p>
								<p>
									<span>Должность (на русском)</span>
									<span>{res?.jobTitle}</span>
								</p>
								<p>
									<span>Примечание (на русском)</span>
									<span>{res?.notice}</span>
								</p>
								<p>
									<span>Дата рождения</span>
									<span>{res?.birthDate}</span>
								</p>
								<p>
									<span>Изменен</span>
									<span>{res?.changedName}</span>
								</p>
								<p>
									<span>Создано</span>
									<span>{res?.createdAt}</span>
								</p>
								<p>
									<span>Изменено</span>
									<span>{res?.changedAt}</span>
								</p>
							</div>
						</td>
					</tr>
				</>
			);
		});
		return tableBody;
	}
	return (
		<div className={"conclusion__coordinator users__page projects_page"}>
			<div className="header_wrapper_name">
				<h1>Пользователи</h1>
				<Link to={"/superadministrator/users/addUser"}>
					<a className={"add_project"}>
						<span>Добавить пользователя</span> {IconsContent.AddProject()}
					</a>
				</Link>
			</div>
			<main className="c-card">
				<div className="main_header ">
					<button
						className={"btn_filter"}
						onClick={() => {
							setIsFilter(true);
						}}
					>
						<span className="name">Фильтр</span>
						{IconsContent.Filter()}
					</button>
					<div className={"btn_search"}>
						{IconsContent.Search()}
						<input type="email" placeholder={"Поиск..."} />
					</div>
				</div>
				<div className="table_wrapper">
					<div className="users__role">
						{usersRolesArray.map((item) => (
							<span
								className={usersRole == item ? "users__role-active" : ""}
								onClick={() => setUsersRole(item)}
							>
								{item}
							</span>
						))}
					</div>
					<table className={"table_styling"}>
						<thead>
							<tr className={"table_heading"}>
								<TableFirst />
								<td>E-MAIL</td>
								<td>ФИО (НА РУССКОМ)</td>
								<td>ИИН</td>
								<td>АКТИВНОСТЬ</td>
								<td className="table_transparent">2022-07-05 00:00:00</td>
								<td className="table_transparent">2022-07-05 00:00:00</td>
								<td className="last"></td>
							</tr>
						</thead>
						<tbody>{usersRoleFunction(usersRole)}</tbody>
					</table>
					<div className="table_wrapper_footer">
						<div className="footer_number">
							<p>
								Найдено {filteredNumber} из {fullNumber}
							</p>
						</div>
						<div className="footer_pagination">
							<Pagination
								nPages={nPages}
								currentPage={currentPage}
								setCurrentPage={setCurrentPage}
							/>
						</div>
					</div>
				</div>
			</main>

			{isFilter && (
				<div className={`modal_filter`}>
					<div
						className={`modal_content ${!isShowFilter ? `disActive` : null}`}
					>
						<div className="modal_header">
							<div className="header_word">
								<h5>Фильтр</h5>
								{IconsContent.Filter("#4ABD9B", `2`)}
							</div>
							<button
								onClick={() => {
									setIsShowFilter(false);
								}}
							>
								{iconsContent.Cancel()}
							</button>
						</div>
						<div className="modal_form">
							<form action="">
								<div className="input_wrapper">
									<label htmlFor="">ID</label>
									<input type="text" />
								</div>
								<div className="input_wrapper">
									<label htmlFor="">E-mail</label>
									<input type="text" />
								</div>
								<div className="input_wrapper">
									<label htmlFor="">ФИО (на русском)</label>
									<input type="text" />
								</div>
								<div className="input_wrapper">
									<label htmlFor="">ИИН</label>
									<input type="text" />
								</div>
								<div className="input_wrapper">
									<label htmlFor="">Должность (на русском)</label>
									<input type="text" />
								</div>
								<div className="input_wrapper">
									<label htmlFor="">Организация (на русском)</label>
									<input type="text" />
								</div>
								<div className="input_wrapper ender">
									<label htmlFor="">Активность</label>
									<Select
										options={[
											{ value: "point1", label: "Пункт1" },
											{ value: "point2", label: "Пункт2" },
										]}
										classNamePrefix="custom-select"
										placeholder="Да"
									/>
								</div>

								<button className={"submit"}>Применить</button>
								<button className={"cancel"}>Сбросить</button>
							</form>
						</div>
					</div>
					<div
						className="modal_cover"
						onClick={() => {
							setIsShowFilter(false);
						}}
					/>
				</div>
			)}
			{
				<div
					className={
						isShowModal
							? "transmittal-letters__modal transmittal-letters__modal-active"
							: "transmittal-letters__modal"
					}
					onClick={() => setIsShowModal(false)}
				>
					<div
						className="transmittal-letters__modal-content"
						onClick={(e) => e.stopPropagation()}
					>
						<p className="transmittal-letters__modal-heading">
							<span>Удаление пользователя</span>
							<span onClick={() => setIsShowModal(false)}>
								{IconsContent.Close()}
							</span>
						</p>
						{isDeleted ? (
							<h6>Пользователь [ФИО пользователя] успешно удален</h6>
						) : (
							<h6>Вы уверены что хотите удалить пользователя [ФИО]?</h6>
						)}
						<div className="btn_action btn_action-admin">
							{isDeleted ? null : (
								<button
									className="btn_finalize"
									onClick={() => setIsDeleted(true)}
								>
									Удалить
								</button>
							)}
							<button
								className="btn_assign"
								onClick={() => {
									setIsShowModal(false);
									setTimeout(() => {
										setIsDeleted(false);
									}, 300);
								}}
							>
								Закрыть
							</button>
						</div>
					</div>
				</div>
			}
		</div>
	);
}
