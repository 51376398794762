import React from 'react'
import SlideToggle from "react-slide-toggle";
import IconsContent from "../../../Assets/img/IconsContent";
import NavigationContent from "../../NavigationContent/NavigationContent";
import Select from 'react-select';


const ReportsPricedDetailsContainer = () => {

    const reportPricedDetails = [
        [
            'id',
            'id'
        ],
        [
            'Наименование',
            'Наименование'
        ],
        [
            'Орган разработчик',
            'Наименование государственного органа разработчика'
        ],
        [
            'Вид НПА',
            'Вид НПА согласно Таблице 95'
        ],
        [
            'Группа НПА',
            'Группа НПА согласно Таблице 95'
        ],
        [
            'Дата подписи ЭЦП',
            'Дата подписи ЭЦП'
        ],
        [
            'Эксперт',
            'Эксперт'
        ],
        [
            'Количество замечаний',
            'Количество замечаний'
        ],
        [
            'Кол-во зам.ОБЩ',
            'Общее количество замечаний от всех экспертов'
        ],
        [
            'БП Общая',
            'Общий базовый показатель согласно Таблице 95'
        ],
        [
            'БП ',
            'Базовый показатель. Рассчитывается по формуле: БП общая/Количество экспертов'
        ],
        [
            'Риски ОБЩ',
            'Общая сумма рисков'
        ],
        [
            'Риски',
            'Рассчитывается по формуле: БП+РИСК по каждому эксперту'
        ],
        [
            'Координатор',
            'Оплата координатору в части реализации проекта и осуществления работ по проведению экспертизы НПА'
        ],
        [
            'Стоимость НПА без НДС',
            'Рассчитывается по формуле: Координатор+ Экспертная часть Общая'
        ],
        [
            'Стоимость НПА с НДС',
            'Рассчитывается по формуле: Стоимость НПА без НДС*1,12'
        ]
    ]
    

    return (
        <div className='projects_page reports-details'>
            <NavigationContent direct={[
                { goTo: `/coordinator`, name: `Главная` },
                { goTo: `/coordinator/reports`, name: `Отчеты` },
                { goTo: `/coordinator/reports/priced/details/1`, name: `Просмотр журнала` }
            ]} name={"Отчет по стоимости проектов"} />
            {/* <h1>Отчет по стоимости проектов</h1> */}
            <main>
             {reportPricedDetails.map(item=>
                <div className='reports-details__content'>
                    <h5>{item[0]}</h5>
                    <p>{item[1]}</p>
                </div>
                )}       
            </main>
        </div>
    )

}

export default ReportsPricedDetailsContainer