import { useEffect, useRef, useState } from "react";
import IconsContent from "../../Assets/img/IconsContent";
import { Link } from "react-router-dom";
import TableFirst from "../TableFirst/TableFirst";
import { InputSelect } from "../Select/InputSelect";
import { useForm } from 'react-hook-form'
import { UserService } from "../../../Main/Services/UserService";
import { useTranslation } from "react-i18next";
import ModalAdministrator from "../Modal/ModalAdministrator";
import Pagination from '@mui/material/Pagination';



export default function TransmittalLettersContainer({ transmittalLetters, branchOfLegislation, documentTypes }) {
    const { t, i18n } = useTranslation("common");
    const nameLocales = `name_${i18n.language}`
    const [filterSideMenu, setFilterSideMenu] = useState(0)
    const [isFilter, setIsFilter] = useState(false)
    const [onClear, setOnClear] = useState(false)
    const [transmittalLettersFiltered, setTransmittalLettersFiltered] = useState(transmittalLetters)
    const [isShowModalConclusionSentForECP, setIsShowModalConclusionSentForECP] = useState(false)
    const userLocation = localStorage.getItem('.UserLocation')
    const [transmittalLetterId, setTransmittalLetterId] = useState()
    const [filteredPage, setFilteredPage] = useState(1)
    const [filteredData, setFilteredData] = useState('')
    const [filteredDataAll, setFilteredDataAll] = useState('')
    const [filteredDataFilled, setFilteredDataFilled] = useState('')
    const [filteredDataUnfilled, setFilteredDataUnfilled] = useState('')
    const [filteredDataAssigned, setFilteredDataAssigned] = useState('')

    useEffect(() => {
        UserService.getFilteredDataLetters(filteredPage, filteredData)
            .then(response => {

                setFilteredDataAll(response.data);
                // setProjectsFiltered(response.data)
                if (filterSideMenu == 0) {
                    setTransmittalLettersFiltered(response.data)
                }
            })
    }, [filteredPage, filteredData])

    useEffect(() => {
        UserService.getTransmittalLettersFilled(filteredPage)
            .then(response => {

                setFilteredDataFilled(response.data);
                if (filterSideMenu == 2) {
                    setTransmittalLettersFiltered(response.data)
                }
            })
    }, [filteredPage])

    useEffect(() => {
        UserService.getTransmittalLettersUnFilled(filteredPage,)
            .then(response => {

                setFilteredDataUnfilled(response.data);
                if (filterSideMenu == 1) {
                    setTransmittalLettersFiltered(response.data)
                }
            })
    }, [filteredPage])

    useEffect(() => {
        UserService.getTransmittalLettersAssigned(filteredPage)
            .then(response => {

                setFilteredDataAssigned(response.data);
                if (filterSideMenu == 3) {
                    setTransmittalLettersFiltered(response.data)
                }
            })
    }, [filteredPage])

    console.log(transmittalLettersFiltered);

    const { register, handleSubmit, control, reset, formState: { errors } } = useForm({ mode: 'onBlur' })

    const onSubmit = (filteredData) => {

        let arrFilterItems = Object.keys(filteredData).map((item, index) => {
            if (Object.values(filteredData)[index] !== '' && Object.values(filteredData)[index] !== undefined && Object.values(filteredData)[index] !== "Invalid date") {
                return `filter[${item}]=${Object.values(filteredData)[index]}`
            }
        })
            .filter(item => item !== undefined)
        console.log(arrFilterItems)
        let newArr = arrFilterItems.map((item, index) => index == arrFilterItems.length - 1 ? `${item}` : `${item}&`)
        console.log(newArr.toString().replace(/[,]/gi, ''))
        //---------------------------------------------

        // UserService.getFilteredData(newArr.toString().replace(/[,]/gi, ''))
        setFilteredData(newArr.toString().replace(/[,]/gi, ''))
        setFilteredPage(1)
        // reset()
    }


    return (
        <div className={'transmittal-letters projects_page'}>
            <div className="header_wrapper_name">
                <h1>Сопроводительные записки</h1>
            </div>
            <main className="main__page">
                <div className="main__page--left">
                    <p className={filterSideMenu == 0 ? "main__page--active" : null} onClick={() => { setFilterSideMenu(0); setFilteredPage(1); setTransmittalLettersFiltered(filteredDataAll) }}>{IconsContent.Folder()}{t("all_letters")}<span>{filteredDataAll?.meta?.total}</span></p>
                    <p className={filterSideMenu == 1 ? "main__page--active" : null} onClick={() => { setFilterSideMenu(1); setFilteredPage(1); setTransmittalLettersFiltered(filteredDataUnfilled) }}>{IconsContent.FolderAccepted()}{t("filled_letters")}<span>{filteredDataUnfilled?.meta?.total}</span></p>
                    <p className={filterSideMenu == 2 ? "main__page--active" : null} onClick={() => { setFilterSideMenu(2); setFilteredPage(1); setTransmittalLettersFiltered(filteredDataFilled) }}>{IconsContent.FolderPending()}{t("unfilled_letters")}<span>{filteredDataFilled?.meta?.total}</span></p>
                    <p className={filterSideMenu == 3 ? "main__page--active" : null} onClick={() => { setFilterSideMenu(3); setFilteredPage(1); setTransmittalLettersFiltered(filteredDataAssigned) }}>{IconsContent.FolderCompleted()}{t("assigned_letters")}<span>{filteredDataAssigned?.meta?.total}</span></p>
                </div>
                <div className="main__page--right">
                    <div className="main_header">
                        <button className={'btn_filter'} onClick={() => { setIsFilter(true) }}>
                            <span className="name">Фильтр</span>
                            {IconsContent.Filter()}
                        </button>
                        <div className={"btn_search"}>
                            {IconsContent.Search()}
                            <input type="text" placeholder={t("search")}  {...register("name", { onChange: () => handleSubmit(onSubmit)() })} />
                        </div>
                        {/* <div className="btn_export">Экспорт в Excel</div> */}
                    </div>
                    <div className="table_wrapper page_coordinator">
                        <table className={'table_styling transmittal-letters__table'}>
                            <thead>
                                <tr className={'table_heading'}>
                                    <TableFirst />
                                    <td>{t("project_id")}</td>
                                    <td>{t("project_name")}</td>
                                    <td></td>
                                    <td className={'last'}></td>

                                </tr>
                            </thead>
                            <tbody>
                                {transmittalLettersFiltered && transmittalLettersFiltered.data.map((item, index) => {

                                    return (
                                        <tr className={'table_body'} key={index}>
                                            <td>{item.id}</td>
                                            <td>{item.project.id} </td>
                                            <td>{item.project[nameLocales]} </td>
                                            <td >
                                                <p className='table_actions'>
                                                    {item.document_type_and_developer == null

                                                        ?

                                                        userLocation !== "/administrator" ?
                                                            <span className='table_active table_nowrap'>
                                                                <Link to={`/coordinator/transmittalletters/form/${item.id}`}>Не заполнено</Link>
                                                            </span> :
                                                            <span className='table_active table_nowrap'>Не заполнено</span>

                                                        :

                                                        item.signed == false ? userLocation == "/administrator" ? <span className='table_active table_nowrap' style={{ backgroundColor: "var(--color-violet)" }} onClick={() => { setIsShowModalConclusionSentForECP(true); setTransmittalLetterId(item.id) }}>Подтвердить личность</span> :
                                                            <span className='table_active table_nowrap' style={{ backgroundColor: "var(--color-violet)" }} >Заполнено</span> :
                                                            <span className='table_active table_nowrap' style={{ backgroundColor: "var(--color-aquamarine)" }} >Подписано</span>}
                                                </p>
                                            </td>
                                            <td>
                                                {item.document_type_and_developer !== null ? <a target="_blank" href={item.src}>{IconsContent.DownLoad()}</a> : null}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <div className="table_wrapper_footer">
                            <div className="footer_number">
                                {transmittalLettersFiltered && <p>Найдено {transmittalLettersFiltered.meta.total} из {transmittalLetters.meta?.total} </p>}
                            </div>
                            <div className="footer_pagination">
                                {transmittalLettersFiltered && transmittalLettersFiltered.meta.total > transmittalLetters.meta.per_page ?
                                    <Pagination count={Math.ceil(transmittalLettersFiltered.meta.total / transmittalLettersFiltered.meta?.per_page)} page={filteredPage} onChange={(event, value) => setFilteredPage(value)} variant="outlined" shape="rounded" /> : null}
                                {/* <button className={'btn_pagination '}>1</button>
                                <button className={'btn_pagination'}>2</button> */}
                            </div>
                        </div>
                    </div>
                </div>


            </main>

            <div className={`modal_filter ${isFilter ? 'active' : ''}`}>
                <div className={`modal_content`}>
                    <div className="modal_header">
                        <div className="header_word">
                            <h5>Фильтр</h5>
                            {IconsContent.Filter('#4ABD9B', `2`)}
                        </div>
                        <button onClick={() => { setIsFilter(false) }}>{IconsContent.Cancel()}</button>
                    </div>
                    <div className="modal_form">

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="input_wrapper">
                                <label htmlFor="">ID</label>
                                <input type="number" {...register(`id`)} />
                            </div>

                            <div className="input_wrapper">
                                <label htmlFor="">Отрасль законодательства</label>
                                <InputSelect control={control} name="branch_of_legislation_id" noRequired onClear={onClear} placeholder="Выберите" options={branchOfLegislation} />

                            </div>
                            <div className="input_wrapper">
                                <label htmlFor="">Тип документа</label>
                                <InputSelect control={control} name="document_type_id" noRequired onClear={onClear} placeholder="Выберите" options={documentTypes} />
                            </div>
                            <div className="filter__ender">
                                <button type="submit" className={'submit'}>Применить</button>
                                <button type="button" onClick={(e) => { setOnClear(true); reset(); e.stopPropagation() }} className={'submit reset '}>Сбросить</button>
                            </div>

                        </form>
                    </div>
                </div>
                <div className="modal_cover" onClick={() => { setIsFilter(false) }} />
            </div>

            <ModalAdministrator
                isShowModalConclusionSentForECP={isShowModalConclusionSentForECP}
                setIsShowModalConclusionSentForECP={setIsShowModalConclusionSentForECP}
                transmittalLetterId={transmittalLetterId}
            />


        </div>
    )
}