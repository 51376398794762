import NavigationContent from "../NavigationContent/NavigationContent";
import IconsContent from "../../Assets/img/IconsContent";
import SlideToggle from "react-slide-toggle";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { UserService } from "../../../Main/Services/UserService";
import AnimateHeight from 'react-animate-height';
import EyeView from "../../Assets/icons/EyeView";
import EyeHide from "../../Assets/icons/EyeHide";
import { Link } from "react-router-dom";
import Preloader from "../Preloader/Preloader";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export default function FormViewDetailContainer({ conclusion }) {
    const { t, i18n } = useTranslation("common");
    const userLocation = localStorage.getItem('.UserLocation')
    const navigate = useNavigate()
    const { register, handleSubmit, control, reset, formState: { errors } } = useForm({ mode: "onBlur" });
    const [fileName, setFileName] = useState('')
    const [fileName2, setFileName2] = useState();
    const [fileName3, setFileName3] = useState('')
    const [error, setError] = useState()
    const [newPlayAnimation, setNewPlayAnimation] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [isShowModalConclusionSentForECP, setIsShowModalConclusionSentForECP] = useState(false)
    const [isShowModalConclusionEdited, setIsShowModalConclusionEdited] = useState(false)
    const [isShowModalConclusionSentToCorrector, setIsShowModalConclusionSentToCorrector] = useState(false)
    const [isShowModalConclusionSentToRework, setIsShowModalConclusionSentToRework] = useState(false)
    const [isShowModalConclusionSentToExpertECP, setIsShowModalConclusionSentToExpertECP] = useState(false)
    const [isShowModalConclusionEdit, setIsShowModalConclusionEdit] = useState(false)
    const [isShowModalConclusionCancel, setIsShowModalConclusionCancel] = useState(false)
    const [isShowModalDocCreated, setIsShowModalDocCreated] = useState(false)
    const [isShowModalError, setIsShowModalError] = useState(false);
    const [height, setHeight] = useState(0);
    const [height2, setHeight2] = useState(0);
    const [height3, setHeight3] = useState(0);
    const [height4, setHeight4] = useState(0);
    const conclusionFormLang = [1, 2]
    const [conclusionLang, setConclusionLang] = useState(1)

    let roleData = {}
    if (userLocation == '/corrector') {
        roleData = { role: "corrector", fileName: "корректировки", statusChangeTo: 12 }
    } else if (userLocation == '/translator') {
        roleData = { role: "translater", fileName: "перевода", statusChangeTo: 13 }
    }
    const actionFormLang = [`Файл ${roleData.fileName} на казахском`, `Файл ${roleData.fileName} на русском`]
    const [actionLang, setActionLang] = useState(`Файл ${roleData.fileName} на казахском`)

    const onSubmit = (newProjectData) => {

        setNewPlayAnimation(true)
        if (userLocation == '/corrector' || userLocation == '/translator') {
            const formData = new FormData()
            actionLang == `Файл ${roleData.fileName} на русском` ?
                formData.append(`${roleData.role}_document_src_ru`, newProjectData.role_document_src_ru[0]) :
                formData.append(`${roleData.role}_document_src_kk`, newProjectData.role_document_src_kk[0])

            formData.append("_method", "put");

            UserService.uploadConclusion(conclusion.id, formData)
                .then(response => {
                    UserService.putConclusion(conclusion.id, { status_id: roleData.statusChangeTo })
                        .then(response => {
                            setNewPlayAnimation(false);

                            setIsShowModalConclusionEdited(true)
                        }).catch(err => { setNewPlayAnimation(false); setIsShowModalError(true) })
                }).catch(err => { setNewPlayAnimation(false); setIsShowModalError(true) });
        } else if (userLocation == '/coordinator') {
            UserService.putConclusion(conclusion.id, { ...newProjectData, status_id: 10, })
                .then(response => {

                    setNewPlayAnimation(false)
                    setIsShowModalConclusionSentToRework(false)
                    setIsShowModalConclusionEdited(true)
                }).catch(err => { setNewPlayAnimation(false); setIsShowModalError(true) })
        } else if (userLocation == '/expert') {
            var formdata = new FormData();
            formdata.append("_method", "put");
            formdata.append("eds[password]", newProjectData.password);
            formdata.append("eds[file]", newProjectData.file3[0])
            formdata.append("status_id", 9)
            UserService.uploadECP(conclusion.id, formdata)
                .then((response) => {
                    setNewPlayAnimation(false)
                    setIsShowModalConclusionSentForECP(false)
                    setIsShowModalDocCreated(true)
                })
                .catch(err => {
                    setNewPlayAnimation(false); setError("Не верный пароль"); console.log(err)
                })
        }
    }

    return (
        <div className={'projects_page addProject_page   conclusionCoordinatorDetails__page'}>
            {newPlayAnimation && <Preloader playAnimation={false} />}
            <NavigationContent direct={[{ goTo: `${userLocation}`, name: `Главная` }, {
                goTo: `${userLocation}/conclusions`,
                name: `Заключения`
            }, { goTo: `${userLocation}/conclusions/details/1`, name: `Форма просмотра` }]} />
            <main style={{ width: `100%` }} >


                <div className="main_header" style={{ justifyContent: "space-between" }}>
                    <h2>{t("conclusion_view_form")}</h2>
                    {userLocation == '/expert' && conclusion.status.id == 1 ?
                        <div className='header__content' >
                            <button className='header__content-correct' onClick={() => setIsShowModalConclusionEdit(true)} >Принять в работу</button>
                            <button className='header__content-upgrade' onClick={() => navigate(`/expert/projects/${conclusion.project.id}`)}>Просмотреть проект</button>
                            <button className='header__content-accept' onClick={() => setIsShowModalConclusionCancel(true)}>Отклонить</button>
                        </div> : null}
                    {userLocation == '/expert' && conclusion.status.id == 14 ?
                        <div className='header__content'>
                            <button style={{ marginRight: '100px', backgroundColor: 'var(--color-red)' }} className='header__content-correct' onClick={() => setIsShowModalConclusionSentForECP(true)} >Подписать заключение ЭЦП</button>
                        </div> 
                        : null}
                    {conclusion.status.id == 9 ? <span className='conclusion-finish'>{IconsContent.DocCreated()} Заключение подписано экпертом</span> : null}
                    {userLocation == '/coordinator' && conclusion.status.id == 4 ?
                        <p>
                            <button className='conclusion-rework' onClick={() => setIsShowModalConclusionSentToRework(true)}>Отправить на доработку{IconsContent.Recycle()}</button>
                            <button className='conclusion-correct' onClick={() => setIsShowModalConclusionSentToCorrector(true)}>Отправить корректору{IconsContent.User()}</button>
                        </p> : null}
                    {userLocation == '/expert' && conclusion.status.id == 2 || userLocation == '/expert' && conclusion.status.id == 10 || userLocation == '/expert' && conclusion.status.id == 12 || userLocation == '/expert' && conclusion.status.id == 13 ?
                        <div className='header__content' style={{ marginBottom: '0', alignSelf: 'right' }} >
                            <button className=' header__content-correct' onClick={() => navigate(`/expert/conclusions/edit/${conclusion.id}`)} >{t("back_to_conclusion_edit")}</button>
                        </div> : null}
                    {userLocation == '/coordinator' && conclusion.status.id == 8 ?
                        <div className='header__content'>
                            <button className='header__content-approve' onClick={() => setIsShowModalConclusionSentToExpertECP(true)}>Одобрить заключение {IconsContent.Checked()}</button>
                            <button className='header__content-upgrade' onClick={() => setIsShowModalConclusionSentToRework(true)}>Отправить на доработку{IconsContent.Recycle()}</button>
                        </div> : null}
                </div>

                {
                    userLocation == '/translator' && conclusion.status.id == 7 || userLocation == '/corrector' && conclusion.status.id == 11 ?
                        <div className="users__role users__role-correction">
                            {actionFormLang.map((item, index) => <span key={index} className={actionLang == item ? 'users__role-active' : ''} onClick={() => setActionLang(item)}>{item}</span>)}
                        </div> : null
                }

                {
                    userLocation == '/corrector' && conclusion.status.id == 11 || userLocation == '/translator' && conclusion.status.id == 7 ?
                        <form className="form_catcher form_catcher-corrector" onSubmit={handleSubmit(onSubmit)}>

                            {actionLang == `Файл ${roleData.fileName} на русском` ?
                                <div className="input_wrapper">
                                    <label className={"label"}> Файл {roleData.fileName} (на русском)</label>
                                    <div className="inner_wrapper">
                                        <input
                                            type="file"
                                            id={"file"}
                                            accept={".doc, .docx"}
                                            {...register(`role_document_src_ru`, {
                                                onChange: (e) =>
                                                    setFileName(e.target.value.replace(`C:\\fakepath\\`, "")),
                                            })}
                                        />
                                        <label htmlFor={"file"} className={"file_contain"}>
                                            <div className={"file_put"}>
                                                <span>Выберите файл</span>
                                                {IconsContent.PaperClip()}
                                            </div>
                                            <span className={"span"}>{fileName ? fileName : "Размер файла не должен превышать 20 МБ"}</span>
                                        </label>
                                    </div>
                                </div> : null}
                            {actionLang == `Файл ${roleData.fileName} на казахском` ? <div className="input_wrapper">
                                <label className={"label"}> Файл { } (на казахском)</label>
                                <div className="inner_wrapper">
                                    <input
                                        type="file"
                                        id={"file2"}
                                        accept={".doc, .docx"}
                                        {...register(`role_document_src_kk`, {
                                            onChange: (e) =>
                                                setFileName2(e.target.value.replace(`C:\\fakepath\\`, "")),
                                        })}
                                    />
                                    <label htmlFor={"file2"} className={"file_contain"}>
                                        <div className={"file_put"}>
                                            <span>Выберите файл</span>
                                            {IconsContent.PaperClip()}
                                        </div>
                                        <span className={"span"}>
                                            {fileName2
                                                ? fileName2
                                                : "Размер файла не должен превышать 20 МБ"}
                                        </span>
                                    </label>
                                </div>
                            </div> : null}
                            <button >Загрузить файл(ы) {roleData.fileName} {IconsContent.File()}</button>
                        </form>
                        : null
                }

                <div className="view_form__content">
                    <div className="content_left ">

                        <div className="transmittal-letters__form ">
                            <form className={'form_catcher-expert'} >
                                <div className="users__role users__role-conclusion">
                                    {conclusionFormLang.map((item, index) => <span key={index} className={conclusionLang == item ? 'users__role-active' : ''} onClick={() => setConclusionLang(item)}>{item == 1 ? t("conclusion_form_kk") : t("conclusion_form_ru")}</span>)}
                                </div>

                                <button type="button" aria-expanded={height !== 0} aria-controls="example-panel" onClick={() => setHeight(height === 0 ? 'auto' : 0)}>
                                    <span> {t("conclusion_form_first_part")}</span>
                                    {height === 0 ? IconsContent.ArrDown() : IconsContent.ArrUp()}
                                </button>
                                <AnimateHeight id="example-panel" duration={500} height={height} style={{ padding: "15px" }}>
                                    {conclusionLang == 1 ? <div className="input_wrapper">
                                        <label htmlFor="">{t("availability_of_competence_of_the_developers_authority_kk")}</label>
                                        <div className="textarea" dangerouslySetInnerHTML={{ __html: conclusion.locales.availability_of_competence_of_the_developers_authority.kk }} />
                                    </div> : null}
                                    {conclusionLang == 2 ? <div className="input_wrapper">
                                        <label htmlFor="">{t("availability_of_competence_of_the_developers_authority_ru")} </label>
                                        <div className="textarea" dangerouslySetInnerHTML={{ __html: conclusion.locales.availability_of_competence_of_the_developers_authority.ru }} />
                                        {/* <textarea disabled type="text" defaultValue={conclusion.locales.availability_of_competence_of_the_developers_authority.ru}  /> */}
                                    </div> : null}
                                    {conclusionLang == 1 ? <div className="input_wrapper">
                                        <label htmlFor="">{t("compliance_with_the_requirements_kk")}</label>
                                        <div className="textarea" dangerouslySetInnerHTML={{ __html: conclusion.locales.compliance_with_the_requirements.kk }} />
                                        {/* <textarea disabled type="text" defaultValue={conclusion.locales.compliance_with_the_requirements.kk}  /> */}
                                    </div> : null}
                                    {conclusionLang == 2 ? <div className="input_wrapper">
                                        <label htmlFor="">{t("compliance_with_the_requirements_ru")}</label>
                                        <div className="textarea" dangerouslySetInnerHTML={{ __html: conclusion.locales.compliance_with_the_requirements.ru }} />
                                        {/* <textarea disabled type="text" defaultValue={conclusion.locales.compliance_with_the_requirements.ru}  /> */}
                                    </div> : null}
                                    {conclusionLang == 1 ? <div className="input_wrapper">
                                        <label htmlFor="">{t("the_stated_and_real_purpose_of_the_project_kk")}</label>
                                        <div className="textarea" dangerouslySetInnerHTML={{ __html: conclusion.locales.the_stated_and_real_purpose_of_the_project.kk }} />
                                        {/* <textarea disabled type="text" defaultValue={conclusion.locales.the_stated_and_real_purpose_of_the_project.kk}  /> */}
                                    </div> : null}
                                    {conclusionLang == 2 ? <div className="input_wrapper">
                                        <label htmlFor="">{t("the_stated_and_real_purpose_of_the_project_ru")}</label>
                                        <div className="textarea" dangerouslySetInnerHTML={{ __html: conclusion.locales.the_stated_and_real_purpose_of_the_project.ru }} />
                                        {/* <textarea disabled type="text" defaultValue={conclusion.locales.the_stated_and_real_purpose_of_the_project.ru}  /> */}
                                    </div> : null}
                                    {conclusionLang == 1 ? <div className="input_wrapper">
                                        <label htmlFor="">{t("public_interest_and_private_interests_kk")}</label>
                                        <div className="textarea" dangerouslySetInnerHTML={{ __html: conclusion.locales.public_interest_and_private_interests.kk }} />
                                        {/* <textarea disabled type="text" defaultValue={conclusion.locales.public_interest_and_private_interests.kk}  /> */}
                                    </div> : null}
                                    {conclusionLang == 2 ? <div className="input_wrapper">
                                        <label htmlFor="">{t("public_interest_and_private_interests_ru")}</label>
                                        <div className="textarea" dangerouslySetInnerHTML={{ __html: conclusion.locales.public_interest_and_private_interests.ru }} />
                                        {/* <textarea disabled type="text" defaultValue={conclusion.locales.public_interest_and_private_interests.ru}  /> */}
                                    </div> : null}
                                    {conclusionLang == 1 ? <div className="input_wrapper">
                                        <label htmlFor="">{"sufficiency_of_argumentation_kk"}</label>
                                        <div className="textarea" dangerouslySetInnerHTML={{ __html: conclusion.locales.sufficiency_of_argumentation.kk }} />
                                        {/* <textarea disabled type="text" defaultValue={conclusion.locales.sufficiency_of_argumentation.kk}  /> */}
                                    </div> : null}
                                    {conclusionLang == 2 ? <div className="input_wrapper">
                                        <label htmlFor="">{"sufficiency_of_argumentation_ru"}</label>
                                        <div className="textarea" dangerouslySetInnerHTML={{ __html: conclusion.locales.sufficiency_of_argumentation.ru }} />
                                        {/* <textarea disabled type="text" defaultValue={conclusion.locales.sufficiency_of_argumentation.ru}  /> */}
                                    </div> : null}
                                    {conclusionLang == 1 ? <div className="input_wrapper">
                                        <label htmlFor="">{t("financial_and_economic_justification_kk")}</label>
                                        <div className="textarea" dangerouslySetInnerHTML={{ __html: conclusion.locales.financial_and_economic_justification.kk }} />
                                        {/* <textarea disabled type="text" defaultValue={conclusion.locales.financial_and_economic_justification.kk}  /> */}
                                    </div> : null}
                                    {conclusionLang == 2 ? <div className="input_wrapper">
                                        <label htmlFor="">{t("financial_and_economic_justification_ru")}</label>
                                        <div className="textarea" dangerouslySetInnerHTML={{ __html: conclusion.locales.financial_and_economic_justification.ru }} />
                                        {/* <textarea disabled type="text" defaultValue={conclusion.locales.financial_and_economic_justification.ru}  /> */}
                                    </div> : null}
                                    {conclusionLang == 1 ? <div className="input_wrapper">
                                        <label htmlFor="">{t("conducting_regulatory_impact_analysis_kk")}</label>
                                        <div className="textarea" dangerouslySetInnerHTML={{ __html: conclusion.locales.conducting_regulatory_impact_analysis.kk }} />
                                        {/* <textarea disabled type="text" defaultValue={conclusion.locales.conducting_regulatory_impact_analysis.kk}  /> */}
                                    </div> : null}
                                    {conclusionLang == 2 ? <div className="input_wrapper">
                                        <label htmlFor="">{t("conducting_regulatory_impact_analysis_ru")}</label>
                                        <div className="textarea" dangerouslySetInnerHTML={{ __html: conclusion.locales.conducting_regulatory_impact_analysis.ru }} />
                                        {/* <textarea disabled type="text" defaultValue={conclusion.locales.conducting_regulatory_impact_analysis.ru}  /> */}
                                    </div> : null}
                                </AnimateHeight>

                                <button type="button" aria-expanded={height2 !== 0} aria-controls="example-panel-2" onClick={() => setHeight2(height2 === 0 ? 'auto' : 0)}>
                                    <span>{t("conclusion_form_scond_part")}</span>
                                    {height2 === 0 ? IconsContent.ArrDown() : IconsContent.ArrUp()}
                                </button>
                                <AnimateHeight id="example-panel-2" duration={500} height={height2} style={{ padding: "15px" }}>
                                    {conclusionLang == 1 ? <div className="input_wrapper">
                                        <label htmlFor="">{t("project_language_kk")} </label>
                                        <div className="textarea" dangerouslySetInnerHTML={{ __html: conclusion.locales.the_language_of_the_npa_project.kk }} />
                                        {/* <textarea disabled type="text" defaultValue={conclusion.locales.the_language_of_the_npa_project.kk}  /> */}
                                    </div> : null}
                                    {conclusionLang == 2 ? <div className="input_wrapper">
                                        <label htmlFor="">{t("project_language_ru")}</label>
                                        <div className="textarea" dangerouslySetInnerHTML={{ __html: conclusion.locales.the_language_of_the_npa_project.ru }} />
                                        {/* <textarea disabled type="text" defaultValue={conclusion.locales.the_language_of_the_npa_project.ru}  /> */}
                                    </div> : null}
                                    {conclusionLang == 1 ? <div className="input_wrapper">
                                        <label htmlFor="">{t("legislative_consistency_of_the_project_kk")}</label>
                                        <div className="textarea" dangerouslySetInnerHTML={{ __html: conclusion.locales.legislative_consistency_of_the_project.kk }} />
                                        {/* <textarea disabled type="text" defaultValue={conclusion.locales.legislative_consistency_of_the_project.kk} /> */}
                                    </div> : null}
                                    {conclusionLang == 2 ? <div className="input_wrapper">
                                        <label htmlFor="">{t("legislative_consistency_of_the_project_ru")}</label>
                                        <div className="textarea" dangerouslySetInnerHTML={{ __html: conclusion.locales.legislative_consistency_of_the_project.ru }} />
                                        {/* <textarea disabled type="text" defaultValue={conclusion.locales.legislative_consistency_of_the_project.ru}  /> */}
                                    </div> : null}
                                    {conclusionLang == 1 ? <div className="input_wrapper">
                                        <label htmlFor="">{t("competence_kk")} </label>
                                        <div className="textarea" dangerouslySetInnerHTML={{ __html: conclusion.locales.competence.kk }} />
                                        {/* <textarea disabled type="text" defaultValue={conclusion.locales.competence.kk}  /> */}
                                    </div> : null}
                                    {conclusionLang == 2 ? <div className="input_wrapper">
                                        <label htmlFor="">{t("competence_ru")} </label>
                                        <div className="textarea" dangerouslySetInnerHTML={{ __html: conclusion.locales.competence.ru }} />
                                        {/* <textarea disabled type="text" defaultValue={conclusion.locales.competence.ru}  /> */}
                                    </div> : null}
                                </AnimateHeight>

                                {conclusion.detailed_analysis_of_risk_factors.map((item, index) =>
                                    <>
                                        <button type="button" aria-expanded={height3 !== 0} aria-controls="example-panel-3" onClick={() => setHeight3(height3 === 0 ? 'auto' : 0)}>
                                            <span>{t("detailed_analysis")} {index + 1}</span>
                                            {height3 === 0 ? IconsContent.ArrDown() : IconsContent.ArrUp()}
                                        </button>
                                        <AnimateHeight id="example-panel-3" duration={500} height={height3} style={{ padding: "15px" }}>
                                            {conclusionLang == 1 ? <div className="input_wrapper">
                                                <label htmlFor="">{t("article_kk")}</label>
                                                <div className="textarea" dangerouslySetInnerHTML={{ __html: item.locales.article.kk }} />
                                            </div> : null}
                                            {conclusionLang == 2 ? <div className="input_wrapper">
                                                <label htmlFor="">{t("article_ru")}</label>
                                                <div className="textarea" dangerouslySetInnerHTML={{ __html: item.locales.article.ru }} />
                                            </div> : null}
                                            {conclusionLang == 1 ? <div className="input_wrapper">
                                                <label htmlFor="">{t("part_kk")}</label>
                                                <div className="textarea" dangerouslySetInnerHTML={{ __html: item.locales.part.kk }} />
                                            </div> : null}
                                            {conclusionLang == 2 ? <div className="input_wrapper">
                                                <label htmlFor="">{t("part_ru")}</label>
                                                <div className="textarea" dangerouslySetInnerHTML={{ __html: item.locales.part.ru }} />
                                            </div> : null}
                                            {conclusionLang == 1 ? <div className="input_wrapper">
                                                <label htmlFor="">{t("point_kk")}</label>
                                                <div className="textarea" dangerouslySetInnerHTML={{ __html: item.locales.point.kk }} />
                                            </div> : null}
                                            {conclusionLang == 2 ? <div className="input_wrapper">
                                                <label htmlFor="">{t("point_ru")}</label>
                                                <div className="textarea" dangerouslySetInnerHTML={{ __html: item.locales.point.ru }} />
                                            </div> : null}
                                            {conclusionLang == 1 ? <div className="input_wrapper">
                                                <label htmlFor="">{t("sub_paragraph_kk")}</label>
                                                <div className="textarea" dangerouslySetInnerHTML={{ __html: item.locales.sub_paragraph.kk }} />
                                            </div> : null}
                                            {conclusionLang == 2 ? <div className="input_wrapper">
                                                <label htmlFor="">{t("sub_paragraph_ru")}</label>
                                                <div className="textarea" dangerouslySetInnerHTML={{ __html: item.locales.sub_paragraph.ru }} />
                                            </div> : null}
                                            {conclusionLang == 1 ? <div className="input_wrapper">
                                                <label htmlFor="">{t("the_text_of_the_problematic_norm_kk")} </label>
                                                <div className="textarea" dangerouslySetInnerHTML={{ __html: item.locales.the_text_of_the_problematic_norm.kk }} />
                                            </div> : null}
                                            {conclusionLang == 2 ? <div className="input_wrapper">
                                                <label htmlFor="">{t("the_text_of_the_problematic_norm_ru")}</label>
                                                <div className="textarea" dangerouslySetInnerHTML={{ __html: item.locales.the_text_of_the_problematic_norm.ru }} />
                                            </div> : null}
                                            {conclusionLang == 1 ? <div className="input_wrapper">
                                                <label htmlFor="">{t("remark_kk")} </label>
                                                <div className="textarea" dangerouslySetInnerHTML={{ __html: item.locales.remark.kk }} />
                                            </div> : null}
                                            {conclusionLang == 2 ? <div className="input_wrapper">
                                                <label htmlFor="">{t("remark_ru")}</label>
                                                <div className="textarea" dangerouslySetInnerHTML={{ __html: item.locales.remark.ru }} />
                                            </div> : null}
                                            {conclusionLang == 1 ? <div className="input_wrapper">
                                                <label htmlFor="">{t("recommendations_kk")}</label>
                                                <div className="textarea" dangerouslySetInnerHTML={{ __html: item.locales.recommendations.kk }} />
                                            </div> : null}
                                            {conclusionLang == 2 ? <div className="input_wrapper">
                                                <label htmlFor="">{t("recommendations_ru")} </label>
                                                <div className="textarea" dangerouslySetInnerHTML={{ __html: item.locales.recommendations.ru }} />
                                            </div> : null}
                                            {/* {conclusionLang == 1 ?  */}
                                            <div className="input_wrapper">
                                                <label htmlFor="">{t("risk_factor")}</label>
                                                <div className="textarea" dangerouslySetInnerHTML={{ __html: item.locales.risk_factor_name[i18n.language] }} />
                                            </div>
                                            {/* {conclusionLang == 2 ? <div className="input_wrapper">
                                            <label htmlFor="">Фактор риска (на русском) </label>
                                            <div className="textarea" dangerouslySetInnerHTML={{ __html: item.locales.risk_factor[i18n.language]}} />
                                        </div> : null} */}
                                            <div className="input_wrapper">
                                                <label htmlFor="">{t("corruption_risks")}</label>
                                                <div className="textarea" >
                                                    {item.locales.corruption_risks.map(val => {
                                                        return <p>{val[0][i18n.language]}</p>
                                                    })}
                                                </div>
                                            </div>
                                            {/* {conclusionLang == 2 ? <div className="input_wrapper">
                                            <label htmlFor="">Риски коррупции (на русском) </label>
                                            <div className="textarea" dangerouslySetInnerHTML={{ __html: item.locales.corruption_risks }} />
                                        </div> : null} */}
                                        </AnimateHeight>
                                    </>)}

                                <button type="button" aria-expanded={height4 !== 0} aria-controls="example-panel-4" onClick={() => setHeight4(height4 === 0 ? 'auto' : 0)}>
                                    <span>Вывод заключения</span>
                                    {height4 === 0 ? IconsContent.ArrDown() : IconsContent.ArrUp()}
                                </button>
                                <AnimateHeight id="example-panel-4" duration={500} height={height4} style={{ padding: "15px" }}>
                                    {conclusionLang == 1 ? <div className="input_wrapper">
                                        <label htmlFor="">{t("output_text_kk")}</label>
                                        <div className="textarea" dangerouslySetInnerHTML={{ __html: conclusion.locales.output_text.kk }} />
                                        {/* <textarea disabled type="text" defaultValue={conclusion.locales.output_text.kk}  /> */}
                                    </div> : null}
                                    {conclusionLang == 2 ? <div className="input_wrapper">
                                        <label htmlFor="">{t("output_text_ru")}</label>
                                        <div className="textarea" dangerouslySetInnerHTML={{ __html: conclusion.locales.output_text.ru }} />
                                        {/* <textarea disabled type="text" defaultValue={conclusion.locales.output_text.ru}  /> */}
                                    </div> : null}

                                </AnimateHeight>
                            </form>
                        </div>
                        <div className="other_inforamtions">
                            <div className="get_inform">
                                <div className="inform_name">
                                    <h5>{t("incoming_letter_number")}</h5>
                                </div>
                                <div className="inform_value">
                                    <p>{conclusion.project.incoming_letter_number}</p>
                                </div>
                            </div>

                            <div className="get_inform">
                                <div className="inform_name">
                                    <h5>{t("outgoing_letter_number")}</h5>
                                </div>
                                <div className="inform_value">
                                    <p>{conclusion.project.outgoing_letter_number}</p>
                                </div>
                            </div>

                            <div className="get_inform">
                                <div className="inform_name">
                                    <h5>{t("outgoing_mail_date")}</h5>
                                </div>
                                <div className="inform_value">
                                    <p>{conclusion?.project?.outgoing_mail_date}</p>
                                </div>
                            </div>

                            <div className="get_inform">
                                <div className="inform_name">
                                    <h5>{t("date_of_receipt_of_the_draft_regulatory_legal_act")}</h5>
                                </div>
                                <div className="inform_value">
                                    <p>{conclusion?.project?.date_of_receipt_of_the_draft_regulatory_legal_act}</p>
                                </div>
                            </div>

                            <div className="get_inform">
                                <div className="inform_name">
                                    <h5>{t("document_type")}</h5>
                                </div>
                                <div className="inform_value">
                                    <p>{conclusion?.project?.document_type !== null ? conclusion?.project?.document_type.locales.name[i18next.language] : 'Нет данных'}</p>
                                </div>
                            </div>

                            <div className="get_inform">
                                <div className="inform_name">
                                    <h5>{t("name")}</h5>
                                </div>
                                <div className="inform_value">
                                    <p>{conclusion?.project?.locales.name[i18n.language]}</p>
                                </div>
                            </div>

                            <div className="get_inform">
                                <div className="inform_name">
                                    <h5>{t("branch_of_legiclation")}</h5>
                                </div>
                                <div className="inform_value">
                                    {conclusion?.project?.branch_of_legislation.map((item, index) =>
                                        <p key={index}>{item?.locales.name[i18n.language]}</p>)}
                                </div>
                            </div>

                            <div className="get_inform">
                                <div className="inform_name">
                                    <h5>{t("purpose_of_act")}</h5>
                                </div>
                                <div className="inform_value">
                                    <p>{conclusion?.project?.locales.purpose_and_structure_of_the_draft_regulatory_legal_act[i18n.language]}</p>
                                </div>
                            </div>
                            <div className="get_inform">
                                <div className="inform_name">
                                    <h5>{t("authority_developer")}</h5>
                                </div>
                                <div className="inform_value">
                                    <p>{conclusion?.project?.authority_developer.locales.name[i18n.language]}</p>
                                </div>
                            </div>

                            <div className="get_inform">
                                <div className="inform_name">
                                    <h5>{t("documents_ru")}</h5>
                                </div>
                                <div className="inform_value">
                                    <p>{conclusion?.project?.locales.documents_submitted_for_scientific_anti_corruption_expertise[i18n.language]}</p>
                                </div>
                            </div>

                            <div className="get_inform">
                                <div className="inform_name">
                                    <h5>{t("documents_kk")}</h5>
                                </div>
                                <div className="inform_value">
                                    <p>{conclusion?.project?.locales.documents_submitted_for_scientific_anti_corruption_expertise[i18n.language]}</p>
                                </div>
                            </div>
                            <div className="get_inform">
                                <div className="inform_name">
                                    <h5>Email</h5>
                                </div>
                                <div className="inform_value">
                                    <p>{conclusion?.project?.email}</p>
                                </div>
                            </div>

                        </div>
                        {/* <div className="logs">
                            <div className="logs_header">
                                <p>Логи</p>
                            </div>

                            <SlideToggle>
                                {({ toggle, setCollapsibleElement }) => (
                                    <div className="my-collapsible">
                                        <button onClick={toggle}>
                                            <span>Молдабек Сырым Пернебекұлы (Изменено)</span>
                                            <span>2022-07-05 18:25:09 {IconsContent.ArrUp()}</span>
                                        </button>
                                        <div className={`hidden_information my-collapsible__content`} ref={setCollapsibleElement}>
                                            <p className="date">12:41:01</p>
                                            <div className="information_doc">
                                                <p className="header">Тип документа: </p>
                                                <p className="description">Нормативные правовые постановления Национального Банка Республики Казахстан и иных центральных государственных органов</p>
                                            </div>
                                            <br />
                                            <div className="information_doc" style={{ marginBottom: 28 }}>
                                                <p className="header">Тип документа: </p>
                                                <p className="description">Нормативные правовые постановления Национального Банка Республики Казахстан и иных центральных государственных органов</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </SlideToggle>
                        </div> */}
                    </div>
                    <div className="content_right">
                        {/* <div className="view_form__information" style={{ marginBottom: "0" }}>
                            <div className="information_file information_file-blue">
                                <a href={conclusion.locales.expert_document_src.kk}>{t("conclusion_files_kk")}{IconsContent.File()}</a>
                                <a href={conclusion?.project?.locales?.archive?.kk}>{t("project_files_kk")}{IconsContent.File()}</a>
                                <a href={conclusion.locales.expert_document_src.ru}>{t("conclusion_files_ru")}{IconsContent.File()}</a>
                                <a href={conclusion?.project?.locales?.archive?.ru}>{t("project_files_ru")}{IconsContent.File()}</a>
                            </div>
                        </div> */}
                        {/* {userLocation == '/expert' && conclusion.status.id == 2 || userLocation == '/expert' && conclusion.status.id == 10 || userLocation == '/expert' && conclusion.status.id == 12 || userLocation == '/expert' && conclusion.status.id == 13 ?
                            <div className='header__content' style={{ marginBottom: '0', alignSelf: 'right' }} >
                                <button className=' header__content-correct' onClick={() => navigate(`/expert/conclusions/edit/${conclusion.id}`)} >Перейти к редактированию заключения</button>
                            </div> : null} */}
                        <div className="inform">
                            <div className="inform_name">
                                <h5 style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>Экспертизы
                                    <button className="addExpert" style={{ backgroundColor: "var(--color-orange)", marginTop: "5px" }}>
                                        <Link to={`${userLocation}/projects/details/${conclusion?.project?.id}`}>{t("back_to_project")}</Link>
                                    </button>
                                </h5>
                            </div>
                            <div className=" conclusionCoordinatorDetails__value">
                                <h5>{t("expert")}: <span>{conclusion.expert?.full_name}</span></h5>
                                <div className='conclusionCoordinatorDetails__value-info '>
                                    <p>ID проекта: <span>{conclusion.project.id}</span></p>
                                    <p>{t("responsible_corrector")}: <span>{conclusion.project.corrector?.full_name}</span></p>
                                    <p>{t("responsible_translator")}: <span>{conclusion.project.translator?.full_name}</span></p>
                                    <p>{t("deadline")}: <span>{conclusion.deadline}</span></p>
                                    {conclusion.locales.expert_document_src.kk && <p>{t("conclusion_files_kk")}:  <a href={conclusion.locales.expert_document_src.kk}>Скачать {IconsContent.DownLoad()}</a></p>}
                                    {conclusion.locales.expert_document_src.ru && <p>{t("conclusion_files_ru")}: <a href={conclusion.locales.expert_document_src.ru}>Скачать {IconsContent.DownLoad()}</a></p>}
                                    {conclusion.locales.translater_document_src.kk && <p>{t("translator_files_ru")}:  <a href={conclusion.locales.translater_document_src.kk}>Скачать {IconsContent.DownLoad()}</a></p>}
                                    {conclusion.locales.translater_document_src.ru && <p>{t("translator_files_ru")}: <a href={conclusion.locales.translater_document_src.ru}>Скачать {IconsContent.DownLoad()}</a></p>}
                                    {conclusion.locales.corrector_document_src.kk && <p>{t("corrector_files_ru")}:  <a href={conclusion.locales.corrector_document_src.kk}>Скачать {IconsContent.DownLoad()}</a></p>}
                                    {conclusion.locales.corrector_document_src.ru && <p>{t("corrector_files_ru")}: <a href={conclusion.locales.corrector_document_src.ru}>Скачать {IconsContent.DownLoad()}</a></p>}
                                    <p>Статус: <span>{conclusion.status.name}</span></p>
                                </div>
                            </div>
                        </div>
                        <div className="inform">
                            <div className="inform_name">
                                <h5>{t("coordinator_comment")}</h5>
                            </div>
                            <div className="inform_value">
                                <p>{conclusion.note}</p>
                            </div>
                        </div>
                        <div className="inform">
                            <div className="inform_name">
                                <h5 style={{ color: "rgb(239, 98, 98)" }}>{t("coordinator_note")}</h5>
                            </div>
                            <div className="inform_value">
                                {conclusion.comments.length > 0 ? <p>{conclusion.comments[conclusion.comments.length - 1].text}</p> : "нет данных"}
                            </div>
                        </div>
                        <div className="inform">
                            <div className="inform_name">
                                <h5>{t("re-examination")}</h5>
                            </div>
                            <div className="inform_value">
                                <p>Экспертизы пока не назначены</p>
                            </div>
                        </div>

                        <div className="inform">
                            <div className="inform_name">
                                <h5>{t("note")}</h5>
                            </div>
                            <div className="inform_value">
                                <p>{conclusion?.project?.note ? conclusion?.project?.note : "Коментарии не указаны"}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={isShowModalConclusionEdited ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                    <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
                        <p>
                            <span onClick={() => setIsShowModalConclusionEdited(false)}>{IconsContent.Close()}</span>
                        </p>
                        <div>
                            <p>{IconsContent.DocCreated()}</p>
                            <p>Данные {roleData.fileName} отправлены </p>
                            <button onClick={() => navigate(`${userLocation}/conclusions`)}>Закрыть</button>
                        </div>
                    </div>
                </div>

                <div className={isShowModalConclusionSentToCorrector ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                    <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
                        <p>
                            <span onClick={() => setIsShowModalConclusionSentToCorrector(false)}>{IconsContent.Close()}</span>
                        </p>
                        <div>
                            <p>{IconsContent.DocCreated()}</p>
                            <p style={{ padding: "10px 30px", textAlign: "center" }}>Вы подтверждаете одобрение предварительного заключения и его отправку корректору?</p>
                            <p>
                                <button className='header__content-correct' onClick={() => {
                                    setNewPlayAnimation(true)
                                    UserService.putConclusion(conclusion.id, { status_id: 11 })
                                        .then(() => { setNewPlayAnimation(false); setIsShowModalConclusionSentToCorrector(false); setIsShowModalConclusionEdited(true) })
                                        .catch(() => { setNewPlayAnimation(false); setIsShowModalConclusionSentToCorrector(false); setIsShowModalError(true) })

                                }}>Принять</button>
                                <button style={{ marginLeft: "20px" }} onClick={() => setIsShowModalConclusionSentToCorrector(false)}>Закрыть</button>
                            </p>

                        </div>
                    </div>
                </div>

                <div className={isShowModalConclusionSentToExpertECP ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                    <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
                        <p>
                            <span onClick={() => setIsShowModalConclusionSentToExpertECP(false)}>{IconsContent.Close()}</span>
                        </p>
                        <div>
                            <p>{IconsContent.DocCreated()}</p>
                            <p style={{ padding: "10px 30px", textAlign: "center" }}>Вы подтверждаете одобрение  заключения и его отправку эксперту на подпись ЭЦП?</p>
                            <p>
                                <button className='header__content-correct' onClick={() => {
                                    UserService.putConclusion(conclusion.id, { status_id: 14 })
                                        .then(() => { setNewPlayAnimation(false); setIsShowModalConclusionSentToExpertECP(false); setIsShowModalConclusionEdited(true) })
                                        .catch(() => { setNewPlayAnimation(false); setIsShowModalConclusionSentToExpertECP(false); setIsShowModalError(true) })
                                }}>Принять</button>
                                <button style={{ marginLeft: "20px" }} onClick={() => setIsShowModalConclusionSentForECP(false)}>Закрыть</button>
                            </p>

                        </div>
                    </div>
                </div>

                <div className={isShowModalConclusionSentToRework ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                    <div className="transmittal-letters__modal-content transmittal-letters__form" >
                        <div className="transmittal-letters__wrapper">
                            <p className="transmittal-letters__modal-heading">
                                <span>Причины доработки</span>
                                <span onClick={() => setIsShowModalConclusionSentToRework(false)}>{IconsContent.Close()}</span>
                            </p>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="input_wrapper ">
                                    <label htmlFor="">Укажите причины</label>
                                    <div>
                                        <textarea type="text" {...register("comment")} />
                                        {errors.note && <p className="form--required">{errors.note.message}</p>}
                                    </div>
                                </div>

                                <div className="btn_action" >
                                    <button className="btn_assign header__content-correct"  >
                                        Отправить
                                    </button>
                                    <button className="btn_assign" type='button' onClick={() => setIsShowModalConclusionSentToRework(false)}>
                                        Отмена
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className={isShowModalConclusionEdit ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                    <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
                        <p>
                            <span onClick={() => setIsShowModalConclusionEdit(false)}>{IconsContent.Close()}</span>
                        </p>
                        <div>
                            <p>{IconsContent.Ask()}</p>
                            <p style={{ textAlign: 'center', padding: '10px 20px' }}>Вы принимаете проект на на проведение научной антикоррупционной экспертизы. Составьте заключение до указанного срока.</p>
                            <div className='isShowModalDeleteUserConfirm'>
                                <button onClick={() => {
                                    setNewPlayAnimation(true);
                                    UserService.putConclusion(conclusion.id, { status_id: 2 })
                                        .then(response => {
                                            setNewPlayAnimation(false)
                                            navigate(`/expert/conclusions/edit/${conclusion.id}`)
                                        })
                                        .catch(() => { setNewPlayAnimation(false); setIsShowModalError(true) })
                                }}>Принять</button>
                                <button onClick={() => { setIsShowModalConclusionEdit(false) }}>Закрыть</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={isShowModalConclusionCancel ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                    <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
                        <p>
                            <span onClick={() => setIsShowModalConclusionCancel(false)}>{IconsContent.Close()}</span>
                        </p>
                        <div>
                            <p>{IconsContent.DocCreated()}</p>
                            <p style={{ textAlign: 'center', padding: '10px 20px' }}>Подтвердите отклонение назначенной вам экспертизы?</p>
                            <div className='isShowModalDeleteUserConfirm'>
                                <button onClick={() =>
                                    UserService.putConclusion(conclusion.id, { status_id: 3 })
                                        .then(response => {

                                            navigate(`/expert/conclusions`)
                                        })
                                }>Подтвердить</button>
                                <button onClick={() => { setIsShowModalConclusionCancel(false) }}>Закрыть</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={isShowModalConclusionSentForECP ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                    <div className="transmittal-letters__modal-content transmittal-letters__form" >
                        <div className="transmittal-letters__wrapper " >
                            <p className="transmittal-letters__modal-heading">
                                <span>Подписание заключения через ЭЦП</span>
                                <span onClick={() => setIsShowModalConclusionSentForECP(false)}>{IconsContent.Close()}</span>
                            </p>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <h2 style={{ margin: '10px 0px 40px 0' }}>Вы отправляете научную антикоррупционную экспертизу уполномоченному координатору. После отправки Вы не сможете его исправить.</h2>
                                <div className='input_wrapper-ecp'>
                                    <div className="inner_wrapper">
                                        <input
                                            type="file"
                                            id={"file"}

                                            {...register("file3", {
                                                onChange: (e) =>
                                                    setFileName3(e.target.value.replace(`C:\\fakepath\\`, "")),
                                            })}
                                        />

                                        <label htmlFor={"file"} className={"file_contain"}>

                                            <div className={"file_put"}>
                                                {errors.file ?
                                                    <span style={{ color: 'red' }}>Выберите файл</span> :
                                                    <span>Выберите файл</span>}
                                                {IconsContent.PaperClip()}

                                            </div>
                                            <span className={"span"}>
                                                {fileName3
                                                    ? fileName3
                                                    : "Размер файла не должен превышать 20 МБ"}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="input_wrapper" style={{ position: "relative" }}>
                                    <label htmlFor="">Введите пароль</label>
                                    <div>
                                        <div className="input-password">
                                            <input type={showPassword ? "password" : "text"} {...register("password")} />
                                            <div style={{ position: "absolute", top: "0", right: "0" }} onClick={() => setShowPassword(!showPassword)}>{showPassword ? <EyeHide /> : <EyeView />} </div>

                                        </div>
                                        {errors.password && <p className="form--required">{errors.password.message}</p>}
                                        {error && <p style={{ textAlign: 'center', marginTop: '20px' }} className="form--required">{error}</p>}
                                    </div>
                                </div>

                                <div className="btn_action" >
                                    <button className="btn_assign header__content-correct" >
                                        Подписать
                                    </button>
                                    <button className="btn_assign" type='button' onClick={() => setIsShowModalConclusionSentForECP(false)}>
                                        Отмена
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className={isShowModalDocCreated ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                    <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
                        <p>
                            <span onClick={() => setIsShowModalDocCreated(false)}>
                                {IconsContent.Close()}
                            </span>
                        </p>
                        <div>
                            <p>{IconsContent.DocCreated()}</p>
                            <p style={{ textAlign: "center", marginTop: "20px" }}>Заключение сформировано без дальнейшей возможности его изменения!</p>
                            <button onClick={() => { setIsShowModalDocCreated(false); window.location.reload(); }}>
                                Закрыть
                            </button>
                        </div>
                    </div>
                </div>

                <div className={isShowModalError ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                    <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
                        <p>
                            <span onClick={() => setIsShowModalError(false)}>
                                {IconsContent.Close()}
                            </span>
                        </p>
                        <div>
                            <p>{IconsContent.Error()}</p>
                            <p>Произошла ошибка</p>
                            <button onClick={() => {
                                setIsShowModalError(false);
                                // window.location.reload();
                            }}>
                                Закрыть
                            </button>
                        </div>
                    </div>
                </div>

            </main >

        </div >
    )
}