import NavigationContent from "../../NavigationContent/NavigationContent";
import IconsContent from "../../../Assets/img/IconsContent";
import SlideToggle from "react-slide-toggle";

export default function FormViewContainer() {
	return (
		<div className={"projects_page administrator_page"}>
			<NavigationContent
				direct={[
					{ goTo: `/superadministrator`, name: `Главная` },
					{
						goTo: `/superadministrator`,
						name: `Проекты`,
					},
					{ goTo: `/superadministrator/projects/1`, name: `Форма просмотра` },
				]}
				name={"Проекты"}
			/>
			<main className="c-card">
				<div className="main_header">
					<h3>
						<strong>Ответственный координатор: </strong>Шаяхмет Гүлшат
						Қайратқызы
					</h3>
					<div className="btn_download btn_download-green">
						<button>
							<span>Скачать файлы</span> {IconsContent.File()}
						</button>
					</div>
				</div>
				<div className="view_form__information">
					<div className="information_status">
						<div className="status_type">
							<p className="name">Статус</p>
							<p className="status_code-admin">Отправлен эксперту</p>
						</div>
						<div className="status_type">
							<p className="name">Стоимость проекта</p>
							<p className="status_code-admin">Не рассчитана</p>
						</div>
						<div className="status_type">
							<p className="name">Создан</p>
							<p className="status_code-admin">05.07.2022 17:23:24</p>
						</div>
					</div>
				</div>
				<div className="view_form__content">
					<div className="content_left">
						<div className="logs">
							<div className="logs_header">
								<p>Логи</p>
							</div>

							<SlideToggle>
								{({ toggle, setCollapsibleElement }) => (
									<div className="my-collapsible">
										<button onClick={toggle}>
											<span>Молдабек Сырым Пернебекұлы (Изменено)</span>
											<span>2022-07-05 18:25:09 {IconsContent.ArrUp()}</span>
										</button>
										<div
											className={`hidden_information my-collapsible__content`}
											ref={setCollapsibleElement}
										>
											<p className="date">12:41:01</p>
											<div className="information_doc">
												<p className="header">Тип документа: </p>
												<p className="description">
													Нормативные правовые постановления Национального Банка
													Республики Казахстан и иных центральных
													государственных органов
												</p>
											</div>
											<br />
											<div
												className="information_doc"
												style={{ marginBottom: 28 }}
											>
												<p className="header">Тип документа: </p>
												<p className="description">
													Нормативные правовые постановления Национального Банка
													Республики Казахстан и иных центральных
													государственных органов
												</p>
											</div>
										</div>
									</div>
								)}
							</SlideToggle>
						</div>
						<div className="other_inforamtions">
							<div className="get_inform">
								<div className="inform_name">
									<h5>Номер входящего письма</h5>
								</div>
								<div className="inform_value">
									<p>кқ-6449</p>
								</div>
							</div>

							<div className="get_inform">
								<div className="inform_name">
									<h5>Номер исходящего письма</h5>
								</div>
								<div className="inform_value">
									<p>3-05/758</p>
								</div>
							</div>

							<div className="get_inform">
								<div className="inform_name">
									<h5>Дата исходящего письма</h5>
								</div>
								<div className="inform_value">
									<p>2022-07-05</p>
								</div>
							</div>

							<div className="get_inform">
								<div className="inform_name">
									<h5>Дата поступления проекта нормативного правового акта</h5>
								</div>
								<div className="inform_value">
									<p>2022-07-05 00:00:00</p>
								</div>
							</div>

							<div className="get_inform">
								<div className="inform_name">
									<h5>Тип документа</h5>
								</div>
								<div className="inform_value">
									<p>Нормативные правовые решения маслихатов</p>
								</div>
							</div>

							<div className="inform_half_wrapper">
								<div className="get_inform half">
									<div className="inform_name">
										<h5>
											Наименование проекта нормативного правового акта (рус)
										</h5>
									</div>
									<div className="inform_value">
										<p>
											Решения маслихата Павлодарской области «О внесении
											изменений в решение Павлодарского областного маслихата от
											22 апреля 2021 года № 23/3 «Об утверждении Правил
											содержания животных в Павлодарской области»
										</p>
									</div>
								</div>

								<div className="get_inform half-1">
									<div className="inform_name">
										<h5>
											Наименование проекта нормативного правового акта (каз)
										</h5>
									</div>
									<div className="inform_value">
										<p>
											«Павлодар облыстық мәслихатының 2021 жылғы 22 сәуірдегі
											№23/3 «Павлодар облысында жануарларды асырау қағидаларын
											бекіту туралы» шешіміне өзгерістер енгізу туралы» Павлодар
											облысы мәслихатының шешім
										</p>
									</div>
								</div>
							</div>

							<div className="get_inform">
								<div className="inform_name">
									<h5>Отрасли законодательства</h5>
								</div>
								<div className="inform_value">
									<p>
										020.000.000. Законодательство о государственном управлении и
										административно-территориальном устройстве
									</p>
								</div>
							</div>

							<div className="get_inform">
								<div className="inform_name">
									<h5>
										Назначение и структура проекта нормативного правового акта
										(рус)
									</h5>
								</div>
								<div className="inform_value">
									<p>
										Назначение проекта заключается в установлении, изменении
										либо отмене правовых норм. Структура акта обусловлена его
										внутренним содержанием.
									</p>
								</div>
							</div>

							<div className="get_inform">
								<div className="inform_name">
									<h5>
										Назначение и структура проекта нормативного правового акта
										(на казахском)
									</h5>
								</div>
								<div className="inform_value">
									<p>
										Құқықтық нормаларды тағайындау, өзгерту немесе күшін
										жою.Актінің құрылымы оның ішкі мазмұнымен шарттастырылған.
									</p>
								</div>
							</div>

							<div className="get_inform">
								<div className="inform_name">
									<h5>
										Документы, представленные для проведения научной
										антикоррупционной экспертизы (на русском)
									</h5>
								</div>
								<div className="inform_value">
									<p>
										Сопроводительное письмо, проект НПА, приложение,
										пояснительная записка, сравнительная таблица
									</p>
								</div>
							</div>

							<div className="get_inform">
								<div className="inform_name">
									<h5>
										Документы, представленные для проведения научной
										антикоррупционной экспертизы (на казахском)
									</h5>
								</div>
								<div className="inform_value">
									<p>
										Ілеспе хат, НҚА жобасы, қосымша, түсіндірме жазба,
										салыстырмалы кесте
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="content_right">
						<div className="inform">
							<div className="inform_name">
								<h5>Экспертизы</h5>
								<div className="btn_download btn_download-blue">
									<button>
										<span>Скачать файлы</span> {IconsContent.File()}
									</button>
								</div>
							</div>
							<div className="expertDetails__value">
								<h5>
									Эксперт: <span>Молдабек Сырым Пернебекұлы</span>
								</h5>
								<div className="conclusionCoordinatorDetails__value-info">
									<p>
										ID проекта: <span>35646</span>
									</p>
									<p>
										Ответственный корректор: <span>Наименование проекта</span>
									</p>
									<p>
										Крайний срок выполнения: <span>Дата (гггг-мм-дд)</span>
									</p>
									<p>
										Статус: <span>Статус</span>
									</p>
									<p>
										Эксперт: <span>ФИО</span>
									</p>
								</div>
							</div>
						</div>

						<div className="inform">
							<div className="inform_name">
								<h5>Повторная экспертиза</h5>
							</div>
							<div className="inform_value">
								<p>Экспертизы пока не назначены</p>
							</div>
						</div>

						<div className="inform">
							<div className="inform_name">
								<h5>Комментарий для экспертов и координаторов</h5>
							</div>
							<div className="inform_value">
								<a
									href={
										"https://drive.google.com/drive/folders/1vkUsfWSHZr4qFSk_s7K8-_y7XvWIblQr?usp=sharing"
									}
									target={"_blank"}
								>
									https://drive.google.com/drive/folders/1vkUsfWSHZr4qFSk_s7K8-_y7XvWIblQr?usp=sharing
								</a>
							</div>
						</div>

						<div className="inform">
							<div className="inform_name_min">
								<h5>Орган-разработчик</h5>
							</div>
							<div className="inform_value_input" style={{ marginBottom: 15 }}>
								<p>Акимат Павлодарской области</p>
							</div>
							<div className="inform_name_min">
								<h5>Еmail</h5>
							</div>
							<div className="inform_value_input" style={{ marginBottom: 15 }}>
								<p>antikor@kazguu.kz</p>
							</div>
						</div>

						<div className="inform">
							<div className="inform_name">
								<h5>Комментарии с экспертами</h5>
							</div>
							<div className="inform_value">
								<p>Заметок пока нет</p>
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>
	);
}
