import "./app.css"
import { BrowserRouter } from "react-router-dom";
import RoutesContent from "./Presentation/Routes/RoutesContent";
import { Suspense, useEffect, useState } from "react";


function App() {

  return (
    <BrowserRouter>
      <Suspense fallback={<div style={{position:"absolute",width:"100%",heigth:"100%",background:"red"}}></div>}>
        <RoutesContent />
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
