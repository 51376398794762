import IconsContent from "../../Assets/img/IconsContent";
import { useEffect, useRef, useState } from "react";
import iconsContent from "../../Assets/img/IconsContent";
import { Link } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import DatePickerInput, { DatePickerInputRange } from "../DatePicker/DatePicker";
import TableFirst from "../TableFirst/TableFirst";
import { useForm } from 'react-hook-form'
import { UserService } from "../../../Main/Services/UserService";
import { InputSelect } from "../Select/InputSelect";
import { useTranslation } from "react-i18next";


export default function ProjectsContainer({ projects, catos, projectStatuses, branchOfLegislation, authorityDevelopers, coordinatorsList, documentTypes, expertsList }) {
    const userLocation = localStorage.getItem('.UserLocation')
    const { t, i18n } = useTranslation("common");
    const [projectsFiltered, setProjectsFiltered] = useState()
    const [onClear, setOnClear] = useState(false)
    const [filteredData, setFilteredData] = useState('')
    const [filteredDataAll, setFilteredDataAll] = useState('')
    const [filteredDataPending, setFilteredDataPending] = useState('')
    const [filteredDataAccepted, setFilteredDataAccepted] = useState('')
    const [filteredDataCompleted, setFilteredDataCompleted] = useState('')
    const [filterSideMenu, setFilterSideMenu] = useState(0)

    const [filteredPage, setFilteredPage] = useState(1)

    useEffect(() => {
        UserService.getFilteredData(filteredPage, filteredData)
            .then(response => {

                setFilteredDataAll(response.data);
                // setProjectsFiltered(response.data)
                if (filterSideMenu == 0) {
                    setProjectsFiltered(response.data)
                }
            })
    }, [filteredPage, filteredData])

    useEffect(() => {
        UserService.getFilteredDataPending(filteredPage, filteredData)
            .then(response => {

                setFilteredDataPending(response.data);
                if (filterSideMenu == 2) {
                    setProjectsFiltered(response.data)
                }
            })
    }, [filteredPage, filteredData])

    useEffect(() => {
        UserService.getFilteredDataAccepted(filteredPage, filteredData)
            .then(response => {

                setFilteredDataAccepted(response.data);
                if (filterSideMenu == 1) {
                    setProjectsFiltered(response.data)
                }
            })
    }, [filteredPage, filteredData])

    useEffect(() => {
        UserService.getFilteredDataCompleted(filteredPage, filteredData)
            .then(response => {

                setFilteredDataCompleted(response.data);
                if (filterSideMenu == 3) {
                    setProjectsFiltered(response.data)
                }
            })
    }, [filteredPage, filteredData])


    const { register, handleSubmit, control, reset, formState: { errors } } = useForm({ mode: 'onBlur' })

    const onSubmit = (filteredData) => {

        //---React-Hook object converting to URL string
        let arrFilterItems = Object.keys(filteredData).map((item, index) => {
            if (Object.values(filteredData)[index] !== '' && Object.values(filteredData)[index] !== undefined && Object.values(filteredData)[index] !== "Invalid date") {
                return `filter[${item}]=${Object.values(filteredData)[index]}`
            }
        })
            .filter(item => item !== undefined)
        console.log(arrFilterItems)
        let newArr = arrFilterItems.map((item, index) => index == arrFilterItems.length - 1 ? `${item}` : `${item}&`)
        console.log(newArr.toString().replace(/[,]/gi, ''))
        //---------------------------------------------

        // UserService.getFilteredData(newArr.toString().replace(/[,]/gi, ''))
        setFilteredData(newArr.toString().replace(/[,]/gi, ''))
        setFilteredPage(1)
        // UserService.getFilteredData(filteredPage,newArr.toString().replace(/[,]/gi, ''))
        //     .then(response => {
        //         
        //         setProjectsFiltered(response.data)
        //     })
        // reset()
    }

    const [isFilter, setIsFilter] = useState(false)


    return (
        <div className={'projects_page'}>
            <div className="header_wrapper_name">
                <h1>{t("project")}</h1>
                {userLocation == '/clerk' ? <Link to={'/clerk/addProject'}><a className={'add_project'}><span>{t("add_project")}</span> {IconsContent.AddProject()}</a></Link> : null}
            </div>
            <main className="main__page">
                <div className="main__page--left">
                    <p className={filterSideMenu == 0 ? "main__page--active" : null} onClick={() => { setFilterSideMenu(0); setFilteredPage(1); setProjectsFiltered(filteredDataAll) }}>{IconsContent.Folder()}{t("all_project")}<span>{filteredDataAll?.meta?.total}</span></p>
                    <p className={filterSideMenu == 1 ? "main__page--active" : null} onClick={() => { setFilterSideMenu(1); setFilteredPage(1); setProjectsFiltered(filteredDataAccepted) }}>{IconsContent.FolderAccepted()}{t("in_operative")}<span>{filteredDataAccepted?.meta?.total}</span></p>
                    <p className={filterSideMenu == 2 ? "main__page--active" : null} onClick={() => { setFilterSideMenu(2); setFilteredPage(1); setProjectsFiltered(filteredDataPending) }}>{IconsContent.FolderPending()}{t("waiting")}<span>{filteredDataPending?.meta?.total}</span></p>
                    <p className={filterSideMenu == 3 ? "main__page--active" : null} onClick={() => { setFilterSideMenu(3); setFilteredPage(1); setProjectsFiltered(filteredDataCompleted) }}>{IconsContent.FolderCompleted()}{t("completed")}<span>{filteredDataCompleted?.meta?.total}</span></p>
                </div>
                <div className="main__page--right">
                    <div className="main_header">
                        <button className={'btn_filter'} onClick={() => { setIsFilter(true) }}>
                            <span className="name">Фильтр</span>
                            {IconsContent.Filter()}
                        </button>
                        <div className={"btn_search"}>
                            {IconsContent.Search()}
                            <input type="email" placeholder={t("search")}  {...register("name", { onChange: () => handleSubmit(onSubmit)() })} />
                        </div>
                        {/* <div className="btn_export">Экспорт в Excel</div> */}
                    </div>
                    <div className="table_wrapper page_coordinator">
                        <table className={'table_styling'}>
                            <thead>
                                <tr className={'table_heading'}>
                                    <TableFirst />
                                    <td>{t("project_name")}</td>
                                    <td>{t("experts")}</td>
                                    <td>СТАТУС</td>
                                    {/* <td colSpan={2} className={'last'}>ОРГАН-РАЗРАБОТЧИК</td> */}
                                    <td className={'last'}></td>
                                </tr>
                            </thead>
                            <tbody>
                                {projectsFiltered && projectsFiltered.data.map((item, index) => {
                                    return (
                                        <tr className={'table_body'} key={index}>
                                            <td>{item.id}</td>
                                            <td>{item.locales.name.ru} </td>
                                            {userLocation == '/expert' ? <td> {item.conclusions.map(item => {
                                                if (item.expert.id == localStorage.getItem('.UserID')) {
                                                    return <p>{item.expert?.full_name}</p>
                                                }
                                            })}</td> : <td>{item.conclusions.map(item => <p>{item.expert?.full_name}</p>)}</td>}
                                            <td>{item.status.locales.name.ru}</td>
                                            {/* <td>{item.authority_developer.locales.name.ru}</td> */}
                                            <td >
                                                <Link
                                                    to={{
                                                        pathname: `${userLocation}/projects/${item.id}`,
                                                        state: item.id
                                                    }}
                                                >{IconsContent.Eye()}</Link>

                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <div className="table_wrapper_footer">
                            <div className="footer_number">
                                {projectsFiltered && <p>Найдено {projectsFiltered.meta.total} из {projects.meta?.total} </p>}
                            </div>
                            <div className="footer_pagination">
                                {projectsFiltered && projectsFiltered.meta?.total > projects.meta?.per_page ?
                                    <Pagination count={Math.ceil(projectsFiltered.meta.total / projectsFiltered.meta?.per_page)} page={filteredPage} onChange={(event, value) => setFilteredPage(value)} variant="outlined" shape="rounded" /> : null}

                                {/* <button className={'btn_pagination '}>1</button>
                                <button className={'btn_pagination'}>2</button> */}
                            </div>
                        </div>
                    </div>
                </div>


            </main>

            <div className={`modal_filter ${isFilter ? 'active' : ''}`}>
                <div className={`modal_content`}>
                    <div className="modal_header">
                        <div className="header_word">
                            <h5>Фильтр</h5>
                            {IconsContent.Filter('#4ABD9B', `2`)}
                        </div>
                        <button onClick={() => { setIsFilter(false) }}>{iconsContent.Cancel()}</button>
                    </div>
                    <div className="modal_form">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="input_wrapper">
                                <label htmlFor="">ID</label>
                                <input type="number" {...register(`id`)} />
                            </div>
                            {/* <div className="input_wrapper">
                                <label htmlFor="">Статус</label>
                                <InputSelect control={control} name="status_id" noRequired onClear={onClear} placeholder="Выберите" options={projectStatuses} />
                            </div> */}
                            <div className="input_wrapper">
                                <label htmlFor="">Отрасль законодательства</label>
                                <InputSelect control={control} name="branch_of_legislation_id" noRequired onClear={onClear} placeholder="Выберите" options={branchOfLegislation} />

                            </div>
                            <div className="input_wrapper">
                                <label htmlFor="">Ответственный координатор</label>
                                <InputSelect control={control} name="coordinator_id" noRequired onClear={onClear} placeholder="Выберите" options={coordinatorsList} />
                            </div>
                            {/* <div className="input_wrapper">
                                    <label htmlFor="">Город/Регион</label>
                                    <Select options={options} classNamePrefix="custom-select" placeholder="Выберите регион или город" />
                                </div> */}
                            <div className="input_wrapper">
                                <label htmlFor="">Тип документа</label>
                                <InputSelect control={control} name="document_type_id" noRequired onClear={onClear} placeholder="Выберите" options={documentTypes} />
                            </div>
                            <div className="input_wrapper">
                                <label htmlFor="">Область/регион</label>
                                <InputSelect control={control} name="document_type_id" noRequired onClear={onClear} placeholder="Выберите" options={catos} />
                            </div>
                            {userLocation == '/coordinator' ? <div className="input_wrapper ">
                                <label htmlFor="">Эксперт</label>
                                <InputSelect control={control} name="expert_id" noRequired onClear={onClear} placeholder="Выберите" options={expertsList} />
                            </div> : null}
                            <div className="input_wrapper ">
                                <label htmlFor="">Орган разработчик</label>
                                <InputSelect control={control} name="authority_developer_id" noRequired onClear={onClear} placeholder="Выберите" options={authorityDevelopers} />
                            </div>
                            {/* <div className="input_wrapper ">
                                    <label htmlFor="">Область/ГРЗ</label>
                                    <Select options={options} classNamePrefix="custom-select" placeholder="Выберите область или ГРЗ" />
                                </div> */}
                            <div className="input_wrapper ">
                                <label htmlFor="">Крайний срок выполнения</label>
                                <DatePickerInput noRequired onClear={onClear} name='deadline' control={control} />
                            </div>
                            {userLocation == '/coordinator' ? <div className="input_wrapper ">
                                <label htmlFor="">Период (дата подписи экспертом)</label>
                                <DatePickerInputRange nameFrom='filtered_expert_date_from' onClear={onClear} control={control} nameTill='filtered_expert_date_till' />
                            </div> : null}
                            {userLocation == '/coordinator' ? <div className="input_wrapper ">
                                <label htmlFor="">Период (дата опубликования)</label>
                                <DatePickerInputRange nameFrom='filtered_publicate_date_from' onClear={onClear} control={control} nameTill='filtered_publicate_date_till' />
                            </div> : null}
                            <div className="input_wrapper ender">
                                <label htmlFor="">Опубликован</label>
                                <InputSelect control={control} name="published" noRequired onClear={onClear} placeholder="Выберите" options={[{ value: 'yes', label: 'Да' }, { value: 'no', label: 'Нет' }]} />
                            </div>

                            <div className="filter__ender">
                                <button type="submit" className={'submit'}>Применить</button>
                                <button type="button" onClick={(e) => setOnClear(!onClear)} className={'submit reset '}>Сбросить</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="modal_cover" onClick={() => { setIsFilter(false) }} />
            </div>
        </div>
    )
}
{/* <button className={'cancel'}>Сбросить</button> */ }
// /api/v1/projects?filter[id]=1000000&filter[status][id]=1&filter[name]=*some text*&filter[branch_of_legislation_id]=111&filter[coordinator_id]=11&filter[expert_id]=111&filter[document_type_id]=111&filter[interpreter_id]=1111&filter[authority_developer_id]=111&filter[deadline]=2022-10-10&filter[published]=1