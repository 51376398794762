import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import NavigationContent from "../../NavigationContent/NavigationContent";
import InputMask from "react-input-mask";
import Select from "react-select";
import GeneratePassword from "../../GeneratePassword/GeneratePassword";

export default function AddUserContainer() {
	const [newUserRole, setNewUserRole] = useState("Coordinator");

	//useForm
	const {
		register,
		//formState: { errors },
		handleSubmit,
	} = useForm({
		mode: "onBlur",
		//resolver: yupResolver(actionCatalogSchema),
	});

	// submit/add data
	const onSubmit = (data) => {
		console.log(data);
	};

	return (
		<div className={"transmittal-letters__form addProject_page projects_page"}>
			<NavigationContent
				direct={[
					{ goTo: `/superadministrator`, name: `Главная` },
					{ goTo: `/superadministrator/users`, name: `Пользователи` },
					{
						goTo: `/superadministrator/users/1`,
						name: `Пользователи - Внутри`,
					},
				]}
				name={"Пользователи - Внутри"}
			/>

			<div className="header_wrapper_name"></div>
			<main>
				<div className="c-card">
					<h5>Обязательные поля *</h5>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="input_wrapper">
							<label htmlFor="">Роль</label>
							<Select
								options={[
									{ value: "Corrector", label: "Корректор" },
									{ value: "Administrator", label: "Администратор" },
									{ value: "Coordinator", label: "Координатор" },
									{ value: "Expert", label: "Эксперт" },
									{ value: "Translator", label: "Переводчик" },
								]}
								onChange={(e) => setNewUserRole(e.value)}
								classNamePrefix="custom-select"
								placeholder="Корректор"
							/>
						</div>
						<div className="input_wrapper">
							<label htmlFor="">E-mail *</label>
							<input type="text" />
						</div>
						<div className="input_wrapper">
							<label htmlFor="">Пароль *</label>
							<GeneratePassword />
						</div>
						<div className="input_wrapper">
							<label htmlFor="">ФИО (на русском) *</label>
							<input type="text" />
						</div>
						<div className="input_wrapper">
							<label htmlFor="">ФИО (на казахском) *</label>
							<input type="text" />
						</div>
						<div className="input_wrapper">
							<label htmlFor="">ИИН *</label>
							<input type="text" />
						</div>
						{newUserRole == "Coordinator" || newUserRole == "Expert" ? (
							<>
								<div className="input_wrapper">
									<label htmlFor="">Дата рождения *</label>
									<input type="text" />
								</div>
								<div className="input_wrapper">
									<label htmlFor="">Телефон *</label>
									<InputMask
										mask="+7 (999) 999 - 99 - 99"
										placeholder="+7 (___) ___ - __ - __"
									/>
								</div>
								<div className="input_wrapper">
									<label htmlFor="">Должность (на русском) *</label>
									<input type="text" />
								</div>
								<div className="input_wrapper">
									<label htmlFor="">Должность (на казахском) *</label>
									<input type="text" />
								</div>
							</>
						) : null}
						<div className="input_wrapper">
							<label htmlFor="">Примечания (на русском)</label>
							<input type="text" />
						</div>
						<div
							className={
								newUserRole !== "Expert"
									? "input_wrapper-last"
									: "input_wrapper"
							}
						>
							<label htmlFor="">Примечания (на казахском)</label>
							<input type="text" />
						</div>
						{/* {newUserRole == "Expert" ? (
						<>
							<div className="input_wrapper">
								<label htmlFor="">Организация (на русском) </label>
								<input type="text" />
							</div>
							<div className="input_wrapper">
								<label htmlFor="">Организация (на русском) </label>
								<input type="text" />
							</div>
							<div className="input_wrapper">
								<label htmlFor="">Вид участия</label>
								<Select
									options={[
										{ value: "Corrector", label: "Пукнт1" },
										{ value: "Administrator", label: "Пункт2" },
									]}
									classNamePrefix="custom-select"
									placeholder="Выберите"
								/>
							</div>
							<div className="input_wrapper">
								<label htmlFor="">Ученая/Академическая степень *</label>
								<Select
									options={[
										{ value: "Corrector", label: "Пукнт1" },
										{ value: "Administrator", label: "Пункт2" },
									]}
									classNamePrefix="custom-select"
									placeholder="Выберите"
								/>
							</div>
							<div className="input_wrapper">
								<label htmlFor="">Научное звание *</label>
								<Select
									options={[
										{ value: "Corrector", label: "Пукнт1" },
										{ value: "Administrator", label: "Пункт2" },
									]}
									classNamePrefix="custom-select"
									placeholder="Выберите"
								/>
							</div>
							<div
								className={
									newUserRole == "Expert"
										? "input_wrapper-last"
										: "input_wrapper"
								}
							>
								<label htmlFor="">Специализация *</label>
								<Select
									options={[
										{ value: "Corrector", label: "Пукнт1" },
										{ value: "Administrator", label: "Пункт2" },
									]}
									classNamePrefix="custom-select"
									placeholder="Выберите"
								/>
							</div>
						</>
					) : null} */}
						<button className={"submit"}>Сохранить</button>
					</form>
				</div>
			</main>
		</div>
	);
}
