import "../../Styles/Layout.css";
import MainLayout from "../../Components/Layout/MainLayout";
import { useLocation } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import IconsContent from "../../Assets/img/IconsContent";
import ConclusionsDetailsContainer from "../../Components/pages/SuperAdministrator/ConclusionsDetailsContainer";
import { useEffect, useState } from "react";
import Preloader from "../../Components/Preloader/Preloader";

export default function ConclusionsSuperAdministratorDetails() {

	const [playAnimation, setPlayAnimation] = useState(false)
    useEffect(() => {
	  const onPageLoad = () => {
		setPlayAnimation(true);
	  };
  
	  if (document.readyState === 'complete') {
		onPageLoad();
	  } else {
		window.addEventListener('load', onPageLoad);
		return () => window.removeEventListener('load', onPageLoad);
	  }
	}, []);
	
	return (
		<MainLayout>
            <Preloader playAnimation={playAnimation}/>
			<Navbar
				direction={[
					{
						name: "Проекты",
						goTo: "/superadministrator",
						iCon: IconsContent.BriefCase(),
					},
					{
						name: "Заключения",
						goTo: "/superadministrator/conclusions",
						iCon: IconsContent.FillCheck(),
					},
					{
						name: "Пользователи",
						goTo: "/superadministrator/users",
						iCon: IconsContent.Users(),
					},
				]}
			/>
			<ConclusionsDetailsContainer />
		</MainLayout>
	);
}
