import "../../Styles/Layout.css";
import TransmittalLettersContainer from "../../Components/pages/TransmittalLettersContainer";
import MainLayout from "../../Components/Layout/MainLayout";
import { useLocation } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import { UserService } from "../../../Main/Services/UserService";
import { useEffect, useState } from "react";
import Preloader from "../../Components/Preloader/Preloader";
import { AdministratorkNavBar } from "../../../Main/Services/http";

export default function TransmittalLettersAdministratorPage() {

  const [playAnimation, setPlayAnimation] = useState(false)

  const [transmittalLetters, setTransmittalLetters] = useState()

  useEffect(() => {

    UserService.getTransmittalLetters()
      .then(response => {
        {
          setTransmittalLetters(response.data);
        }
      });

  }, [])
  useEffect(() => {
    const onPageLoad = () => {
      setPlayAnimation(true);
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <MainLayout>
      <Preloader playAnimation={playAnimation} />
      <Navbar direction={AdministratorkNavBar()} />
      {transmittalLetters && <TransmittalLettersContainer
        transmittalLetters={transmittalLetters} />}
    </MainLayout>
  )
}