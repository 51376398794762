import React from 'react'
import { Controller } from 'react-hook-form'
import Select from "react-select";
import { useRef, useEffect } from 'react';

export const InputSelect = ({ options, name, placeholder, requiredMessage, defaultValue, noRequired, onClear, control, selectedValue = () => { } }) => {
    const selectInputRef = useRef();


    useEffect(() => {
        if (typeof onClear == 'boolean') {

            selectInputRef.current.clearValue()
        }
    }, [onClear]);



    return (
        <>{noRequired ?
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange } }) =>
                    <Select
                        // isClearable
                        ref={selectInputRef}
                        // onChange={(e) =>onChange(e?.value)}
                        onChange={(e) => {
                            onChange(e?.value);
                            console.log(e);
                            selectedValue(e)
                        }}
                        classNamePrefix="custom-select"
                        placeholder={placeholder}
                        options={options}
                        defaultInputValue={defaultValue}
                    />
                } /> :
            <Controller
                name={name}
                control={control}
                rules={{ required: "Выберите поле" }}
                render={({ field: { onChange, ref } }) =>
                    <Select
                        // name={name}
                        // isClearable
                        // ref={ref}
                        ref={selectInputRef}
                        // onChange={(e) =>onChange(e?.value)}
                        onChange={(e) => {
                            onChange(e?.value);
                            console.log(e);
                            selectedValue(e)
                        }}
                        classNamePrefix="custom-select"
                        placeholder={placeholder}
                        options={options}
                        defaultValue={defaultValue}
                    />
                } />
        }
        </>
    )
}
