import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style'


const ExportExcel = ({ excelData, excelData2, fileName }) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8';
    const fileExtension = '.xlsx'
    console.log(Object.keys(excelData[0]))
    console.log(excelData)

    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(excelData);
        ws['!cols'] = [...Array(Object.keys(excelData[0]).length)].map((_, index) => {
            if (index == 0) { return { wpx: 50 } }
            if (index < 4) { return { wpx: 200 } }
            else { return { wpx: 150 } }
        })
        ws['!rows'] = [...Array(excelData.length + 1)].map((_, index) => {
            if (index == 0) { return { hpx: 30 } }
            else { return { hpx: 20 } }
        });
        for (let i in ws) {
            if (typeof (ws[i]) != "object") continue;
            let cell = XLSX.utils.decode_cell(i);
            if (cell.c == 0 || cell.c >= 4) { // first column
                ws[i].s = { // bottom border
                    alignment: {
                        horizontal: "center",
                        vertical: "center"
                    }
                };
            }

            if (cell.r == 0) { // first row
                ws[i].s = { // bottom border
                    alignment: {
                        horizontal: "center",
                        vertical: "center"
                    },
                    font: {
                        color: { rgb: "ef6262" },
                        bold: true
                    }
                };
            }
            if (cell.r % 2) { // every other row

            }
        }

        // const ws2 = XLSX.utils.json_to_sheet(excelData2)
        // const merge = [
        //     { s: { r: 1, c: 0 }, e: { r: 3, c: 0 } }
        // ];
        // ws2["!merges"] = merge
        // ws2['!cols'] = [...Array(Object.keys(excelData2[0]).length)].map((_,index)=>{
        //      return  {wpx: 120}
        // })
        // ws2['!rows'] = [...Array(excelData2.length + 1)].map((_, index) => {
        //     if (index == 0) { return { hpx: 30 } }
        //     else { return { hpx: 20 } }
        // });
        // for (let i in ws2) {
        //     if (typeof (ws2[i]) != "object") continue;
        //     let cell = XLSX.utils.decode_cell(i)
        //         ws2[i].s = { 
        //             alignment: {
        //                 horizontal: "center",
        //                 vertical: "center"
        //             }
        //         };
        //     if (cell.r == 0) { // first row
        //         ws2[i].s = { // bottom border
        //             alignment: {
        //                 horizontal: "center",
        //                 vertical: "center"
        //             },
        //             fill:{
        //                 fgColor: { rgb: "e2e2e2" }
        //             },
        //             font:{
        //                 color: { rgb: "ef6262" },
        //                 bold: true
        //             },
        //             border:{
        //                 bottom: { style: "medium", color: { rgb: "0d152c" } },   
        //                 right: { style: "medium", color: { rgb: "0d152c" } },   
        //             }

        //         };
        //     }
        // }

        // const wb = { Sheets: { 'data': ws, 'data2':ws2 }, SheetNames: ['data', 'data2'] };
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension)
    }
    return (
        <button onClick={e => exportToExcel(fileName)} >Экспорт в Excel</button>
    )
}

export default ExportExcel