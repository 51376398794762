import React, { useState } from "react";

export default function GeneratePassword({ passValue }) {
	const [generate, setGenerate] = useState();

	const generatePass = () => {
		let pass = "";
		let string =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZ" + "abcdefghijklmnopqrstuvwxyz0123456789@#$";
		for (let i = 1; i <= 8; i++) {
			let char = Math.floor(Math.random() * string.length + 1);
			setGenerate((pass += string.charAt(char)));
		}
	};
	return (
		<div className="superadmin_user-generate">
			<input type="text" value={generate ? generate : passValue} />
			<p className="superadmin_user-generate-text" onClick={generatePass}>
				Сгенерировать
			</p>
		</div>
	);
}
