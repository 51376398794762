import MainLayout from "../../Components/Layout/MainLayout";
import IconsContent from "../../Assets/img/IconsContent";
import Navbar from "../../Components/Navbar/Navbar";
import ConclusionsExpertEditContainer from "../../Components/pages/Expert/ConclusionsExpertEditContainer";
import { useState, useEffect } from "react";
import { UserService } from "../../../Main/Services/UserService";
import { useLocation } from "react-router-dom";
import Preloader from "../../Components/Preloader/Preloader";
import { ExpertNavBar } from "../../../Main/Services/http";
import { useTranslation } from "react-i18next";

function ConclusionsExpertEdit() {


    let conclusionId = useLocation().pathname.replace(/[a-z\/]/g, '')
    const [riskFactors, setRiskFactors] = useState()
    const [corruptionFactors, setCorruptionFactors] = useState()
    const [conclusion, setConclusion] = useState()
    const { t, i18n } = useTranslation("common");

    useEffect(() => {
        UserService.getConclusion(conclusionId)
            .then(response => {
                let arr = response.data.data
                setConclusion(arr);

            })
        UserService.getRiskFactors()
            .then(response => {
                let arr = response.data.data.map(item => { return { value: item.id, label: item.locales.name[i18n.language] } })
                setRiskFactors(arr);

            })
        UserService.getCorruptionFactors()
            .then(response => {
                let arr = response.data.data.map(item => { return { value: item.id, label: item.name } })
                setCorruptionFactors(arr);

            })

    }, []);

    const [playAnimation, setPlayAnimation] = useState(false)
    useEffect(() => {
        const onPageLoad = () => {
            setPlayAnimation(true);
        };

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    return (
        <MainLayout>
            <Preloader playAnimation={playAnimation} />
            <Navbar direction={ExpertNavBar()} />
            {conclusion && riskFactors && corruptionFactors && <ConclusionsExpertEditContainer
                conclusion={conclusion}
                riskFactors={riskFactors}
                corruptionFactors={corruptionFactors}
            />}


        </MainLayout>
    )
}

export default ConclusionsExpertEdit;