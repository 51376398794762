import React from 'react'
import '../Statistics.scss'
import {BarChart,Bar,XAxis,YAxis,CartesianGrid,Tooltip,Legend} from "recharts";

const StatisticsContainerByProject = () => {

  const initialData = () => {
     let statisticArr = [
    {
      id: 'aa',
      title: 'Проэктов',
      number: 1,
      color: '#003b5d'
    },
    {
      id: 'ab',
      title: 'Разработчиков НПА',
      number: 324,
      color: '#4abd9b'
    },
    {
      id: 'ac',
      title: 'Принятых проэктов',
      number: 1,
      color: '#2147a7'
    },
    {
      id: 'ad',
      title: 'Отклонено проэктов',
      number: 35,
      color: '#ef8c62'
    },
  ]
    return (
      statisticArr.map(item =>
        <div key={item.id} className='statistics__dataInitial-item' style={{ backgroundColor: item.color }}>
          <p>{item.number}</p>
          <p>{item.title}</p>
        </div>)
    )
  }

  const dataBar1 = [
    {
      name: "",
      uv: 4000,
      pv: 2400,
      lv: 490,
      amt: 2400
    },
    {
      name: "",
      uv: 3000,
      pv: 1398,
      lv: 490,
      amt: 2210
    },
    {
      name: "",
      uv: 2000,
      pv: 9800,
      lv: 490,
      amt: 2290
    },
    {
      name: "",
      uv: 2780,
      pv: 3908,
      lv: 490,
      amt: 2000
    },
    {
      name: "",
      uv: 1890,
      pv: 4800,
      lv: 490,
      amt: 2181
    },
    {
      name: "",
      uv: 2390,
      pv: 3800,
      lv: 490,
      amt: 2500
    },
    {
      name: "",
      uv: 3490,
      pv: 4300,
      lv: 490,
      amt: 2100
    },
    {
      name: "",
      uv: 4000,
      pv: 2400,
      lv: 490,
      amt: 2400
    },
    {
      name: "",
      uv: 3000,
      pv: 1398,
      lv: 490,
      amt: 2210
    },
    {
      name: "",
      uv: 2000,
      pv: 9800,
      lv: 490,
      amt: 2290
    },
    {
      name: "",
      uv: 2780,
      pv: 3908,
      lv: 490,
      amt: 2000
    },
    {
      name: "",
      uv: 1890,
      pv: 4800,
      lv: 490,
      amt: 2181
    },
    {
      name: "",
      uv: 2390,
      pv: 3800,
      lv: 490,
      amt: 2500
    },
    {
      name: "",
      uv: 3490,
      pv: 4300,
      lv: 490,
      amt: 2100
    },
    {
      name: "",
      uv: 2390,
      pv: 3800,
      lv: 490,
      amt: 2500
    },
    {
      name: "",
      uv: 3490,
      pv: 4300,
      lv: 490,
      amt: 2100
    },
    {
      name: "",
      uv: 4000,
      pv: 2400,
      lv: 490,
      amt: 2400
    },
    {
      name: "",
      uv: 3000,
      pv: 1398,
      lv: 490,
      amt: 2210
    },
    {
      name: "",
      uv: 2000,
      pv: 9800,
      lv: 490,
      amt: 2290
    },
    {
      name: "",
      uv: 2780,
      pv: 3908,
      lv: 490,
      amt: 2000
    },
    {
      name: "",
      uv: 1890,
      pv: 4800,
      lv: 490,
      amt: 2181
    },
    {
      name: "",
      uv: 2390,
      pv: 3800,
      lv: 490,
      amt: 2500
    },
    {
      name: "",
      uv: 3490,
      pv: 4300,
      lv: 490,
      amt: 2100
    },
  ];

  const dataBar2 = [
    {
      name: "",
      uv: 4000,
      pv: 2400,
      lv: 490,
      amt: 2400
    },
    {
      name: "",
      uv: 3000,
      pv: 1398,
      lv: 490,
      amt: 2210
    },
    {
      name: "",
      uv: 2000,
      pv: 9800,
      lv: 490,
      amt: 2290
    },
    {
      name: "",
      uv: 2780,
      pv: 3908,
      lv: 490,
      amt: 2000
    },
    {
      name: "",
      uv: 1890,
      pv: 4800,
      lv: 490,
      amt: 2181
    },
    {
      name: "",
      uv: 2390,
      pv: 3800,
      lv: 490,
      amt: 2500
    },
    {
      name: "",
      uv: 3490,
      pv: 4300,
      lv: 490,
      amt: 2100
    },
    {
      name: "",
      uv: 2390,
      pv: 3800,
      lv: 490,
      amt: 2500
    },
    {
      name: "",
      uv: 3490,
      pv: 4300,
      lv: 490,
      amt: 2100
    },
    {
      name: "",
      uv: 3490,
      pv: 4300,
      lv: 490,
      amt: 2100
    },
  ];

  return (
    <div className='statistics'>
      <h1>Статистика</h1>
      <div className='statistics__dataInitial'>
        <div className='statistics__data-container'>
          <h3>Статистические данные</h3>
          <div className='statistics__dataInitial-content'>
            {initialData()}
          </div>
        </div>
      </div>
      <div className='statistics__dataBar1'>
        <div className='statistics__data-container'>
          <h3>По типам</h3>
          <div className='statistics__dataBar1-content'>
            <BarChart width={1200}
              height={400}
              data={dataBar1}
              barGap={0}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="1 1" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="pv" name='Нормативные правовые постановления РК' fill="#2147a7" />
              <Bar dataKey="uv" name='Нормативные правовые приказы министров РК' fill="#4abd9b" />
              <Bar dataKey="lv" name='Иных руководителей центральных государственных органов' fill="#ef8c62" />
            </BarChart>
          </div>
        </div>
      </div>
      <div className='statistics__dataBar2'>
        <div className='statistics__data-container'>
          <h3>По статусам проэкта</h3>
          <div className='statistics__dataBar2-content'>
            <BarChart
              width={1200}
              height={400}
              data={dataBar2}
              barGap={0}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="1 1" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="pv" name='Отправлено эксперту' fill="#2147a7" />
              <Bar dataKey="uv" name='Назначено' fill="#4abd9b" />
              <Bar dataKey="lv" name='Отказов' fill="#ef8c62" />
            </BarChart>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StatisticsContainerByProject