import MainLayout from "../../Components/Layout/MainLayout";
import ConclusionsContainer from "../../Components/pages/ConclusionsContainer";
import { ClerkNavBar } from "../../../Main/Services/http";
import Navbar from "../../Components/Navbar/Navbar";
import { useEffect, useState } from "react";
import { UserService } from "../../../Main/Services/UserService";
import { useTranslation } from "react-i18next";

export default function Conclusion() {
    const { t, i18n } = useTranslation("common")
    const [conclusions, setConclusions] = useState()
    const [conclusionStatuses, setConclusionStatuses] = useState()
    const [expertsList, setExpertsList] = useState()
    const [correctorsList, setCorrectorsList] = useState()
    const [translatorsList, setTranslatorsList] = useState()
    useEffect(() => {
        UserService.getConclusions()
            .then(response => {
                let arr = response.data
                setConclusions(arr);

            }).catch(err => console.log(err));
        UserService.getExperts()
            .then(response => {

                let arr = response.data.data.map(item => { return { value: item.id, label: item.full_name } })
                setExpertsList(arr);
            });
        UserService.getConclusionStatuses()
            .then(response => {
                let arr = response.data.data.map(item => { return { value: item.id, label: item.locales.name } })
                setConclusionStatuses(arr);

            })
        UserService.getCorrectors()
            .then(response => {

                let arr = response.data.data.map(item => { return { value: item.id, label: item.full_name } })

                setCorrectorsList(arr);
            });
        UserService.getTranslators()
            .then(response => {
                let arr = response.data.data.map(item => { return { value: item.id, label: item.full_name } })

                setTranslatorsList(arr);
            })
    }, [i18n]);

    return (
        <MainLayout >
            <Navbar direction={ClerkNavBar()} />
            {conclusions && expertsList && correctorsList && translatorsList && <ConclusionsContainer
                conclusions={conclusions}
                expertsList={expertsList}
                correctorsList={correctorsList}
                translatorsList={translatorsList}
                conclusionStatuses={conclusionStatuses}
            />}
        </MainLayout>
    )
}