import "../../Styles/Layout.css";
import TransmittalLettersContainer from "../../Components/pages/TransmittalLettersContainer";
import MainLayout from "../../Components/Layout/MainLayout";
import { useLocation } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import { CoordinatorNavBar } from "../../../Main/Services/http";
import { UserService } from "../../../Main/Services/UserService";
import { useEffect, useState } from "react";
import Preloader from "../../Components/Preloader/Preloader";

export default function TransmittalLettersPage() {

  const [branchOfLegislation, setBranchOfLegislation] = useState()
  const [transmittalLetters, setTransmittalLetters] = useState()
  const [documentTypes, setDocumentTypes] = useState()
  useEffect(() => {

    UserService.getTransmittalLetters()
      .then(response => {
        {
          setTransmittalLetters(response.data);
        }
      });
    // UserService.getBranchOfLegislation()
    //     .then(response => {
    //         let arr = response.data.data.map(item => { return { value: item.id, label: item.locales.name.ru } })
    //         setBranchOfLegislation(arr)
    //     });
    // UserService.getDocumentTypes()
    //     .then(response => {
    //         let arr = response.data.data.map(item => { return { value: item.id, label: item.locales.name.ru } })
    //         setDocumentTypes(arr);
    //     });
  }, [])

  const [playAnimation, setPlayAnimation] = useState(false)
  useEffect(() => {
    const onPageLoad = () => {
      setPlayAnimation(true);
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <MainLayout>
      <Preloader playAnimation={playAnimation} />

      <Navbar direction={CoordinatorNavBar()} />
      {transmittalLetters && <TransmittalLettersContainer
        transmittalLetters={transmittalLetters} />}
    </MainLayout>
  )
}