import React from 'react'
import { useLocation } from 'react-router-dom'
import './Statistics.scss'


const StatisticsContainerByExpertsDetails = () => {

    const expertData = useLocation().state
    
    return (
        <div className='statistics'>
            <h1>Детальная статистика по эксперту</h1>
            <div className='statistics__expertDetails'>
                <div className='statistics__data-container'>
                    <h3>Детальная статистика по эксперту</h3>
                    <div className='statistics__expertDetails-content'>
                        <h4>ФИО: <span>{expertData.name}</span></h4>
                        <p className='statistics__expertDetails-title'>
                            Специализации <br />
                            <span >{expertData.expertDetailsText}</span>
                        </p>
                        <p className='statistics__expertDetails-title'>Активность в системе: <span>{expertData.inActive ? 'Да' : 'Нет'}</span></p>
                        <p className='statistics__expertDetails-isData'>{expertData.projectData ? 'Данные' : 'Нет данных'}</p>
                        <table>
                            <thead>
                                <tr>
                                    <td>#</td>
                                    <td>Наименование коррупциогенной нормы	</td>
                                    <td>Общее количество рекомендаций (% от всех норм)	</td>
                                    <td>Принятые государственным органом</td>
                                </tr>
                            </thead>
                            {expertData.projectData &&
                                <tbody>
                                    <tr>
                                        <td>{expertData.projectData.id}</td>
                                        <td>{expertData.projectData.corruptionFormName}</td>
                                        <td>{expertData.projectData.recomendationsNumber}</td>
                                        <td>{expertData.projectData.acceptedByGov}</td>
                                    </tr>
                                </tbody>}
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StatisticsContainerByExpertsDetails