import { useLocation } from "react-router-dom";
import MainLayout from "../../Components/Layout/MainLayout";
import ReportsContainer from "../../Components/pages/ReportsContainer";
import { CoordinatorNavBar } from "../../../Main/Services/http";
import Navbar from "../../Components/Navbar/Navbar";
import Preloader from "../../Components/Preloader/Preloader";
import { useEffect, useState } from "react";
import { UserService } from '../../../Main/Services/UserService'

export default function Reports() {
  let num = useLocation().state
  const [authorityDevelopers, setAuthorityDevelopers] = useState()
  const [expertsList, setExpertsList] = useState()
  const [projectsReport, setProjectsReport] = useState()

  useEffect(() => {
    UserService.reports(1)
      .then(res => setProjectsReport(res.data))
      .catch(err => console.log(err))
    UserService.getExperts()
      .then(response => {
        let arr = response.data.data.map(item => { return { value: item.id, label: item.full_name } })
        setExpertsList(arr);
      });
    UserService.getAuthorityDevelopers()
      .then(response => {
        let arr = response.data.data.map(item => { return { value: item.id, label: item.locales.name.ru } })
        setAuthorityDevelopers(arr)
      });

  }, []);
  const [playAnimation, setPlayAnimation] = useState(false)
  useEffect(() => {
    const onPageLoad = () => {
      setPlayAnimation(true);
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <MainLayout isExpertRole num={num}>
      <Preloader playAnimation={playAnimation} />
      <Navbar direction={CoordinatorNavBar()} />
      {projectsReport && <ReportsContainer expertsList={expertsList} authorityDevelopers={authorityDevelopers} projectsReport={projectsReport} />}
    </MainLayout>
  )
}