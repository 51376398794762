import React, { useState } from "react";
import IconsContent from "../../../Assets/img/IconsContent";

const UserDetailsContainer = () => {
	const [isShowModal, setIsShowModal] = useState(false);
	const [isDeleted, setIsDeleted] = useState(false);
	const userDetails = [
		["E-mail", "mail@mail.ru"],
		["ФИО на русском", "Шаяхмет Гулшат Кайраткызы"],
		["ФИО на казахском", "Шаяхмет Гулшат Кайраткызы"],
		["ИИН", "880377854584"],
		["Примечание на русском", "Примечание на русском"],
		["Примечание на казахском", "Примечание на казахском"],
	];

	return (
		<div className="projects_page users__page">
			<h1>Пользователи внутри</h1>
			<main>
				<div className="users__page-container c-card">
					<div className="users__page-header">
						<p>
							{IconsContent.User("#4ABD9B")}
							<span>Шаяхмет Гүлшат Қайратқызы</span>
						</p>
						<p className="superadmin_user-action">
							<a onClick={() => setIsShowModal(true)}>
								{IconsContent.DeleteUser()}
							</a>
							<a href="/superadministrator/users/editUser">
								{IconsContent.EditUser()}
							</a>
						</p>
					</div>
					<p className="users__page-role">Переводчик</p>
					{userDetails.map((item) => (
						<div className="reports-details__content">
							<label className="superadmin_label">{item[0]}</label>
							<input
								className="superadmin_input-users"
								type="text"
								disabled
								value={item[1]}
							/>
						</div>
					))}
				</div>
			</main>
			{
				<div
					className={
						isShowModal
							? "transmittal-letters__modal transmittal-letters__modal-active"
							: "transmittal-letters__modal"
					}
					onClick={() => setIsShowModal(false)}
				>
					<div
						className="transmittal-letters__modal-content"
						onClick={(e) => e.stopPropagation()}
					>
						<p className="transmittal-letters__modal-heading">
							<span>Удаление пользователя</span>
							<span onClick={() => setIsShowModal(false)}>
								{IconsContent.Close()}
							</span>
						</p>
						{isDeleted ? (
							<h6>Пользователь [ФИО пользователя] успешно удален</h6>
						) : (
							<h6>Вы уверены что хотите удалить пользователя [ФИО]?</h6>
						)}
						<div className="btn_action btn_action-admin">
							{isDeleted ? null : (
								<button
									className="btn_finalize"
									onClick={() => setIsDeleted(true)}
								>
									Удалить
								</button>
							)}
							<button
								className="btn_assign"
								onClick={() => {
									setIsShowModal(false);
									setTimeout(() => {
										setIsDeleted(false);
									}, 300);
								}}
							>
								Закрыть
							</button>
						</div>
					</div>
				</div>
			}
		</div>
	);
};

export default UserDetailsContainer;
