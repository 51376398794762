import MainLayout from "../../Components/Layout/MainLayout";
import FormViewContainer from "../../Components/pages/FormViewContainer";
import { ClerkNavBar } from "../../../Main/Services/http";
import Navbar from "../../Components/Navbar/Navbar";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserService } from "../../../Main/Services/UserService";

export default function FormView() {
    let projectId = useLocation().pathname.replace(/[a-z\/]/g, '')
    const [project, setProject] = useState()
    useEffect(() => {
        UserService.getProject(projectId).then(response => {
            setProject(response.data.data);
        })
    }, []);
    return (
        <MainLayout>
            <Navbar direction={ClerkNavBar()} />
            {project && <FormViewContainer
                project={project} />}
        </MainLayout>
    )
}