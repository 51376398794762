import Navbar from "../../../Components/Navbar/Navbar";
import IconsContent from "../../../Assets/img/IconsContent";
import MainLayout from "../../../Components/Layout/MainLayout";
import StatisticsContainerByNPADevsDetails from "../../../Components/pages/Expert/StatisticsContainers/StatisticsContainerByNPADevsDetails";
import { useEffect, useState } from "react";
import Preloader from "../../../Components/Preloader/Preloader";
import { ExpertNavBar } from "../../../../Main/Services/http";
export default function StatisticsExpertsDetails(){
    const [playAnimation, setPlayAnimation] = useState(false)
    useEffect(() => {
	  const onPageLoad = () => {
		setPlayAnimation(true);
	  };
  
	  if (document.readyState === 'complete') {
		onPageLoad();
	  } else {
		window.addEventListener('load', onPageLoad);
		return () => window.removeEventListener('load', onPageLoad);
	  }
	}, []);
    
    return(
        <MainLayout>
            <Preloader playAnimation={playAnimation}/>
            <Navbar direction={ExpertNavBar()} />
            <StatisticsContainerByNPADevsDetails />
        </MainLayout>
    )
}
