import "../../Styles/Layout.css";
import UsersContainer from "../../Components/pages/Administrator/UsersContainer";
import MainLayout from "../../Components/Layout/MainLayout";
import Navbar from "../../Components/Navbar/Navbar";
import IconsContent from "../../Assets/img/IconsContent";
import { useState, useEffect } from "react";
import { UserService } from "../../../Main/Services/UserService";
import Preloader from "../../Components/Preloader/Preloader";
import { AdministratorkNavBar } from "../../../Main/Services/http";

export default function UsersCoordinator() {



  const [users, setUsers] = useState()

  useEffect(() => {
    UserService.getUsers()
      .then(response => {
        let arr = response.data
        // .map(item => { return { value: item.id, label: item.full_name } })

        setUsers(arr);
      });
  }, [])

  const [playAnimation, setPlayAnimation] = useState(false)
  useEffect(() => {
    const onPageLoad = () => {
      setPlayAnimation(true);
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);
  return (
    <MainLayout>
      <Preloader playAnimation={playAnimation} />
      <Navbar direction={AdministratorkNavBar()} />
      {users && <UsersContainer
        // correctorsList={correctorsList}
        // coordinatorsList={coordinatorsList}
        // clerksList={clerksList}
        // expertsList={expertsList}
        users={users}
      // translatorsList={translatorsList} 
      />}
    </MainLayout>
  )
}