import React, { useState, useRef, useEffect } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import IconsContent from '../../Assets/img/IconsContent';
import { registerLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import kk from 'date-fns/locale/kk';
import { Controller } from 'react-hook-form';
import moment from 'moment';
import { useTranslation } from "react-i18next";



const DatePickerInput = ({ name, control, noRequired, onClear }) => {
    const { t, i18n } = useTranslation("common")
    const selectInputRef = useRef();
    let [startDate, setStartDate] = useState();
    registerLocale(`kk`, kk)
    registerLocale(`ru`, ru)

    useEffect(() => {
        if (typeof onClear == 'boolean') {

            selectInputRef.current.clear()
        }
    }, [onClear]);


    return (
        <div className='calendar '>
            {noRequired ? <Controller
                control={control}
                name={name}
                render={({ field }) =>
                    <DatePicker
                        locale={i18n.language}
                        ref={selectInputRef}
                        onChange={(date) => {
                            setStartDate(date)
                            field.onChange(moment(date).format("YYYY-MM-DD"))
                            // field.onChange(moment(startDate).format("YYYY-MM-DD")) 
                        }}
                        placeholderText="Выберите"
                        dateFormat="yyyy-MM-dd"
                        selected={startDate}
                    />
                }
            /> :
                <Controller
                    control={control}
                    name={name}
                    rules={{ required: "Заполните обязательное поле" }}
                    render={({ field }) =>
                        <DatePicker
                            locale={i18n.language}
                            ref={selectInputRef}
                            onChange={(date) => {
                                setStartDate(date);
                                field.onChange(moment(date).format("YYYY-MM-DD"))
                            }}
                            placeholderText="Выберите"
                            dateFormat="yyyy-MM-dd"
                            selected={startDate}
                        />
                    }
                />
            }
            <span>{IconsContent.DatePickerCalendar()}</span>
        </div>
    )
}
export default DatePickerInput


export function DatePickerInputRange({ nameFrom, nameTill, control, onClear }) {
    const { t, i18n } = useTranslation("common")
    registerLocale(`kk`, kk)
    registerLocale(`ru`, ru)
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const selectInputRef = useRef();
    const selectInputRef2 = useRef();

    useEffect(() => {
        if (typeof onClear == 'boolean') {

            selectInputRef.current.clear()
            selectInputRef2.current.clear()
        }
    }, [onClear]);

    return (
        <div className="date-input__range">
            <p>с</p>
            <div className='calendar'>
                <Controller
                    control={control}
                    name={nameFrom}
                    // rules={{ required: "Заполните обязательное поле" }}
                    render={({ field }) =>
                        <DatePicker
                            ref={selectInputRef}
                            // placeholderText={moment(startDate).format("YYYY-MM-DD")}
                            onChange={(date) => { setStartDate(date); field.onChange(moment(date).format("YYYY-MM-DD")) }}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            dateFormat="yyyy-MM-dd"
                            selected={startDate}
                            placeholderText="Выберите"
                        />}
                />
                <span>{IconsContent.DatePickerCalendar()}</span>
            </div>
            <p>до</p>
            <div className='calendar'>
                <Controller
                    control={control}
                    name={nameTill}
                    // rules={{ required: "Заполните обязательное поле" }}
                    render={({ field }) =>
                        <DatePicker
                            ref={selectInputRef2}
                            placeholderText="Выберите"
                            onChange={(date) => { setEndDate(date); field.onChange(moment(date).format("YYYY-MM-DD")) }}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            dateFormat="yyyy-MM-dd"
                            selected={endDate}
                        // placeholderText={moment(endDate).format("YYYY-MM-DD")}
                        />}
                />
                <span>{IconsContent.DatePickerCalendar()}</span>
            </div>

        </div>
    )
}
