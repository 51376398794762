import { useLocation } from "react-router-dom";
import MainLayout from "../../Components/Layout/MainLayout";
import ReportsPricedContainer from "../../Components/pages/ReportsPricedContainer";
import { CoordinatorNavBar } from "../../../Main/Services/http";
import Navbar from "../../Components/Navbar/Navbar";
import Preloader from "../../Components/Preloader/Preloader";
import { useEffect, useState } from "react";
import { UserService } from '../../../Main/Services/UserService'

export default function Reports() {


  const [playAnimation, setPlayAnimation] = useState(false)
  useEffect(() => {
    const onPageLoad = () => {
      setPlayAnimation(true);
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);
  const [projects, setProjects] = useState()
  const [filteredPage, setFilteredPage] = useState(1)
  const [expertsList, setExpertsList] = useState()
  const [documentTypes, setDocumentTypes] = useState()
  const [authorityDevelopers, setAuthorityDevelopers] = useState()
  const [filteredData, setFilteredData] = useState('')


  useEffect(() => {
    UserService.getDocumentTypes()
      .then(response => {
        let arr = response.data.data.map(item => { return { value: item.id, label: item.locales.name.ru } })
        setDocumentTypes(arr);
      });

    UserService.getExperts()
      .then(response => {
        let arr = response.data.data.map(item => { return { value: item.id, label: item.full_name } })
        setExpertsList(arr);
      });
    UserService.getAuthorityDevelopers()
      .then(response => {
        let arr = response.data.data.map(item => { return { value: item.id, label: item.locales.name.ru } })
        setAuthorityDevelopers(arr)
      });
  }, []);
  useEffect(() => {
    UserService.getNPA(filteredPage, filteredData).then(res => {
      setProjects(res.data); console.log(res)
    }).catch(err => console.log(err))
  }, [filteredData, filteredPage])
  return (
    <MainLayout >
      <Preloader playAnimation={playAnimation} />
      <Navbar direction={CoordinatorNavBar()} />
      {projects && <ReportsPricedContainer
        projects={projects}
        setFilteredPage={setFilteredPage}
        filteredPage={filteredPage}
        expertsList={expertsList}
        documentTypes={documentTypes}
        authorityDevelopers={authorityDevelopers}
        setFilteredData={setFilteredData}
      />}
    </MainLayout>
  )
}