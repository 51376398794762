import SlideDownButton from "../../Button/SlideDownButton";


export default function FactorsContainer(){

    return(
        <div className={'FactorsPage'}>
            <h1>Факторы риска</h1>

            <main>
                <div className="factors__content">
                    <div className="content">
                        <SlideDownButton name={'Введение новых понятий, не определенных законодательством или проектом'} description={'Фактором коррупционных рисков является использование терминов, не определенных законодательством или текстом проекта нормативного правового акта, не имеющих широкое или распространенное определение, которое придавало бы им единый смысл, и может повлечь различное толкование этих терминов, которыми могут злоупотребить.'}/>
                        <SlideDownButton name={'Двоякая формулировка'} description={'Фактором коррупционных рисков является использование терминов, не определенных законодательством или текстом проекта нормативного правового акта, не имеющих широкое или распространенное определение, которое придавало бы им единый смысл, и может повлечь различное толкование этих терминов, которыми могут злоупотребить.'}/>
                        <SlideDownButton name={'Ошибочные отсылочные и бланкетные нормы'} description={'Фактором коррупционных рисков является использование терминов, не определенных законодательством или текстом проекта нормативного правового акта, не имеющих широкое или распространенное определение, которое придавало бы им единый смысл, и может повлечь различное толкование этих терминов, которыми могут злоупотребить.'}/>
                        <SlideDownButton name={'Коллизии норм права'} description={'Фактором коррупционных рисков является использование терминов, не определенных законодательством или текстом проекта нормативного правового акта, не имеющих широкое или распространенное определение, которое придавало бы им единый смысл, и может повлечь различное толкование этих терминов, которыми могут злоупотребить.'}/>
                        <SlideDownButton name={'Правовой пробел'} description={'Фактором коррупционных рисков является использование терминов, не определенных законодательством или текстом проекта нормативного правового акта, не имеющих широкое или распространенное определение, которое придавало бы им единый смысл, и может повлечь различное толкование этих терминов, которыми могут злоупотребить.'}/>
                        <SlideDownButton name={'Недостаточная прозрачность деятельности'} description={'Фактором коррупционных рисков является использование терминов, не определенных законодательством или текстом проекта нормативного правового акта, не имеющих широкое или распространенное определение, которое придавало бы им единый смысл, и может повлечь различное толкование этих терминов, которыми могут злоупотребить.'}/>
                        <SlideDownButton name={'Недостаточный доступ к информации, представляющей общественный интерес'} description={'Фактором коррупционных рисков является использование терминов, не определенных законодательством или текстом проекта нормативного правового акта, не имеющих широкое или распространенное определение, которое придавало бы им единый смысл, и может повлечь различное толкование этих терминов, которыми могут злоупотребить.'}/>
                        <SlideDownButton name={'Необоснованные затраты по отношению к общественной пользе'} description={'Фактором коррупционных рисков является использование терминов, не определенных законодательством или текстом проекта нормативного правового акта, не имеющих широкое или распространенное определение, которое придавало бы им единый смысл, и может повлечь различное толкование этих терминов, которыми могут злоупотребить.'}/>
                    </div>
                </div>
            </main>
        </div>
    )
}