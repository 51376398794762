import IconsContent from "../../Assets/img/IconsContent";
import AnimateHeight from "react-animate-height";
import {useState} from "react";

export default function SlideDownButton({name, description}){
    const [isClick, setIsClick] = useState(false);
    const [height, setHeight] = useState(0);

    return(
        <div className={`type_risk ${isClick ?'active': null}`}>
            <div className={`header `}
                 aria-expanded={height !== 0}
                 aria-controls="example-panel"
                 onClick={() => {
                     setHeight(height === 0 ? 'auto' : 0)
                     setIsClick(!isClick)
                 }}>
                <p>{name}</p>
                {IconsContent.ArrUp(`${isClick ? `#4ABD9B` : `#0D152C`}`)}
            </div>
            <AnimateHeight
                id="body"
                className={'body'}
                duration={500}
                height={height} // see props documentation below
            >
                <p>{description}</p>
            </AnimateHeight>
        </div>
    )
}