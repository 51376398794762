import "../../Styles/Layout.css";
import UserDetailsContainer from "../../Components/pages/Administrator/UserDetailsContainer";
import MainLayout from "../../Components/Layout/MainLayout";
import Navbar from "../../Components/Navbar/Navbar";
import IconsContent from "../../Assets/img/IconsContent";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { UserService } from "../../../Main/Services/UserService";
import Preloader from "../../Components/Preloader/Preloader";
import { AdministratorkNavBar } from "../../../Main/Services/http";

export default function UserDetailsCoordinator() {
  let userId = useLocation().pathname.replace(/[a-z\/]/g, '')
  console.log(userId)
  const [userData, setUserData] = useState()

  useEffect(() => {
    UserService.getUser(userId)
      .then(response => {
        let arr = response.data.data

        setUserData(arr);
      })

  }, []);
  const [playAnimation, setPlayAnimation] = useState(false)
  useEffect(() => {
    const onPageLoad = () => {
      setPlayAnimation(true);
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <MainLayout>
      <Preloader playAnimation={playAnimation} />
      <Navbar direction={AdministratorkNavBar()} />
      <UserDetailsContainer
        userData={userData}
      />
    </MainLayout>
  )
}