import IconsContent from "../../../Assets/img/IconsContent";
import { useEffect, useState } from "react";
import iconsContent from "../../../Assets/img/IconsContent";
import { Link, useNavigate } from "react-router-dom";
import Select from 'react-select';
import TableFirst from "../../TableFirst/TableFirst";
import { UserService } from "../../../../Main/Services/UserService";
import { useForm } from 'react-hook-form'
import { useTranslation } from "react-i18next";
import Pagination from '@mui/material/Pagination';


export default function UsersContainer({ users }) {
    console.log(users)
    const [isFilter, setIsFilter] = useState(false)
    const [usersFiltered, setUsersFiltered] = useState()
    const [filterSideMenu, setFilterSideMenu] = useState(0)
    const [filteredData, setFilteredData] = useState('')
    const [filteredDataAll, setFilteredDataAll] = useState([])
    const [filteredDataCoordinators, setFilteredDataCoordinators] = useState([])
    const [filteredDataCorrectors, setFilteredDataCorrectors] = useState([])
    const [filteredDataTranslators, setFilteredDataTranslators] = useState([])
    const [filteredDataExperts, setFilteredDataExperts] = useState([])
    const [filteredDataClerks, setFilteredDataClerks] = useState([])
    const [filteredPage, setFilteredPage] = useState(1)
    const [isShowTableData, setIsShowTableData] = useState()
    const { t, i18n } = useTranslation("common");

    const { register, handleSubmit, control, reset, formState: { errors } } = useForm({ mode: 'onBlur' })
    const onSubmit = (filteredData) => {

        //---React-Hook object converting to URL string
        let arrFilterItems = Object.keys(filteredData).map((item, index) => {
            if (Object.values(filteredData)[index] !== '' && Object.values(filteredData)[index] !== undefined && Object.values(filteredData)[index] !== "Invalid date") {
                return `filter[${item}]=${Object.values(filteredData)[index]}`
            }
        })
            .filter(item => item !== undefined)
        console.log(arrFilterItems)
        let newArr = arrFilterItems.map((item, index) => index == arrFilterItems.length - 1 ? `${item}` : `${item}&`)
        console.log(newArr.toString().replace(/[,]/gi, ''))
        //---------------------------------------------

        // UserService.getFilteredData(newArr.toString().replace(/[,]/gi, ''))
        setFilteredData(newArr.toString().replace(/[,]/gi, ''))
        setFilteredPage(1)
        // UserService.getFilteredData(filteredPage,newArr.toString().replace(/[,]/gi, ''))
        //     .then(response => {
        //         
        //         setProjectsFiltered(response.data)
        //     })
        // reset()
    }

    useEffect(() => {
        UserService.getUsersFiltered(filteredPage, filteredData)
            .then(response => {

                setFilteredDataAll(response.data);
                // setProjectsFiltered(response.data)
                if (filterSideMenu == 0) {
                    setUsersFiltered(response.data)
                }
            })
    }, [filteredPage, filteredData])

    useEffect(() => {
        UserService.getCoordinators(filteredPage, filteredData)
            .then(response => {

                setFilteredDataCoordinators(response.data);
                if (filterSideMenu == 1) {
                    setUsersFiltered(response.data)
                }
            })
    }, [filteredPage, filteredData])

    useEffect(() => {
        UserService.getTranslators(filteredPage, filteredData)
            .then(response => {

                setFilteredDataTranslators(response.data);
                if (filterSideMenu == 2) {
                    setUsersFiltered(response.data)
                }
            })
    }, [filteredPage, filteredData])

    useEffect(() => {
        UserService.getClerks(filteredPage, filteredData)
            .then(response => {

                setFilteredDataClerks(response.data);
                if (filterSideMenu == 3) {
                    setUsersFiltered(response.data)
                }
            })
    }, [filteredPage, filteredData])

    useEffect(() => {
        UserService.getExperts(filteredPage, filteredData)
            .then(response => {

                setFilteredDataExperts(response.data);
                if (filterSideMenu == 4) {
                    setUsersFiltered(response.data)
                }
            })
    }, [filteredPage, filteredData])
    useEffect(() => {
        UserService.getCorrectors(filteredPage, filteredData)
            .then(response => {

                setFilteredDataCorrectors(response.data);
                if (filterSideMenu == 5) {
                    setUsersFiltered(response.data)
                }
            })
    }, [filteredPage, filteredData])

    return (
        <div className={'conclusion__coordinator users__page projects_page'}>
            <div className="header_wrapper_name">
                <h1>Пользователи</h1>
                <Link to={'/administrator/addUser'}><a className={'add_project'}><span>{t("add_user")}</span> {IconsContent.AddProject()}</a></Link>
            </div>
            <main className="main__page">
                <div className="main__page--left">
                    <p className={filterSideMenu == 0 ? "main__page--active" : null} onClick={() => { setFilterSideMenu(0); setFilteredPage(1); setUsersFiltered(filteredDataAll) }}>{IconsContent.Users()}{t("all_users")}<span>{filteredDataAll?.meta?.total}</span></p>
                    <p className={filterSideMenu == 1 ? "main__page--active" : null} onClick={() => { setFilterSideMenu(1); setFilteredPage(1); setUsersFiltered(filteredDataCoordinators) }}>{IconsContent.Users()}{t("coordinator")}<span>{filteredDataCoordinators?.meta?.total}</span></p>
                    <p className={filterSideMenu == 2 ? "main__page--active" : null} onClick={() => { setFilterSideMenu(2); setFilteredPage(1); setUsersFiltered(filteredDataTranslators) }}>{IconsContent.Users()}{t("translator")}<span>{filteredDataTranslators?.meta?.total}</span></p>
                    <p className={filterSideMenu == 3 ? "main__page--active" : null} onClick={() => { setFilterSideMenu(3); setFilteredPage(1); setUsersFiltered(filteredDataClerks) }}>{IconsContent.Users()}{t("clerk")}<span>{filteredDataClerks?.meta?.total}</span></p>
                    <p className={filterSideMenu == 4 ? "main__page--active" : null} onClick={() => { setFilterSideMenu(4); setFilteredPage(1); setUsersFiltered(filteredDataExperts) }}>{IconsContent.Users()}{t("expert")}<span>{filteredDataExperts?.meta?.total}</span></p>
                    <p className={filterSideMenu == 5 ? "main__page--active" : null} onClick={() => { setFilterSideMenu(5); setFilteredPage(1); setUsersFiltered(filteredDataCorrectors) }}>{IconsContent.Users()}{t("corrector")}<span>{filteredDataCorrectors?.meta?.total}</span></p>
                </div>
                <div className="main__page--right">
                    <div className="main_header">
                        <button className={'btn_filter'} onClick={() => { setIsFilter(true) }}>
                            <span className="name">Фильтр</span>
                            {IconsContent.Filter()}
                        </button>
                        <div className={"btn_search"}>
                            {IconsContent.Search()}
                            <input type="email" placeholder={t("search")} />
                        </div>
                    </div>
                    <div className="table_wrapper">

                        <table className={'table_styling'}>
                            <thead >
                                <tr className={'table_heading'}>
                                    <TableFirst />
                                    <td>E-MAIL</td>
                                    <td>ФИО</td>
                                    <td colSpan={2}>ИИН</td>
                                    <td className="table_transparent">2022-07-05 00:00:00</td>
                                    <td className="table_transparent">2022-07-05 00:00:00</td>
                                    <td className="last"></td>
                                </tr>
                            </thead>
                            {usersFiltered && usersFiltered.data.map((res, index) => (
                                <tbody key={index}>
                                    <tr className={'table_body '} style={{ backgroundColor: `${index % 2 === 1 ? `#F9F9F9` : `inherit`}` }}>
                                        <td>{res?.id}</td>
                                        <td >{res?.email}</td>
                                        <td>{res?.full_name}</td>
                                        <td>{res?.tin}</td>
                                        <td className='table_status'>
                                            <p>
                                                <span onClick={() => setIsShowTableData(isShowTableData == index ? null : index)}>{IconsContent.ArrowDownGreen()}</span>
                                            </p>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td className={'li_a'}>
                                            <Link to={`/administrator/user/details/${res?.id}`} state={45456}>{IconsContent.Eye()}</Link>

                                        </td>
                                    </tr>
                                    <tr className={isShowTableData == index ? 'table_body table_folding' : 'none'}>
                                        <td colSpan={8}>
                                            <div>
                                                <p>
                                                    <span>Телефон</span>
                                                    <span>{res?.phone}</span>
                                                </p>
                                                <p>
                                                    <span>Должность (на русском)</span>
                                                    <span>{res.job_title}</span>
                                                </p>
                                                <p>
                                                    <span>Примечание (на русском)</span>
                                                    <span>{res?.note}</span>
                                                </p>
                                                <p>
                                                    <span>Дата рождения</span>
                                                    <span>{res?.birthDate}</span>
                                                </p>
                                                <p>
                                                    <span>Изменен</span>
                                                    <span>{res?.changedName}</span>
                                                </p>
                                                <p>
                                                    <span>Создано</span>
                                                    <span>{res?.createdAt}</span>
                                                </p>
                                                <p>
                                                    <span>Изменено</span>
                                                    <span>{res?.changedAt}</span>
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>)
                            )}

                        </table>
                        <div className="table_wrapper_footer">
                            <div className="footer_number">
                                {usersFiltered && <p>Найдено {usersFiltered.meta.total} из {users.meta?.total} </p>}
                            </div>
                            <div className="footer_pagination">
                                {usersFiltered && usersFiltered.meta.total > users.meta.per_page ?
                                    <Pagination count={Math.ceil(usersFiltered.meta.total / usersFiltered.meta?.per_page)} page={filteredPage} onChange={(event, value) => setFilteredPage(value)} variant="outlined" shape="rounded" />
                                    : null}
                                {/* <button className={'btn_pagination '}>1</button>
                                <button className={'btn_pagination'}>2</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </main>


            <div className={`modal_filter ${isFilter ? 'active' : ''}`}>
                <div className={`modal_content `}>
                    <div className="modal_header">
                        <div className="header_word">
                            <h5>Фильтр</h5>
                            {IconsContent.Filter('#4ABD9B', `2`)}
                        </div>
                        <button onClick={() => setIsFilter(false)}>{iconsContent.Cancel()}</button>
                    </div>
                    <div className="modal_form">
                        <form action="">
                            <div className="input_wrapper">
                                <label htmlFor="">ID</label>
                                <input type="text" />
                            </div>
                            <div className="input_wrapper">
                                <label htmlFor="">E-mail</label>
                                <input type="text" />
                            </div>
                            <div className="input_wrapper">
                                <label htmlFor="">ФИО (на русском)</label>
                                <input type="text" />
                            </div>
                            <div className="input_wrapper">
                                <label htmlFor="">ИИН</label>
                                <input type="text" />
                            </div>
                            <div className="input_wrapper">
                                <label htmlFor="">Должность (на русском)</label>
                                <input type="text" />
                            </div>
                            <div className="input_wrapper">
                                <label htmlFor="">Организация (на русском)</label>
                                <input type="text" />
                            </div>
                            <div className="input_wrapper ender">
                                <label htmlFor="">Активность</label>
                                <Select options={[{ value: 'point1', label: 'Пункт1' }, { value: 'point2', label: 'Пункт2' }]} classNamePrefix="custom-select" placeholder="Да" />
                            </div>

                            <button className={'submit'}>Применить</button>
                            <button className={'cancel'}>Сбросить</button>
                        </form>
                    </div>
                </div>
                <div className="modal_cover" onClick={() => setIsFilter(false)} />
            </div>

        </div>
    )
}
