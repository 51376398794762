import React from 'react'
import { useState, useEffect } from "react";
import { useForm, Controller } from 'react-hook-form'
import { UserService } from "../../../Main/Services/UserService";
import Select from "react-select";
import IconsContent from '../../Assets/img/IconsContent';
import { InputSelect } from "../Select/InputSelect";
import DatePickerInput from "../DatePicker/DatePicker";
import Preloader from "../Preloader/Preloader";

const ModalAddDataAcceptedNPA = ({ projectId, setNewPlayAnimation, isShowModalAddNPAData, setIsShowModalAddNPAData, setIsExpertAdded, setIsShowModalError }) => {



    const { register, reset, control, handleSubmit, formState: { errors } } = useForm({ mode: 'onBlur' })

    const onSubmit = (reportData) => {
        reportData = { ...reportData, project_id: projectId }
        setNewPlayAnimation(true)
        UserService.addReport(reportData)
            .then(response => {

                setNewPlayAnimation(false)
                setIsShowModalAddNPAData(false)
                setIsExpertAdded(true)
            }).catch(err => { console.log(err); setNewPlayAnimation(false); setIsShowModalError(true) });
    }

    return (
        <>
            <div className={isShowModalAddNPAData ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                <div className="transmittal-letters__modal-content transmittal-letters__form" >
                    <div className="transmittal-letters__wrapper">
                        <p className="transmittal-letters__modal-heading">
                            <span>Указать данные принятого НПА</span>
                            <span onClick={() => setIsShowModalAddNPAData(false)}>{IconsContent.Close()}</span>
                        </p>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="input_wrapper ">
                                <label htmlFor="">Ссылка на НПА</label>
                                <input type="text" {...register("npa_link", { required: "Заполните обязательное поле" })} />
                                {errors.npa_link && <p className="form--required">{errors.npa_link.message}</p>}
                            </div>

                            <div className="input_wrapper input_wrapper-addProject">
                                <label htmlFor="">Номер принятого НПА</label>
                                <input type="text" {...register("accepted_npa_number", { required: "Заполните обязательное поле" })} />
                                {errors.accepted_npa_number && <p className="form--required">{errors.accepted_npa_number.message}</p>}
                            </div>
                            <div className="input_wrapper input_wrapper-addProject">
                                <label htmlFor="">Наименование принятого НПА (на казахском)</label>
                                <input type="text" {...register("accepted_npa_name_kk", { required: "Заполните обязательное поле" })} />
                                {errors.npa_number && <p className="form--required">{errors.npa_number.message}</p>}
                            </div>
                            <div className="input_wrapper input_wrapper-addProject">
                                <label htmlFor="">Наименование принятого НПА (на русском)</label>
                                <input type="text" {...register("accepted_npa_name_ru", { required: "Заполните обязательное поле" })} />
                                {errors.accepted_npa_name_ru && <p className="form--required">{errors.accepted_npa_name_ru.message}</p>}
                            </div>

                            <div className="input_wrapper addProject_page">
                                <label htmlFor="">Дата принятия</label>
                                <DatePickerInput name='date_of_adoption' control={control} />
                                {errors.date_of_adoption && <p className="form--required">{errors.date_of_adoption.message}</p>}
                            </div>

                            <div className="btn_action" >
                                <button className="btn_assign">Добавить</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ModalAddDataAcceptedNPA