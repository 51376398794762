import React, { useState } from "react";
import styles from "./styles.module.scss"

const Preloader = ({ playAnimation }) => {
  const [hidden, setHidden] = useState(false)
  if (playAnimation) {
    setTimeout(() => {
      setHidden(true)
    }, 1000);
  }

  return (
    <div className={`${styles.pageLoader} ${playAnimation ? styles.close : ''} ${hidden ? styles.hidden : ''}`}>
      <div />
    </div>
  )
}

export default Preloader

