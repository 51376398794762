import { Link } from "react-router-dom";

export default function NavigationContent({ direct, name, ai }) {
    const directions = direct.map((element, index) => index !== (direct.length - 1) ? <Link to={element?.goTo}>{element.name} /</Link> : <p>{element.name}</p>)

    return (
        <div className="direction">
            <div className="directions">
                {directions}
            </div>
            <div style={{display:"flex", justifyContent:"space-between"}}>
                <h1>{name}</h1>
                {ai}
            </div>
        </div>
    )
}