import MainLayout from "../../Components/Layout/MainLayout";
import IconsContent from "../../Assets/img/IconsContent";
import Navbar from "../../Components/Navbar/Navbar";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import { UserService } from "../../../Main/Services/UserService";
import ConclusionDetailsContainer from '../../Components/pages/ConclusionDetailsContainer'
import Preloader from "../../Components/Preloader/Preloader";

export default function FormViewDetail() {
    const [conclusion, setConclusion] = useState(null)
    let getIdDate = useLocation().pathname.replace(/[a-z\/]/g, '')

    useEffect(() => {
        UserService.getConclusion(getIdDate)
            .then(response => {
                let arr = response.data.data
                setConclusion(arr);

            }).catch(err => console.log(err));

    }, []);
    const [playAnimation, setPlayAnimation] = useState(false)
    useEffect(() => {
        const onPageLoad = () => {
            setPlayAnimation(true);
        };

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    return (
        <MainLayout>
            <Preloader playAnimation={playAnimation} />
            <Navbar direction={[
                {
                    name: 'Проекты',
                    goTo: '/translator',
                    iCon: IconsContent.BriefCase()
                },
                {
                    name: 'Заключения',
                    goTo: '/translator/conclusions',
                    iCon: IconsContent.ClipBoard()
                },
                {
                    name: 'Факторы риска',
                    goTo: '/translator/factors',
                    iCon: IconsContent.AlertCircle()
                },
            ]
            } />
            {conclusion &&
                <ConclusionDetailsContainer conclusion={conclusion} />
            }
        </MainLayout>
    )
}