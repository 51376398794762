import "../../Styles/Layout.css";
import ConclusionDetailsContainer from "../../Components/pages/ConclusionDetailsContainer";
import MainLayout from "../../Components/Layout/MainLayout";
import { useLocation } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import IconsContent from "../../Assets/img/IconsContent";
import { useEffect, useState } from "react";
import Preloader from "../../Components/Preloader/Preloader";
import { AdministratorkNavBar } from "../../../Main/Services/http";
import { UserService } from "../../../Main/Services/UserService";

export default function ConclusionCoordinator() {

  const [playAnimation, setPlayAnimation] = useState(false)
  let conclusionId = useLocation().pathname.replace(/[a-z\/]/g, '')
  const [conclusion, setConclusion] = useState()

  useEffect(() => {
    UserService.getConclusion(conclusionId)
      .then(response => {
        let arr = response.data.data
        setConclusion(arr);

      })
  }, []);

  useEffect(() => {
    const onPageLoad = () => {
      setPlayAnimation(true);
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <MainLayout>
      <Preloader playAnimation={playAnimation} />
      <Navbar direction={AdministratorkNavBar()} />
      {conclusion && <ConclusionDetailsContainer conclusion={conclusion} />}
    </MainLayout>
  )
}