import React from 'react'
import { useForm } from 'react-hook-form'
import IconsContent from "../../../Assets/img/IconsContent";
import { useState, useEffect } from "react";
import AnimateHeight from 'react-animate-height';
import { UserService } from '../../../../Main/Services/UserService';
import { useTranslation } from "react-i18next";



const AnimatedHeightAi = ({ id, risk_id, risk_factor_name, article, reason, submit }) => {
    const [height, setHeight] = useState(0);
    const [textComment, setTextComment] = useState('')
    const [agree, setAgree] = useState(true)
    console.log(agree)

    useEffect(() => {
        submit && UserService.putLowAnalys(id, { agree: agree, comment: textComment })
    }, [submit])

    return (
        <>
            <button type="button" aria-expanded={height !== 0} aria-controls="example-panel" onClick={() => setHeight(height === 0 ? 'auto' : 0)}>
                <span>Фактор риска № {risk_id}: {risk_factor_name}</span>
                {height === 0 ? IconsContent.ArrDown() : IconsContent.ArrUp()}
            </button>
            <AnimateHeight id="example-panel" duration={500} height={height} >
                <h3 className='ai__details--header'>Статья</h3>
                <p className='ai__details--content'>{article}</p>
                <h3 className='ai__details--header'> Причина: </h3>
                <p className='ai__details--content'>{reason}</p>
                <h3 className='ai__details--header'>Комментарии</h3>
                <textarea style={{ resize: 'none' }} onChange={(e) => setTextComment(e.target.value)}></textarea>
                <div className='ai__voiting'>
                    <p>
                        <input type="radio" id="contactChoice1" name={`check${id}`} onClick={() => setAgree(true)} defaultChecked={true} />
                        <label for="contactChoice2">Согласен</label>
                    </p>
                    <p>
                        <input type="radio" id="contactChoice2" name={`check${id}`} onClick={() => setAgree(false)} />
                        <label for="contactChoice2">Не согласен</label>
                    </p>
                </div>
            </AnimateHeight>
        </>
    )
}

const AiForm = ({ isShowModalAi, setIsShowModalAi, comments }) => {
    const [submit, setSubmit] = useState(false)
    const { t, i18n } = useTranslation("common")
    return (
        <div className={isShowModalAi ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal "}>
            <div className="transmittal-letters__modal-content transmittal-letters__form" style={{ padding: '20px 5px', width: "800px" }}>
                <div className="transmittal-letters__wrapper">
                    <p className="transmittal-letters__modal-heading">
                        <span>Факторы рисков</span>
                        <span onClick={() => setIsShowModalAi(false)}>{IconsContent.Close()}</span>
                    </p>
                    <form className={'form_catcher-expert'}>
                        {comments && comments.map((item, index) => {
                            return (
                                <AnimatedHeightAi
                                    key={index}
                                    id={item?.id}
                                    risk_id={item?.risk_factor_id?.id}
                                    risk_factor_name={item?.risk_factor_id?.locales?.name[i18n.language]}
                                    article={item?.clause_text}
                                    reason={item?.reason}
                                    submit={submit}
                                />)
                        })}
                        <div className="save">
                            <button type='button' className="save_draft" onClick={() => {
                                setSubmit(!submit);
                                // window.location.reload()
                            }
                            }>Сохранить</button>
                            <button type='button' className="save_push" onClick={() => setIsShowModalAi(false)} >Закрыть</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AiForm

