import MainLayout from "../../Components/Layout/MainLayout";
import FormViewContainer from "../../Components/pages/FormViewContainer";
import IconsContent from "../../Assets/img/IconsContent";
import Navbar from "../../Components/Navbar/Navbar";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserService } from "../../../Main/Services/UserService";
import Preloader from "../../Components/Preloader/Preloader";

export default function FormView() {
    let projectId = useLocation().pathname.replace(/[a-z\/]/g, '')
    const [project, setProject] = useState()
    useEffect(() => {
        UserService.getProject(projectId).then(response => {
            setProject(response.data.data); console.log(project)
        })
    }, []);
    const [playAnimation, setPlayAnimation] = useState(false)
    useEffect(() => {
        const onPageLoad = () => {
            setPlayAnimation(true);
        };

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    return (
        <MainLayout>
            <Preloader playAnimation={playAnimation} />
            <Navbar direction={[
                {
                    name: 'Проекты',
                    goTo: '/translator',
                    iCon: IconsContent.BriefCase()
                },
                {
                    name: 'Заключения',
                    goTo: '/translator/conclusions',
                    iCon: IconsContent.ClipBoard()
                },
                {
                    name: 'Факторы риска',
                    goTo: '/translator/factors',
                    iCon: IconsContent.AlertCircle()
                },
            ]
            } />
            {project && <FormViewContainer
                project={project} />}
        </MainLayout>
    )
}