import '../../Styles/LoginPage.css'
import Language from "../../Components/Language/Language";
import AuthForm from "../../Components/AuthForm/AuthForm";
import {useTranslation} from "react-i18next";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Preloader from '../../Components/Preloader/Preloader';


function LoginPage(){
    const [playAnimation, setPlayAnimation] = useState(false)

	useEffect(() => {
	  const onPageLoad = () => {
		setPlayAnimation(true);
	  };
  
	  if (document.readyState === 'complete') {
		onPageLoad();
	  } else {
		window.addEventListener('load', onPageLoad);
		return () => window.removeEventListener('load', onPageLoad);
	  }
	}, []);

    const {t, i18n} = useTranslation('login')
    const navigate = useNavigate();
    let userLocation = localStorage.getItem('.UserLocation')

   useEffect(()=>{
        if(userLocation !== null){
            navigate(userLocation)
        } 
   },[])
    return(
        <div className={'loginPage_wrapper'}>
            <Preloader playAnimation={playAnimation}/>

            <div className="login_container">
                <Link to="/home"><img src="./img/Logo.webp" alt="Logo Anticor" className={'img_logo'}/></Link>
                <Language />
                <AuthForm />
                {/* <Link to='/corrector'>{t('register')}</Link> */}
            </div>
            <div className={'cover'} />
        </div>
    )
}

export default LoginPage