import '../../Styles/LoginPage.css'
import Language from "../../Components/Language/Language";
import AuthForm from "../../Components/AuthForm/AuthForm";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Preloader from '../../Components/Preloader/Preloader';
import { useEffect, useState } from 'react';

function CoordinatorWelcomePage(){

    const {t, i18n} = useTranslation('login')

    const [playAnimation, setPlayAnimation] = useState(false)
    useEffect(() => {
	  const onPageLoad = () => {
		setPlayAnimation(true);
	  };
  
	  if (document.readyState === 'complete') {
		onPageLoad();
	  } else {
		window.addEventListener('load', onPageLoad);
		return () => window.removeEventListener('load', onPageLoad);
	  }
	}, []);
    
    return(
        <div className={'loginPage_wrapper'}>
            <Preloader playAnimation={playAnimation}/>
            <div className="welcome_conatainer">
                <img src="/img/qr.png" alt="Qr" className={'img_logo'}/>
                <h1>Чтобы авторизоваться в системе наведите на QR код</h1>
            </div>
            <div className={'cover'} />
        </div>
    )
}

export default CoordinatorWelcomePage