import "../../Styles/Layout.css";
import ProjectsContainerEconomist from "../../Components/pages/ProjectsContainerEconomist";
import MainLayout from "../../Components/Layout/MainLayout";
import Navbar from "../../Components/Navbar/Navbar";
import { EconomistNavBar } from "../../../Main/Services/http";
import { UserService } from "../../../Main/Services/UserService";
import { useEffect, useState } from "react";
import Preloader from "../../Components/Preloader/Preloader";

export default function CoordinatorIndexPage() {

    const [projects, setProjects] = useState()
    const [npaData, setNpaData] = useState()
    const [filteredPage, setFilteredPage] = useState(1)
    const [expertsList, setExpertsList] = useState()
    const [documentTypes, setDocumentTypes] = useState()
    const [authorityDevelopers, setAuthorityDevelopers] = useState()
    const [filteredData, setFilteredData] = useState('')

    useEffect(() => {
        UserService.getDocumentTypes()
            .then(response => {
                let arr = response.data.data.map(item => { return { value: item.id, label: item.locales.name.ru } })
                setDocumentTypes(arr);
            });

        UserService.getExperts()
            .then(response => {
                let arr = response.data.data.map(item => { return { value: item.id, label: item.full_name } })
                setExpertsList(arr);
            });
        UserService.getAuthorityDevelopers()
            .then(response => {
                let arr = response.data.data.map(item => { return { value: item.id, label: item.locales.name.ru } })
                setAuthorityDevelopers(arr)
            });
        UserService.getNPA2()
            .then(response => {
                setNpaData(response.data);
            });

    }, []);
    useEffect(() => {
        UserService.getNPA(filteredPage, filteredData).then(res => {
            setProjects(res.data); console.log(res)
        }).catch(err => console.log(err))
    }, [filteredData, filteredPage])

    const [playAnimation, setPlayAnimation] = useState(false)

    useEffect(() => {
        const onPageLoad = () => {
            setPlayAnimation(true);
        };

        if (document.readyState === 'complete') {
            setTimeout(() => {
                onPageLoad();
            }, 2000);
        } else {
            window.addEventListener('load', onPageLoad);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    return (
        <MainLayout>
            <Preloader playAnimation={playAnimation} />

            <Navbar direction={EconomistNavBar()} />
            {projects &&
                // branchOfLegislation && projectStatuses && documentTypes && authorityDevelopers && coordinatorsList && expertsList && 
                <ProjectsContainerEconomist
                    projects={projects}
                    setFilteredPage={setFilteredPage}
                    filteredPage={filteredPage}
                    expertsList={expertsList}
                    documentTypes={documentTypes}
                    authorityDevelopers={authorityDevelopers}
                    setFilteredData={setFilteredData}
                    npaData={npaData}
                />}
        </MainLayout>
    )
}