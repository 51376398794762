// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Language_LanguageComponent__y5unb{\n    display: flex;\n    gap: 22px;\n    margin-bottom: 30px;\n}\n\nbutton{\n    font-size: 16px;\n    line-height: 19px;\n    color: #FFFFFF;\n    cursor: pointer;\n}\n\nbutton.Language_active__ejQMX{\n    color: #4ABD9B;\n}", "",{"version":3,"sources":["webpack://./src/Presentation/Components/Language/Language.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".LanguageComponent{\n    display: flex;\n    gap: 22px;\n    margin-bottom: 30px;\n}\n\nbutton{\n    font-size: 16px;\n    line-height: 19px;\n    color: #FFFFFF;\n    cursor: pointer;\n}\n\nbutton.active{\n    color: #4ABD9B;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LanguageComponent": "Language_LanguageComponent__y5unb",
	"active": "Language_active__ejQMX"
};
export default ___CSS_LOADER_EXPORT___;
