export default function Email() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={'form_svg'}>
            <g clip-path="url(#clip0_1_52)">
                <path
                    d="M9.99996 13.3333C11.8409 13.3333 13.3333 11.8409 13.3333 10C13.3333 8.15905 11.8409 6.66667 9.99996 6.66667C8.15901 6.66667 6.66663 8.15905 6.66663 10C6.66663 11.8409 8.15901 13.3333 9.99996 13.3333Z"
                    stroke="#4ABD9B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M13.3333 6.66667V10.8333C13.3333 11.4964 13.5967 12.1323 14.0655 12.6011C14.5344 13.0699 15.1703 13.3333 15.8333 13.3333C16.4963 13.3333 17.1322 13.0699 17.6011 12.6011C18.0699 12.1323 18.3333 11.4964 18.3333 10.8333V10C18.3332 8.11919 17.6968 6.29373 16.5277 4.82044C15.3586 3.34715 13.7254 2.31269 11.8938 1.88525C10.0622 1.45781 8.13991 1.66253 6.43942 2.46614C4.73894 3.26974 3.3603 4.62496 2.52768 6.31144C1.69507 7.99791 1.45744 9.91645 1.85344 11.7551C2.24944 13.5937 3.25578 15.2444 4.70883 16.4386C6.16187 17.6328 7.97616 18.3003 9.85669 18.3326C11.7372 18.365 13.5734 17.7602 15.0666 16.6167"
                    stroke="#4ABD9B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_1_52">
                    <rect width="20" height="20" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}
