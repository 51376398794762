import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import IconsContent from "../../../Assets/img/IconsContent";
import TableFirst from "../../TableFirst/TableFirst";
import DatePickerInput from "../../DatePicker/DatePicker";
import Select from "react-select";
import Pagination from "../../Pagination/Pagination";

export default function ProjectsContainer() {
	const [fullNumber, setFullNumber] = useState(14435);
	const [filteredNumber, setFilteredNumber] = useState(24);
	const [isFilter, setIsFilter] = useState(false);
	const [isShowFilter, setIsShowFilter] = useState(false);
	const [isShowModal, setIsShowModal] = useState(-1);
	const [isShowRestoreModal, setIsShowRestoreModal] = useState(-1);
	const [isDeleted, setIsDeleted] = useState([]);

	const [currentPage, setCurrentPage] = useState(1);
	const [recordsPerPage] = useState(2);

	const options = [
		{ value: "new", label: "Новый" },
		{ value: "sentToExpert", label: "Отправлен эксперту" },
		{ value: "acceptedByExpert", label: "Принято экспертом" },
		{ value: "preparedPZ", label: "Подготовленно ПЗ" },
		{ value: "sentToCorrector", label: "Отправлено корректору" },
		{ value: "onCorrectingByExpert", label: "На корректировке у эксперта" },
		{ value: "sentToTranslater", label: "Отправленно переводчику" },
		{ value: "sentTranslation", label: "Отправлен перевод" },
		{ value: "rejectedByExpert", label: "Отклонен экспертом" },
		{ value: "conclusionSent", label: "Заключение отправлено" },
	];

	useEffect(() => {
		setTimeout(() => {
			setIsShowFilter(isFilter);
		}, 1);
	}, [isFilter]);

	useEffect(() => {
		if (!isShowFilter) {
			setTimeout(() => {
				setIsFilter(isShowFilter);
			}, 500);
		}
	}, [isShowFilter]);

	const stateInfo = [
		{
			id: 1,
			name: 'Решения маслихата города Косшы "Об утверждении норм образования и накопления коммунальных отходов по городу Косшы"',
			status: "Заключение отправлено",
			organ: "Акимат города Косшы",
		},
		{
			id: 2,
			name: "Решения маслихата Павлодарской области «О внесении изменений в решение Павлодарского областного маслихата от 22 апреля 2021 года № 23/3 «Об утверждении Правил содержания животных в Павлодарской области»",
			status: "Заключение отправлено",
			organ: "Акимат города Павлодар",
		},
		{
			id: 3,
			name: 'Решения маслихата Костанайского района Костанайской области "Об утверждении Правил проведения раздельных сходов местного сообщества и количественного состава представителей жителей улиц, многоквартирных жилых домов для участия в сходе местного сообщества Мичуринского сельского округа Костанайского района Костанайской области"',
			status: "Новый",
			organ: "Аппарат акима Костанайского района (Костанайская область)",
		},
	];

	const [state, setState] = useState(stateInfo);
	const indexOfLastRecord = currentPage * recordsPerPage;
	const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
	const currentRecords = state.slice(indexOfFirstRecord, indexOfLastRecord);
	const nPages = Math.ceil(state.length / recordsPerPage);
	const handleRestore = (id) => {
		setIsShowRestoreModal(id);
		const index = isDeleted.indexOf(id);
		if (index > -1) {
			isDeleted.splice(index, 1);
		}
	};

	const stateTable = currentRecords.map((item, index) => {
		return (
			<tr className={"table_body"}>
				<td
					className={isDeleted.includes(item.id) && "superadmin_deleted-info"}
				>
					{item.id}
				</td>
				<td>
					{isDeleted.includes(item.id) ? (
						<p className="superadmin_deleted-info">
							<span>Удален</span> <br /> {item.name}
						</p>
					) : (
						<p>{item.name}</p>
					)}
				</td>
				<td
					className={isDeleted.includes(item.id) && "superadmin_deleted-info"}
				>
					{item.status}
				</td>
				<td
					className={isDeleted.includes(item.id) && "superadmin_deleted-info"}
				>
					{item.organ}
				</td>
				<td className="table_actions">
					{isDeleted.includes(item.id) ? (
						<a
							onClick={() => {
								handleRestore(item.id);
							}}
						>
							{IconsContent.Restore()}
						</a>
					) : (
						<>
							<a onClick={() => setIsShowModal(item.id)}>
								{IconsContent.Delete()}
							</a>
							<Link to={`/superadministrator/projects/${item.id}`} href="#">
								{IconsContent.Eye()}
							</Link>
						</>
					)}
				</td>
			</tr>
		);
	});

	return (
		<div className={"projects_page"}>
			<div className="header_wrapper_name">
				<h1>Проекты</h1>
			</div>
			<main className="c-card">
				<div className="main_header">
					<button
						className={"btn_filter"}
						onClick={() => {
							setIsFilter(true);
						}}
					>
						<span className="name">Фильтр</span>
						{IconsContent.Filter()}
					</button>
					<div className={"btn_search"}>
						{IconsContent.Search()}
						<input type="email" placeholder={"Поиск..."} />
					</div>
					{/* <div className="btn_export">Экспорт в Excel</div> */}
				</div>
				<div className="table_wrapper page_coordinator">
					<table className={"table_styling"}>
						<thead>
							<tr className={"table_heading"}>
								<TableFirst state={state} setState={setState} />
								<td>НАИМЕНОВАНИЕ ПРОЕКТА НОРМАТИВНОГО ПРАВОВОГО АКТА</td>
								<td>СТАТУС</td>
								<td colSpan={2} className={"last"}>
									ОРГАН-РАЗРАБОТЧИК
								</td>
							</tr>
						</thead>
						<tbody>{stateTable}</tbody>
					</table>
					<div className="table_wrapper_footer">
						<div className="footer_number">
							<p>
								Найдено {filteredNumber} из {fullNumber}
							</p>
						</div>
						<div className="footer_pagination">
							<Pagination
								nPages={nPages}
								currentPage={currentPage}
								setCurrentPage={setCurrentPage}
							/>
						</div>
					</div>
				</div>
			</main>

			{isFilter && (
				<div className={`modal_filter`}>
					<div
						className={`modal_content ${!isShowFilter ? `disActive` : null}`}
					>
						<div className="modal_header">
							<div className="header_word">
								<h5>Фильтр</h5>
								{IconsContent.Filter("#4ABD9B", `2`)}
							</div>
							<button
								onClick={() => {
									setIsShowFilter(false);
								}}
							>
								{IconsContent.Cancel()}
							</button>
						</div>
						<div className="modal_form">
							<form action="">
								<div className="input_wrapper">
									<label htmlFor="">ID</label>
									<input type="text" />
								</div>
								<div className="input_wrapper">
									<label htmlFor="">
										Наименование проекта нормативного правового акта
									</label>
									<input type="text" />
								</div>
								<div className="input_wrapper">
									<label htmlFor="">Статус</label>
									<Select
										options={options}
										classNamePrefix="custom-select"
										placeholder="Выберите статус"
									/>
								</div>
								<div className="input_wrapper">
									<label htmlFor="">Отрасль законодательства</label>
									<Select
										options={options}
										classNamePrefix="custom-select"
										placeholder="Выберите отрасль"
									/>
								</div>
								<div className="input_wrapper">
									<label htmlFor="">Ответственный координатор</label>
									<Select
										options={options}
										classNamePrefix="custom-select"
										placeholder="Выберите координатора"
									/>
								</div>
								<div className="input_wrapper">
									<label htmlFor="">Город/Регион</label>
									<Select
										options={options}
										classNamePrefix="custom-select"
										placeholder="Выберите регион или город"
									/>
								</div>
								<div className="input_wrapper">
									<label htmlFor="">Тип документа</label>
									<Select
										options={options}
										classNamePrefix="custom-select"
										placeholder="Выберите тип документа"
									/>
								</div>
								<div className="input_wrapper ">
									<label htmlFor="">Эксперт</label>
									<Select
										options={options}
										classNamePrefix="custom-select"
										placeholder="Выберите"
									/>
								</div>
								<div className="input_wrapper ">
									<label htmlFor="">Орган разработчик</label>
									<Select
										options={options}
										classNamePrefix="custom-select"
										placeholder="Выберите"
									/>
								</div>
								<div className="input_wrapper ">
									<label htmlFor="">Область/ГРЗ</label>
									<Select
										options={options}
										classNamePrefix="custom-select"
										placeholder="Выберите область или ГРЗ"
									/>
								</div>
								<div className="input_wrapper ">
									<label htmlFor="">Крайний срок выполнения</label>
									<DatePickerInput />
								</div>
								<div className="input_wrapper ">
									<label htmlFor="">Период (дата подписи экспертом)</label>
									<div className="date-input__range">
										<p>с</p>
										<DatePickerInput startDate={true} />
										<p>до</p>
										<DatePickerInput endDate={true} />
									</div>
								</div>
								<div className="input_wrapper ">
									<label htmlFor="">Период (дата опубликования)</label>
									<div className="date-input__range">
										<p>с</p>
										<DatePickerInput startDate={true} />
										<p>до</p>
										<DatePickerInput endDate={true} />
									</div>
								</div>
								<div className="input_wrapper ender">
									<label htmlFor="">Опубликован</label>
									<Select
										options={[
											{ value: "yes", label: "Да" },
											{ value: "no", label: "Нет" },
										]}
										classNamePrefix="custom-select"
										placeholder="Да"
									/>
								</div>
								<div className="filter__ender">
									<button className={"submit"}>Применить</button>
									<button className={"cancel"}>Сбросить</button>
								</div>
							</form>
						</div>
					</div>
					<div
						className="modal_cover"
						onClick={() => {
							setIsShowFilter(false);
						}}
					/>
				</div>
			)}
			{
				<>
					{state.map((item, index) => (
						<div
							className={
								isShowModal === item.id
									? "transmittal-letters__modal transmittal-letters__modal-active"
									: "transmittal-letters__modal"
							}
							onClick={() => setIsShowModal(-1)}
						>
							<div
								className="transmittal-letters__modal-content"
								onClick={(e) => e.stopPropagation()}
							>
								<p className="transmittal-letters__modal-heading">
									<span>Удаление пользователя</span>
									<span onClick={() => setIsShowModal(-1)}>
										{IconsContent.Close()}
									</span>
								</p>
								{isDeleted.includes(item.id) ? (
									<h6>Проект {item.name} успешно удален</h6>
								) : (
									<h6>Вы уверены, что хотите удалить проект {item.name}?</h6>
								)}
								<div className="btn_action btn_action-admin">
									{isDeleted.includes(item.id) ? null : (
										<button
											className="btn_finalize"
											onClick={() => setIsDeleted([...isDeleted, item.id])}
										>
											Удалить
										</button>
									)}
									<button
										className="btn_assign"
										onClick={() => {
											setIsShowModal(-1);
										}}
									>
										Закрыть
									</button>
								</div>
							</div>
						</div>
					))}
				</>
			}
			{
				<>
					{state.map((item, index) => (
						<div
							className={
								isShowRestoreModal === item.id
									? "transmittal-letters__modal transmittal-letters__modal-active"
									: "transmittal-letters__modal"
							}
							onClick={() => setIsShowRestoreModal(-1)}
						>
							<div
								className="transmittal-letters__modal-content"
								onClick={(e) => e.stopPropagation()}
							>
								<p className="transmittal-letters__modal-heading">
									<span>Восстановление проекта</span>
									<span onClick={() => setIsShowRestoreModal(-1)}>
										{IconsContent.Close()}
									</span>
								</p>
								<h6>Проект {item.name} успешно восстановлен</h6>
								<div className="btn_action btn_action-admin">
									<button
										className="btn_assign"
										onClick={() => {
											setIsShowRestoreModal(-1);
										}}
									>
										Закрыть
									</button>
								</div>
							</div>
						</div>
					))}
				</>
			}
		</div>
	);
}
